import PropTypes from 'prop-types';

const Dash = ({ fill, ...rest }) => (
  <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M15 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1C0 1.26522 0.105357 1.51957 0.292893 1.70711C0.48043 1.89464 0.734784 2 1 2H15C15.2652 2 15.5196 1.89464 15.7071 1.70711C15.8946 1.51957 16 1.26522 16 1C16 0.734784 15.8946 0.48043 15.7071 0.292893C15.5196 0.105357 15.2652 0 15 0Z"
      fill={fill}
    />
  </svg>
);

Dash.propTypes = {
  fill: PropTypes.string,
};

Dash.defaultProps = {
  fill: '#fff',
};

export { Dash };
