import { get, isEmpty } from 'lodash';
import styled from 'styled-components';

import { form, P } from '../../../ui-library';
import { useCalculateCargoMetrics } from '../../../../hooks/use-calculate-cargo-metrics';
import { useTemplateState } from '../../../template-context';

const { useFormState } = form;

const CargoItemSummary = () => {
  const { watch } = useFormState();

  const { apiFields } = useTemplateState();

  const values = watch();
  const { items = [] } = values;

  const itemVolumeUOM = get(values, apiFields.itemVolumeUOM);
  const itemWeightUOM = get(values, apiFields.itemWeightUOM);

  const { quantity, weight, volume } = useCalculateCargoMetrics(items);

  if (isEmpty(items)) {
    return <P>You have not yet added any cargo items to this booking request.</P>;
  }

  return (
    <SummaryWrapper>
      <P>Quantity: {quantity}</P>
      {!!itemWeightUOM && (
        <P>
          Weight: {weight} {itemWeightUOM}
        </P>
      )}
      {!!itemVolumeUOM && (
        <P>
          Volume: {volume} {itemVolumeUOM}
        </P>
      )}
    </SummaryWrapper>
  );
};

const SummaryWrapper = styled.div`
  display: grid;
  grid-column-gap: 2rem;
  grid-auto-flow: column;
`;

export { CargoItemSummary };
