import { animated, useSpring } from 'react-spring';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Arrow } from '../icons';

const SelectArrow = ({ isActive, ...props }) => {
  const rotateAnimation = useSpring({ transform: isActive ? 'rotate(-180deg)' : 'rotate(0deg)' });

  return (
    <RotationContainer style={rotateAnimation} {...props}>
      <Arrow />
    </RotationContainer>
  );
};

const RotationContainer = styled(animated.div)`
  height: 1em;
  margin-left: 0.5em;
  width: 1em;
  position: relative;
  right: ${({ theme }) => theme.space.xs};

  path {
    fill: ${({ theme }) => theme.colors.greySeven};
  }

  &:hover {
    path {
      fill: ${({ theme }) => theme.colors.interactionSix};
    }
  }
`;

SelectArrow.propTypes = {
  isActive: PropTypes.bool,
};

SelectArrow.defaultProps = {
  isActive: false,
};

export { SelectArrow };
