import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {
  Box,
  Breadcrumbs,
  constants,
  Content,
  ContentHeaderWrapper,
  DetailsList,
  H2,
  icons,
  NotFound,
  usePageTitle,
} from '../../components/ui-library';
import { ContractPermissions } from '../../components/contract-permissions';
import { HeaderBar } from '../../components/header-bar';
import { useRootStore } from '../../components/store-provider/lib';

const { DetailsTitle, DetailsListItem } = DetailsList;
const { LoadingIndicator } = icons;

const breadCrumbTrail = {
  title: 'User Details',
  ancestors: [
    {
      title: 'Manage Users',
      url: '/users',
    },
  ],
};

const UserProfile = () => {
  const { userId } = useParams();

  const { UserStore } = useRootStore();

  const { isFetchingUser, selected, fetchUser } = UserStore;

  useEffect(() => {
    fetchUser(userId);
  }, [fetchUser, userId]);

  usePageTitle(selected ? `${selected.FullName}'s Profile` : 'User Profile');

  return (
    <>
      <HeaderBar>
        <Breadcrumbs entry={breadCrumbTrail} />
      </HeaderBar>
      <Content maxWidth={constants.globalStyles.detailsMaxWidth}>
        {isFetchingUser && <LoadingIndicator />}
        {!isFetchingUser && !selected && <NotFound type="User Details" identifier={userId} />}
        {!isFetchingUser && selected && (
          <>
            <ContentHeaderWrapper mt="1rem">
              <H2 m={0}>{selected.FullName}</H2>
              {/* @TODO: wire this up, if we can. has to come through AAD */}
              {/* <Box mb={{ xs: 10, md: 0 }}>
              <Badge
                color={constants.colors.infoNine}
                backgroundColor={constants.colors.infoOne}
                border={`1px solid ${constants.colors.infoNine}`}
              >
                Invitation Pending
              </Badge>
            </Box> */}
            </ContentHeaderWrapper>
            <Box>
              <DetailsTitle>Profile</DetailsTitle>
              <DetailsListItem title="Email">{selected.EmailAddress}</DetailsListItem>
              <DetailsListItem title="Organisation">{selected.AccountName}</DetailsListItem>
            </Box>
            <Box>
              <DetailsTitle mt="2rem">Contract Permissions</DetailsTitle>
              <ContractPermissions />
            </Box>
          </>
        )}
      </Content>
    </>
  );
};

const ObservedUserProfile = observer(UserProfile);

export { ObservedUserProfile as UserProfile };
