import { Checkbox, form } from '../../../ui-library';

const { useFormState } = form;

const ResetCheckbox = () => {
  const { watch, setValue } = useFormState();

  const { dangerousGoodsIndicator, overDimensionalFlag, urgentFlag } = watch();

  const value = !dangerousGoodsIndicator && !overDimensionalFlag && !urgentFlag;

  const handleOnChange = () => {
    setValue('dangerousGoodsIndicator', false);
    setValue('overDimensionalFlag', false);
    setValue('urgentFlag', false);
  };

  return <Checkbox value={value} onChange={handleOnChange} label="None of the above" />;
};

export { ResetCheckbox };
