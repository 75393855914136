import { Controller } from 'react-hook-form';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import { Button, Flex, form, P } from '../ui-library';
import { DeleteItemModal } from '../delete-item-modal';
import { ItemForm } from './item-form';
import { ItemModal } from '../item-modal';
import { SelectAllButton } from '../select-all-button';
import { SKUTable } from '../sku-table';
import { useHydrateFormValue } from '../booking-form/use-hydrate-form-value';
import { useItemSelect } from '../../hooks/use-item-select';
import { useModal } from '../../hooks/use-modal';
import { useModalState } from '../modal-context';
import { useRootStore } from '../store-provider/lib';

const { ErrorLabel, Form, useFormState } = form;

const SKUListing = () => {
  const field = 'skuDetails';

  const { modalContainer } = useModalState();

  const { ToastStore } = useRootStore();
  const { control, getError } = useFormState();

  const { closeModal, isModalOpen, openModal } = useModal();

  useHydrateFormValue(field, []);

  const error = getError(field);

  const {
    activeItem,
    clearSelection,
    handleAddItem,
    handleRemoveItems,
    handleSubmit,
    isAllSelected,
    selectedItems,
    setActiveItemIndex,
    toggleAll,
    toggleItem,
  } = useItemSelect(field);

  return (
    <Controller
      control={control}
      name={field}
      render={({ value = [], onBlur, onChange }) => {
        const hasItemsSelected = selectedItems.length > 0;

        const duplicateItems = () => {
          const newValues = handleAddItem({
            [field]: selectedItems.map((index) => {
              return value[index];
            }),
          });

          onChange(newValues);
        };

        return (
          <Wrapper>
            <div>
              <ListingStatus alignItems="center" color="greySeven" mb={3}>
                {hasItemsSelected && (
                  <P>
                    {selectedItems.length} {selectedItems.length === 1 ? 'item' : 'items'} selected
                  </P>
                )}
              </ListingStatus>
              {hasItemsSelected ? (
                <Flex>
                  <Button
                    variant="tertiary"
                    minWidth="140px"
                    mr={2}
                    onClick={() => {
                      duplicateItems();
                      clearSelection();
                      onBlur();
                    }}
                  >
                    Duplicate
                  </Button>
                  <DeleteItemModal
                    message={`Delete ${selectedItems.length} SKU ${selectedItems.length === 1 ? 'item' : 'items'}?`}
                    onDeleteItem={() => {
                      onChange(handleRemoveItems());

                      ToastStore.addToast({
                        variant: 'success',
                        description: `${selectedItems.length} ${selectedItems.length === 1 ? 'item' : 'items'} deleted`,
                      });

                      clearSelection();
                      onBlur();
                    }}
                  />
                </Flex>
              ) : (
                <Button
                  minWidth="220px"
                  variant="secondary"
                  onClick={() => {
                    setActiveItemIndex(null);
                    openModal();
                  }}
                >
                  Add Item
                </Button>
              )}
            </div>
            {error && (
              <div mb={0}>
                <ErrorLabel>{error}</ErrorLabel>
              </div>
            )}
            {value.length > 0 && (
              <SKUTable
                items={value}
                onClick={(index) => {
                  setActiveItemIndex(index);
                  openModal();
                }}
                SelectAllButton={
                  <SelectAllButton
                    onClick={toggleAll}
                    hasItemsSelected={hasItemsSelected}
                    isAllSelected={isAllSelected}
                  />
                }
                selectedItems={selectedItems}
                toggleItem={toggleItem}
              />
            )}
            {!!modalContainer &&
              createPortal(
                <Form
                  name="skuDetails"
                  onSubmit={(data) => {
                    onChange(handleSubmit(data));
                    onBlur();
                    closeModal();
                  }}
                >
                  <ItemModal
                    buttonText={activeItem === null ? 'Add Item' : 'Save Item'}
                    title={activeItem === null ? 'Add SKU Item' : 'Edit SKU Item'}
                    isModalOpen={isModalOpen}
                    closeModal={closeModal}
                  >
                    <ItemForm activeItem={activeItem} />
                  </ItemModal>
                </Form>,
                modalContainer
              )}
          </Wrapper>
        );
      }}
    />
  );
};

const ListingStatus = styled(Flex)`
  ${P} {
    font-size: ${({ theme }) => theme.fontSize.small};
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  grid-column: 1 / -1;
`;

export { SKUListing };
