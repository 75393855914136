const deliveryStatuses = {
  created: {
    key: 'Created',
    label: 'Created',
    variant: 'created',
  },
  processing: {
    key: 'Processing',
    label: 'Processed',
    variant: 'processing',
  },
  inTransit: {
    key: 'In Transit',
    label: 'In Transit',
    variant: 'inTransit',
  },
  lastMile: {
    key: 'Last Mile',
    label: 'Last Mile',
    variant: 'lastMile',
  },
  delivered: {
    key: 'Delivered',
    label: 'Delivered',
    variant: 'delivered',
  },
  cancelled: {
    key: 'Cancelled',
    label: 'Cancelled',
    variant: 'cancelled',
  },
};

const caseStatuses = {
  awaitingYou: {
    key: 'awaiting-you',
    label: 'Awaiting You',
    variant: 'awaitingYou',
  },
  awaitingLinfox: {
    key: 'awaiting-linfox',
    label: 'Awaiting Linfox',
    variant: 'awaitingLinfox',
  },
};

export { caseStatuses, deliveryStatuses };
