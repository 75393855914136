import PropTypes from 'prop-types';

const ArrowFilled = ({ fill, ...rest }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...rest} fill={fill}>
    <path d="M3.66667 6.66659L17 6.66659L10.3333 13.3333L3.66667 6.66659Z" />
  </svg>
);

ArrowFilled.propTypes = {
  fill: PropTypes.string,
};

ArrowFilled.defaultProps = {
  fill: '#fff',
};

export { ArrowFilled };
