import { max } from 'date-fns';
import styled from 'styled-components';

import { BookingFormRow } from '../booking-form-row';
import { DateTimeWindowPicker } from '../date-time-window-picker';
import { DottedDivider } from '../dotted-divider';
import { form } from '../../ui-library';
import { useTemplateState } from '../../template-context';

const { useFormState } = form;

const now = new Date();

const Schedule = () => {
  const { apiFields } = useTemplateState();

  const { watch } = useFormState();

  const fromValue = watch(apiFields.pickUpFromDateTime);

  const fromDate = fromValue ? new Date(fromValue) : null;

  return (
    <ScheduleWrapper>
      <BookingFormRow mb={0}>
        <DateTimeWindowPicker from="pickUpFromDateTime" to="pickUpToDateTime" earliestDate={now} />
      </BookingFormRow>
      <DottedDivider ml={7} />
      <BookingFormRow mb={0}>
        <DateTimeWindowPicker
          from="deliveryFromDateTime"
          to="deliveryToDateTime"
          earliestDate={max([fromDate, now].filter(Boolean))}
        />
      </BookingFormRow>
    </ScheduleWrapper>
  );
};

const ScheduleWrapper = styled.div`
  display: grid;
  grid-row-gap: 2rem;
`;

export { Schedule };
