import { get } from 'lodash';
import { observer } from 'mobx-react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { ActionBar } from '../../../components/action-bar';
import { AddressString } from '../../../components/address-string';
import { BookingCell } from '../../../components/booking-cell';
import { BookingInformation } from '../booking-information';
import { BookingSpecialConsiderations } from '../../../components/booking-special-considerations';
import { Box, constants, Content, Flex, P, StatusLabel } from '../../../components/ui-library';
import { CargoListingsSummary } from '../cargo-listings-summary';
import { CargoTable } from '../../../components/cargo-table';
import { DetailField } from '../../../components/detail-field';
import { EmptyResponse } from '../../../components/empty-response';
import { formatDate } from '../../../utils/format-date';
import { Link } from '../../../components/link';
import { ListItem } from '../../../components/booking-form/list-item';
import { makeIsBookingSectionAvailable } from '../../../utils/make-is-booking-section-available';
import { ReviewButtonBar } from '../review-button-bar';
import { SectionHeader } from '../section-header';
import { SupportingDocumentsList } from '../supporting-documents-list';
import { TimeWindow } from '../../../components/time-window';
import { useDetailContext } from '../../../components/detail-context';
import { useRootStore } from '../../../components/store-provider/lib';

const transformDateTime = (date) => formatDate(date, 'D MMM YYYY, h:mm A');

const BookingDetailScreen = () => {
  const history = useHistory();
  const { pathname } = useLocation();

  const {
    BookingStore: { duplicateBooking, submitBooking },
  } = useRootStore();

  const { data, normalisedFields, bookingID, isDraftBooking } = useDetailContext();

  // Handling if a user somehow accesses the /bookings/[bookingID] route when its in draft mode
  if (isDraftBooking && !pathname.startsWith('/bookings/review')) {
    history.push(`/bookings/draft/${bookingID}`);
  }

  const handleOnDuplicate = async () => {
    const newBookingID = await duplicateBooking({ bookingID });

    if (newBookingID) {
      history.push(`/bookings/draft/${newBookingID}`);
    }
  };

  const handleOnEdit = () => {
    history.push(`/bookings/draft/${bookingID}`);
  };

  const handleOnSubmit = async () => {
    const submitted = await submitBooking(data);

    if (submitted) {
      history.push(`/bookings/${bookingID}`);
    }
  };

  const isBookingSectionAvailable = makeIsBookingSectionAvailable(normalisedFields);

  return (
    <>
      {!isDraftBooking && <ActionBar handleOnDuplicate={handleOnDuplicate} />}
      <BookingDetailsContent maxWidth={constants.globalStyles.wideDetailsMaxWidth} mb={isDraftBooking && '3rem'}>
        <BookingDetails>
          {!isDraftBooking && (
            <DoubleSection>
              <BookingInformation>
                <BookingCell
                  header={
                    <StatusLabel type="booking" variant={data.bookingStatus}>
                      {data.bookingStatus}
                    </StatusLabel>
                  }
                  icon="Status"
                >
                  <P>{transformDateTime(data.creationDateTime)}</P>
                </BookingCell>
                {/* Do we need to check if they have permissions to access this link? */}
                <BookingCell header="Shipment ID" icon="Info">
                  {data.shipmentNumber ? <Link to={`/track/${data.shipmentNumber}`}>{data.shipmentNumber}</Link> : '-'}
                </BookingCell>
              </BookingInformation>
            </DoubleSection>
          )}
          {isBookingSectionAvailable('booking-details') && (
            <DoubleSection>
              <SectionHeader icon="Clipboard">Booking Details</SectionHeader>
              <BookingInformation>
                <BookingCell header="Overview" icon="Info">
                  <P>{data.contractName}</P>
                  <P>{data.serviceName}</P>
                  <P>{data.commodityName}</P>
                  <BookingSpecialConsiderations pt={2} data={data} />
                </BookingCell>
                <BookingCell header="Requester" icon="Profile">
                  <P>{data.createdBy}</P>
                </BookingCell>
              </BookingInformation>
            </DoubleSection>
          )}

          {isBookingSectionAvailable('schedule') && (
            <DoubleSection>
              <SectionHeader icon="Clock">Schedule</SectionHeader>
              <BookingInformation>
                <BookingCell header="Pick up Window" icon="PickUp">
                  <P>
                    <TimeWindow
                      from={get(data, 'pickupDetails.pickupFromDateTime')}
                      to={get(data, 'pickupDetails.pickupToDateTime')}
                    />
                  </P>
                </BookingCell>
                <BookingCell header="Delivery Window" icon="Delivery">
                  <P>
                    <TimeWindow
                      from={get(data, 'deliveryDetails.deliveryFromDateTime')}
                      to={get(data, 'deliveryDetails.deliveryToDateTime')}
                    />
                  </P>
                </BookingCell>
              </BookingInformation>
            </DoubleSection>
          )}

          {isBookingSectionAvailable('pick-up') && (
            <SingleSection>
              <SectionHeader icon="PickUp">Pick up</SectionHeader>
              <BookingInformation>
                <BookingCell header="Location" icon="LocationMarker">
                  <P>
                    <AddressString location={get(data, 'pickupDetails.location')} />
                  </P>
                  {/* TODO: add lat/lng */}
                </BookingCell>
                <BookingCell header="Contact Person" icon="Messages">
                  {get(data, 'pickupDetails.contact') ? (
                    <P>
                      {data.pickupDetails.contact.contactName} &mdash; {data.pickupDetails.contact.phone}
                    </P>
                  ) : (
                    <EmptyResponse />
                  )}
                </BookingCell>
                <DetailField field="pickUpReference">
                  {({ label, value }) => (
                    <BookingCell header={label} icon="Info">
                      {value ? <P>{value}</P> : <EmptyResponse />}
                    </BookingCell>
                  )}
                </DetailField>
                <DetailField field="pickUpInstructions">
                  {({ label, value }) => (
                    <BookingCell header={label} icon="Info" color={value ? 'successTen' : null}>
                      {value ? <P>{value}</P> : <EmptyResponse />}
                    </BookingCell>
                  )}
                </DetailField>
              </BookingInformation>
            </SingleSection>
          )}

          {isBookingSectionAvailable('delivery') && (
            <SingleSection>
              <SectionHeader icon="Delivery">Delivery</SectionHeader>
              <BookingInformation>
                <BookingCell header="Location" icon="LocationMarker">
                  <P>
                    <AddressString location={get(data, 'deliveryDetails.location')} />
                  </P>
                  {/* TODO: add lat/lng */}
                </BookingCell>
                <BookingCell header="Contact Person" icon="Messages">
                  {get(data, 'deliveryDetails.contact') ? (
                    <P>
                      {data.deliveryDetails.contact.contactName} &mdash; {data.deliveryDetails.contact.phone}
                    </P>
                  ) : (
                    <EmptyResponse />
                  )}
                </BookingCell>
                <DetailField field="deliveryReference">
                  {({ label, value }) => (
                    <BookingCell header={label} icon="Info">
                      {value ? <P>{value}</P> : <EmptyResponse />}
                    </BookingCell>
                  )}
                </DetailField>
                <DetailField field="deliveryInstructions">
                  {({ label, value }) => (
                    <BookingCell header={label} icon="Info" color={value ? 'successTen' : null}>
                      {value ? <P>{value}</P> : <EmptyResponse />}
                    </BookingCell>
                  )}
                </DetailField>
              </BookingInformation>
            </SingleSection>
          )}

          {isBookingSectionAvailable('rail-transport') && (
            <DoubleSection>
              <SectionHeader icon="Paperclip">Rail Transport</SectionHeader>
              <BookingInformation>
                <DetailField field="railOrigin">
                  {({ label, value }) => (
                    <BookingCell header={label} icon="LocationMarker">
                      {value ? <P>{value}</P> : <EmptyResponse />}
                    </BookingCell>
                  )}
                </DetailField>
                <DetailField field="railDestination">
                  {({ label, value }) => (
                    <BookingCell header={label} icon="LocationMarker">
                      {value ? <P>{value}</P> : <EmptyResponse />}
                    </BookingCell>
                  )}
                </DetailField>
                <DetailField field="railDepartureDateTime">
                  {({ label, value }) => (
                    <BookingCell header={label} icon="Clock">
                      {value ? <P>{transformDateTime(value)}</P> : <EmptyResponse />}
                    </BookingCell>
                  )}
                </DetailField>
              </BookingInformation>
            </DoubleSection>
          )}

          {isBookingSectionAvailable('cargo') && (
            <DoubleSection>
              <SectionHeader icon="Cargo">Cargo</SectionHeader>
              <BookingInformation isMultiColumn={false}>
                <Flex px={4} py={2} width="100%" flexDirection="column">
                  {get(data, 'items') ? (
                    <>
                      <Box mb={4}>
                        <CargoListingsSummary items={data.items} />
                      </Box>
                      <CargoTable normalisedFields={normalisedFields} items={data.items} />
                    </>
                  ) : (
                    <Flex justifyContent="center" color="greyFive">
                      No cargo items added
                    </Flex>
                  )}
                </Flex>
              </BookingInformation>
            </DoubleSection>
          )}

          {isBookingSectionAvailable('special-equipment') && (
            <DoubleSection>
              <SectionHeader icon="Toolbox">Special Equipment</SectionHeader>
              <BookingInformation isMultiColumn={false}>
                {get(data, 'equipments') ? (
                  <DualColumn width="100%" flexDirection="column">
                    {data.equipments.map((item, index) => {
                      // we don't know which fields each special equipment will have, so try and fetch both

                      const specialEquipment = get(item, 'packingEquipment') || get(item, 'specialisedHandling');
                      const notes = get(item, 'notePackingEquipment') || get(item, 'noteSpecialHandling');

                      return (
                        <ListItem
                          // eslint-disable-next-line react/no-array-index-key
                          key={`specialEquipment-${index}`}
                          name={specialEquipment}
                          description={notes}
                        />
                      );
                    })}
                  </DualColumn>
                ) : (
                  <Flex justifyContent="center" color="greyFive" py={2}>
                    No special equipment requested
                  </Flex>
                )}
              </BookingInformation>
            </DoubleSection>
          )}

          {isBookingSectionAvailable('supporting-documents') && (
            <DoubleSection>
              <SectionHeader icon="Paperclip">Supporting Documents</SectionHeader>
              <BookingInformation isMultiColumn={false}>
                <DualColumn width="100%" flexDirection="column">
                  <SupportingDocumentsList bookingID={bookingID} />
                </DualColumn>
              </BookingInformation>
            </DoubleSection>
          )}
        </BookingDetails>
      </BookingDetailsContent>

      {isDraftBooking && <ReviewButtonBar onSubmit={handleOnSubmit} onEdit={handleOnEdit} />}
    </>
  );
};

const BookingDetailsContent = styled(Content)`
  background-color: ${({ theme }) => theme.global.backgroundColor};
`;

const BookingDetails = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(28rem, 1fr));
  grid-template-rows: auto;

  ${P} ~ ${P} {
    margin-top: ${({ theme }) => theme.space.xxs};
  }
`;

const Row = styled.div`
  display: grid;
  grid-template-rows: max-content;
`;

const SingleSection = styled(Row)`
  grid-column: span 1;
`;

const DoubleSection = styled(Row)`
  grid-column: 1 / -1;
`;

const DualColumn = styled(Row)`
  padding: 1rem 2rem;
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr;
`;

const ObservedBookingDetailScreen = observer(BookingDetailScreen);
export { ObservedBookingDetailScreen as BookingDetailScreen };
