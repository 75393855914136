import { get } from 'lodash';
import { useEffect, useState } from 'react';

import { useRootStore } from '../../components/store-provider/lib';

const useCaseListingTemplate = () => {
  const { CaseStore, AuthStore } = useRootStore();

  const { getTemplate } = CaseStore;
  const { isFetching, isSignedIn, user } = AuthStore;
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (!isSignedIn || isFetching) return;

    const fetchTemplate = async () => {
      const templateData = await getTemplate({ accountID: user.AccountID });

      setColumns(get(templateData, 'listingTemplateJSON.fields') || []);
    };

    fetchTemplate();
  }, [getTemplate, isFetching, isSignedIn, user]);

  return { columns };
};

export { useCaseListingTemplate };
