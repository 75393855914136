import { createContext, useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { useFilterContext } from '../filter';
import { useRootStore } from '../../components/store-provider/lib';

const CaseListingContext = createContext();

const { Provider } = CaseListingContext;

const CaseListingProvider = ({ children }) => {
  const { filters, parameters, setFilters } = useFilterContext();

  const { CaseStore } = useRootStore();

  const { loadCases } = CaseStore;

  const clearResults = useRef(false);

  const [cases, setCases] = useState([]);

  useEffect(() => {
    const fetchCases = async () => {
      const caseData = await loadCases(parameters);

      if (clearResults.current) {
        setCases(caseData);
      } else {
        setCases([...cases, ...caseData]);
      }

      clearResults.current = true;
    };

    if (clearResults.current) {
      setCases([]);
      setFilters({ start: 0 });
    }

    fetchCases();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameters.toString()]);

  const loadMore = () => {
    clearResults.current = false;
    setFilters({ start: Number(filters.start || 0) + Number(filters.limit) });
  };

  return <Provider value={{ cases, loadMore }}>{children}</Provider>;
};

const useCaseListingContext = () => {
  const context = useContext(CaseListingContext);

  if (context === undefined) {
    throw new Error('Component must be used within a CaseListingProvider.');
  }

  return context || {};
};

CaseListingProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { CaseListingProvider, useCaseListingContext };
