import { Controller } from 'react-hook-form';
import { DateUtils } from 'react-day-picker';
import PropTypes from 'prop-types';

import { Calendar } from '../../../calendar';
import { useFormState } from '../..';

const CalendarRange = ({ name, validation }) => {
  const { control } = useFormState();

  const handleDayClick = (onChange, value) => (day) => {
    let { from, to } = value;

    if (from && to) {
      from = null;
      to = null;
    }

    onChange(DateUtils.addDayToRange(day, { from, to }));
  };

  return (
    <Controller
      control={control}
      name={name}
      validation={validation}
      render={({ onChange, value }) => {
        const modifiers = { start: value.from, end: value.to };

        return <Calendar modifiers={modifiers} onChange={handleDayClick(onChange, value)} value={value} />;
      }}
    />
  );
};

CalendarRange.propTypes = {
  name: PropTypes.string.isRequired,
  validation: PropTypes.shape(),
};

CalendarRange.defaultProps = {
  validation: null,
};

export { CalendarRange };
