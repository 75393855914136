import { animated, useTransition } from 'react-spring';
import { noop } from 'lodash';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box, Flex } from '../grid';
import { Cross } from '../icons';
import { useOverlay } from '../overlay';

const ESCAPE = 27;

const Modal = ({
  children,
  title,
  footer,
  height,
  isOpen,
  closeModal,
  isScrollable,
  dismissible,
  dismissOnOverlayClick,
  size,
}) => {
  const triggerClose = () => {
    // Clicking the overlay will only dismiss if open and its dismissible
    if (isOpen && dismissible) closeModal();
  };

  const triggerOverlayClick = () => {
    // Clicking the overlay will only dismiss if all conditions are true
    if (isOpen && dismissOnOverlayClick && dismissible) closeModal();
  };

  const ref = useOverlay(triggerClose, isOpen, { zIndex: 5 });

  // Handle Escape key
  useEffect(() => {
    const handler = (e) => {
      if (e.keyCode === ESCAPE) {
        e.preventDefault();
        triggerClose();
      }
    };

    window.addEventListener('keydown', handler);

    return () => {
      window.removeEventListener('keydown', handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set up Modal animations
  const transitions = useTransition(isOpen, {
    from: { transform: 'translateY(-70px)', opacity: 0 },
    enter: { transform: 'translateY(0px)', opacity: 1 },
    leave: { transform: 'translateY(-10px)', opaciry: 0 },
  });

  const wrapInTransition = (content) => {
    return transitions(
      (props, item, key) =>
        item && (
          <Dialog role="dialog" key={key} style={props} size={size}>
            {content}
          </Dialog>
        )
    );
  };

  return (
    <Box display={isOpen ? `block` : `none`}>
      <ModalContainer ref={ref}>
        <Backdrop onClick={() => triggerOverlayClick()} />
        {wrapInTransition(
          <div data-testid="modal-dialog">
            {title && (
              <Header>
                {title}
                {dismissible && (
                  <CloseButton data-testid="close-button" role="button" onClick={() => triggerClose()}>
                    <Cross />
                  </CloseButton>
                )}
              </Header>
            )}
            <Content height={height} overflowY={isScrollable && 'auto'} p={4}>
              {children}
            </Content>
            {footer && <Footer>{footer}</Footer>}
          </div>
        )}
      </ModalContainer>
    </Box>
  );
};

const ModalContainer = styled(Flex)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: start;
  padding: 0 ${({ theme }) => theme.space.sm};
  z-index: 10;
`;

const Backdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`;

const Dialog = styled(animated.div)`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  margin: auto;
  width: 100%;
  min-width: 320px;
  max-width: ${({ size }) => {
    switch (size) {
      case 'sm':
        return '480';
      case 'lg':
        return '850';
      case 'md':
      default:
        return '630';
    }
  }}px;
  z-index: 2;
`;

const Header = styled(Flex)`
  align-items: center;
  justify-content: center;
  position: relative;
  height: 60px;
  border-bottom: solid 1px ${({ theme }) => theme.colors.greyTwo};
  font-size: 20px;
  font-weight: bold;
  padding: 0 ${({ theme }) => theme.space.md};
`;

const Content = styled(Box)`
  max-height: 75vh;
`;

const Footer = styled(Flex)`
  align-items: center;
  justify-content: flex-end;
  position: relative;
  height: 60px;
  border-top: solid 1px ${({ theme }) => theme.colors.greyTwo};
  padding: ${({ theme }) => theme.space.xxs} ${({ theme }) => theme.space.md};
`;

const CloseButton = styled.div`
  position: absolute;
  top: 50%;
  right: ${({ theme }) => theme.space.md};
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  margin-top: -1rem;
  margin-right: -0.5rem;
  display: grid;
  place-items: center;

  svg {
    width: 24px;
    height: 24px;
  }

  svg path {
    fill: ${(props) => props.theme.colors.black};
  }

  &:hover {
    svg path {
      fill: ${(props) => props.theme.colors.dangerFive};
    }
  }
`;

Modal.propTypes = {
  children: PropTypes.node,
  closeModal: PropTypes.func,
  dismissible: PropTypes.bool,
  footer: PropTypes.node,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isOpen: PropTypes.bool,
  isScrollable: PropTypes.bool,
  dismissOnOverlayClick: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  title: PropTypes.string,
};

Modal.defaultProps = {
  children: null,
  closeModal: noop,
  dismissible: true,
  footer: null,
  height: 'auto',
  isOpen: false,
  isScrollable: false,
  dismissOnOverlayClick: false,
  size: 'md',
  title: '',
};

export { Modal };
