import PropTypes from 'prop-types';

import { Button, H3, Modal, ModalFooterButtonWrapper, P } from '../../ui-library';

const DeleteBookingModal = ({ onDeleteBooking, closeModal, isModalOpen }) => (
  <Modal
    size="sm"
    isOpen={isModalOpen}
    closeModal={closeModal}
    footer={
      <ModalFooterButtonWrapper closeModal={closeModal}>
        <Button onClick={onDeleteBooking} variant="destructive">
          Delete
        </Button>
      </ModalFooterButtonWrapper>
    }
  >
    <>
      <H3 as="h1" mt={0} mb={3}>
        Delete booking request?
      </H3>
      <P>You will no longer be able to refer back to this booking request. This action cannot be undone.</P>
    </>
  </Modal>
);

DeleteBookingModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  onDeleteBooking: PropTypes.func.isRequired,
};

export { DeleteBookingModal };
