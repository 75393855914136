import { get } from 'lodash';
import { useEffect } from 'react';

import { form } from '../../ui-library';
import { useTemplateState } from '../../template-context';

const { useFormState } = form;

const useHydrateFormValue = (field, defaultValue = null, prepopulateField = true) => {
  const { formData } = useTemplateState();
  const { setValue } = useFormState();

  useEffect(() => {
    if (field && prepopulateField) {
      const value = get(formData, field) || defaultValue;

      // only validate if the field is non-falsy
      // this hook runs when initially populating form values, so Required validations
      // should not trigger if the form has been resumed from an incomplete state.
      let shouldValidate = !!value;

      if (Array.isArray(value) && value.length === 0) {
        shouldValidate = false;
      }

      setValue(field, value, { shouldValidate });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export { useHydrateFormValue };
