import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { LoadingIndicator } from '../../icons';

const Option = ({ label, onClick, closeModal }) => {
  const [isFetching, setIsFetching] = useState(false);

  const handleClick = async () => {
    setIsFetching(true);
    await onClick();
    setIsFetching(false);

    closeModal();
  };

  return (
    <OptionWrapper>
      <OptionButton type="button" onClick={handleClick}>
        {label}
      </OptionButton>
      {isFetching && <LoadingIndicator />}
    </OptionWrapper>
  );
};

const OptionWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 2rem;

  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.greyThree};
  }
`;

const OptionButton = styled.button`
  padding: 16px 0;
  width: 100%;
  text-align: left;
  color: ${({ theme }) => theme.colors.greyNine};
`;

Option.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export { Option };
