// This is the responsive 1 REM unit constant

const rem = {
  sm: '12px',
  md: '16px',
  lg: '18px',
  xl: '24px',
};

export { rem };
