import { forIn, isFunction } from 'lodash';
import { types } from 'mobx-state-tree';

import { AccountStore } from '../stores/account';
import { AuthStore } from '../stores/authentication';
import { BookingStore } from '../stores/booking';
import { CaseStore } from '../stores/case';
import { SettingStore } from '../stores/setting';
import { ShipmentStore } from '../stores/shipment';
import { ToastStore } from '../stores/toast';
import { UserStore } from '../stores/user';
import DefectRequestStore from '../stores/defect-request';

const RootStore = types
  .compose(
    types.model({
      AuthStore: types.optional(AuthStore, {}),
      AccountStore: types.optional(AccountStore, {}),
      BookingStore: types.optional(BookingStore, {}),
      CaseStore: types.optional(CaseStore, {}),
      DefectRequestStore: types.optional(DefectRequestStore, {}),
      SettingStore: types.optional(SettingStore, {}),
      ShipmentStore: types.optional(ShipmentStore, {}),
      ToastStore: types.optional(ToastStore, {}),
      UserStore: types.optional(UserStore, {}),
    })
  )
  .actions((self) => ({
    clearSessionData() {
      forIn(self, (store) => {
        if (isFunction(store.clearSessionData)) {
          store.clearSessionData();
        }
      });
    },
  }));

export { RootStore };
