import { space } from 'styled-system';
import styled from 'styled-components';

import { Flex } from '../grid';
import { mobileOnly } from '../../utils/responsive-styles';

const ContentHeaderWrapper = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.space.lg};
  margin-bottom: ${({ theme }) => theme.space.xl};
  ${space}

  ${mobileOnly} {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`;

export { ContentHeaderWrapper };
