import { PropTypes } from 'prop-types';

import { Button, icons, ShowOn } from '../ui-library';
import { CloseButton } from '../shipment-milestones/styles';
import { useMapState } from './map-context';

const ShowMapComponent = ({ title, showMap }) => {
  const { Cross } = icons;
  const { setShowMap } = useMapState();

  return (
    <>
      <ShowOn screenSize="mobile">
        {title === 'Close Map' ? (
          <CloseButton onClick={() => setShowMap(showMap)}>
            <Cross fill="black" />
          </CloseButton>
        ) : (
          <Button variant="secondary" minWidth="165px" mt="4" onClick={() => setShowMap(showMap)}>
            {title}
          </Button>
        )}
      </ShowOn>
      <ShowOn screenSize="desktop">
        <Button variant="secondary" minWidth="165px" mt="4" onClick={() => setShowMap(showMap)}>
          {title}
        </Button>
      </ShowOn>
    </>
  );
};

ShowMapComponent.propTypes = {
  showMap: PropTypes.bool,
  title: PropTypes.node.isRequired,
};

ShowMapComponent.defaultProps = {
  showMap: false,
};

export { ShowMapComponent };
