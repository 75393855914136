import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box } from '../grid';

const calcPercentage = (total, count) => `${(count / total) * 100}%`;

const ProgressBar = ({ total, count }) => {
  return (
    <Wrapper>
      <Text>
        You have viewed {count} of {total} items
      </Text>
      <Bar>
        <Progress width={calcPercentage(total, count)} data-testid="progress-bar" />
      </Bar>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  margin-bottom: ${({ theme }) => theme.space.xs};
  text-align: center;
`;

const Bar = styled(Box)`
  width: 100%;
  height: 3px;
  margin-top: ${({ theme }) => theme.space.xxs};
  background: ${({ theme }) => theme.colors.greyThree};
  position: relative;
  border-radius: 3px;
`;

const Progress = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  border-radius: 3px;
  background: ${({ theme }) => theme.colors.interactionFive};
  transition: width linear 150ms;
`;

const Text = styled.span`
  font-size: ${({ theme }) => theme.fontSize.xSmall};
  color: ${({ theme }) => theme.colors.greySix};
`;

ProgressBar.propTypes = {
  total: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
};

export { ProgressBar };
