/* stylelint-disable no-descending-specificity */
import styled from 'styled-components';

import { Flex, icons, responsiveStyles } from '../ui-library';

const { mobileOnly, desktopOnly } = responsiveStyles;
const { Download } = icons;

const MILESTONE_SQUARE_HEIGHT_MOBILE = '80px';

const SQUARE_WIDTH = '25%';
const CIRCLE_SIZE_MOBILE = '24px';
const CIRCLE_SIZE = '30px';

const Wrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.white};
  min-height: 350px;
  align-items: center;
  position: relative;
  margin-bottom: ${({ theme }) => theme.space.lg};

  ${mobileOnly} {
    font-size: ${({ theme }) => theme.fontSize.small};
    height: 380px;
    ${({ minHeight }) => minHeight && 'min-height: 460px;'};
    flex-direction: column;
    margin-bottom: 20px;
  }
`;

const MapWrapper = styled.div`
  ${({ setDisplayMap }) => (setDisplayMap ? 'visibility: visible' : 'visibility: hidden')};

  width: 932px;
  height: 370px;
  top: -60px;
  z-index: 1;
  position: absolute;

  .etaTime {
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.white};
    flex-direction: end;
    font-size: 14px;
    padding: 4px 12px;
    position: absolute;
    top: 35px;
    right: 0;
  }

  ${mobileOnly} {
    width: 287px;
    height: 396px;
  }
`;

const MilestoneWrapper = styled.div`
  width: ${SQUARE_WIDTH};
  height: 255px;
  z-index: 1;
  position: absolute;
  left: calc(${SQUARE_WIDTH} * ${({ index }) => index});
  ${({ hideMilestoneDueToCancelledShipment }) => hideMilestoneDueToCancelledShipment && 'display: none;'}

  ${mobileOnly} {
    left: 0;

    ${({ index, setGap }) =>
      index && index === 3 && setGap
        ? `top: calc(calc(${MILESTONE_SQUARE_HEIGHT_MOBILE} * ${index}) + ${setGap} - 50px);`
        : `top: calc(${MILESTONE_SQUARE_HEIGHT_MOBILE} * ${index});`};

    ${({ index }) => index && index === 2 && `top: calc(calc(${MILESTONE_SQUARE_HEIGHT_MOBILE} * ${index}) + 10px);`};

    height: ${MILESTONE_SQUARE_HEIGHT_MOBILE};
    width: 100%;
  }
`;

const MilestoneInnerWrapper = styled.div`
  border-radius: 10px;
  border: 2px solid transparent;
  ${({ isCurrentMilestone, theme }) =>
    isCurrentMilestone &&
    `border-color: ${theme.colors.successNine};
     background-color: ${theme.colors.currentMilestoneBackground};`};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${mobileOnly} {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    ${({ isInTransit }) => isInTransit && 'height: 110px;'};
    ${({ isLastMile }) => isLastMile && 'height: 177px;'};
    ${({ isInTransit, isLastMile }) => !isInTransit && !isLastMile && 'height: 63px'};
  }
`;

const ColumnWrapper = styled(Flex)`
  flex-direction: column;
  height: 100%;
  padding: 5px 0;
  justify-content: space-evenly;
`;

const Circle = styled.div`
  width: ${CIRCLE_SIZE};
  height: ${CIRCLE_SIZE};
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${({ theme }) => theme.colors.greyFour};

  svg {
    fill: ${({ theme }) => theme.colors.greyFour};
  }

  ${mobileOnly} {
    width: ${CIRCLE_SIZE_MOBILE};
    height: ${CIRCLE_SIZE_MOBILE};

    > svg {
      transform: scale(0.8);
    }
  }
`;

const InPastCircle = styled(Circle)`
  background-color: ${({ theme }) => theme.colors.successNine};
  border: 2px solid ${({ theme }) => theme.colors.successNine};

  svg {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

const CancelledCircle = styled(Circle)`
  background-color: ${({ theme }) => theme.colors.dangerSix};
  border: 2px solid ${({ theme }) => theme.colors.dangerSix};
`;

const StatusWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  ${({ isCurrentMilestone }) => isCurrentMilestone && 'background: none'};
  padding: 5px;

  ${desktopOnly} {
    color: ${({ theme }) => theme.colors.successTen};
    font-size: ${({ theme }) => theme.fontSize.small};
    padding: 0 0.5rem;
    text-align: center;
    line-height: 1.5;
  }

  ${mobileOnly} {
    padding: 8px;
    padding-right: 12px;
  }
`;

const buildLineBorderStyles = ({ milestoneIsInFuture, showCancelledLine, theme }, borderDirection) => `
  border-${borderDirection}: 2px solid ${theme.colors.successNine};

  ${
    milestoneIsInFuture &&
    `border-${borderDirection}: 2px dashed ${theme.colors.greyFour};
  `
  };

  ${
    showCancelledLine &&
    `border-${borderDirection}: 2px solid ${theme.colors.dangerSix};
  `
  };
`;

const Line = styled(Flex)`
  position: absolute;
  z-index: 0;

  ${mobileOnly} {
    ${(props) => buildLineBorderStyles(props, 'left')};

    ${({ index, setGap }) =>
      index && index === 3 && setGap
        ? `top: calc(calc(${MILESTONE_SQUARE_HEIGHT_MOBILE} * calc(${index} - 1)) + ${setGap});
          height: calc(${MILESTONE_SQUARE_HEIGHT_MOBILE} - 40px);`
        : `top: calc(calc(${MILESTONE_SQUARE_HEIGHT_MOBILE} * calc(${index} - 1)) + 2px);
          height: ${MILESTONE_SQUARE_HEIGHT_MOBILE};`};

    left: calc(calc(${CIRCLE_SIZE_MOBILE} / 2) + 10px);
  }

  ${desktopOnly} {
    ${(props) => buildLineBorderStyles(props, 'top')};
    width: ${SQUARE_WIDTH};
    margin-left: calc(${`-${SQUARE_WIDTH}`} / 2);
    left: calc(${SQUARE_WIDTH} * ${({ index }) => index});
  }
`;

const SubSection = styled.div`
  height: 70px;
  display: flex;
  align-items: center;

  ${mobileOnly} {
    height: auto;
  }
`;

const DescWrapper = styled(SubSection)`
  ${mobileOnly} {
    color: ${({ theme }) => theme.colors.successTen};
    font-size: ${({ theme }) => theme.fontSize.small};
    line-height: 1.75;
  }
`;

const StyledDownloadIcon = styled(Download)`
  width: 15px;
  height: 15px;

  fill: ${({ theme }) => theme.colors.interactionSix};
`;
const DateWrapper = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.greyFive};
  font-size: ${({ theme }) => theme.fontSize.xSmall};

  ${mobileOnly} {
    flex-direction: row;
    font-size: ${({ theme }) => theme.fontSize.small};
  }
`;

const EtaWrapper = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.25;

  ${mobileOnly} {
    font-size: 1.25rem;
    line-height: 1;
    padding-top: 18px;
  }
`;

const CloseButton = styled.button`
  border: 1px solid black;
  > svg {
    width: 16px;
    height: 16px;
    margin: 0.25rem;
  }
  margin-top: 2rem;
`;

export {
  CancelledCircle,
  Circle,
  CloseButton,
  ColumnWrapper,
  DateWrapper,
  EtaWrapper,
  StyledDownloadIcon,
  InPastCircle,
  Line,
  MapWrapper,
  MilestoneInnerWrapper,
  MilestoneWrapper,
  SubSection,
  DescWrapper,
  StatusWrapper,
  Wrapper,
};
