import PropTypes from 'prop-types';
import styled from 'styled-components';

import { constants, H3 } from '../ui-library';

const CaseFormSection = ({ children, title }) => {
  return (
    <Wrapper>
      <H3>{title}</H3>
      <FieldWrapper>{children}</FieldWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: ${constants.colors.white};
  display: grid;
  border: 1px solid ${constants.colors.greyThree};
  border-radius: 4px;
  grid-gap: 1rem;
  padding: 2rem;
`;

const FieldWrapper = styled.div`
  display: grid;
  grid-column-gap: 1rem;
  grid-row-gap: 2rem;
  grid-template-columns: repeat(6, 1fr);
  padding: 1rem;
`;

CaseFormSection.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export { CaseFormSection };
