import PropTypes from 'prop-types';
import styled from 'styled-components';

import { H2, icons } from '../../../components/ui-library';

const SectionHeader = ({ children, icon }) => {
  const { [icon]: IconComponent = null } = icons;

  return (
    <BookingHeader>
      {IconComponent && <IconComponent height="2rem" width="2rem" />}
      <H2>{children}</H2>
    </BookingHeader>
  );
};

const BookingHeader = styled.div`
  align-items: center;
  display: grid;
  grid-column-gap: 1rem;
  grid-template-columns: max-content 1fr;
  padding: 1rem 3rem;
`;

SectionHeader.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.oneOf(Object.keys(icons)).isRequired,
};

export { SectionHeader };
