import { constants, icons, QuickLinks as UiLibQuickLinks } from '../ui-library';
import { useFeatureFlag } from '../../hooks/use-feature-flag';

const { contractPrivileges } = constants;

const { Search, Users, Booking, Messages } = icons;

const QuickLinks = () => {
  const links = [
    {
      enabled: useFeatureFlag('DEFECTS'),
      heading: 'Defect Repair Requisition',
      description: 'Raise a new Defect Repair Requisition (DRR)',
      linkTo: '/defects/new',
      Icon: <Messages />,
      privilegeName: contractPrivileges.defect,
    },
    {
      enabled: useFeatureFlag('TRACK_AND_TRACE'),
      heading: 'Track & Trace',
      description: 'Track the status of your delivery',
      linkTo: '/track',
      Icon: <Search />,
      privilegeName: contractPrivileges.track,
    },
    {
      enabled: useFeatureFlag('ONLINE_BOOKINGS'),
      heading: 'Bookings',
      description: 'Create a booking for your delivery',
      linkTo: '/bookings',
      Icon: <Booking />,
      privilegeName: contractPrivileges.booking,
    },
    {
      enabled: useFeatureFlag('CASE_MANAGEMENT'),
      heading: 'Cases',
      description: 'Contact your Customer Service team for assistance',
      linkTo: '/cases',
      Icon: <Messages />,
      privilegeName: contractPrivileges.caseManagement,
    },
    {
      enabled: useFeatureFlag('USER_MANAGEMENT'),
      heading: 'User Management',
      description: 'Manage users within your organisation',
      linkTo: '/users',
      Icon: <Users />,
      privilegeName: contractPrivileges.admin,
    },
  ];

  return <UiLibQuickLinks links={links} />;
};

export { QuickLinks };
