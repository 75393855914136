import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { DiscardChangesModal } from './discard-changes-modal';
import { form } from '../ui-library';

const { useFormState } = form;
const DiscardChangesContext = createContext();

const DiscardChangesProvider = ({ children }) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const [triggerDiscardChanges, setTriggerDiscardChanges] = useState(false);
  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState(false);
  const [nextLocation, setNextLocation] = useState(false);

  // Handle logic to continue navigation using a ref, as the stateful check
  // won't update in the same render that the `handlePrompt` call runs.
  const continueNavigation = useRef(false);

  useEffect(() => {
    return () => {
      continueNavigation.current = false;
    };
  });

  // using this workaround to show our custom modal component, using Prompt
  // https://michaelchan-13570.medium.com/using-react-router-v4-prompt-with-custom-modal-component-ca839f5faf39
  const handlePrompt = (location) => {
    const destinationIsNotCurrentPage = location.pathname !== pathname;

    if (destinationIsNotCurrentPage && !continueNavigation.current) {
      setNextLocation(location);
      setIsDiscardModalOpen(true);
      return false;
    }

    return true;
  };

  const handleDiscardChanges = () => {
    setIsDiscardModalOpen(false);
    setTriggerDiscardChanges(false);

    continueNavigation.current = true;

    history.push(nextLocation);
  };

  const value = {
    triggerDiscardChanges,
    setTriggerDiscardChanges,
  };

  return (
    <DiscardChangesContext.Provider value={value}>
      <Prompt when={triggerDiscardChanges} message={handlePrompt} />
      <DiscardChangesModal
        onDiscardChanges={handleDiscardChanges}
        closeModal={() => setIsDiscardModalOpen(false)}
        isModalOpen={isDiscardModalOpen}
      />
      {children}
    </DiscardChangesContext.Provider>
  );
};

const useDiscardChangesContext = () => {
  const context = useContext(DiscardChangesContext);

  if (context === undefined) {
    throw new Error('Component must be used within a DiscardChangesProvider.');
  }

  return context || {};
};

const FormDiscardChangesWrapper = ({ children }) => {
  const { triggerDiscardChanges, setTriggerDiscardChanges } = useDiscardChangesContext();
  const { isDirty } = useFormState();

  useEffect(() => {
    if (!triggerDiscardChanges && isDirty) {
      setTriggerDiscardChanges(true);
    }
  }, [triggerDiscardChanges, setTriggerDiscardChanges, isDirty]);

  return children;
};

DiscardChangesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { DiscardChangesProvider, FormDiscardChangesWrapper, useDiscardChangesContext };
