import { flow, getEnv, types } from 'mobx-state-tree';
import { get } from 'lodash';
import { reaction } from 'mobx';

import { Contract } from '../../models/user/contract';
import { filterParameters } from '../../utils/filter-parameters';
import { User } from '../../models/user';

const UserStore = types
  .model('UserStore', {
    users: types.optional(types.array(User), []),
    hasFetched: false, // used to control the initial data load
    isFetching: true,
    isFetchingUser: true,
    limit: types.optional(types.number, 10),
    parameters: types.optional(
      types.model('Parameters', {
        keyword: types.optional(types.string, ''),
      }),
      {}
    ),
    selected: types.maybe(User),
    start: types.optional(types.number, 1),
    totalResults: types.optional(types.number, 0),
  })
  .actions((self) => {
    const { api, logger, setQueryString } = getEnv(self);

    // When any parameters change, clear the current state, and begin from the first page.
    reaction(
      () => self.activeParameters,
      async () => {
        await self.getAllContacts();
      }
    );

    reaction(
      () => self.start,
      async (start) => {
        await self.getAllContacts({ start }, false);
      }
    );

    return {
      fetchUser: flow(function* (contactId) {
        self.isFetchingUser = true;

        const {
          data: { contracts = [], contact },
        } = yield api.get(`user/contact/${contactId}`);

        const mappedContracts = yield Promise.all(
          contracts.map(async (data) => {
            const contract = Contract.create({ ...data, ContactID: contactId }, getEnv(self));

            await contract.fetchContractPermissions();

            return contract;
          })
        );

        self.isFetchingUser = false;

        self.selected = { ...contact, contracts: mappedContracts };
      }),
      getAllContacts: flow(function* (overrides = {}, clear = true) {
        self.setQueryString(overrides);

        if (clear) {
          self.users = [];
          self.totalResults = 0;
          self.start = 1;
        }

        self.isFetching = true;

        const params = new URLSearchParams({ ...self.activeParameters, limit: self.limit, start: self.start });

        const response = yield api.get(`user/contacts?${params.toString()}`);

        const {
          data: {
            pagination: { count },
            contactDetails: users = [],
          },
        } = response;

        self.totalResults = count;

        self.users = [...self.users, ...users];

        self.hasFetched = true;
        self.isFetching = false;
      }),
      resetContactList: flow(function* () {
        self.users = [];
        if (self.start > 1) {
          // This will trigger a reaction, so no need to manually call the API.
          self.start = 1;
        } else {
          yield self.getAllContacts();
        }
      }),
      inviteUser: flow(function* ({ accountId, emailAddress, firstName, lastName, phoneNumber }) {
        const body = { accountId, emailAddress, firstName, lastName, phoneNumber };

        const response = yield api.post('user/invite', {
          body,
        });

        if (response.status !== 200) {
          logger.error('User could not be invited.', { data: JSON.stringify({ body, response }) });

          throw new Error('User could not be invited.');
        }

        const { contactId } = get(response, 'data.message');

        yield self.fetchUser(contactId);
      }),
      loadMore: () => {
        self.start += 1;
      },
      selectUser: (user) => {
        self.selected = user;
      },
      setParameters: (parameters) => {
        self.parameters = { ...self.parameters, ...parameters, start: 1 };
      },
      setQueryString: (overrides = {}) => {
        setQueryString({ ...self.activeParameters, ...overrides });
      },
    };
  })
  .views((self) => ({
    get activeParameters() {
      if (self.parameters.keyword) {
        return { keyword: self.parameters.keyword };
      }

      return filterParameters(self.parameters);
    },
    get keyword() {
      return self.parameters.keyword;
    },
    get hasFetchedContacts() {
      return self.count > 0;
    },
  }));

export { UserStore };
