import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Flex } from '../grid';
import { Link } from '../link';
import { Option, OptionList } from '../dropdown-options';
import { Profile, SignOut } from '../icons';
import { SelectArrow } from '../select-arrow';
import { useOverlay } from '../overlay';
import { UserInformation } from '../user-information';
import { userPropTypes } from '../../prop-types';

const PROFILE_ROUTE = '/users/profile';

const UserMenu = ({ login, logout, user, isFetchingUser }) => {
  const [listActive, setListActive] = useState(false);

  // @TODO: fix overlay is showing filter bar atm
  // see FilterBarWrapper for temporary solution
  const ref = useOverlay(
    () => {
      setListActive(false);
    },
    listActive,
    { zIndex: 5 }
  );

  return (
    <UserMenuWrapper ref={ref}>
      <CurrentOption>
        <UserInformation
          user={user}
          isFetchingUser={isFetchingUser}
          login={login}
          toggleUserMenu={() => {
            setListActive(!listActive);
            // logEvent(analyticsCategory.NAV, 'UserMenu', 'Open');
          }}
        >
          <StyleSelectArrow isActive={listActive} />
        </UserInformation>
      </CurrentOption>
      {listActive && (
        <OptionList data-testid="user-menu">
          <Link onClick={() => setListActive(false)} to={PROFILE_ROUTE}>
            <Option>
              <StyledProfileIcon />
              <ProfileLink>My Profile</ProfileLink>
            </Option>
          </Link>
          <Option onClick={logout}>
            <StyledSignoutIcon />
            <LogoutLink>Log out</LogoutLink>
          </Option>
        </OptionList>
      )}
    </UserMenuWrapper>
  );
};

const UserMenuWrapper = styled.div`
  display: grid;
  justify-self: end;
  position: relative;
  width: max-content;
`;

const CurrentOption = styled(Flex)`
  color: ${({ theme }) => theme.colors.interactionSix};
  cursor: pointer;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-left: ${({ theme }) => theme.space.xxs};
`;

const StyledProfileIcon = styled(Profile)`
  fill: ${({ theme }) => theme.colors.black};
  margin-right: 13px;
  vertical-align: middle;
  display: inline-block;
`;

const StyledSignoutIcon = styled(SignOut)`
  fill: ${({ theme }) => theme.colors.dangerSix};
  margin-right: 13px;
  vertical-align: middle;
  display: inline-block;
`;

const LogoutLink = styled.span`
  color: ${({ theme }) => theme.colors.dangerSix};
  vertical-align: middle;
  display: inline-block;
`;

const ProfileLink = styled.span`
  color: ${({ theme }) => theme.colors.black};
  vertical-align: middle;
  display: inline-block;
`;

const StyleSelectArrow = styled(SelectArrow)`
  margin-left: 2rem;
  margin-right: -1rem;

  path {
    fill: ${({ theme }) => theme.colors.white};
  }

  &:hover {
    path {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`;

UserMenu.propTypes = {
  login: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  isFetchingUser: PropTypes.bool,
  user: userPropTypes,
};

UserMenu.defaultProps = {
  isFetchingUser: false,
  user: null,
};

export { UserMenu };
