import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Arrow } from '../../icons';
import { Flex } from '../../grid';
import { H4 } from '../../type';
import { Link } from '../../link';

const QuickLink = ({ Icon, heading, description, linkTo }) => (
  <QuickLinkButton to={linkTo}>
    <IconWrapper>{Icon}</IconWrapper>
    <LinkText flex={1} flexDirection="column">
      <H4 mt={0}>{heading}</H4>
      <Description>{description}</Description>
    </LinkText>
    <ArrowIcon />
  </QuickLinkButton>
);

const QuickLinkButton = styled(Link)`
  display: flex;
  text-decoration: none;
  align-items: center;
  padding: ${({ theme }) => theme.space.xs};
  background-color: ${({ theme }) => theme.colors.greyOne};
  border: 1px solid ${({ theme }) => theme.colors.greyTwo};
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  max-width: 100%;

  &:hover {
    background-color: ${({ theme }) => theme.colors.greyTwo};
  }
`;

const IconWrapper = styled.div`
  svg {
    fill: ${({ theme }) => theme.colors.interactionFive};
  }
`;

const LinkText = styled(Flex)`
  padding: 0 ${({ theme }) => theme.space.xs};
`;

const Description = styled.div`
  color: ${({ theme }) => theme.colors.greyFive};
  font-size: ${({ theme }) => theme.fontSize.small};
`;

const ArrowIcon = styled(Arrow)`
  transform: rotate(-90deg);

  path {
    fill: ${({ theme }) => theme.colors.greyFive};
  }
`;

QuickLink.propTypes = {
  Icon: PropTypes.node.isRequired,
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
};

export { QuickLink };
