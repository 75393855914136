import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Chip } from '../chip';
import { SearchForm } from './search-form';
import { Search as SearchIcon } from '../icons';

const Search = ({ placeholder, keyword, onSubmit, setHideFilters }) => {
  const [isVisible, setIsVisible] = useState(!!keyword);

  const onCancel = () => {
    onSubmit({ keyword: '' });
    setIsVisible(false);
  };

  useEffect(() => {
    setHideFilters(!!keyword || isVisible);
  }, [keyword, setHideFilters, isVisible]);

  if (keyword || isVisible) {
    return <SearchForm placeholder={placeholder} keyword={keyword} onCancel={onCancel} onSubmit={onSubmit} />;
  }

  return (
    <Chip onClick={() => setIsVisible(true)} icon={<SearchIcon />} isSearchButton>
      Search
    </Chip>
  );
};

Search.propTypes = {
  placeholder: PropTypes.string,
  keyword: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  setHideFilters: PropTypes.func,
};

Search.defaultProps = {
  placeholder: 'Search',
  keyword: '',
  setHideFilters: () => {},
};

export { Search };
