import { createPortal } from 'react-dom';
import { useState } from 'react';
import PropTypes from 'prop-types';

import { Box, Button, FileUpload, form, Modal, ModalFooterButtonWrapper } from '../../../ui-library';
import { DynamicFormInput } from '../../../dynamic-form-input';
import { useModalState } from '../../../modal-context';

const { Form, Label, Input, SubmitButton } = form;

const AttachDocumentModal = ({ onSubmit }) => {
  const { modalContainer } = useModalState();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleOpen = () => setIsModalOpen(!isModalOpen);
  const closeModal = () => setIsModalOpen(false);

  const handleSubmit = async (event) => {
    await onSubmit(event);

    closeModal();
  };

  return (
    <>
      <Button onClick={toggleOpen} variant="secondary" minWidth="220px">
        Attach Document
      </Button>
      {!!modalContainer &&
        createPortal(
          <Form mode="onBlur" onSubmit={handleSubmit}>
            <Modal
              title="Attach Document"
              height="450px"
              isOpen={isModalOpen}
              isScrollable
              closeModal={closeModal}
              footer={
                <ModalFooterButtonWrapper closeModal={closeModal}>
                  <SubmitButton>Attach</SubmitButton>
                </ModalFooterButtonWrapper>
              }
            >
              <DynamicFormInput
                name="supportingDocumentTypes"
                render={({ label, name, options, type, ...props }) => (
                  <Input name={name} type={type} options={options} {...props}>
                    {label}
                  </Input>
                )}
              />
              <Box mt={3}>
                <Label htmlFor="file">Attachments</Label>
                <FileUpload
                  name="file"
                  validation={{
                    validate: {
                      ensureFileUpload: (value) => (value && value.length > 0) || 'Please select a file to upload.',
                    },
                  }}
                />
              </Box>
            </Modal>
          </Form>,
          modalContainer
        )}
    </>
  );
};

AttachDocumentModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export { AttachDocumentModal };
