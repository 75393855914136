import { get } from 'lodash';
import { useCallback } from 'react';

import { logger } from '../../utils/logger';
import { useRootStore } from '../../components/store-provider/lib';

const useSupportingDocuments = (bookingID) => {
  const {
    BookingStore: { deleteAttachment, documents, fetchAttachment, uploadAttachment },
  } = useRootStore();

  const handleAttachmentUpload = useCallback(
    async (fields) => {
      const { file } = fields;

      // FileUpload returns an array, regardless of whether multiple files can be selected.
      // Deconstruct the array to retrieve the first (and only) attachment.
      const [attachment] = file;

      // TODO: use template `apiField` here.
      const documentType = get(fields, 'documents[0].supportingDocuments');

      await uploadAttachment({ file: attachment, documentType });
    },
    [uploadAttachment]
  );

  const handleDeleteAttachment = async (bookingDocumentID) => {
    try {
      await deleteAttachment({ bookingID, bookingDocumentID });
    } catch (error) {
      // eslint-disable-next-line no-alert
      window.alert(`An error occurred while removing this attachment.`);
      logger.error(error);
    }
  };

  const handleAttachmentDownload = async (attachment) => {
    try {
      const url = await fetchAttachment({ bookingID, ...attachment });

      const a = document.createElement('a');
      a.href = url;
      a.click();
    } catch (error) {
      // eslint-disable-next-line no-alert
      window.alert(`${attachment.name} could not be downloaded at this time.`);
      logger.error(error);
    }
  };

  return { documents, handleAttachmentDownload, handleAttachmentUpload, handleDeleteAttachment };
};

export { useSupportingDocuments };
