import PropTypes from 'prop-types';

const Send = ({ fill, ...rest }) => (
  <svg width="20" height="18" viewBox="0 0 20 18" xmlns="http://www.w3.org/2000/svg" {...rest} fill={fill}>
    <path
      d="M1.67148 17.3995L19.1215 9.91953C19.9315 9.56953 19.9315 8.42953 19.1215 8.07953L1.67148 0.599529C1.01148 0.309529 0.281484 0.799529 0.281484 1.50953L0.271484 6.11953C0.271484 6.61953 0.641484 7.04953 1.14148 7.10953L15.2715 8.99953L1.14148 10.8795C0.641484 10.9495 0.271484 11.3795 0.271484 11.8795L0.281484 16.4895C0.281484 17.1995 1.01148 17.6895 1.67148 17.3995Z"
      fill={fill}
    />
  </svg>
);

Send.propTypes = {
  fill: PropTypes.string,
};

Send.defaultProps = {
  fill: '#49525D',
};

export { Send };
