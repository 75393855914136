import { grid } from 'styled-system';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { DynamicFormInput } from '../../dynamic-form-input';
import { LabelledInput } from '../../labelled-input';
import { skuFields } from '../../../constants/case';
import { useHydrateItem } from '../../../hooks/use-hydrate-item';

const ItemForm = ({ activeItem }) => {
  useHydrateItem(activeItem ? { skuDetails: [activeItem] } : null);

  return skuFields.map((field) => (
    <DynamicFormInput
      key={field}
      name={field}
      render={(props) => {
        let width = 2;

        if (props.type === 'numberSpinner') {
          width = 1;
        }

        return (
          <Cell gridColumn={`span ${width}`}>
            <LabelledInput {...props} />
          </Cell>
        );
      }}
    />
  ));
};

ItemForm.propTypes = {
  activeItem: PropTypes.shape(),
};

ItemForm.defaultProps = {
  activeItem: null,
};

const Cell = styled.div`
  ${grid};
  align-self: center;
`;

export { ItemForm };
