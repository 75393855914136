import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Box, Flex } from '../grid';
import { DoubleArrowRight, ErrorWithBackground } from '../icons';
import { H5 } from '../type';

const BookingTab = ({ isActive, labels, title, hasError }) => (
  <Wrapper isActive={isActive} justifyContent="space-between" alignItems="center">
    <Box>
      <Flex alignItems="center" mb={1}>
        <StyledIcon isActive={isActive} data-testid="booking-tab-icon">
          <DoubleArrowRight />
        </StyledIcon>
        <H5 m="0">{title}</H5>
      </Flex>
      <Flex flexDirection="column" alignItems="flex-start">
        {labels.map(({ key, value, placeholder, icon }) => (
          <LabelWrapper alignItems="center" key={key}>
            {icon && <Box mr={1}>{icon}</Box>}

            <Label hasValue={!!value}>{value || placeholder}</Label>
          </LabelWrapper>
        ))}
      </Flex>
    </Box>
    {hasError && <ErrorWithBackgroundIcon />}
  </Wrapper>
);

const Wrapper = styled.div`
  align-items: center;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr max-content;
  grid-column-gap: 1rem;
  background: transparent;
  border: solid 1px transparent;
  border-width: 1px 0;
  padding: ${({ theme }) => theme.space.xs} ${({ theme }) => theme.space.md};
  position: relative;
  transition: background linear 150ms;

  :hover {
    background: ${({ theme }) => theme.colors.greyTwo};
  }

  ${({ isActive, theme }) =>
    isActive &&
    css`
      border-color: ${theme.colors.borderColorOne};
      background: ${theme.colors.white};

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 8px;
        background: ${theme.colors.primaryFive};
      }
    `}
`;

const StyledIcon = styled.span`
  background: ${({ theme }) => theme.colors.primaryFive};
  display: grid;
  place-items: center;
  width: ${({ theme }) => theme.space.sm};
  height: ${({ theme }) => theme.space.sm};
  border-radius: 50%;
  margin-right: ${({ theme }) => theme.space.xxs};
  margin-left: ${({ isActive, theme }) => (isActive ? `0` : `-${theme.space.md}`)};
  opacity: ${({ isActive }) => (isActive ? `1` : `0`)};
  transition: all linear 250ms;

  svg {
    width: 1rem;
    height: 1rem;
  }
`;

const Label = styled.span`
  border: 1px solid ${({ theme }) => theme.colors.greyThree};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.greyFour};
  display: inline-block;
  font-size: ${({ theme }) => theme.fontSize.xSmall};
  line-height: 18px;
  padding: 2px 8px;

  ${({ hasValue }) =>
    hasValue &&
    css`
      border-color: transparent;
      color: ${({ theme }) => theme.colors.interactionTen};
    `}

  & + & {
    margin-top: 4px;
  }
`;

const LabelWrapper = styled(Flex)`
  :not(:last-child) {
    margin-bottom: 0.25rem;
  }

  svg {
    fill: ${({ theme }) => theme.colors.greyNine};
    width: 1rem;
    height: 1rem;
  }
`;

const ErrorWithBackgroundIcon = styled(ErrorWithBackground)`
  width: 20px;
  height: 20px;
`;

BookingTab.propTypes = {
  hasError: PropTypes.bool,
  isActive: PropTypes.bool,
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      placeholder: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
      icon: PropTypes.node,
    })
  ),
  title: PropTypes.string.isRequired,
};

BookingTab.defaultProps = {
  hasError: false,
  isActive: false,
  labels: [],
};

export { BookingTab };
