import { Controller } from 'react-hook-form';
import { isNil } from 'lodash';
import { space } from 'styled-system';
import { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { backgroundStyle, borderStyle, focusStyle, sizeStyle } from '../styles';
import { Box, Flex } from '../../../grid';
import { Dash, Plus } from '../../../icons';
import { ErrorLabel } from '../error-label';
import { useFormState } from '../..';

const NumberSpinner = ({ name, showErrorPoppedOut, validation, ...props }) => {
  const [hasFocus, setFocus] = useState(false);
  const [hasHover, setHover] = useState(false);

  const { control, getError, watch } = useFormState();

  const defaultValue = watch(name) || '0';

  const error = getError(name);

  const incrementBy = (value, increment) => {
    // Reset if the value is undefined OR value is <= 0 and we are incrementing down
    if (value === undefined || (value <= 0 && increment < 0)) {
      return 0;
    }

    return Number(value) + increment;
  };

  return (
    <>
      <Controller
        control={control}
        defaultValue={defaultValue}
        name={name}
        rules={validation}
        render={({ onChange, value }) => {
          // Allowed keys that can be pressed, this includes 0-9 (48-57),
          // backspace (8) and a generic catchall (0) for non-printed keys (arrow keys etc)
          const allowedKeyCodes = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 0, 8];

          // Handle non-input keypresses, eg arrow up + down to increment and decrement the value.
          // Note that this event checks e.keyCode, as e.charCode will be 0 for all non-input characters.
          const onKeyDown = (e) => {
            const ARROW_UP = 38;
            const ARROW_DOWN = 40;

            switch (e.keyCode) {
              case ARROW_UP:
                e.preventDefault(); // prevent arrow key from moving cursor in input field
                onChange(Number(e.target.value) + 1);
                break;
              case ARROW_DOWN:
                e.preventDefault(); // prevent arrow key from moving cursor in input field
                onChange(Math.max(0, Number(e.target.value) - 1));
                break;
              default:
            }
          };

          // Prevents invalid input characters (eg A-Z) being input into the field.
          const onKeyPress = (e) => {
            const key = e.charCode;

            if (!allowedKeyCodes.includes(key)) {
              e.preventDefault();
            }
          };

          // If a valid input keypress is detected, fire the `onChange` handler with the new value of the input.
          // This must be performed in `onKeyUp`, as the input value updates when this is triggered.
          const onKeyUp = (e) => {
            const key = e.charCode;

            if (!allowedKeyCodes.includes(key)) {
              e.preventDefault();
            } else {
              onChange(Number(e.target.value));
            }
          };

          const onFocus = (e) => {
            // If the input is 0, and the user begins to type, the field will appear as eg `0123`.
            // If we select the field value on focus, the user will replace the existing value when they type.
            // Realistically, I think this is a more optimal and frequent use-case -- I don't anticipate
            // any users will append a value once they set it, and will instead replace an existing value.
            e.target.select();

            setFocus(true);
          };

          return (
            <Flex alignItems="center">
              <IncrementButton
                role="button"
                aria-label="Decrement"
                onClick={() => onChange(incrementBy(value, -1))}
                mr={2}
              >
                <Dash />
              </IncrementButton>
              <StyledTextInput
                hasError={!isNil(error)}
                hasFocus={hasFocus}
                hasHover={hasHover}
                onFocus={onFocus}
                onBlur={() => setFocus(false)}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                onKeyDown={onKeyDown}
                onKeyPress={onKeyPress}
                onKeyUp={onKeyUp}
                value={value || '0'}
                onChange={onChange}
                type="text"
                {...props}
              />
              <IncrementButton
                role="button"
                aria-label="Increment"
                onClick={() => onChange(incrementBy(value, 1))}
                ml={2}
              >
                <Plus />
              </IncrementButton>
            </Flex>
          );
        }}
      />
      {error && <ErrorLabel showPoppedOut={showErrorPoppedOut}>{error}</ErrorLabel>}
    </>
  );
};

const StyledTextInput = styled.input`
  ${backgroundStyle};
  ${borderStyle};
  ${focusStyle};
  ${sizeStyle};
  ${space};

  font-size: ${({ theme }) => theme.fontSize.base};
  flex: 1;

  ${({ hasError, theme }) =>
    hasError &&
    css`
      border-color: ${theme.colors.dangerSeven};
    `}

  ${({ disabled, theme }) =>
    disabled &&
    css`
      border-color: ${theme.colors.greyTwo};
    `}

  &::placeholder {
    color: ${({ theme }) => theme.colors.greyFour};
    opacity: 1;
  }
`;

const IncrementButton = styled(Box)`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.greyTwo};
  display: grid;
  place-items: center;

  svg {
    width: 10px;
    height: 10px;

    path {
      fill: ${({ theme }) => theme.colors.greyNine};
    }
  }

  &:hover {
    background: ${({ theme }) => theme.colors.greyThree};
  }
`;

NumberSpinner.propTypes = {
  name: PropTypes.string.isRequired,
  validation: PropTypes.shape(),
  showErrorPoppedOut: PropTypes.bool,
};

NumberSpinner.defaultProps = {
  validation: null,
  showErrorPoppedOut: false,
};

export { NumberSpinner };
