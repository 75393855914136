import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box, Flex } from '../../ui-library';

const BookingFormRow = ({ icon, iconColor, iconHeight, iconWidth, mb, mt, isInputRow, children }) => (
  <Flex mb={mb} mt={mt}>
    <IconWrapper
      height={32}
      width={32}
      // In an ideal world, Icons already have these styled-system props
      // so we wouldn't need to prop drill
      iconColor={iconColor}
      iconHeight={iconHeight}
      iconWidth={iconWidth}
      justifyContent="center"
      mr={4}
      position="relative"
      top={isInputRow ? 28 : 0}
    >
      {icon}
    </IconWrapper>
    <Box width="100%">{children}</Box>
  </Flex>
);

const IconWrapper = styled(Flex)`
  svg {
    fill: ${({ iconColor, theme }) => iconColor || theme.colors.greyNine};
    height: ${({ iconHeight }) => iconHeight};
    width: ${({ iconWidth }) => iconWidth};
  }
`;

BookingFormRow.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.node,
  iconColor: PropTypes.string,
  iconHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  iconWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isInputRow: PropTypes.bool,
  mb: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

BookingFormRow.defaultProps = {
  icon: null,
  iconColor: null,
  iconHeight: 32,
  iconWidth: 32,
  isInputRow: false,
  mb: 4,
  mt: 0,
};

export { BookingFormRow };
