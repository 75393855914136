import { color } from 'styled-system';
import styled from 'styled-components';

import { useComponent } from '../ui-library-provider';

const Link = (props) => {
  const { LinkComponent } = useComponent();

  return <LinkComponent {...props} />;
};

const StyledLink = styled(Link)`
  ${color};
`;

export { StyledLink as Link };
