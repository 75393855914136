import styled from 'styled-components';

import { globalStyles } from '../../constants';
import { TableRow } from './table-row';

const TableLegend = styled(TableRow)`
  color: ${({ theme }) => theme.colors.greyFour};
  min-height: unset;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 11px;
  padding-bottom: ${({ theme }) => theme.space.xs};
  padding-top: ${({ theme }) => theme.space.xs};
  background: ${globalStyles.backgroundColor};
  position: sticky;
  top: 0;
`;

export { TableLegend };
