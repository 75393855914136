// Translate the `type` field from the template into a suppported input type.
const formatTemplateType = (type) => {
  switch (type) {
    case 'Checkbox':
      return 'checkbox';
    case 'List':
      return 'list';
    case 'Multi-Select':
    case 'Select':
      return 'select';
    case 'Text Area':
      return 'textarea';
    case 'Date Time':
      return 'datetimepicker';
    case 'Date':
      return 'datepicker';
    case 'Number':
      return 'numberSpinner';
    case 'Double':
      return 'double';
    case 'Type':
    case 'Literal':
    default:
      return 'text';
  }
};

export { formatTemplateType };
