import { autorun } from 'mobx';
import { getEnv, types } from 'mobx-state-tree';

const SettingStore = types
  .model('SettingStore', {
    maintenanceMessage: types.maybeNull(types.string),
  })
  .actions((self) => {
    const { api } = getEnv(self);

    autorun(async () => {
      const { data, status } = await api.get('settings', {
        acquireSilently: true,
        restricted: false,
      });

      if (status === 200 && data.maintenanceMessage) {
        self.setMaintenanceMessage(data.maintenanceMessage);
      }
    });

    return {
      setMaintenanceMessage: (message) => {
        self.maintenanceMessage = message;
      },
    };
  });

export { SettingStore };
