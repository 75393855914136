// Map input fields to template items.
const formSectionMapping = {
  bookingDetails: [
    'bookingCreatedBy',
    'bookingCreatedOn',
    'dangerousGoodsIndicator',
    'dangerousGoodsIndicatorMessage',
    'customerReferenceNumber',
    'bookingWebInstructions',
    'urgentFlag',
    'overDimensionalFlag',
    'contract',
    'commodityName',
    'serviceName',
  ],
  schedule: [
    'pickUpFromDateTime' /* ✅  */,
    'pickUpToDateTime' /* ✅  */,
    'deliveryFromDateTime' /* ✅  */,
    'deliveryToDateTime' /* ✅  */,
  ],
  pickUp: [
    'newPickUpLocation',
    'pickUpLocationCode',
    'pickUpLocationName',
    'pickUpAddress1',
    'pickUpAddress2',
    'pickUpSuburb',
    'pickUpPostCode',
    'pickUpState',
    'pickUpCountry',
    'pickUpLatitude',
    'pickUpLongitude',
    'pickUpContactName',
    'pickUpContactPhone',
    'pickUpReference',
    'pickUpInstructions',
    'pickUpLocationType',
  ],
  delivery: [
    'newDeliveryLocation',
    'deliveryLocationCode',
    'deliveryLocationName',
    'deliveryAddress1',
    'deliveryAddress2',
    'deliverySuburb',
    'deliveryPostCode',
    'deliveryState',
    'deliveryCountry',
    'deliveryLongitude',
    'deliveryLatitude',
    'deliveryContactName',
    'deliveryContactPhone',
    'deliveryReference',
    'deliveryInstructions',
    'deliveryLocationType',
  ],
  cargo: [
    'itemCargo', // ✅
    'itemProduct', // ✅
    'itemBookingLineItemID', // ✅
    'itemType', // ✅
    'itemTypeDescription', // ????
    'itemQty', // ✅
    'itemQtyText', // ✅
    'itemLength', // ✅
    'itemHeight', // ✅
    'itemWidth', // ✅
    'itemDimsUOM', // ✅
    'itemGroWeight', // ✅
    'itemWeightUOM', // ✅
    'itemVolume', // ✅
    'itemVolumeText', // ✅
    'itemVolumeUOM', // ✅
    'itemReference', // ✅
    'itemDocketNum', // ✅
    'itemDescription', // ✅
    'itemTemperature', // ✅
    'outOfGauge', // ✅
    'batchNumber', // ✅
    'itemSSCC', // ✅
    'expiryDate', // ✅
    'itemExpiryValidate', // ✅
    'minLifeDays', // ✅
    'shippingReference', // ✅
    'containerReference', // ✅
    'containerNumber', // ✅
    'ecncrn', // ✅
    'seal', // ✅
    'itemDangerousGoodsIndicator', // ✅
    'dgClass', // ✅
    'dgUnNumber', // ✅
    'dgShippingName', // ✅
    'dgTechnicalName', // ✅
    'dgKiloLitre', // ✅
    'dgCountPackages', // ✅
    'dgPackingType', // ✅
    'dgPackingGroup', // ✅
    'dgHazchem', // ✅
    'dgSubsidiaryRisk', // ✅
    'dgContactName', // ✅
    'dgContactPhone', // ✅
    'cargoTableVariant', // ✅
  ],
  specialEquipment: [
    'specialisedHandling' /* ✅  */,
    'noteSpecialHandling' /* ✅  */,
    'packingEquipment' /* ✅  */,
    'notePackingEquipment' /* ✅  */,
  ],
  /* ✅  railTransport fields included programatically */
  railTransport: [
    'railOrigin',
    'railDestination',
    'railDepartureDateTime',
    'shippingCompany',
    'shippingACOS',
    'shippingStevedore',
    'shippingLLOYDS',
    'shippingVessel',
    'shippingDischargePort',
    'shippingFinalDestination',
    'shippingLoadingPort',
    'shippingOutVoyage',
    'shippingLateApproved',
    'shippingUnderbond',
  ],
  supportingDocuments: ['supportingDocumentTypes' /* ✅  */],
};

export { formSectionMapping };
