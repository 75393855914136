import { ifProp } from 'styled-tools';
import { useEffect, useState } from 'react';
import { variant } from 'styled-system';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { backgroundStyle, borderStyle, cursorStyle, focusStyle } from '../../styles';
import { Dash, Tick } from '../../../../icons';
import { Flex } from '../../../../grid';

const CheckboxWrapper = ({ alignItems, children, disabled, inputId, indeterminate, label, onChange, value, size }) => {
  const [isActive, setActive] = useState(!!value);
  const [hasFocus, setFocus] = useState(false);
  const [hasHover, setHover] = useState(false);

  useEffect(() => {
    setActive(value);
  }, [value]);

  const labelProps = { disabled };

  // Only use htmlFor if we have a controlled input.
  if (inputId) {
    labelProps.htmlFor = inputId;
  }

  return (
    <CheckboxContainer
      onClick={(e) => {
        e.stopPropagation();
        onChange(!value);
      }}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      alignItems={alignItems}
    >
      <IconContainer disabled={disabled} hasFocus={hasFocus} hasHover={hasHover} isActive={isActive} variant={size}>
        {children}
        {indeterminate ? <Dash /> : <Tick />}
      </IconContainer>
      <Label {...labelProps}>{label}</Label>
    </CheckboxContainer>
  );
};

const CheckboxContainer = styled(Flex)`
  margin-bottom: ${({ theme }) => theme.space.xs};
  position: relative;
  width: max-content;

  :last-child {
    margin-bottom: 0;
  }
`;

const containerSizeVariant = variant({
  variants: {
    sm: {
      height: '1rem',
      width: '1rem',
      minHeight: '1rem',
      minWidth: '1rem',
    },
    md: {
      minHeight: '1.5rem',
      minWidth: '1.5rem',
    },
  },
});

const iconSizeVariant = variant({
  variants: {
    sm: {
      height: '0.65rem',
      width: '0.65rem',
    },
    md: {
      height: '1rem',
      width: '1rem',
    },
  },
});

const IconContainer = styled(Flex)`
  ${containerSizeVariant};
  ${backgroundStyle};
  ${focusStyle};
  ${borderStyle};

  align-items: center;
  justify-content: center;
  position: relative;

  svg {
    ${iconSizeVariant};
    display: ${ifProp('isActive', 'block', 'none')};

    path {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`;

const Label = styled.label`
  padding-left: ${({ theme }) => theme.space.xs};

  ${cursorStyle};
`;

CheckboxWrapper.propTypes = {
  alignItems: PropTypes.oneOf(['flex-start', 'center']),
  children: PropTypes.node,
  disabled: PropTypes.bool,
  indeterminate: PropTypes.bool,
  inputId: PropTypes.string,
  label: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['sm', 'md']),
  value: PropTypes.bool,
};

CheckboxWrapper.defaultProps = {
  alignItems: 'center',
  children: null,
  disabled: false,
  indeterminate: false,
  inputId: undefined,
  size: 'md',
  value: undefined,
};

export { CheckboxWrapper };
