import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Cross } from '../../../../icons/cross';

const ClearIndicator = ({ innerProps: { ref, ...innerProps } }) => <StyledCross ref={ref} {...innerProps} />;

const StyledCross = styled(Cross)`
  position: relative;
  right: ${({ theme }) => theme.space.xs};

  path {
    fill: ${({ theme }) => theme.colors.greySeven};
  }

  &:hover {
    path {
      fill: ${({ theme }) => theme.colors.interactionSix};
    }
  }
`;

ClearIndicator.propTypes = {
  innerProps: PropTypes.shape().isRequired,
};

export { ClearIndicator };
