import PropTypes from 'prop-types';
import styled from 'styled-components';

import { DesktopNavigation } from '../desktop-navigation';
import { Flex } from '../grid';
import { globalStyles } from '../../constants';
import { Link } from '../link';
import { MobileNavigation } from '../mobile-navigation';
import { navigationRoutesPropType, userPropTypes } from '../../prop-types';
import { UserMenu } from '../user-menu';

const Header = ({ children, isSignedIn, navigationRoutes, login, logout, user, isFetchingUser, currentRoute }) => {
  const getIsCurrentRoute = (to) => currentRoute === to.replace('/', '');
  const showDesktopNavigation = currentRoute !== '' && isSignedIn;

  // Remove any navigation routes which are explictly not `enabled`.
  const activeNavigation = navigationRoutes.filter(({ enabled = true }) => enabled);

  return (
    <StyledHeader>
      <Wrapper>
        <Flex>
          {showDesktopNavigation && (
            <DesktopNavigation navigationRoutes={activeNavigation} getIsCurrentRoute={getIsCurrentRoute} />
          )}
          {isSignedIn && (
            <MobileNavigation
              getIsCurrentRoute={getIsCurrentRoute}
              navigationRoutes={activeNavigation}
              logout={logout}
            />
          )}
        </Flex>
        <AppLogo>
          <StyledLink to="/">
            {/* TODO: this is here so we can render a Logo from the portal. Ideally, this entire component should live in apps/portal */}
            {children}
          </StyledLink>
        </AppLogo>
        <UserMenu login={login} logout={logout} isFetchingUser={isFetchingUser} user={user} />
      </Wrapper>
    </StyledHeader>
  );
};

const StyledHeader = styled.div`
  background-color: ${({ theme }) => theme.colors.black};
  color: white;
  z-index: 8;
  position: relative;
`;

const Wrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-auto-flow: column;
  grid-template-columns: 1fr max-content 1fr;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  height: ${({ theme }) => theme.global.headerHeight};
  padding: 0 ${({ theme }) => theme.space.sm};
  max-width: ${globalStyles.pageMaxWidth};
  width: 100%;
`;

const AppLogo = styled.div`
  width: ${({ theme }) => theme.global.headerHeight};
`;

const StyledLink = styled(Link)`
  position: relative;
  z-index: 1;
`;

Header.propTypes = {
  children: PropTypes.node.isRequired,
  currentRoute: PropTypes.string.isRequired,
  isFetchingUser: PropTypes.bool.isRequired,
  isSignedIn: PropTypes.bool.isRequired,
  login: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  navigationRoutes: navigationRoutesPropType.isRequired,
  user: userPropTypes,
};

Header.defaultProps = {
  user: null,
};

export { Header };
