import PropTypes from 'prop-types';

import { CalendarRange } from './calendar-range';
import { Checkbox } from './checkbox';
import { DatePicker } from './datepicker';
import { DateTimePicker } from './datetimepicker';
import { Double } from './double';
import { HiddenInput } from './hidden';
import { ListInput } from './list-input';
import { NumberSpinner } from './number-spinner';
import { RadioButton } from './radio-button';
import { Select } from './select';
import { TextArea } from './textarea';
import { TextInput } from './text-input';

const Input = ({ name, type, validation, ...props }) => {
  let Component;

  switch (type) {
    case 'radio':
      Component = RadioButton;
      break;
    case 'checkbox':
      Component = Checkbox;
      break;
    case 'double':
      Component = Double;
      break;
    case 'select':
      Component = Select;
      break;
    case 'datepicker':
      Component = DatePicker;
      break;
    case 'datetimepicker':
      Component = DateTimePicker;
      break;
    case 'list':
      Component = ListInput;
      break;
    case 'numberSpinner':
      Component = NumberSpinner;
      break;
    case 'textarea':
      Component = TextArea;
      break;
    case 'calendarRange':
      Component = CalendarRange;
      break;
    case 'hidden':
      Component = HiddenInput;
      break;
    default:
      Component = TextInput;
  }

  return <Component name={name} validation={validation} {...props} />;
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  validation: PropTypes.shape(),
};

Input.defaultProps = {
  validation: null,
};

export { Input };
