import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Flex, icons } from '../ui-library';

const { Tick, Dash } = icons;

const SelectAllButton = ({ onClick, isAllSelected, hasItemsSelected }) => (
  <CheckboxContainer as="button" onClick={onClick} isActive={hasItemsSelected}>
    {isAllSelected && <Tick />}
    {hasItemsSelected && !isAllSelected && <Dash />}
  </CheckboxContainer>
);

const CheckboxContainer = styled(Flex)`
  align-items: center;
  justify-content: center;
  background: ${({ isActive, theme }) => (isActive ? theme.colors.interactionFive : theme.colors.white)};
  border: 1px solid ${({ isActive, theme }) => (isActive ? theme.colors.interactionSix : theme.colors.greyThree)};
  border-radius: ${({ theme }) => theme.global.borderRadius};
  width: 1rem;
  height: 1rem;

  svg {
    height: 0.65rem;
    width: 0.65rem;

    path {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`;

SelectAllButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isAllSelected: PropTypes.bool,
  hasItemsSelected: PropTypes.bool,
};

SelectAllButton.defaultProps = {
  isAllSelected: false,
  hasItemsSelected: false,
};

export { SelectAllButton };
