import { color, layout, position, space, typography } from 'styled-system';
import { css } from 'styled-components';

const styledSystemProps = css`
  ${color}
  ${layout}
  ${position}
  ${space}
  ${typography}
`;

const H1Styles = css`
  font-family: ${({ theme }) => theme.global.brandFontFamily};
  font-size: ${({ theme }) => theme.fontSize.xLarge};
  line-height: ${({ theme }) => theme.lineHeight.heading.xxLarge};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  letter-spacing: ${({ theme }) => theme.global.letterSpacing};
  margin: 0;
  ${styledSystemProps};
`;

const H2Styles = css`
  font-family: ${({ theme }) => theme.global.brandFontFamily};
  font-size: ${({ theme }) => theme.fontSize.large};
  line-height: ${({ theme }) => theme.lineHeight.heading.xLarge};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  letter-spacing: ${({ theme }) => theme.global.letterSpacing};
  margin: 0.5rem 0;
  ${styledSystemProps};
`;

const H3Styles = css`
  font-family: ${({ theme }) => theme.global.brandFontFamily};
  font-size: ${({ theme }) => theme.fontSize.medium};
  line-height: ${({ theme }) => theme.lineHeight.heading.large};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  letter-spacing: ${({ theme }) => theme.global.letterSpacing};
  margin: 0.5rem 0;
  ${styledSystemProps};
`;

const H4Styles = css`
  font-family: ${({ theme }) => theme.global.brandFontFamily};
  font-size: ${({ theme }) => theme.fontSize.base};
  line-height: ${({ theme }) => theme.lineHeight.heading.medium};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin: 0.5rem 0;
  ${styledSystemProps};
`;

const H5Styles = css`
  font-family: ${({ theme }) => theme.global.brandFontFamily};
  font-size: ${({ theme }) => theme.fontSize.small};
  line-height: ${({ theme }) => theme.lineHeight.heading.basel};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin: 0.5rem 0;
  ${styledSystemProps};
`;

const H6Styles = css`
  font-family: ${({ theme }) => theme.global.brandFontFamily};
  font-size: ${({ theme }) => theme.fontSize.small};
  line-height: ${({ theme }) => theme.lineHeight.heading.basel};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin: 0.5rem 0;
  ${styledSystemProps};
`;

export { styledSystemProps, H1Styles, H2Styles, H3Styles, H4Styles, H5Styles, H6Styles };
