import { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const FilterContext = createContext();

const FilterProvider = ({ children }) => {
  const [activeFilterPanel, setActiveFilterPanel] = useState(null);
  const [isInactiveFilters, setIsInactiveFilters] = useState(true);

  const closeFilterPanel = () => setActiveFilterPanel(null);

  const toggleIsOpen = (id) => {
    if (activeFilterPanel === id) {
      return closeFilterPanel();
    }

    return setActiveFilterPanel(id);
  };

  const contextValue = {
    activeFilterPanel,
    setActiveFilterPanel,
    closeFilterPanel,
    toggleIsOpen,
    isInactiveFilters,
    setIsInactiveFilters,
  };

  return <FilterContext.Provider value={contextValue}>{children}</FilterContext.Provider>;
};

FilterProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { FilterProvider, FilterContext };
