import { createPortal } from 'react-dom';
import { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box } from '../grid';
import { FilterContext } from './filter-provider';
import { FilterTypeDate } from './filter-type-date';
import { FilterTypeInput } from './filter-type-input';
import { FilterTypeRadio } from './filter-type-radio';
import { mobileOnly } from '../../utils/responsive-styles';

const FilterPanel = ({
  topOffset,
  leftOffset,
  name,
  type,
  data,
  defaultValues,
  onSubmit,
  onCancel,
  onUpdateLabel,
  isVisible,
  resetKey,
}) => {
  const { isInactiveFilters } = useContext(FilterContext);

  let Component;

  switch (type) {
    case 'radio':
      Component = FilterTypeRadio;
      break;
    case 'date':
      Component = FilterTypeDate;
      break;
    case 'input':
    default:
      Component = FilterTypeInput;
      break;
  }

  const portalContainer = document && document.getElementById('portal');

  if (isInactiveFilters || !portalContainer || (!isVisible && type !== 'date')) {
    return null;
  }

  return createPortal(
    <StyledBox isVisible={isVisible} top={topOffset} left={leftOffset}>
      <Component
        {...data}
        defaultValues={defaultValues}
        name={name}
        onSubmit={onSubmit}
        onCancel={onCancel}
        onUpdateLabel={onUpdateLabel}
        resetKey={resetKey}
      />
    </StyledBox>,
    portalContainer
  );
};

const StyledBox = styled(Box)`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 12px 8px 24px rgba(0, 0, 0, 0.1), -12px 8px 24px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  min-width: 20rem;
  border: 1px solid ${({ theme }) => theme.colors.greyThree};
  font-size: ${({ theme }) => theme.fontSize.small};
  overflow-y: auto;
  width: fit-content;
  position: absolute;
  z-index: 5;

  ${mobileOnly} {
    border-radius: 0;
    position: fixed;
    bottom: 0;
    top: 0;
    width: 100vw;
    left: 0;
    z-index: 9;
  }
`;

FilterPanel.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.shape({}),
  defaultValues: PropTypes.shape({}),
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onUpdateLabel: PropTypes.func,
  type: PropTypes.oneOf(['date', 'radio', 'input']).isRequired,
  isVisible: PropTypes.bool,
  topOffset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  leftOffset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  resetKey: PropTypes.string,
};

FilterPanel.defaultProps = {
  data: {},
  defaultValues: {},
  onSubmit: () => null,
  onCancel: () => null,
  onUpdateLabel: () => null,
  isVisible: false,
  topOffset: 0,
  leftOffset: 0,
  resetKey: '',
};

export { FilterPanel };
