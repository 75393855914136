import { animated, useTransition } from 'react-spring';
import { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useClickOutside } from '../../hooks/use-click-outside';

const OverlayContext = createContext();

const useOverlay = (callback, trigger, { zIndex = 0, isPortal = false } = {}) => {
  const { setOverlay, setZIndex } = useContext(OverlayContext);

  useEffect(() => {
    if (trigger) {
      setZIndex(zIndex);
    }

    setOverlay(trigger);

    return () => {
      setOverlay(false);
    };
  }, [setOverlay, setZIndex, trigger, zIndex]);

  const ref = useClickOutside(callback, trigger, isPortal);

  return ref;
};

const Overlay = () => {
  const { overlayVisible, zIndex } = useContext(OverlayContext);

  const transitions = useTransition(overlayVisible, {
    from: { opacity: 0 },
    enter: { opacity: 0.4 },
    leave: { opacity: 0 },
    config: { tension: 300, friction: 40 },
  });

  return transitions(
    (props, item, key) => item && <AnimatedOverlay data-testid="overlay" key={key} style={props} zIndex={zIndex} />
  );
};

const AnimatedOverlay = styled(({ zIndex, ...props }) => <animated.div {...props} />)`
  background: black;
  content: '';
  display: block;
  height: 100vh;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${({ zIndex }) => zIndex};
`;

const OverlayContainer = ({ children, hidden }) => {
  const [overlayVisible, setOverlay] = useState(false);
  const [zIndex, setZIndex] = useState(0);

  return (
    <OverlayContext.Provider value={{ overlayVisible, setOverlay, zIndex, setZIndex }}>
      {children}
      {!hidden && <Overlay />}
    </OverlayContext.Provider>
  );
};

OverlayContainer.propTypes = {
  children: PropTypes.node.isRequired,
  hidden: PropTypes.bool,
};

OverlayContainer.defaultProps = {
  hidden: false,
};

export { OverlayContainer, useOverlay };
