import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { BookingDetailScreen } from './booking-detail-screen';
import { constants, Content, Grid, usePageTitle } from '../../components/ui-library';
import { DetailProvider } from '../../components/detail-context';
import { SkeletonRow } from '../../components/skeleton-row';
import { useRootStore } from '../../components/store-provider/lib';

const BookingDetail = () => {
  usePageTitle('Booking');

  const { bookingID } = useParams();

  const [data, setData] = useState();
  const [templateFields, setTemplateFields] = useState([]);
  const [hasLoaded, setHasLoaded] = useState(false);

  const {
    BookingStore: { loadBookingDetail },
  } = useRootStore();

  useEffect(() => {
    const fetchData = async () => {
      const { data: fetchedData, fields: fetchedFields } = await loadBookingDetail(bookingID);

      setData(fetchedData);
      setTemplateFields(fetchedFields);
      setHasLoaded(true);
    };

    if (bookingID) {
      fetchData();
    }
  }, [bookingID, loadBookingDetail]);

  const normalisedFields = useMemo(
    () =>
      templateFields.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.id]: cur,
        }),
        {}
      ),
    [templateFields]
  );

  if (!hasLoaded || !data) {
    return (
      <Content maxWidth={constants.globalStyles.wideDetailsMaxWidth}>
        <SkeletonRow width="30%" isHeader height="2.5rem" mb={3} />
        <SkeletonRow height="12rem" mb={6} />

        <SkeletonRow width="20%" isHeader height="2.5rem" mb={3} />
        <SkeletonRow height="10rem" mb={6} />

        <Grid gridTemplateColumns="1fr 1fr" gridColumnGap={4}>
          <div>
            <SkeletonRow width="45%" isHeader height="2.5rem" mb={3} />
            <SkeletonRow height="20rem" mb="4rem" />
          </div>
          <div>
            <SkeletonRow width="40%" isHeader height="2.5rem" mb={3} />
            <SkeletonRow height="20rem" mb="4rem" />
          </div>
        </Grid>
      </Content>
    );
  }

  const isDraftBooking = data.bookingStatus === 'Draft';

  return (
    <DetailProvider value={{ bookingID, data, isDraftBooking, normalisedFields }}>
      <BookingDetailScreen />
    </DetailProvider>
  );
};

export { BookingDetail };
