import { types } from 'mobx-state-tree';

const Question = types.model('Question', {
  answer: types.maybeNull(types.string),
  question: types.string,
});

const Defect = types.model('Defect', {
  defectLogger: types.string,
  defectNumber: types.identifier,
  faultCategory: types.string,
  faultDescription: types.string,
  fleetIdentifier: types.string,
  faultReportedDate: types.string,
  faultReportedTime: types.string,
  operatorName: types.string,
  qa_details: types.array(Question),
});

export { Defect };
