import { observer } from 'mobx-react';
import { useEffect } from 'react';

import {
  constants,
  Content,
  icons,
  PageNotFound,
  usePageTitle,
  Profile as UserProfile,
} from '../../components/ui-library';
import { useRootStore } from '../../components/store-provider/lib';

const { LoadingIndicator } = icons;

const Profile = () => {
  const { AuthStore, AccountStore } = useRootStore();
  usePageTitle('Your Profile');

  const { isSignedIn, isFetching } = AuthStore;

  useEffect(() => {
    if (isSignedIn) {
      AccountStore.loadAccount();
    }
  }, [AccountStore, isSignedIn]);

  if (!isSignedIn && !isFetching) return <PageNotFound />;

  if (isFetching)
    return (
      <Content maxWidth={constants.globalStyles.detailsMaxWidth}>
        <LoadingIndicator mt="5rem" />
      </Content>
    );

  const { contracts, emailAddress, fullName, organisation } = AuthStore;

  return (
    <Content maxWidth={constants.globalStyles.detailsMaxWidth}>
      <UserProfile contracts={contracts} emailAddress={emailAddress} fullName={fullName} organisation={organisation} />
    </Content>
  );
};

const ObservedProfile = observer(Profile);
export { ObservedProfile as Profile };
