const detailFields = [
  'orderNumber',
  'loadNumber',
  'freightClass',
  'customerReferenceNumber',
  'purchaseOrderNumber',
  'deliveryDate',
  'caseDeliveryState',
  'site',
  'entireOrder',
];

const skuFields = ['sku', 'batch', 'skuDescription', 'condition', 'useByDate', 'pallets', 'stock', 'inStock'];

export { detailFields, skuFields };
