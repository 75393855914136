import { space } from 'styled-system';
import styled from 'styled-components';

const Placeholder = styled.span`
  ${space};

  border: 1px solid ${({ theme }) => theme.colors.greyThree};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.greyFour};
  display: inline-block;
  font-size: ${({ theme }) => theme.fontSize.xSmall};
  line-height: 18px;
  padding: 2px 8px;
  width: max-content;
`;

export { Placeholder };
