import { camelCase, get, isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useReducer, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { BookingContextProvider } from '../booking-context';
import { TemplateContextProvider } from '../template-context';
import { useDiscardChangesContext } from '../discard-changes-provider';
import { useHydrateBookingData } from '../../hooks/use-hydrate-booking-data';
import { useRootStore } from '../store-provider/lib';

/* eslint-disable no-param-reassign */
const proxyHandler = {
  set: (obj, prop, value) => {
    if (typeof obj[prop] === 'object') {
      obj[prop] = { ...obj[prop], ...value };
    } else {
      obj[prop] = value;
    }

    return true;
  },
};

const BookingProvider = ({ value, children }) => {
  const forms = useRef({});
  const sectionsSummary = useRef({});

  const [formHasErrors, setFormHasErrors] = useState(false);

  // Store functions to be run after the booking is saved.
  const [postSaveHooks, addPostSaveHook] = useReducer((state, hook) => ({ ...state, ...hook }), {});

  const history = useHistory();
  const { BookingStore } = useRootStore();
  const { setTriggerDiscardChanges } = useDiscardChangesContext();

  const registerForm = (form) => {
    forms.current = { ...forms.current, ...form };
  };

  const registerSectionsSummary = (section) => {
    sectionsSummary.current = { ...sectionsSummary.current, ...section };
  };

  const sections = Object.keys(forms.current);

  const isValid = () => {
    return sections.reduce((acc, cur) => {
      const form = forms.current[cur];

      form.trigger();

      return acc && form.isValid;
    }, true);
  };

  const { bookingData = {}, templateData = {} } = useHydrateBookingData();

  const compileFormData = () => {
    // need to specifically past these in, as a new booking won't have it in bookingData
    const { serviceID, contractID, commodityID } = templateData;

    const sectionData = new Proxy({}, proxyHandler);

    sections.forEach((section) => {
      const form = forms.current[section];

      const values = form.getValues();

      Object.assign(sectionData, values);
    }, {});

    // createdByAccount needs to be omitted for updating a booking
    // @TODO: move this to an array of keys to exclude
    const {
      createdByAccount,
      checkboxItemFour,
      company,
      customAddressPickUp,
      customAddressDelivery,
      labelData,
      ...formData
    } = {
      ...bookingData,
      ...sectionData,
      serviceID,
      contractID,
      commodityID,
    };

    return formData;
  };

  const saveBooking = async () => {
    const data = compileFormData();

    const bookingID = await BookingStore.saveBooking(data);

    Promise.all(
      Object.values(postSaveHooks).map(async (fn) => {
        await fn();
      })
    );

    // reset all fields, so we can determine if any changes have been made
    // since last saved by using isDirty
    Object.values(forms.current).forEach(async (form) => {
      const values = form.getValues();
      await form.reset(values);
    });

    setTriggerDiscardChanges(false);

    return bookingID;
  };

  const bookingID = get(bookingData, 'bookingID');
  const isNotDraftBooking = bookingData.bookingStatus !== 'Draft';

  // Redirecting non-draft bookings to the booking detail page
  if (bookingID && isNotDraftBooking) {
    history.push(`/bookings/${bookingID}`);
  }

  const triggerValidation = () => {
    setFormHasErrors(false);

    const valid = isValid();

    if (!valid) {
      // Scroll to first error
      const firstError = Object.keys(forms.current).find((formKey) => {
        if (!isEmpty(forms.current[formKey].errors)) {
          return formKey;
        }
        return null;
      });

      history.push({ hash: camelCase(firstError) });

      setFormHasErrors(true);
    }

    return valid;
  };

  return (
    <BookingContextProvider
      value={{
        ...value,
        addPostSaveHook,
        bookingData,
        saveBooking,
        registerForm,
        registerSectionsSummary,
        sectionSummaryRefs: sectionsSummary.current,
        formHasErrors,
        triggerValidation,
      }}
    >
      <TemplateContextProvider
        value={{
          formData: bookingData, // TODO: refactor approach
          templateData,
        }}
      >
        {children}
      </TemplateContextProvider>
    </BookingContextProvider>
  );
};

BookingProvider.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.shape(),
};

BookingProvider.defaultProps = {
  value: {},
};

export { BookingProvider };
