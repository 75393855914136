import PropTypes from 'prop-types';
import styled from 'styled-components';

import { constants, Flex } from '../ui-library';

const HeaderBar = ({ children }) => {
  return (
    <Wrapper>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        mx="auto"
        py={2}
        px={3}
        flex={1}
        maxWidth={constants.globalStyles.pageMaxWidth}
      >
        {children}
      </Flex>
    </Wrapper>
  );
};

const Wrapper = styled(Flex)`
  box-shadow: inset 0px -1px 0px ${({ theme }) => theme.colors.borderColorOne};
  background-color: ${({ theme }) => theme.colors.white};
  text-decoration: none;
`;

HeaderBar.propTypes = {
  children: PropTypes.node.isRequired,
};

export { HeaderBar };
