import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { BookingCreateModal } from '../../components/booking-create-modal';
import { BookingFilterBar } from '../../components/booking-filter-bar';
import { BookingListing } from '../../components/booking-listing';
import { Content, usePageTitle } from '../../components/ui-library';
import { useRootStore } from '../../components/store-provider/lib';

const Bookings = () => {
  usePageTitle('Bookings');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { BookingStore } = useRootStore();

  const { hasFetched, loadBookings, setParameters, setQueryString } = BookingStore;

  const { search } = useLocation();

  useEffect(() => {
    if (!hasFetched) {
      const params = new URLSearchParams(search);

      const initialKeyword = params.has('keyword') ? params.get('keyword') : '';
      const initialBookingStatus = params.has('bookingStatus') ? params.get('bookingStatus') : '';
      const initialBookingLocation = params.has('location') ? params.get('location') : '';
      const initialCreatedBy = params.has('createdBy') ? params.get('createdBy') : '';

      const emptyParams = !initialKeyword && !initialBookingStatus && !initialBookingLocation && !initialCreatedBy;

      if (emptyParams) {
        loadBookings();
      }

      setParameters({
        keyword: initialKeyword,
        bookingStatus: initialBookingStatus,
        location: initialBookingLocation,
        createdBy: initialCreatedBy,
      });
    } else {
      setQueryString();
    }
  }, [hasFetched, loadBookings, search, setParameters, setQueryString]);

  return (
    <>
      <BookingCreateModal isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)} />
      <BookingFilterBar showModal={() => setIsModalOpen(true)} />
      <Content isListing>
        <BookingListing />
      </Content>
    </>
  );
};

export { Bookings };
