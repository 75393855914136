import { BrowserRouter } from 'react-router-dom';
import React from 'react';

import { BrandingProvider, useBranding } from './contexts/branding';
import { constants, GlobalCSS, UiLibraryProvider } from './components/ui-library';
import { Layout } from './components/layout';
import { Link } from './components/link';
import { logger } from './utils/logger';
import { Login } from './components/login';
import { Privilege } from './components/privilege';
import { StoreProvider } from './components/store-provider';

// merge the color mode with the base theme
// to create a new theme object
const getTheme = (branding) => ({
  ...constants.theme,
  colors: branding === 'bevChain' ? constants.colorsBevChain : constants.colors,
});

const App = () => {
  const branding = useBranding();

  return (
    <StoreProvider>
      <BrowserRouter>
        <Login />
        <BrandingProvider>
          <UiLibraryProvider
            branding={branding}
            theme={getTheme(branding)}
            LinkComponent={Link}
            PrivilegeComponent={Privilege}
            logger={logger}
          >
            <GlobalCSS />
            <Layout />
          </UiLibraryProvider>
        </BrandingProvider>
      </BrowserRouter>
    </StoreProvider>
  );
};

export default App;
