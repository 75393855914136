import { getRoot, types } from 'mobx-state-tree';
import { reaction } from 'mobx';

import { ClaimData } from './claim-data';
import { Contract } from './contract';

const User = types
  .model('User', {
    AADUserObjectID: types.maybeNull(types.string),
    Account: types.maybeNull(types.string),
    AccountID: types.maybeNull(types.string),
    AccountName: types.maybeNull(types.string),
    ContactID: types.maybeNull(types.string),
    Contract: types.maybeNull(types.string),
    EmailAddress: types.maybeNull(types.string),
    FullName: types.maybeNull(types.string),
    PhoneNumber: types.maybeNull(types.string),
    claimsSet: types.maybeNull(ClaimData),
    contracts: types.optional(types.array(Contract), []),
    isFetching: false,
  })
  .actions((self) => {
    const { UserStore } = getRoot(self);

    reaction(
      () => self.activeContracts.length,
      () => {
        UserStore.resetContactList();
      }
    );

    return {};
  })
  .views((self) => ({
    get activeContracts() {
      return self.contracts.filter(({ isActive }) => isActive === true);
    },
  }));

export { User };
