import { color, flex, layout, space as StyledSystemSpace } from 'styled-system';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { breakpoints, grid, space } from '../../constants';
import { Flex } from './flex';

const Container = ({ className, maxWidth, children, ...props }) => {
  const containerMaxWidth = maxWidth || grid.containerMaxWidth;

  return (
    <StyledContainer className={className} maxWidth={containerMaxWidth} {...props}>
      {children}
    </StyledContainer>
  );
};

const StyledContainer = styled(Flex)`
  ${StyledSystemSpace}
  ${flex}
  ${layout}
  ${color}

  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  padding-left: ${space.sm};
  padding-right: ${space.sm};
  width: 100%;

  @media (min-width: ${breakpoints.md}px) {
    padding-left: ${space.md};
    padding-right: ${space.md};
  }
`;

Container.propTypes = {
  className: PropTypes.string,
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.node,
};

Container.defaultProps = {
  className: null,
  maxWidth: null,
  children: null,
};

export { Container };
