import PropTypes from 'prop-types';

import { Privilege } from '../../privilege';
import { StyledLink } from '../styled-link';

const NavigationLink = ({ isActive, to, privilegeName, onClick, children }) => (
  <Privilege name={privilegeName}>
    <StyledLink {...{ to, onClick, isActive, 'data-testid': `link-${to}` }}>{children}</StyledLink>
  </Privilege>
);

NavigationLink.propTypes = {
  children: PropTypes.node.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  privilegeName: PropTypes.string,
  to: PropTypes.string.isRequired,
};

NavigationLink.defaultProps = {
  privilegeName: null,
};

export { NavigationLink };
