const fontSize = {
  xxxLarge: '3.25rem',
  xxLarge: '2.5rem',
  xLarge: '2rem',
  large: '1.5rem',
  medium: '1.25rem',
  base: '1rem',
  small: '0.8333333333rem',
  xSmall: '0.75rem',
  xxSmall: '0.6666666667rem',
  xxxSmall: '0.5833333333rem',
  xxxxSmall: '0.5rem',
};

export { fontSize };
