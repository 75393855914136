import PropTypes from 'prop-types';

import { Box } from '../grid';
import { Button } from '../button';
import { FilterPanelLayout } from './filter-panel-layout';
import { Form, SubmitButton, useFormState } from '../form';
import { Input } from '../form/input';

const FilterTypeDate = ({ defaultValues, onSubmit, onCancel, showDayButtons }) => {
  const handleOnSubmit = ({ date }) => {
    const { day, from, to } = date;

    onSubmit({ date: { day, from, to: to || from } });
  };

  return (
    <Form onSubmit={handleOnSubmit} defaultValues={{ date: defaultValues }}>
      <FilterPanelLayout
        title="Choose date"
        onCancel={onCancel}
        body={<Input name="date" type="calendarRange" />}
        footer={
          <>
            {showDayButtons ? <DayButtons handleOnSubmit={handleOnSubmit} /> : <Box />}
            <SubmitButton>Apply</SubmitButton>
          </>
        }
      />
    </Form>
  );
};

FilterTypeDate.propTypes = {
  defaultValues: PropTypes.shape({
    from: PropTypes.instanceOf(Date),
    to: PropTypes.instanceOf(Date),
    label: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  showDayButtons: PropTypes.bool,
};

FilterTypeDate.defaultProps = {
  defaultValues: {},
  onSubmit: () => null,
  onCancel: () => null,
  showDayButtons: true,
};

const DayButtons = ({ handleOnSubmit }) => {
  const { setValue } = useFormState();

  // When user selects the 'Today' button
  const handleSelectToday = () => {
    const day = new Date();

    const dateObj = {
      day: 'Today',
      from: day,
      to: day,
    };

    // required to update the selected date in the calendar
    setValue('date', dateObj);

    handleOnSubmit({ date: dateObj, label: 'Today' });
  };

  // When user selects the 'Tomorrow' button
  const handleSelectTomorrow = () => {
    const day = new Date();

    day.setDate(day.getDate() + 1);

    const dateObj = {
      day: 'Tomorrow',
      from: day,
      to: day,
    };

    // required to update the selected date in the calendar
    setValue('date', dateObj);

    handleOnSubmit({ date: dateObj, label: 'Tomorrow' });
  };

  return (
    <Box>
      <Button onClick={handleSelectToday} mr="0.5rem">
        Today
      </Button>
      <Button onClick={handleSelectTomorrow}>Tomorrow</Button>
    </Box>
  );
};

DayButtons.propTypes = {
  handleOnSubmit: PropTypes.func.isRequired,
};

export { FilterTypeDate };
