import PropTypes from 'prop-types';
import styled from 'styled-components';

import { AddressString } from '../../../../address-string';
import { icons, Label } from '../../../../ui-library';

const { Cross } = icons;

const LocationBadge = ({ location, onClick }) => (
  <AddressWrapper>
    <AddressString location={location} includeLocationName />
    <ClearButton type="button" onClick={onClick} />
  </AddressWrapper>
);

const AddressWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.greyOne};
  border: 1px solid ${({ theme }) => theme.colors.greyTwo};
  border-radius: 6px;
  align-items: center;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 1rem;
  grid-template-columns: 1fr max-content;
  padding: 1rem;
  grid-column: span 2;
  line-height: 1.2rem;

  ${Label} {
    font-size: ${({ theme }) => theme.fontSize.small};
    line-height: 1rem;
  }

  svg {
    fill: ${({ theme }) => theme.colors.greyEight};
    height: 24px;
    width: 24px;
  }
`;

const ClearButton = styled(Cross)`
  cursor: pointer;
`;

LocationBadge.propTypes = {
  location: PropTypes.shape({
    address1: PropTypes.string.isRequired,
    locationName: PropTypes.string.isRequired,
    suburb: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    postcode: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export { LocationBadge };
