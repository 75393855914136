import PropTypes from 'prop-types';

const navigationRoutesPropType = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string,
    icon: PropTypes.node,
    privilegeName: PropTypes.string,
    to: PropTypes.string,
  })
);

export { navigationRoutesPropType };
