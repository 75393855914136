import PropTypes from 'prop-types';

import { colors } from '../../../constants';

const RightChevron = ({ fill, ...rest }) => (
  <svg width="8" height="12" viewBox="0 0 8 12" xmlns="http://www.w3.org/2000/svg" fill={fill} {...rest}>
    <path d="M1.19249 1.02745C1.00624 1.21481 0.901702 1.46826 0.901702 1.73245C0.901702 1.99663 1.00624 2.25009 1.19249 2.43745L4.7325 6.02745L1.1925 9.56745C1.00624 9.75481 0.901703 10.0083 0.901703 10.2724C0.901703 10.5366 1.00624 10.7901 1.1925 10.9774C1.28546 11.0712 1.39606 11.1456 1.51792 11.1963C1.63978 11.2471 1.77048 11.2732 1.9025 11.2732C2.03451 11.2732 2.16521 11.2471 2.28707 11.1963C2.40893 11.1456 2.51953 11.0712 2.6125 10.9774L6.85249 6.73745C6.94622 6.64449 7.02062 6.53388 7.07139 6.41203C7.12215 6.29017 7.14829 6.15946 7.14829 6.02745C7.14829 5.89544 7.12215 5.76473 7.07139 5.64287C7.02062 5.52101 6.94622 5.41041 6.85249 5.31745L2.61249 1.02745C2.51953 0.933721 2.40893 0.859326 2.28707 0.808557C2.16521 0.757789 2.03451 0.731649 1.90249 0.731649C1.77048 0.731649 1.63978 0.757789 1.51792 0.808557C1.39606 0.859326 1.28546 0.933721 1.19249 1.02745Z" />
  </svg>
);

RightChevron.propTypes = {
  fill: PropTypes.string,
};

RightChevron.defaultProps = {
  fill: colors.white,
};

export { RightChevron };
