import moment from 'moment';

const formatDate = (date, dateFormat = '') => {
  const timestamp = Date.parse(date);
  if (!Number.isNaN(timestamp)) {
    const isSameYear = moment().isSame(moment(date), 'year');
    const defaultDateFormat = isSameYear ? 'D MMM, h:mm A' : 'D MMM YYYY, h:mm A';
    return moment(date).format(dateFormat || defaultDateFormat);
  }

  if (!date) {
    return '';
  }

  return 'Invalid date';
};

const formatDateEta = (from, to, actual) => {
  if (actual) {
    return formatDate(actual);
  }
  if (from && to) {
    const isSameDay = moment(from).isSame(moment(to), 'day');
    const fromDateTime = formatDate(from);
    const toDateTime = isSameDay ? formatDate(to, 'h:mm A') : formatDate(to);
    return `${fromDateTime} - ${toDateTime}`;
  }

  return '';
};

export { formatDate, formatDateEta };
