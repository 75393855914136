import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colors } from '../../../constants';
import { CrossWithBackground, TickWithBackground } from '../../icons';
import { Flex } from '../../grid';

const DefectItem = ({ defect, targetDefectNumber, success }) => (
  <Wrapper p="1rem" alignItems="center" justifyContent="space-between" success={success}>
    <Flex flex={7} alignItems="center">
      {success ? <SuccessIcon /> : <FailedIcon />}
      {defect.faultCategory}
    </Flex>
    {success && (
      <Flex flex={3} fontWeight="bold">
        {targetDefectNumber}
      </Flex>
    )}
  </Wrapper>
);

const Wrapper = styled(Flex)`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.greyTwo};
  background-color: ${({ theme, success }) => (success ? theme.colors.greyOne : theme.colors.white)};
  font-size: ${({ theme }) => theme.fontSize.small};

  :not(:last-child) {
    margin-bottom: 0.5rem;
  }

  svg {
    margin-right: 0.5rem;
    width: 20px;
    height: 20px;
    min-width: 20px;
  }
`;

const SuccessIcon = styled(TickWithBackground).attrs(() => ({
  backgroundColor: `${colors.successTen}`,
}))``;

const FailedIcon = styled(CrossWithBackground).attrs(() => ({
  backgroundColor: `${colors.dangerSeven}`,
}))``;

DefectItem.propTypes = {
  defect: PropTypes.shape({
    faultCategory: PropTypes.string,
    faultDescription: PropTypes.string,
  }).isRequired,
  targetDefectNumber: PropTypes.string,
  success: PropTypes.bool.isRequired,
};

DefectItem.defaultProps = {
  targetDefectNumber: null,
};

export { DefectItem };
