import { observer } from 'mobx-react';

import { Accordion, Box, DetailsList, Flex } from '../ui-library';
import { TogglePermission } from './toggle-permission';
import { useRootStore } from '../store-provider/lib';

const { DetailsTitle } = DetailsList;

const permissionLabels = {
  booking: 'Online Bookings',
  track: 'Track & Trace',
  case: 'Case Management',
  'eDRR - Admin': 'Admin',
  'eDRR - User': 'Defect',
};

const ContractPermissions = () => {
  const { UserStore } = useRootStore();

  const { selected } = UserStore;

  const { contracts } = selected;

  return (
    <Flex flexDirection="column">
      {contracts.map((contract, index) => (
        <Box mb="2rem" key={contract.ContractID}>
          <Accordion
            isOpen={contract.isActive}
            isOpenText="Revoke Access"
            isClosedText="Enable Access"
            onClick={contract.isActive ? contract.disable : contract.enable}
            title={<DetailsTitle subtitle>{contract.ContractName || `Contract ${index + 1}`}</DetailsTitle>}
            body={contract.permissions.map((permission) => (
              <TogglePermission
                key={permission.PermissionID}
                title={permissionLabels[permission.PermissionItemID]}
                onClick={async () => {
                  if (permission.isActive) {
                    await permission.disable();
                    return;
                  }

                  await permission.enable();
                }}
                isActive={permission.isActive}
              />
            ))}
          />
        </Box>
      ))}
    </Flex>
  );
};

const ObservedContractPermissions = observer(ContractPermissions);
export { ObservedContractPermissions as ContractPermissions };
