import PropTypes from 'prop-types';

const Bullet = ({ fill, ...rest }) => (
  <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 6C4.65 6 6 4.65 6 3C6 1.35 4.65 0 3 0C1.35 0 0 1.35 0 3C0 4.65 1.35 6 3 6Z"
      fill={fill}
    />
  </svg>
);

Bullet.propTypes = {
  fill: PropTypes.string,
};

Bullet.defaultProps = {
  fill: '#fff',
};

export { Bullet };
