import PropTypes from 'prop-types';

const AddressString = ({ location }) => {
  const { locationName, address1, suburb, state, postcode } = location || {};

  const address = [locationName, address1, suburb, state, postcode].filter(Boolean).join(', ');

  return address;
};

AddressString.propTypes = {
  includeLocationName: PropTypes.bool,
  location: PropTypes.shape({
    address1: PropTypes.string.isRequired,
    suburb: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    postcode: PropTypes.string.isRequired,
  }).isRequired,
};

AddressString.defaultProps = {
  includeLocationName: false,
};

export { AddressString };
