import PropTypes from 'prop-types';

import { P } from '../ui-library';

const BookingSpecialConsiderations = ({ data, ...props }) => {
  const { dangerousGoodsIndicator = false, urgentFlag = false, overDimensionalFlag = false } = data;

  const specialConsiderations = [];

  if (urgentFlag) {
    specialConsiderations.push('Urgent');
  }

  if (dangerousGoodsIndicator) {
    specialConsiderations.push('Contains Dangerous Goods');
  }

  if (overDimensionalFlag) {
    specialConsiderations.push('Over Dimensions');
  }

  if (specialConsiderations.length === 0) return null;

  return (
    <P color="warningTen" {...props}>
      {specialConsiderations.join(', ')}
    </P>
  );
};

BookingSpecialConsiderations.propTypes = {
  data: PropTypes.shape({
    dangerousGoodsIndicator: PropTypes.bool,
    overDimensionalFlag: PropTypes.bool,
    urgentFlag: PropTypes.bool,
  }).isRequired,
};

export { BookingSpecialConsiderations };
