import styled from 'styled-components';

import { hideOnDesktop, hideOnMobile } from '../../utils/responsive-styles';

const ShowOn = styled.div`
  display: contents;

  ${({ screenSize }) => {
    switch (screenSize) {
      case 'desktop':
        return hideOnMobile;
      case 'mobile':
      default:
        return hideOnDesktop;
    }
  }};
`;

export { ShowOn };
