import { createContext, useContext } from 'react';

const BookingContext = createContext();

const { Provider } = BookingContext;

const useBookingState = () => {
  const context = useContext(BookingContext);

  if (context === undefined) {
    throw new Error('Component must be used within a BookingProvider.');
  }

  return context || {};
};

export { Provider as BookingContextProvider, useBookingState };
