import PropTypes from 'prop-types';
import styled from 'styled-components';

const BookingInformation = ({ children, isMultiColumn }) => (
  <Wrapper>
    <Content isMultiColumn={isMultiColumn}>{children}</Content>
  </Wrapper>
);

const Content = styled.div`
  display: grid;
  grid-template-columns: ${({ isMultiColumn }) => isMultiColumn && `repeat(auto-fill, minmax(26rem, 1fr))`};
  grid-template-rows: max-content;
  grid-column-gap: 4rem;
`;

// The wrapper handles the styling of the white box in which the information blocks sit for each section.
// This is to ensure two neighbouring sections share the same height, without the cells from shorter
// sections stretching to fill the available space.
// (This is achieved by setting `grid-template-rows: max-content`)
const Wrapper = styled.div`
  display: grid;
  background: white;
  border-radius: 0.25rem;
  border: 1px solid ${({ theme }) => theme.colors.greyThree};
  grid-template-rows: max-content;
  padding: 1rem;
`;

BookingInformation.propTypes = {
  isMultiColumn: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

BookingInformation.defaultProps = {
  isMultiColumn: true,
};

export { BookingInformation };
