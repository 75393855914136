/* eslint-disable react/no-array-index-key  */

import { isFunction } from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Checkbox, constants, Table } from '../ui-library';
import { DetailField } from '../detail-field';
import { DetailProvider } from '../detail-context';
import { skuFields } from '../../constants/case';
import { useTemplateState } from '../template-context';

const { TableBody, TableRow, TableLegend, TableCell } = Table;

const SKUTable = ({ items, onClick, SelectAllButton, selectedItems, toggleItem }) => {
  const isEditable = SelectAllButton && isFunction(toggleItem);

  const { normalisedFields } = useTemplateState();

  if (!items || items.length === 0) return null;

  // remove `caseDetails.skuDetails[0].` from apiField
  // depending on where the SKUTable is rendered, `caseDetails.` may or may not have already been removed
  const normalisedFieldsProxy = new Proxy(normalisedFields, {
    get(target, prop) {
      const { [prop]: field = null } = target;

      if (!field) return undefined;

      // We need to handle `condition` vs `conditionName`.
      // `condition` is used on the Case Create page, and will be the ID value.
      // `conditionName` will be used on the Case Detail page, and will be the friendly string.
      if (prop === 'condition') {
        // eslint-disable-next-line react/prop-types
        const { conditionName = null } = items[0];

        if (conditionName) {
          return {
            name: 'Condition',
            apiField: 'conditionName',
            fieldtype: 'Text',
          };
        }
      }

      const { apiField = '' } = field;

      return { ...field, apiField: apiField.replace('caseDetails.', '').replace('skuDetails[0].', '') };
    },
  });

  return (
    <TableWrapper isEditable={isEditable}>
      <TableLegend>
        {isEditable && <TableCell>{SelectAllButton}</TableCell>}
        <DetailProvider value={{ data: items[0], normalisedFields: normalisedFieldsProxy }}>
          {skuFields.map((field) => (
            <DetailField field={field} key={field}>
              {({ label }) => <TableCell>{label}</TableCell>}
            </DetailField>
          ))}
        </DetailProvider>
      </TableLegend>
      <TableBody>
        {items.map((item, index) => {
          return (
            <DetailProvider key={`skuRow${index}`} value={{ data: item, normalisedFields: normalisedFieldsProxy }}>
              <StyledTableRow
                isEditable={isEditable}
                onClick={() => {
                  if (isEditable) onClick(index);
                }}
              >
                {isEditable && (
                  <TableCell>
                    <Checkbox value={selectedItems.includes(index)} onChange={() => toggleItem(index)} size="sm" />
                  </TableCell>
                )}
                {skuFields.map((field) => (
                  <DetailField field={field} key={field}>
                    {({ value }) => <TableCell>{value}</TableCell>}
                  </DetailField>
                ))}
              </StyledTableRow>
            </DetailProvider>
          );
        })}
      </TableBody>
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  margin: 0 -0.5rem;

  @media (min-width: ${constants.breakpoints.xl}) {
    margin: 0;
  }

  ${TableCell} {
    overflow-wrap: anywhere;
    white-space: break-spaces;
  }

  ${TableRow} {
    grid-column-gap: 1rem;
    grid-template-columns: ${({ isEditable }) => (isEditable ? '1rem 1fr' : '1fr')};
  }

  ${TableLegend} {
    padding-left: ${({ theme }) => theme.space.xs};
    padding-right: ${({ theme }) => theme.space.xs};
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderColorOne};
  }

  ${TableBody} {
    box-shadow: none;
    border: 1px solid ${({ theme }) => theme.colors.borderColorOne};
    border-width: 0 1px;
    max-height: 50vh;
    overflow-y: auto;
  }
`;

const StyledTableRow = styled(TableRow)`
  min-height: 52px;
  padding: ${({ theme }) => theme.space.xxs} ${({ theme }) => theme.space.xs};
  white-space: nowrap;
  background: transparent;
  cursor: ${({ isEditable }) => isEditable && 'pointer'};

  & > * {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &:nth-child(odd) {
    background: ${({ theme }) => theme.colors.greyOne};
  }

  &:hover {
    background: ${({ theme }) => theme.colors.greyTwo};
  }
`;

SKUTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onClick: PropTypes.func,
  SelectAllButton: PropTypes.node,
  selectedItems: PropTypes.arrayOf(PropTypes.shape({})),
  toggleItem: PropTypes.func,
};

SKUTable.defaultProps = {
  onClick: () => null,
  SelectAllButton: null,
  selectedItems: null,
  toggleItem: null,
};

export { SKUTable };
