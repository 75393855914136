import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { BookingTemplateOptionForm } from './booking-template-option-form';
import { form, Modal, ModalFooterButtonWrapper } from '../ui-library';
import { useRootStore } from '../store-provider/lib';

const { Form, SubmitButton } = form;

const BookingCreateModal = ({ isOpen, closeModal }) => {
  const history = useHistory();
  const {
    BookingStore: { createBooking },
  } = useRootStore();

  const handleOnSubmit = async (values) => {
    const bookingID = await createBooking(values);

    closeModal();

    history.push({ pathname: `/bookings/draft/${bookingID}` });
  };

  return (
    <Form mode="onSubmit" onSubmit={handleOnSubmit}>
      <Modal
        isOpen={isOpen}
        closeModal={closeModal}
        title="New Booking"
        footer={
          <ModalFooterButtonWrapper closeModal={closeModal}>
            <SubmitButton>Create</SubmitButton>
          </ModalFooterButtonWrapper>
        }
      >
        <BookingTemplateOptionForm />
      </Modal>
    </Form>
  );
};

BookingCreateModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
};

BookingCreateModal.defaultProps = {
  isOpen: false,
};

const ObservedBookingCreateModal = observer(BookingCreateModal);
export { ObservedBookingCreateModal as BookingCreateModal };
