import PropTypes from 'prop-types';
import styled from 'styled-components';

import { LoadingIndicator } from '../icons/loading-indicator';
import { useAsyncOnClick } from '../../hooks/use-async-on-click';

const Accordion = ({ title, body, onClick, isOpen, isOpenText, isClosedText }) => {
  const [handleClick, isFetching] = useAsyncOnClick(onClick);

  const displayText = isOpen ? isOpenText : isClosedText;

  return (
    <>
      <AccordionTitle>
        {title}
        <AccordionToggle isOpen={isOpen} onClick={handleClick}>
          {isFetching && <StyledLoadingIndicator />}
          {!isFetching && displayText}
        </AccordionToggle>
      </AccordionTitle>
      {isOpen && body}
    </>
  );
};

const StyledLoadingIndicator = styled(LoadingIndicator).attrs(({ theme }) => ({
  color: theme.colors.interactionFive,
}))``;

const AccordionTitle = styled.div`
  position: relative;
`;

const AccordionToggle = styled.div`
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  position: absolute;
  top: 50%;
  right: 0;
  line-height: 20px;
  margin-top: -10px;
  color: ${({ theme, isOpen }) => (isOpen ? theme.colors.dangerSix : theme.colors.interactionSix)};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-right: ${({ theme }) => theme.space.sm};
  }

  :hover {
    text-decoration: underline;
  }
`;

Accordion.propTypes = {
  title: PropTypes.node.isRequired,
  body: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  isOpenText: PropTypes.string,
  isClosedText: PropTypes.string,
  onClick: PropTypes.func,
};

Accordion.defaultProps = {
  isOpen: false,
  onClick: () => null,
  isOpenText: 'Close',
  isClosedText: 'Reveal',
};

export { Accordion };
