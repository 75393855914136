import { observer } from 'mobx-react';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

import { constants, Header, icons, MaintenanceMessage, OverlayContainer, responsiveStyles } from '../ui-library';
import { Logo } from '../logo';
import { Routes } from '../../routes';
import { ToastNotifications } from '../toast-notifications';
import { useBranding } from '../../contexts/branding';
import { useFeatureFlag } from '../../hooks/use-feature-flag';
import { useRootStore } from '../store-provider/lib';

const { mobileOnly } = responsiveStyles;
const { contractPrivileges } = constants;

const Layout = () => {
  const branding = useBranding();
  const { AuthStore, SettingStore } = useRootStore();
  const { Truck, Users, Messages, Booking } = icons;

  const { params: { view: currentRoute = '' } = {} } = useRouteMatch('/:view/') || {};

  const { maintenanceMessage } = SettingStore;

  const navigationRoutes = [
    {
      enabled: useFeatureFlag('TRACK_AND_TRACE'),
      label: 'Track & Trace',
      icon: <Truck />,
      privilegeName: contractPrivileges.track,
      to: '/track',
    },
    {
      enabled: useFeatureFlag('CASE_MANAGEMENT'),
      label: 'Cases',
      icon: <Messages />,
      privilegeName: contractPrivileges.caseManagement,
      to: '/cases',
    },
    {
      enabled: useFeatureFlag('USER_MANAGEMENT'),
      label: 'Users',
      icon: <Users />,
      privilegeName: contractPrivileges.admin,
      to: '/users',
    },
    {
      enabled: useFeatureFlag('ONLINE_BOOKINGS'),
      label: 'Bookings',
      icon: <Booking />,
      privilegeName: contractPrivileges.booking,
      to: '/bookings',
    },
    {
      enabled: useFeatureFlag('DEFECTS'),
      label: 'Defects',
      icon: <Messages />,
      privilegeName: contractPrivileges.defect,
      to: '/defects/new',
    },
  ];

  return (
    <OverlayContainer>
      {maintenanceMessage && <MaintenanceMessage message={maintenanceMessage} />}
      <ToastNotifications />
      <Header
        isSignedIn={AuthStore.isSignedIn}
        navigationRoutes={navigationRoutes}
        login={AuthStore.login}
        logout={AuthStore.logout}
        user={AuthStore.user}
        isFetchingUser={AuthStore.isFetching}
        currentRoute={currentRoute}
      >
        <Logo branding={branding} />
      </Header>
      <Routes />
      <Portal id="portal" />
    </OverlayContainer>
  );
};

const Portal = styled.div`
  z-index: 10;

  ${mobileOnly} {
    position: relative;
  }
`;

const ObservedLayout = observer(Layout);
export { ObservedLayout as Layout };
