import PropTypes from 'prop-types';

import { colors } from '../../../constants/colors';

const ErrorWithBackground = ({ backgroundColor, fill, ...rest }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill={fill} {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5557 20.3147C15.9112 21.4135 13.9778 22 12 22C10.6868 22 9.38642 21.7413 8.17316 21.2388C6.95991 20.7363 5.85752 19.9997 4.92893 19.0711C4.00035 18.1425 3.26375 17.0401 2.7612 15.8268C2.25866 14.6136 2 13.3132 2 12C2 10.0222 2.58649 8.08879 3.68531 6.4443C4.78412 4.79981 6.3459 3.51808 8.17317 2.76121C10.0004 2.00433 12.0111 1.8063 13.9509 2.19215C15.8907 2.57801 17.6725 3.53041 19.0711 4.92894C20.4696 6.32746 21.422 8.10929 21.8079 10.0491C22.1937 11.9889 21.9957 13.9996 21.2388 15.8268C20.4819 17.6541 19.2002 19.2159 17.5557 20.3147ZM11.9959 6C11.6776 6 11.3724 6.12643 11.1474 6.35146C10.9223 6.5765 10.7959 6.88172 10.7959 7.19997V11.9999C10.7959 12.3181 10.9223 12.6233 11.1474 12.8484C11.3724 13.0734 11.6776 13.1998 11.9959 13.1998C12.3141 13.1998 12.6194 13.0734 12.8444 12.8484C13.0694 12.6233 13.1959 12.3181 13.1959 11.9999V7.19997C13.1959 6.88172 13.0694 6.5765 12.8444 6.35146C12.6194 6.12643 12.3141 6 11.9959 6ZM13.0999 16.3438C13.0736 16.2673 13.0373 16.1947 12.9919 16.1278L12.8479 15.9478C12.6791 15.7813 12.4648 15.6685 12.2321 15.6236C11.9993 15.5788 11.7584 15.6039 11.5399 15.6958C11.3945 15.7565 11.2605 15.8418 11.1439 15.9478C11.0327 16.0599 10.9447 16.1929 10.885 16.3391C10.8253 16.4853 10.795 16.6418 10.7959 16.7997C10.7978 16.9566 10.8304 17.1115 10.8919 17.2557C10.9458 17.4046 11.0318 17.5399 11.1438 17.6519C11.2558 17.7638 11.391 17.8498 11.5399 17.9037C11.6835 17.9672 11.8388 18 11.9959 18C12.1529 18 12.3082 17.9672 12.4519 17.9037C12.6008 17.8498 12.736 17.7638 12.848 17.6519C12.96 17.5399 13.046 17.4046 13.0999 17.2557C13.1614 17.1115 13.194 16.9566 13.1959 16.7997C13.2018 16.7199 13.2018 16.6396 13.1959 16.5598C13.1752 16.4832 13.1428 16.4104 13.0999 16.3438Z"
      fill={backgroundColor}
    />
  </svg>
);

ErrorWithBackground.propTypes = {
  backgroundColor: PropTypes.string,
  fill: PropTypes.string,
};

ErrorWithBackground.defaultProps = {
  backgroundColor: colors.dangerSeven,
  fill: colors.white,
};

export { ErrorWithBackground };
