import { find, map, some } from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { AdditionalReferences } from '../additional-references';
import { Box, DetailsList, StatusLabel } from '../../../components/ui-library';
import { caseStatuses } from '../../../constants/statuses';
import { getBrandStatus } from '../../../utils/get-brand-status';
import { getStatus } from '../../../utils/get-status';
import { SKUDetails } from '../../../components/sku-details';
import { useBranding } from '../../../contexts/branding';

const { DetailsListItem } = DetailsList;

const CaseDetails = ({ additionalReferences, groupedFields, skuDetails, statusFields, statusName }) => {
  const branding = useBranding();

  const resolution = {
    date: find(statusFields, ['apiField', 'resolutionDateTime']),
    message: find(statusFields, ['apiField', 'correctiveActions']),
  };

  return (
    <Wrapper>
      {resolution.date?.value && resolution.message?.value && (
        <>
          <Resolution>
            <Box as="dt" fontWeight="bold">
              Resolution
            </Box>
            <Box as="dd" ml={0} mt="xs">
              {resolution.message.value}
            </Box>
            <Box position="absolute" top="xs" right="sm">
              {resolution.date.value}
            </Box>
          </Resolution>
          <Divider />
        </>
      )}
      <StyledDetailsListItem title="Status">
        <StatusLabel branding={branding} type="case" variant={getStatus(caseStatuses, statusName).variant}>
          {getBrandStatus(branding, statusName)}
        </StatusLabel>
      </StyledDetailsListItem>
      {groupedFields.length > 0 &&
        map(
          groupedFields,
          (group) =>
            group.length > 0 && (
              <>
                {map(group, ({ id, name, value }) => (
                  <DetailsListItem key={id} title={name} clampLines={id === 'description' ? 3 : 0}>
                    {value ?? '-'}
                  </DetailsListItem>
                ))}
                {some(group, ['id', 'orderNumber']) && (
                  <DetailsListItem key="skuDetails" title="SKU Details">
                    {skuDetails.length > 0 && <SKUDetails items={skuDetails} />}
                  </DetailsListItem>
                )}
                <Divider />
              </>
            )
        )}
      {additionalReferences.length > 0 && (
        <>
          <AdditionalReferences additionalReferences={additionalReferences} />
          <Divider />
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  background-color: ${({ theme }) => theme.colors.greyOne};
  padding: ${({ theme }) => theme.space.xs} ${({ theme }) => theme.space.sm};
`;

const Resolution = styled(Box)`
  background-color: ${({ theme }) => theme.colors.successOne};
  border: 1px solid ${({ theme }) => theme.colors.successNine};
  border-radius: 4px;
  font-size: 14px;
  padding: ${({ theme }) => theme.space.xs} ${({ theme }) => theme.space.sm};
  position: relative;
`;

const StyledDetailsListItem = styled(DetailsListItem)`
  align-items: center;
`;

const Divider = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.greyThree};
  margin: ${({ theme }) => theme.space.xs} ${({ theme }) => theme.space.sm};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin: ${({ theme }) => theme.space.sm};
  }
`;

CaseDetails.propTypes = {
  groupedFields: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        apiField: PropTypes.string,
        id: PropTypes.string,
        name: PropTypes.string,
        type: PropTypes.string,
        validation: PropTypes.string,
        value: PropTypes.string,
      })
    )
  ).isRequired,
  skuDetails: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  statusFields: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  statusName: PropTypes.string.isRequired,
  additionalReferences: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export { CaseDetails };
