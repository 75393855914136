import PropTypes from 'prop-types';

import { useFormState } from '../..';

const HiddenInput = ({ name, ...props }) => {
  const { register } = useFormState();

  return <input name={name} {...props} type="hidden" ref={register} />;
};

HiddenInput.propTypes = {
  name: PropTypes.string.isRequired,
};

export { HiddenInput };
