import { space } from 'styled-system';
import styled, { css } from 'styled-components';

const DetailsTitle = styled.h2`
  color: ${({ theme }) => theme.colors.greyNine};
  font-size: ${({ theme }) => theme.fontSize.medium};
  border-bottom: solid 1px ${({ theme }) => theme.colors.greyTwo};
  position: relative;
  padding: ${({ theme }) => theme.space.sm};
  margin: 0 -${({ theme }) => theme.space.sm};
  ${space}

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin: 0;
    padding-left: 0;
  }

  ${({ subtitle }) =>
    subtitle &&
    css`
      font-size: ${({ theme }) => theme.fontSize.base};

      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        padding-left: ${({ theme }) => theme.space.sm};
      }
    `}
`;

export { DetailsTitle };
