import { differenceInHours, format, formatDistanceToNowStrict } from 'date-fns';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useCaseListingTemplateContext } from '../../contexts/case-listing-template';

const formatRelativeTimestamp = (date) => {
  const difference = formatDistanceToNowStrict(date, { addSuffix: true });

  // format the strings to be shorter
  return difference
    .replace(/ second(s)?/, 's')
    .replace(/ minute(s)?/, 'm')
    .replace(/ hour(s)?/, 'h')
    .replace(/ day(s)?/, 'd')
    .replace(/ week(s)?/, 'w')
    .replace(/ month(s)?/, 'mth')
    .replace(/ year(s)?/, 'y');
};

const RelativeTimeStamp = ({ date, showDistanceString }) => {
  const { columns } = useCaseListingTemplateContext();

  const newHourOffset = columns.find(({ id }) => id === 'newHourOffset');
  const oldHourOffset = columns.find(({ id }) => id === 'oldHourOffset');

  // if date is not set, casting it as a Date below will result in Jan 1, 1970 being returned.
  if (!date) {
    return null;
  }

  const now = new Date();
  const createdDateTime = new Date(date);

  const distanceString = formatRelativeTimestamp(createdDateTime);

  if (newHourOffset) {
    if (differenceInHours(now, createdDateTime) < newHourOffset.default) {
      if (showDistanceString) return <NewTime>{distanceString}</NewTime>;
    }
  }

  if (oldHourOffset) {
    if (differenceInHours(now, createdDateTime) > oldHourOffset.default) {
      return (
        <Time>
          {format(createdDateTime, 'd LLL yyyy')}
          <br /> {showDistanceString && <OldTime>{distanceString}</OldTime>}
        </Time>
      );
    }
  }

  return <Time>{format(createdDateTime, 'd LLL yyyy')}</Time>;
};

const Time = styled.div`
  justify-self: center;
`;

const NewTime = styled(Time)`
  background-color: ${({ theme }) => theme.colors.successOne};
  border-radius: 1rem;
  color: ${({ theme }) => theme.colors.successNine};
  font-size: 0.8rem;
  font-weight: bold;
  padding: 0.5rem 1.5rem;
`;

const OldTime = styled.div`
  color: ${({ theme }) => theme.colors.dangerNine};
  font-size: 0.8rem;
  padding-top: 0.5rem;
  text-align: center;
`;

RelativeTimeStamp.propTypes = {
  date: PropTypes.string.isRequired,
  showDistanceString: PropTypes.bool,
};
RelativeTimeStamp.defaultProps = {
  showDistanceString: true,
};

export { RelativeTimeStamp };
