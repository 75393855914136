import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Content, FilterBarWrapper, Search, usePageTitle } from '../../components/ui-library';
import { UserListing } from '../../components/user-listing';
import { useRootStore } from '../../components/store-provider/lib';

const Users = () => {
  usePageTitle('Users');
  const { search } = useLocation();
  const { UserStore } = useRootStore();

  const { keyword, getAllContacts, hasFetchedContacts, setParameters } = UserStore;
  const [searchActive, setSearchActive] = useState(!!keyword);

  useEffect(() => {
    const params = new URLSearchParams(search);

    const initialKeyword = params.has('keyword') ? params.get('keyword') : '';

    if (!initialKeyword || initialKeyword === keyword) {
      if (!hasFetchedContacts) {
        getAllContacts();
      }
      return;
    }

    setParameters({ keyword: initialKeyword });
    // eslint-disable-next-line
  }, [setParameters]);

  useEffect(() => {
    setSearchActive(!!keyword);
  }, [keyword]);

  return (
    <>
      <FilterBarWrapper>
        <Search
          searchActive={searchActive}
          setSearchActive={setSearchActive}
          keyword={keyword}
          placeholder="Search users by Name or Email"
          onSubmit={setParameters}
        />
        {!searchActive && (
          <StyledButton ml="1rem" to="/users/invite">
            Add User
          </StyledButton>
        )}
      </FilterBarWrapper>
      <Content isListing>
        <UserListing />
      </Content>
    </>
  );
};

const StyledButton = styled(Button)`
  width: max-content;
  justify-self: end;
`;

const ObservedUsers = observer(Users);
export { ObservedUsers as Users };
