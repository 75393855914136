import { createGlobalStyle } from 'styled-components';
import { useState } from 'react';

import { BookingDraftHeaderBar } from '../../components/booking-draft-header-bar';
import { BookingForm } from '../../components/booking-form';
import { BookingLayout } from '../../components/booking-layout';
import { BookingProvider } from '../../components/booking-provider';
import { BookingReviewPanel } from '../../components/booking-review-panel';
import { DiscardChangesProvider } from '../../components/discard-changes-provider';
import { usePageTitle } from '../../components/ui-library';

const BookingCreate = () => {
  usePageTitle('Create Booking');

  const [visibleSection, setVisibleSection] = useState();

  const sectionNodes = [];

  const addSection = (node) => {
    sectionNodes.push(node);
  };

  const bookingProviderState = {
    addSection,
    visibleSection,
    setVisibleSection,
  };

  return (
    <>
      <PageStyles />
      <DiscardChangesProvider>
        <BookingProvider value={bookingProviderState}>
          <BookingDraftHeaderBar />
          <BookingLayout>
            <BookingReviewPanel />
            <BookingForm />
          </BookingLayout>
        </BookingProvider>
      </DiscardChangesProvider>
    </>
  );
};

const PageStyles = createGlobalStyle`
  body {
    overflow: hidden;
  }

  body > div {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
`;

export { BookingCreate };
