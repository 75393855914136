import { createContext, useContext, useMemo } from 'react';

const TemplateContext = createContext();

const { Provider } = TemplateContext;

const useTemplateState = () => {
  const context = useContext(TemplateContext);

  if (context === undefined) {
    throw new Error('Component must be used within a TemplateProvider.');
  }

  const { formData, templateData } = context;

  const { fields = [] } = templateData;

  // Normalise fields object
  const normalisedFields = useMemo(
    () =>
      fields.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.id]: cur,
        }),
        {}
      ),
    [fields]
  );

  const apiFields = new Proxy(normalisedFields, {
    get(target, prop) {
      const { [prop]: field = {} } = target;

      const { apiField = null } = field;

      return apiField;
    },
  });

  return { apiFields, fields, normalisedFields, formData, templateData } || {};
};

export { Provider as TemplateContextProvider, useTemplateState };
