import PropTypes from 'prop-types';

const Download = ({ fill, ...rest }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path d="M21.5272 16.3324V20.5501C21.5272 20.6345 21.515 20.7123 21.4972 20.7845C21.4129 21.1989 21.0472 21.5101 20.615 21.5101H3.37727C2.91508 21.5101 2.53166 21.1557 2.48289 20.7057C2.4707 20.6514 2.4707 20.6035 2.4707 20.5435V16.3258C2.4707 15.828 2.87851 15.4258 3.3707 15.4258C3.61632 15.4258 3.84509 15.528 4.00632 15.6902C4.16851 15.8524 4.2707 16.0802 4.2707 16.3258V19.7035H19.7329V16.3258C19.7329 15.828 20.1408 15.4258 20.6329 15.4258C20.8786 15.4258 21.1073 15.528 21.2686 15.6902C21.4251 15.858 21.5273 16.0858 21.5273 16.3324L21.5272 16.3324Z" />
    <path d="M17.1898 12.1076L12.7675 16.5298C12.7619 16.542 12.7497 16.5476 12.7432 16.5542C12.581 16.7164 12.371 16.8186 12.161 16.8485C12.1432 16.8485 12.1254 16.8542 12.1066 16.8542C12.071 16.8598 12.0344 16.8598 11.9988 16.8598L11.8966 16.8542C11.8788 16.8542 11.861 16.8485 11.8422 16.8485C11.6266 16.8186 11.4222 16.7164 11.26 16.5542C11.2544 16.5485 11.2422 16.5364 11.2357 16.5298L6.81343 12.1076C6.60905 11.9032 6.50781 11.6332 6.50781 11.3632C6.50781 11.0932 6.61 10.8232 6.81343 10.6188C7.22124 10.211 7.88781 10.211 8.30124 10.6188L10.959 13.2765L10.9618 3.53975C10.9618 2.96413 11.4362 2.48975 12.0118 2.48975C12.2996 2.48975 12.564 2.60975 12.7562 2.79536C12.9484 2.98755 13.0618 3.24536 13.0618 3.53975V13.2775L15.7196 10.6197C16.1274 10.2119 16.794 10.2119 17.2074 10.6197C17.5974 11.0341 17.5974 11.6997 17.1896 12.1076H17.1898Z" />
  </svg>
);

Download.propTypes = {
  fill: PropTypes.string,
};

Download.defaultProps = {
  fill: '#fff',
};

export { Download };
