import { createContext, useContext } from 'react';

const ModalContext = createContext();

const { Provider } = ModalContext;

const useModalState = () => {
  const context = useContext(ModalContext);

  if (context === undefined) {
    throw new Error('Component must be used within a ModalContextProvider.');
  }

  return context || {};
};

export { Provider as ModalContextProvider, useModalState };
