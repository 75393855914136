import { types } from 'mobx-state-tree';

import { Contract } from '../contract';

const Claim = types.model('Claim', {
  accountId: types.string,
  contactId: types.string,
  contracts: types.array(Contract),
  userId: types.string,
  userName: types.string,
});

export { Claim };
