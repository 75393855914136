import PropTypes from 'prop-types';
import styled from 'styled-components';

import { SelectArrow } from '../../ui-library';

const SortableColumn = ({ label, isDescending, onSortChange, isActive }) => {
  return (
    <Wrapper
      isActive={isActive}
      onClick={() => {
        onSortChange(!isDescending);
      }}
    >
      <StyledLabel>{label}</StyledLabel>
      <StyledSelectArrow isActive={isDescending} isActiveColumn={isActive} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  color: ${({ isActive, theme }) => (isActive ? theme.colors.greySeven : theme.colors.greyFour)};
  grid-auto-flow: column;
  grid-template-columns: max-content max-content;
  grid-gap: 1rem;
  align-items: center;
  cursor: pointer;
  margin: 0 auto;
`;

const StyledLabel = styled.div`
  justify-self: center;
`;

const StyledSelectArrow = styled(SelectArrow)`
  /* Required to avoid glitchy animations when used in the legend. */
  height: 1rem;
  width: 1rem;

  margin-left: 0;
  right: 0;

  path {
    fill: ${({ isActiveColumn, theme }) => (isActiveColumn ? theme.colors.greySeven : theme.colors.greyFour)};
  }

  &:hover {
    path {
      fill: ${({ theme }) => theme.colors.greyFour};
    }
  }
`;

SortableColumn.propTypes = {
  label: PropTypes.string.isRequired,
  isDescending: PropTypes.bool,
  onSortChange: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
};

SortableColumn.defaultProps = {
  isDescending: true,
  isActive: false,
};

export { SortableColumn };
