import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

import { TableRow } from './table-row';

const TableLoading = ({ rows, cols, ...props }) => (
  <>
    {[...Array(rows)].map((e, r) => (
      // eslint-disable-next-line react/no-array-index-key
      <StyledRow {...props} key={`row-${r}`} data-testid="table-loading-row">
        {[...Array(cols)].map((v, c) => (
          // eslint-disable-next-line react/no-array-index-key
          <LoadBar key={`col-${c}`} data-testid="table-loading-bar" />
        ))}
      </StyledRow>
    ))}
  </>
);

const LoadBar = styled.div`
  background: ${({ theme }) => theme.colors.greyTwo};
  border-radius: ${({ theme }) => theme.rem.sm};
  height: ${({ theme }) => theme.rem.sm};
  width: 80%;
`;

const animateHorz = keyframes`
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
`;

const StyledRow = styled(TableRow)`
  position: relative;
  background: ${({ theme }) => theme.colors.white};

  :hover {
    background-color: ${({ theme }) => theme.colors.white};
  }

  :before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    animation-name: ${animateHorz};
    animation-duration: 2.5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    background: linear-gradient(to right, #bbbbbb 2%, #666666 18%, #bbbbbb 33%);
    background-size: 50%;
    mix-blend-mode: overlay;
  }
`;

TableLoading.propTypes = {
  rows: PropTypes.number,
  cols: PropTypes.number,
};

TableLoading.defaultProps = {
  rows: 25,
  cols: 5,
};

export { TableLoading };
