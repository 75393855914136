import { down } from 'styled-breakpoints';
import styled from 'styled-components';

const TableRow = styled.div`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColorOne};
  color: ${({ theme }) => theme.colors.greySeven};
  box-sizing: border-box;
  display: grid;
  font-size: 14px;
  min-height: 72px;
  padding: 1.5rem;
  text-decoration: none;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  grid-column-gap: 2rem;
  background: white;

  :hover {
    background: ${({ theme }) => theme.colors.greyOne};
  }

  ${down('sm')} {
    padding: ${({ theme }) => theme.space.xs};
    font-size: 12px;
    line-height: 1.6;

    & > :first-child {
      text-transform: uppercase;
      font-size: 11px;
      font-weight: ${({ theme }) => theme.fontWeight.bold};
      color: ${({ theme }) => theme.colors.greyFour};
    }

    & > :last-child {
      text-align: right;
    }
  }
`;

export { TableRow };
