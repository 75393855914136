/* eslint-disable react/display-name */
import { createContext, useContext } from 'react';
import { includes } from 'lodash';
import PropTypes from 'prop-types';

const BrandingContext = createContext();

const BrandingProvider = ({ children }) => {
  const branding = includes(window.location.hostname, 'bevchain') ? 'bevChain' : 'linfox';

  return <BrandingContext.Provider value={branding}>{children}</BrandingContext.Provider>;
};

const useBranding = () => {
  const context = useContext(BrandingContext);

  if (context === undefined) {
    throw new Error('Component must be used within a BrandingProvider.');
  }

  return context || {};
};

export { BrandingProvider, BrandingContext, useBranding };

BrandingProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
