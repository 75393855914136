import { isEqual } from 'date-fns';
import { useMemo } from 'react';

import { form } from '../../ui-library';
import { useTemplateState } from '../../template-context';

const { useFormState } = form;

const useValidationRules = (validations = [], templateType) => {
  const { apiFields } = useTemplateState();

  const { getValues } = useFormState();

  return useMemo(
    () =>
      validations.reduce((acc, { type, error, ...fields }) => {
        switch (type) {
          case 'Required':
            // Checkboxes cannot be 'required'.
            if (templateType === 'Checkbox') return acc;

            return { ...acc, required: error };
          case 'Range': {
            const { limit } = fields;

            return { ...acc, maxLength: { message: error, value: parseInt(limit, 10) } };
          }
          case 'Max': {
            const { limit } = fields;

            return { ...acc, max: { message: error, value: parseInt(limit, 10) } };
          }
          case 'Min': {
            const { limit } = fields;

            return { ...acc, min: { message: error, value: parseInt(limit, 10) } };
          }
          case 'Compare': {
            const { compareField, operator } = fields;

            if (!compareField) {
              return acc;
            }

            const customValidator = (value) => {
              const comparedValue = getValues(apiFields[compareField]);

              if (!comparedValue) return error;

              switch (operator) {
                case '<':
                  return value < comparedValue || error;
                case '>':
                  return value > comparedValue || error;
                case '=':
                  return isEqual(value, comparedValue) || error;
                default:
                  return true;
              }
            };

            return { ...acc, validate: { ...(acc.validate || {}), [compareField]: customValidator } };
          }
          default:
            return acc;
        }
      }, {}),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};

export { useValidationRules };
