import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Drawer } from './drawer';
import { Flex } from '../grid';
import { Hamburger } from '../icons';
import { hideOnDesktop } from '../../utils/responsive-styles';
import { navigationRoutesPropType } from '../../prop-types';

const MobileNavigation = ({ getIsCurrentRoute, navigationRoutes, logout }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onOpenDrawer = () => {
    setIsOpen(!isOpen);
    // logEvent(analyticsCategory.NAV, 'Drawer', 'Open');
  };

  const onCloseDrawer = () => {
    setIsOpen(false);
    // logEvent(analyticsCategory.NAV, 'Drawer', 'Close');
  };

  return (
    <>
      <NavigationContainer onClick={onOpenDrawer} data-testid="mobile-navigation">
        <Hamburger />
      </NavigationContainer>
      <Drawer
        isOpen={isOpen}
        closeDrawer={onCloseDrawer}
        navigationRoutes={navigationRoutes}
        logout={logout}
        getIsCurrentRoute={getIsCurrentRoute}
      />
    </>
  );
};

const NavigationContainer = styled(Flex)`
  ${hideOnDesktop}
  z-index: 1;
`;

MobileNavigation.propTypes = {
  getIsCurrentRoute: PropTypes.func.isRequired,
  navigationRoutes: navigationRoutesPropType.isRequired,
  logout: PropTypes.func.isRequired,
};

export { MobileNavigation };
