import { createPortal } from 'react-dom';
import { forwardRef, useCallback, useMemo, useState } from 'react';
import { isEmpty, kebabCase, pick } from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BookingFormRow } from '../booking-form-row';
import { Box, form, H2 } from '../../ui-library';
import { FormDiscardChangesWrapper } from '../../discard-changes-provider';
import { formSectionMapping } from '../../../utils/form-section-mapping';
import { ModalContextProvider } from '../../modal-context';
import { SectionSummary } from './section-summary';
import { useBookingState } from '../../booking-context';
import { useTemplateState } from '../../template-context';

const { Form } = form;

const DIVIDER_HEIGHT = 48;
const formMaxWidth = '900px';

const SectionFormWrapper = forwardRef(({ sectionIcon, id, maxWidth, title, children, showDivider }, ref) => {
  const { sectionSummaryRefs, registerForm } = useBookingState();

  const { normalisedFields } = useTemplateState();

  const [modalContainer, setModalContainer] = useState();

  const mount = useCallback(
    (node) => {
      registerForm({ [id]: node });
    },
    [registerForm, id]
  );

  const keys = formSectionMapping[id];

  // only calculate sectionFields on first render
  // this relies on fields always being available: hence, this wrapper can only render
  // once data has been loaded, and relies on BookingLoadingScreen to block early renders.
  // If this value is calculated on every render, the following memoised functions
  // will not be consistent, which will have downstream impacts.
  const [sectionFields] = useState(() => pick(normalisedFields, keys));

  const defaultValues = useMemo(
    () =>
      Object.keys(sectionFields).reduce((acc, cur) => {
        const { apiField, default: defaultValue } = sectionFields[cur];

        return { ...acc, [apiField]: defaultValue };
      }, {}),
    [sectionFields]
  );

  if (isEmpty(sectionFields)) return null;

  return (
    <ModalContextProvider value={{ modalContainer }}>
      <Box ref={ref} id={id}>
        <Box p={4} pr={6} maxWidth={maxWidth} id={kebabCase(id)}>
          <BookingFormRow mb={4} icon={sectionIcon} iconHeight="32px" iconWidth="32px">
            <H2 m={0}>{title}</H2>
          </BookingFormRow>
          <div ref={setModalContainer} />
          <Form ref={mount} mode="onBlur" defaultValues={defaultValues}>
            <FormDiscardChangesWrapper>
              {children}
              {!!sectionSummaryRefs[id] && createPortal(<SectionSummary id={id} />, sectionSummaryRefs[id])}
            </FormDiscardChangesWrapper>
          </Form>
        </Box>
        {showDivider && <SectionDivider />}
      </Box>
    </ModalContextProvider>
  );
});

const SectionDivider = styled(Box)`
  background-color: ${({ theme }) => theme.colors.greyTwo};
  height: ${DIVIDER_HEIGHT}px;
  width: 100%;
`;

SectionFormWrapper.displayName = 'SectionFormWrapper';

SectionFormWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  maxWidth: PropTypes.string,
  id: PropTypes.string.isRequired,
  sectionIcon: PropTypes.node.isRequired,
  showDivider: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

SectionFormWrapper.defaultProps = {
  maxWidth: formMaxWidth,
  showDivider: true,
};

export { SectionFormWrapper, DIVIDER_HEIGHT };
