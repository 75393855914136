import { grid, layout, space, variant as styledVariant } from 'styled-system';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import styledPropTypes from '@styled-system/prop-types';

import { LoadingIndicator } from '../icons';
import { useAsyncOnClick } from '../../hooks/use-async-on-click';
import { Wrapper } from './wrapper';

const Button = ({ children, disabled, download, isSubmitting, onClick, variant, to, type, ...styledProps }) => {
  const [handleClick, isLoading] = useAsyncOnClick(onClick, isSubmitting);

  return (
    <StyledButton
      px="1rem"
      py="0.75rem"
      {...styledProps}
      disabled={disabled || isLoading}
      download={download}
      isLoading={isLoading}
      onClick={handleClick}
      to={to}
      type={type}
      variant={variant}
    >
      {!isLoading && children}
      {isLoading && (
        <IconContainer data-testid="loading-indicator">
          <LoadingIndicator variant={variant} />
        </IconContainer>
      )}
    </StyledButton>
  );
};

const buttonVariant = styledVariant({
  variants: {
    primary: {
      backgroundColor: 'primaryFour',
      borderColor: 'primaryFour',
      color: 'greyNine',
      '&:hover:not([disabled])': {
        backgroundColor: 'primaryFive',
        borderColor: 'primaryFive',
      },
    },
    // secondary: {
    //   backgroundColor: colors.white,
    //   borderColor: colors.white,
    //   color: colors.greyNine,
    //   '&:hover:not([disabled])': {
    //     backgroundColor: colors.primaryFive,
    //     borderColor: colors.primaryFive,
    //   },
    // },
    // tertiary: {
    //   backgroundColor: 'transparent',
    //   borderColor: 'transparent',
    //   color: colors.interactionSix,
    //   '&:hover:not([disabled])': {
    //     backgroundColor: 'transparent',
    //     borderColor: 'transparent',
    //     textDecoration: 'underline',
    //   },
    // },
    primaryTextButton: {
      backgroundColor: 'white',
      borderColor: 'white',
      color: 'greyNine',
      '&:hover:not([disabled])': {
        backgroundColor: 'primaryFive',
        borderColor: 'primaryFive',
      },
    },
    secondaryTextButton: {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      color: 'interactionSix',
      '&:hover:not([disabled])': {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        textDecoration: 'underline',
      },
    },
    secondary: {
      backgroundColor: 'white',
      borderColor: 'greyNine',
      color: 'greyNine',
      '&:hover:not([disabled])': {
        backgroundColor: 'black',
        borderColor: 'black',
        color: 'white',
      },
    },
    tertiary: {
      backgroundColor: 'greyTwo',
      borderColor: 'greyTwo',
      color: 'greyNine',
      '&:hover:not([disabled])': {
        backgroundColor: 'greyThree',
      },
    },
    destructive: {
      backgroundColor: 'dangerEight',
      borderColor: 'dangerEight',
      color: 'white',
      '&:hover:not([disabled])': {
        backgroundColor: 'dangerTen',
      },
    },
    iconOnly: {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      color: 'interactionSix',
      padding: 0,
      '&:hover:not([disabled])': {
        'svg > path': {
          fill: 'interactionSix',
        },
      },
      '&:focus:not([disabled])': {
        'svg > path': {
          fill: 'interactionSix',
        },
      },
    },
  },
});

const StyledButton = styled(({ isLoading, isFetching, variant, alignSelf, minWidth, ...props }) => (
  <Wrapper {...props} />
))`
  ${grid};
  ${layout};
  ${space};
  ${buttonVariant};

  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-align: center;
  text-decoration: none;
  white-space: nowrap;

  &:disabled {
    color: ${({ isLoading, theme }) => (isLoading ? 'transparent' : theme.colors.greySix)};
    cursor: not-allowed;

    ${({ isLoading, theme }) =>
      !isLoading &&
      css`
        background-color: ${theme.colors.greyThree};
        border-color: ${theme.colors.greyThree};
      `}
  }

  ${'' /* fix button styles on Safari  */}
  ${({ to }) =>
    to &&
    css`
      -webkit-appearance: none !important;
    `}
`;

const IconContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

Button.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  download: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onClick: PropTypes.func,
  to: PropTypes.string,
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'tertiary',
    'secondaryBookings',
    'tertiaryBookings',
    'destructive',
    'primaryTextButton',
    'secondaryTextButton',
  ]),
  ...styledPropTypes.flex,
  ...styledPropTypes.layout,
};

Button.defaultProps = {
  disabled: false,
  download: false,
  isSubmitting: false,
  onClick: () => {},
  to: null,
  type: 'button',
  variant: 'primary',
};

export { Button };
