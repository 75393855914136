import { format } from 'date-fns';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import { useDetailContext } from '../detail-context';

const DetailField = ({ children, field }) => {
  const { data, normalisedFields } = useDetailContext();

  const templateFieldData = normalisedFields[field];

  if (!templateFieldData) return null;

  const { apiField, name: label, options = [], type } = templateFieldData;

  let value = get(data, apiField, null);

  // Do not attempt to transform null values.
  if (value === null) {
    return children ? children({ label, value }) : value;
  }

  // If we have a valid value, we may need to transform the response.
  switch (type) {
    case 'Checkbox': {
      // The API may return this value as 'Yes' or `true`, depending on implementation.
      if (value === 'Yes') {
        break;
      }

      value = value === true ? 'Yes' : 'No';
      break;
    }
    case 'Date': {
      value = format(new Date(value), 'd LLL yyyy');
      break;
    }
    case 'Select': {
      // for Select fields, return the friendly text data
      // TODO: handle Multi-Select if necessary (this may involve design considerations)
      const { text = null } = options.find((option) => option.value === value) || {};

      value = text;
      break;
    }
    default:
  }

  return children ? children({ label, value }) : value;
};

DetailField.propTypes = {
  children: PropTypes.func,
  field: PropTypes.string.isRequired,
};

DetailField.defaultProps = {
  children: null,
};

export { DetailField };
