import { createGlobalStyle } from 'styled-components';
import { normalize } from 'polished';

const BaseStyles = createGlobalStyle`
  ${normalize}

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  *:focus {
    outline: none;
  }

  ::selection {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.black};
  }

  ::-moz-selection {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.black};
  }

  body {
    background: ${({ theme }) => theme.colors.white};
  }

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: inherit;
    outline: none;
    padding: 0;
  }

  ul,
  ol {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  figure {
    margin: 0;
  }

  img {
    height: auto;
    max-width: 100%;
    vertical-align: top;
  }

  svg {
    display: inline-block;
    vertical-align: top;
  }

  a,
  input,
  select,
  textarea,
  button {
    -webkit-tap-highlight-color: ${({ theme }) => theme.global.tapHighlightColor};
    transition-duration: 0.25s;
    transition-property: background-color, color, opacity, border, border-color, background-position, outline, box-shadow, border-bottom, text-decoration, left, top, transform;
    transition-timing-function: ${({ theme }) => theme.global.easing};
    transform-origin: center center;
  }
`;

export { BaseStyles };
