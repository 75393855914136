import PropTypes from 'prop-types';

const Status = ({ fill, ...rest }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill={fill} {...rest}>
    <path d="M21.71 7.29C21.617 7.19627 21.5064 7.12188 21.3846 7.07111C21.2627 7.02034 21.132 6.9942 21 6.9942C20.868 6.9942 20.7373 7.02034 20.6154 7.07111C20.4936 7.12188 20.383 7.19627 20.29 7.29L14 13.59L9.71 9.29C9.61704 9.19627 9.50644 9.12188 9.38458 9.07111C9.26272 9.02034 9.13201 8.9942 9 8.9942C8.86799 8.9942 8.73728 9.02034 8.61542 9.07111C8.49356 9.12188 8.38296 9.19627 8.29 9.29L2.29 15.29C2.19627 15.383 2.12188 15.4936 2.07111 15.6154C2.02034 15.7373 1.9942 15.868 1.9942 16C1.9942 16.132 2.02034 16.2627 2.07111 16.3846C2.12188 16.5064 2.19627 16.617 2.29 16.71C2.38296 16.8037 2.49356 16.8781 2.61542 16.9289C2.73728 16.9797 2.86799 17.0058 3 17.0058C3.13201 17.0058 3.26272 16.9797 3.38458 16.9289C3.50644 16.8781 3.61704 16.8037 3.71 16.71L9 11.41L13.29 15.71C13.383 15.8037 13.4936 15.8781 13.6154 15.9289C13.7373 15.9797 13.868 16.0058 14 16.0058C14.132 16.0058 14.2627 15.9797 14.3846 15.9289C14.5064 15.8781 14.617 15.8037 14.71 15.71L21.71 8.71C21.8037 8.61704 21.8781 8.50644 21.9289 8.38458C21.9797 8.26272 22.0058 8.13201 22.0058 8C22.0058 7.86799 21.9797 7.73728 21.9289 7.61542C21.8781 7.49356 21.8037 7.38296 21.71 7.29Z" />
  </svg>
);

Status.propTypes = {
  fill: PropTypes.string,
};

Status.defaultProps = {
  fill: '#fff',
};

export { Status };
