import { observer } from 'mobx-react';
import { useEffect } from 'react';

import { useRootStore } from '../store-provider/lib';

const Login = () => {
  const { AuthStore } = useRootStore();

  useEffect(() => {
    AuthStore.login({ acquireSilently: true });
  }, [AuthStore]);

  return null;
};

const ObservedLogin = observer(Login);
export { ObservedLogin as Login };
