import PropTypes from 'prop-types';

const Rail = ({ fill, ...rest }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...rest} fill={fill}>
    <path d="M13.6071 15.125C13.6071 15.9879 12.8876 16.6875 12 16.6875C11.1124 16.6875 10.3929 15.9879 10.3929 15.125C10.3929 14.2621 11.1124 13.5625 12 13.5625C12.8876 13.5625 13.6071 14.2621 13.6071 15.125ZM21 6.2857V15.2143C21 17.1359 19.244 18.7545 17.02 19.3046L19.3808 21.5999C19.5326 21.7475 19.4251 22 19.2103 22H17.2646C17.2012 22 17.1385 21.9879 17.08 21.9643C17.0216 21.9408 16.9684 21.9062 16.9236 21.8627L14.4934 19.5H9.50656L7.07636 21.8627C6.98594 21.9506 6.86331 22 6.73544 22H4.78971C4.57496 22 4.4674 21.7475 4.61924 21.5999L6.98005 19.3046C4.76328 18.7563 3 17.1428 3 15.2143V6.2857C3 3.79406 5.85272 2 8.55613 2H15.4439C18.183 2 21 3.79383 21 6.2857ZM4.92857 10.75H19.0714V7.625H4.92857V10.75ZM5.04003 5.75H18.9633C18.5534 4.72906 17.0359 3.875 15.4438 3.875H8.55613C6.99471 3.875 5.46194 4.72148 5.04003 5.75ZM19.0714 15.2143V12.625H4.92857V15.2143C4.92857 16.6287 7.01681 17.625 8.55613 17.625H15.4439C16.9612 17.625 19.0714 16.6413 19.0714 15.2143Z" />
  </svg>
);

Rail.propTypes = {
  fill: PropTypes.string,
};

Rail.defaultProps = {
  fill: '#000',
};

export { Rail };
