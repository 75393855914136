import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';

import { CaseCreateModal } from '../../components/case-create-modal';
import { CaseFilterBar } from '../../components/case-filter-bar';
import { CaseListing } from '../../components/case-listing';
import { CaseListingProvider } from '../../contexts/case-listing';
import { Content, usePageTitle } from '../../components/ui-library';
import { useRootStore } from '../../components/store-provider/lib';

const Cases = () => {
  usePageTitle('Cases');
  const { AuthStore } = useRootStore();
  const history = useHistory();

  const contracts = AuthStore.contractsByPrivilege('case');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleOpen = () => setIsModalOpen(!isModalOpen);
  const closeModal = () => setIsModalOpen(false);

  const navigateToCaseCreate = ({ contract }) => {
    history.push({ pathname: `/cases/new/${contract.contractId}` });
  };

  return (
    <>
      {contracts.length > 1 && (
        <CaseCreateModal
          contracts={contracts}
          isOpen={isModalOpen}
          closeModal={closeModal}
          handleSubmit={navigateToCaseCreate}
        />
      )}
      <CaseListingProvider>
        <CaseFilterBar
          handleCreateCase={() => {
            if (contracts.length > 1) {
              toggleOpen();
              return;
            }

            navigateToCaseCreate({ contract: contracts[0] });
          }}
          contracts={contracts}
        />
        <Content isListing>
          <CaseListing />
        </Content>
      </CaseListingProvider>
    </>
  );
};

const ObservedCases = observer(Cases);
export { ObservedCases as Cases };
