import PropTypes from 'prop-types';
import styled from 'styled-components';

import { H4, icons } from '../ui-library';

const BookingCell = ({ children, header, icon, color }) => {
  const { [icon]: IconComponent = null } = icons;

  return (
    <Cell>
      {IconComponent && (
        <IconWrapper color={color}>
          <Icon as={IconComponent} />
        </IconWrapper>
      )}
      <Header color={color}>{header}</Header>
      <Content>{children}</Content>
    </Cell>
  );
};

const Cell = styled.div`
  align-items: center;
  display: grid;
  grid-template-areas: 'icon header' '. content';
  grid-column-gap: 2rem;
  grid-row-gap: 0.5rem;
  grid-template-columns: 2rem 1fr;
  grid-template-rows: 2rem 1fr;
  padding: 1rem 2rem;

  a {
    color: ${({ theme }) => theme.colors.interactionSix};
    text-decoration: none;
  }
`;

const Icon = styled.div.attrs(() => ({ height: '1rem', width: '1rem' }))`
  justify-self: center;
  fill: ${({ theme }) => theme.colors.greyNine};
  grid-area: icon;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  background-color: ${({ color, theme }) => (color ? theme.colors[color] : theme.colors.greyTwo)};

  ${Icon} {
    fill: ${({ color, theme }) => (color ? theme.colors.white : theme.colors.greyNine)};
  }
`;

const Header = styled(H4)`
  color: ${({ color, theme }) => (color ? theme.colors[color] : theme.colors.greySeven)};
  grid-area: header;
`;

const Content = styled.div`
  align-self: start;
  color: ${({ theme }) => theme.colors.greySeven};
  grid-area: content;
`;

BookingCell.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node.isRequired,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  icon: PropTypes.oneOf(Object.keys(icons)).isRequired,
};

BookingCell.defaultProps = {
  color: undefined,
};

export { BookingCell };
