import { flow, getEnv, types } from 'mobx-state-tree';

import { Asset } from '../../models/asset';
import { Defect } from '../../models/defect';
import { DefectStatus } from '../../models/defect-status';

const DefectRequestStore = types
  .model({
    assets: types.optional(types.array(Asset), []),
    defects: types.optional(types.array(Defect), []),
    defectStatus: types.optional(types.array(DefectStatus), []),
  })
  .actions((self) => {
    const { api } = getEnv(self);

    return {
      raiseDefects: flow(function* (defects, isRetry = false) {
        if (!isRetry) {
          self.defects = defects;
        }

        // Clear asset list in preparation for subsequent defects to be raised.
        self.assets = [];

        const response = yield api.post('defect/new', {
          body: { defectData: defects },
        });

        const {
          message: { defectStatus },
        } = response.data;

        const updated = defectStatus.map(({ sourceDefectNumber, targetDefectNumber, targetStatus }) => ({
          defect: sourceDefectNumber,
          targetDefectNumber,
          targetStatus,
        }));

        self.defectStatus = updated;
      }),
      retryFailedRequests: flow(function* () {
        yield self.raiseDefects(
          self.failedRequests.map(({ defect }) => defect),
          true
        );
      }),
      setAssets: (assets) => {
        self.assets = assets;
      },
    };
  })
  .views((self) => ({
    get assetDetails() {
      return self.assets.map(({ constring, fleetNo }) => ({
        label: constring,
        value: fleetNo,
      }));
    },
    get failedRequests() {
      return self.defectStatus.filter(({ targetStatus }) => targetStatus === 'Failed');
    },
    get successfulRequests() {
      return self.defectStatus.filter(({ targetStatus }) => targetStatus === 'Reported');
    },
  }));

export default DefectRequestStore;
