import { grid } from 'styled-system';
import styled from 'styled-components';

import { DynamicFormInput } from '../../../dynamic-form-input';
import { form, H4 } from '../../../ui-library';
import { LabelledInput } from '../../../labelled-input';

const { Input } = form;

const DangerousGoods = () => {
  return (
    <>
      <DynamicFormInput
        name="itemDangerousGoodsIndicator"
        render={({ label, name, type, ...props }) => (
          <>
            <Cell gridColumn="span 4">
              <H4>Dangerous Goods</H4>
            </Cell>

            <Cell gridColumn="span 4">
              <Input name={name} type={type} {...props}>
                {label}
              </Input>
            </Cell>
          </>
        )}
      />
      <DynamicFormInput name="dgClass" render={(props) => <LabelledInput {...props} />} />
      <DynamicFormInput name="dgUnNumber" render={(props) => <LabelledInput {...props} />} />
      <DynamicFormInput name="dgKiloLitre" render={(props) => <LabelledInput {...props} />} />
      <DynamicFormInput name="dgCountPackages" render={(props) => <LabelledInput {...props} />} />
      <DynamicFormInput name="dgPackingType" render={(props) => <LabelledInput {...props} />} />
      <DynamicFormInput name="dgPackingGroup" render={(props) => <LabelledInput {...props} />} />
      <DynamicFormInput name="dgSubsidiaryRisk" render={(props) => <LabelledInput {...props} />} />
      <DynamicFormInput name="dgHazchem" render={(props) => <LabelledInput {...props} />} />
      <DynamicFormInput
        name="dgTechnicalName"
        render={(props) => (
          <Cell gridColumn="span 2">
            <LabelledInput {...props} />
          </Cell>
        )}
      />
      <DynamicFormInput
        name="dgShippingName"
        render={(props) => (
          <Cell gridColumn="span 2">
            <LabelledInput {...props} />
          </Cell>
        )}
      />
      <DynamicFormInput
        name="dgContactName"
        render={(props) => (
          <Cell gridColumn="span 2">
            <LabelledInput {...props} />
          </Cell>
        )}
      />
      <DynamicFormInput
        name="dgContactPhone"
        render={(props) => (
          <Cell gridColumn="span 2">
            <LabelledInput {...props} />
          </Cell>
        )}
      />
    </>
  );
};

const Cell = styled.div`
  ${grid};
`;

export { DangerousGoods };
