import PropTypes from 'prop-types';

import { DetailsList } from '../../../components/ui-library';
import { useTemplateState } from '../../../components/template-context';

const { DetailsListItem } = DetailsList;

const AdditionalReferences = ({ additionalReferences }) => {
  const { fields } = useTemplateState();

  return additionalReferences.map(({ name, value }) => {
    // Find the additionalReference `value` field
    // (each additional reference has a "Literal" field type for the label, and another field type (eg Text or Select) for the value)
    const additionalReferenceValue = fields.find(
      ({ name: fieldName, type }) => fieldName === name && type !== 'Literal'
    );

    if (!additionalReferenceValue) return null;

    let displayValue = value;

    // If the additionalReference `value` field is a Select type, we need to display the `text` and not the `value`.
    if (additionalReferenceValue.type === 'Select') {
      const { options = [] } = additionalReferenceValue;

      const selectedOption = options.find((option) => option.value === value);

      if (!selectedOption) return null;

      displayValue = selectedOption.text;
    }

    return (
      <DetailsListItem key={name} title={name}>
        {displayValue}
      </DetailsListItem>
    );
  });
};

AdditionalReferences.propTypes = {
  additionalReferences: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};

AdditionalReferences.defaultProps = {
  additionalReferences: [],
};

export { AdditionalReferences };
