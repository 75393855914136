import { useEffect, useRef, useState } from 'react';

import { useRootStore } from '../../components/store-provider/lib';

const useWebsocket = ({ url, onmessage }) => {
  const { AuthStore } = useRootStore();

  const { refreshToken } = AuthStore;

  const [connected, setConnected] = useState(false);

  const websocket = useRef(null);

  useEffect(() => {
    const handleConnection = async () => {
      const accessToken = await refreshToken();

      websocket.current = new WebSocket(`${url}?auth=${accessToken}`);

      websocket.current.onopen = () => setConnected(true);

      websocket.current.onclose = () => handleConnection();

      websocket.current.onmessage = onmessage;
    };

    handleConnection();

    return () => {
      websocket.current.onclose = () => null;

      websocket.current.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendMessage = (body) => websocket.current.send(JSON.stringify(body));

  return { connected, sendMessage };
};

export { useWebsocket };
