import PropTypes from 'prop-types';

const ArrowLeft = ({ fill, ...rest }) => (
  <svg width="20" height="20" viewBox="0 0 1000 1000" xmlns="http://www.w3.org/2000/svg" {...rest} fill={fill}>
    <path
      d="M336 275L126 485h806c13 0 23 10 23 23s-10 23-23 23H126l210 210c11 11 11 21 0 32-5 5-10 7-16 7s-11-2-16-7L55 524c-11-11-11-21 0-32l249-249c21-22 53 10 32 32z"
      fill="#000000"
    />
  </svg>
);

ArrowLeft.propTypes = {
  fill: PropTypes.string,
};

ArrowLeft.defaultProps = {
  fill: '#000',
};

export { ArrowLeft };
