import { useEffect, useState } from 'react';

import { form } from '../../components/ui-library';
import { useTemplateState } from '../../components/template-context';

const { useFormState } = form;

const useHydrateItem = (activeItem = null) => {
  const {
    templateData: { fields: templateFields },
  } = useTemplateState();

  const { apiFields } = useTemplateState();

  const { reset, setValue } = useFormState();

  const [shouldUpdate, setShouldUpdate] = useState(false);

  const fieldNames = Object.values(apiFields);

  useEffect(() => {
    if (activeItem === null) {
      fieldNames.forEach((field) => {
        const templateField = templateFields.find((x) => x.apiField === field);

        const { default: defaultValue = null, type } = templateField;

        // Format defaultValue depending on templateType
        switch (type) {
          case 'Checkbox':
            // Checkboxes must be boolean
            setValue(field, !!defaultValue);
            break;
          case 'Select':
          case 'Multi-Select':
            // Select fields cannot have a null default value.
            setValue(field, defaultValue || '');
            break;
          default:
            // Everything else (eg text, date, datetime) can be safely null.
            setValue(field, defaultValue);
        }

        setShouldUpdate(true);
      });
    } else {
      reset(activeItem);
      setShouldUpdate(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return shouldUpdate;
};

export { useHydrateItem };
