import styled from 'styled-components';

const Label = styled.label`
  display: block;
  font-size: ${({ theme }) => theme.fontSize.xSmall};
  color: ${({ theme }) => theme.colors.greySeven};
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 1;
  margin-bottom: ${({ theme }) => theme.space.xxs};
  user-select: none;

  span {
    color: ${({ theme }) => theme.colors.dangerSeven};
    font-size: 1em;
    line-height: 0;
  }
`;

export { Label };
