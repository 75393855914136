import PropTypes from 'prop-types';

import { useHasPrivilege } from '../../hooks/use-has-privilege';

const Privilege = ({ name, children, fallback }) => {
  const hasPrivilege = useHasPrivilege(name);

  return hasPrivilege ? children : fallback;
};

Privilege.propTypes = {
  name: PropTypes.string,
  children: PropTypes.node.isRequired,
  fallback: PropTypes.node,
};

Privilege.defaultProps = {
  fallback: null,
};

export { Privilege };
