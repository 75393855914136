import { get, isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ListItem } from '../../../components/booking-form/list-item';
import { useSupportingDocuments } from '../../../hooks/use-supporting-documents';

const SupportingDocumentsList = ({ bookingID }) => {
  const { documents, handleAttachmentDownload } = useSupportingDocuments(bookingID);

  if (isEmpty(documents)) {
    return <EmptyState>No supporting documents attached</EmptyState>;
  }
  return (
    <>
      {documents.map((item) => {
        const documentType = get(item, 'documentType');
        const name = get(item, 'name');
        const bookingDocumentID = get(item, 'bookingDocumentID');

        return (
          <ListItem
            key={`document-${name}`}
            name={`${documentType} (${name})`}
            actions={[
              {
                label: 'Download',
                onClick: () =>
                  handleAttachmentDownload({
                    name,
                    bookingDocumentID,
                  }),
              },
            ]}
          />
        );
      })}
    </>
  );
};

const EmptyState = styled.div`
  display: grid;
  grid-column: span 2;
  text-align: center;
  color: ${({ theme }) => theme.colors.greyFive};
`;

SupportingDocumentsList.propTypes = {
  bookingID: PropTypes.string.isRequired,
};

const ObservedSupportingDocumentsList = observer(SupportingDocumentsList);
export { ObservedSupportingDocumentsList as SupportingDocumentsList };
