import PropTypes from 'prop-types';

import { Button } from '../button';

const variantMapping = {
  primary: 'primaryTextButton',
  secondary: 'secondaryTextButton',
};

const TextButton = ({ variant, ...props }) => <Button variant={variantMapping[variant]} {...props} />;

TextButton.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary']),
};

TextButton.defaultProps = {
  variant: 'primary',
};

export { TextButton };
