import PropTypes from 'prop-types';

const Flag = ({ fill, ...rest }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...rest} fill={fill}>
    <path
      d="M20.457 2C20.1836 2 19.8984 2.05859 19.6289 2.17969C17.6562 3.06641 16.1992 3.36328 14.9766 3.36328C12.3945 3.36328 10.8945 2.01562 7.91406 2.01562C6.80469 2.01562 5.48438 2.20703 3.83203 2.71875C3.73047 2.30859 3.37891 2 2.9375 2C2.41797 2 2 2.41797 2 2.9375V21.5312C2 21.7891 2.21094 22 2.46875 22H3.40625C3.66406 22 3.875 21.7891 3.875 21.5312V15.5508C5.33203 15.0898 6.59375 14.9062 7.72266 14.9062C10.8945 14.9062 13.1055 16.25 16.2812 16.25C17.6602 16.25 19.2148 15.9961 21.1133 15.2734C21.6602 15.0625 22.0039 14.5742 22.0039 14.0547V3.30469C22 2.50781 21.2734 2 20.457 2ZM20.125 13.6367C18.7461 14.1328 17.4844 14.375 16.2773 14.375C13.3281 14.375 11.2578 13.0312 7.71875 13.0312C6.47266 13.0312 5.19922 13.2148 3.87109 13.5859V4.67578C5.42578 4.14844 6.75391 3.89062 7.91406 3.89062C10.5039 3.89062 12.0234 5.23828 14.9766 5.23828C16.5508 5.23828 18.1914 4.84766 20.125 4.00781V13.6367Z"
      fill="#49525D"
    />
  </svg>
);

Flag.propTypes = {
  fill: PropTypes.string,
};

Flag.defaultProps = {
  fill: '#fff',
};

export { Flag };
