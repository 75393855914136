import { addMinutes, endOfDay, format, startOfHour } from 'date-fns';

// Create an array of times between a provided `start` and `end` date.
// Interval is a number of minutes between times.
const createTimeArray = (interval, start, end = null) => {
  const now = new Date();

  const endOfStartDate = endOfDay(start);

  // If the requested start time is in the past, set the start time to the beginning of this hour.
  // NOTE: this will technically allow you to select a time in the past, but it's better to limit
  // start time to 12pm if the user makes a booking at 12:01, rather than force them to select 1pm.
  const startTime = start > now ? start : startOfHour(now);

  // If an end time is set, and it is earlier than the final time available on `startDate`, return the end time.
  // Otherwise, clamp the end time to the end of the day provided by `start`.
  const endTime = end && end < endOfStartDate ? end : endOfStartDate;

  // Create an array of {label, value} keypairs in the format `9:00 am`
  // Only return times which occur before `endTime`.
  const times = Array.from({ length: 24 * (60 / interval) }, (_, i) => {
    const nextTime = addMinutes(startTime, interval * i);

    if (nextTime > endTime) return undefined;

    const formatted = format(nextTime, 'h:mm a');

    return { label: formatted, value: formatted };
  });

  return times.filter(Boolean);
};

export { createTimeArray };
