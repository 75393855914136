import { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, H3, Modal, ModalFooterButtonWrapper } from '../ui-library';

const DeleteItemModal = ({ message, onDeleteItem }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => setIsModalOpen(false);

  const handleDeleteItem = () => {
    onDeleteItem();

    closeModal();
  };

  return (
    <>
      <Button variant="tertiary" minWidth="140px" onClick={() => setIsModalOpen(true)}>
        Delete
      </Button>
      <Modal
        size="sm"
        isOpen={isModalOpen}
        closeModal={closeModal}
        footer={
          <ModalFooterButtonWrapper closeModal={closeModal}>
            <Button onClick={handleDeleteItem} variant="destructive">
              Delete
            </Button>
          </ModalFooterButtonWrapper>
        }
      >
        <H3 as="h1" m={0}>
          {message}
        </H3>
      </Modal>
    </>
  );
};

DeleteItemModal.propTypes = {
  message: PropTypes.string.isRequired,
  onDeleteItem: PropTypes.func.isRequired,
};

export { DeleteItemModal };
