import PropTypes from 'prop-types';

const bevChainLogo = '/assets/bevchain-logo@2x.png';
const linfoxLogo = '/assets/linfox-logo@2x.png';

const Logo = ({ branding }) => (
  <img
    src={branding === 'bevChain' ? bevChainLogo : linfoxLogo}
    alt={branding === 'bevChain' ? 'BevChain' : 'Linfox'}
  />
);

export { Logo };

Logo.propTypes = {
  branding: PropTypes.oneOf(['linfox', 'bevChain']),
};

Logo.defaultProps = {
  branding: 'linfox',
};
