import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Action } from './action';
import { Box, Flex, icons } from '../../ui-library';

const { TickWithBackground } = icons;

const ListItem = ({ name, description, actions }) => (
  <Wrapper py={2} px={4} flex={1} alignItems={!description && 'center'}>
    <TickWithBackgroundIcon />
    <Box ml={2} width="100%">
      <Item>
        {name}
        {!isEmpty(actions) && (
          <ActionList>
            {actions.map(({ label, onClick }) => (
              <Action label={label} onClick={onClick} key={label} />
            ))}
          </ActionList>
        )}
      </Item>
      {description && (
        <Box mt={1} color="greySix">
          {description}
        </Box>
      )}
    </Box>
  </Wrapper>
);

const Item = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
`;

const ActionList = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 5rem;
  grid-column-gap: 1rem;
`;

const Wrapper = styled(Flex)`
  background: ${({ theme }) => theme.colors.greyOne};
  border: 1px solid ${({ theme }) => theme.colors.greyTwo};
  border-radius: 6px;
`;

const TickWithBackgroundIcon = styled(TickWithBackground)`
  height: 20px;
  width: 20px;
  position: relative;
  top: -2px;
`;

ListItem.propTypes = {
  description: PropTypes.string,
  name: PropTypes.string.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
};

ListItem.defaultProps = {
  actions: null,
  description: null,
};

export { ListItem };
