import PropTypes from 'prop-types';
import styled from 'styled-components';

const ErrorLabel = ({ children, showPoppedOut }) => <StyledError showPoppedOut={showPoppedOut}>{children}</StyledError>;

const StyledError = styled.div`
  color: ${({ theme }) => theme.colors.dangerSeven};
  font-size: ${({ theme }) => theme.fontSize.xSmall};
  padding-top: ${({ theme }) => theme.space.xxs};

  ${({ showPoppedOut }) =>
    showPoppedOut &&
    `
    background: ${({ theme }) => theme.colors.white};
    box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.1);
    padding: 0 0.5rem;
    line-height: 24px;
    border-radius: 3px;
    margin-top: 4px;
    border: solid 1px ${({ theme }) => theme.colors.greyTwo};
  `}
`;

ErrorLabel.propTypes = {
  children: PropTypes.node.isRequired,
  showPoppedOut: PropTypes.bool,
};

ErrorLabel.defaultProps = {
  showPoppedOut: false,
};

export { ErrorLabel };
