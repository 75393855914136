import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button } from '../button';
import { Flex } from '../grid';
import { ProgressBar } from './progress-bar';

const shouldShowButton = (totalResults, count) => count < totalResults;

const LoadMoreButton = ({ onSubmit, totalResults, count, children }) => (
  <StyledFlex justifyContent="center" flexDirection="column">
    <ProgressBar total={totalResults} count={count} />
    {shouldShowButton(totalResults, count) && <Button onClick={onSubmit}>{children}</Button>}
  </StyledFlex>
);

const StyledFlex = styled(Flex)`
  position: relative;
  max-width: 250px;
  margin: ${({ theme }) => theme.space.lg} auto;
`;

LoadMoreButton.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  totalResults: PropTypes.number,
  count: PropTypes.number,
  children: PropTypes.node,
};

LoadMoreButton.defaultProps = {
  totalResults: 0,
  count: 0,
  children: 'Load More',
};

export { LoadMoreButton };
