import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Alert, Box, Button, Flex, TextButton } from '../../ui-library';
import { useRootStore } from '../../store-provider/lib';

const Confirmation = ({ addAnotherUser }) => {
  const { UserStore } = useRootStore();

  const { selected } = UserStore;

  const { FullName } = selected;

  return (
    <>
      <Box mb="1.5rem">
        <Alert type="success">{FullName} has been successfully invited to your associated contracts</Alert>
      </Box>
      <ElementWrapper flexDirection="column" alignItems="center">
        <Button to="/users" mb="1.5rem" minWidth={160}>
          Back to User List
        </Button>
        <TextButton variant="secondary" onClick={addAnotherUser}>
          Add Another User
        </TextButton>
      </ElementWrapper>
    </>
  );
};

const ElementWrapper = styled(Flex)`
  margin-top: ${({ theme }) => theme.space.sm};
`;

Confirmation.propTypes = {
  addAnotherUser: PropTypes.func.isRequired,
};

export { Confirmation };
