import PropTypes from 'prop-types';

import { colors } from '../../../constants/colors';

const TickWithBackground = ({ backgroundColor, fill, ...rest }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill={fill} {...rest}>
    <circle cx="12" cy="12" r="11" fill={backgroundColor} />
    <path d="M15.8236 8.37101L10.3501 13.8573L8.24495 11.7521C8.13057 11.6185 7.98982 11.51 7.83153 11.4335C7.67324 11.3569 7.50083 11.3138 7.32512 11.307C7.1494 11.3003 6.97419 11.3299 6.81046 11.394C6.64673 11.4582 6.49803 11.5555 6.37369 11.6798C6.24935 11.8041 6.15206 11.9528 6.08792 12.1166C6.02377 12.2803 5.99416 12.4555 6.00095 12.6312C6.00774 12.8069 6.05077 12.9793 6.12735 13.1376C6.20394 13.2959 6.31241 13.4367 6.44597 13.551L9.44427 16.5621C9.56349 16.6804 9.70487 16.7739 9.86032 16.8374C10.0158 16.9009 10.1822 16.9331 10.3501 16.9321C10.6848 16.9307 11.0056 16.7978 11.2432 16.5621L17.6226 10.1827C17.7422 10.0641 17.8371 9.92303 17.9019 9.76755C17.9667 9.61208 18 9.44531 18 9.27688C18 9.10845 17.9667 8.94169 17.9019 8.78621C17.8371 8.63073 17.7422 8.48962 17.6226 8.37101C17.3835 8.13338 17.0602 8 16.7231 8C16.386 8 16.0627 8.13338 15.8236 8.37101Z" />
  </svg>
);

TickWithBackground.propTypes = {
  backgroundColor: PropTypes.string,
  fill: PropTypes.string,
};

TickWithBackground.defaultProps = {
  backgroundColor: colors.successTen,
  fill: colors.white,
};

export { TickWithBackground };
