import { useEffect, useRef, useState } from 'react';

const useAsyncOnClick = (onClick, isSubmitting = false) => {
  const [isFetching, setIsFetching] = useState(isSubmitting);

  const callback = useRef(() => {
    setIsFetching(false);
  });

  const handleClick = async () => {
    setIsFetching(true);
    await Promise.resolve(onClick());

    callback.current();
  };

  useEffect(() => {
    setIsFetching(isSubmitting);
  }, [isSubmitting]);

  useEffect(() => {
    return () => {
      callback.current = () => {};
    };
  }, []);

  return [handleClick, isFetching];
};

export { useAsyncOnClick };
