import styled from 'styled-components';

import { breakpoints } from '../../../constants';
import { TableRow } from '../table-row';

const NoResults = styled(TableRow)`
  text-align: center;
  grid-template-columns: 1fr;
  font-size: ${({ theme }) => theme.fontSize.base};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  padding-top: ${({ theme }) => theme.space.xl};
  padding-bottom: ${({ theme }) => theme.space.xl};

  @media (min-width: ${breakpoints.md}) {
    grid-template-columns: 1fr !important;
  }
`;

export { NoResults };
