import { types } from 'mobx-state-tree';

const Asset = types.model('Asset', {
  licenseNo: types.string,
  objectStatus: types.string,
  fleetNo: types.string,
  equipmentNo: types.string,
  modelNo: types.string,
  constring: types.string,
  manufacturer: types.string,
});

export { Asset };
