import * as yup from 'yup';
import { format } from 'date-fns';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { useRef, useState } from 'react';
import styled from 'styled-components';

import {
  Alert,
  Col,
  constants,
  Container,
  Flex,
  form,
  H1,
  H3,
  P,
  Row,
  usePageTitle,
} from '../../components/ui-library';
import { DefectForm } from '../../components/defect-form';
import { useRootStore } from '../../components/store-provider/lib';
import { useWebsocket } from '../../hooks/use-websocket';

const { Form, SubmitButton, Input, InputGroup, Label } = form;

const validationSchema = yup.object().shape({
  fleetIdentifier: yup.string().required('Equipment Asset Number is required.'),
  DRR_LOCATION: yup.string().required('Location is required.'),
  operatorName: yup.string().required('Operator Name is required'),
  DRR_PHONENUMBER: yup
    .string()
    .matches(/[+0-9() ]+/, 'Please enter a valid phone number.')
    .required('Contact Number is required.'),
  DRR_SITENAME: yup.string().nullable(),
  DRR_SITENUMBER: yup
    .string()
    .matches(/([+0-9() ]+|^$)/, 'Please enter a valid phone number.')
    .nullable(),
  DRR_BREAKDOWN: yup.mixed().oneOf(['YES', 'NO'], 'Selection is required.').required(),
  DRR_WAITINGSIDEROAD: yup.mixed().oneOf(['YES', 'NO'], 'Selection is required.').required(),
  DRR_DG: yup.mixed().oneOf(['YES', 'NO'], 'Selection is required.').required(),
  DRR_LOCATIONSAFETY: yup.mixed().oneOf(['YES', 'NO'], 'Selection is required.').required(),
  DRR_LOADED: yup.mixed().oneOf(['YES', 'NO'], 'Selection is required.').required(),
});

const RaiseDefect = () => {
  usePageTitle('Defect Repair Requisition');

  const defectRef = useRef();

  const history = useHistory();

  const [defectContainer, setDefectContainer] = useState();

  const { AuthStore, DefectRequestStore } = useRootStore();

  const { assetDetails, setAssets } = DefectRequestStore;

  const { connected, sendMessage } = useWebsocket({
    url: process.env.REACT_APP_WS_URL,
    onmessage: ({ data }) => {
      const {
        data: { equipmentDetails = [] },
      } = JSON.parse(data);

      if (equipmentDetails.length > 0) {
        setAssets(equipmentDetails);
      }
    },
  });

  const onSubmit = async (values) => {
    const defectValues = defectRef.current.getValues();

    const now = new Date();

    const faultReportedDate = format(now, 'dd/MM/yyyy');
    const faultReportedTime = format(now, 'HH:mm:ss');

    const defectLogger = AuthStore.fullName;

    const {
      DRR_BREAKDOWN,
      DRR_DG,
      DRR_LOADED,
      DRR_LOCATION,
      DRR_LOCATIONSAFETY,
      DRR_PHONENUMBER,
      DRR_SITENAME,
      DRR_SITENUMBER,
      DRR_WAITINGSIDEROAD,
      ...shared
    } = values;

    const qaDetails = [
      { question: 'DRR_BREAKDOWN', answer: DRR_BREAKDOWN },
      { question: 'DRR_DG', answer: DRR_DG },
      { question: 'DRR_LOADED', answer: DRR_LOADED },
      { question: 'DRR_LOCATION', answer: DRR_LOCATION },
      { question: 'DRR_LOCATIONSAFETY', answer: DRR_LOCATIONSAFETY },
      { question: 'DRR_PHONENUMBER', answer: DRR_PHONENUMBER },
      { question: 'DRR_SITENAME', answer: DRR_SITENAME },
      { question: 'DRR_SITENUMBER', answer: DRR_SITENUMBER },
      { question: 'DRR_WAITINGSIDEROAD', answer: DRR_WAITINGSIDEROAD },
    ];

    const payload = defectValues.map(({ DRR_REPAIRCODE, ...defect }, index) => {
      return {
        ...defect,
        ...shared,
        defectNumber: `${index + 1}`,
        defectLogger,
        faultReportedDate,
        faultReportedTime,
        qa_details: [...qaDetails, { question: 'DRR_REPAIRCODE', answer: DRR_REPAIRCODE }],
      };
    });

    await DefectRequestStore.raiseDefects(payload);

    history.push('/defects/status');
  };

  const [isValid, setValid] = useState(false);

  return (
    <Wrapper flex={1} alignItems="center" flexDirection="column">
      <Flex maxWidth={constants.globalStyles.formMaxWidth} width="100%">
        <H1>Defect Repair Requisition</H1>
      </Flex>
      <Flex maxWidth={constants.globalStyles.formMaxWidth} width="100%">
        <StyledForm mt="4rem" onSubmit={onSubmit} validationSchema={validationSchema} width="100%">
          <StyledRow>
            <StyledCol>
              <Label htmlFor="fleetIdentifier">Equipment Rego Number / Fleet Number</Label>
              <Input
                disabled={!connected}
                id="fleetIdentifier"
                isClearable
                onClear={() => {
                  setAssets([]);
                }}
                type="select"
                name="fleetIdentifier"
                options={assetDetails}
                onInputChange={(keyword) => sendMessage({ keyword })}
                placeholder={
                  connected ? 'Please begin typing a registration or fleet number...' : 'Please wait, connecting...'
                }
              />
            </StyledCol>
          </StyledRow>
          <StyledRow>
            <StyledCol>
              <Label htmlFor="DRR_LOCATION">Location of Equipment</Label>
              <Input id="DRR_LOCATION" type="text" name="DRR_LOCATION" />
            </StyledCol>
          </StyledRow>
          <StyledRow>
            <StyledCol>
              <Label htmlFor="operatorName">Operator Name</Label>
              <Input id="operatorName" type="text" name="operatorName" />
            </StyledCol>
            <StyledCol>
              <Label htmlFor="DRR_PHONENUMBER">Contact Number</Label>
              <Input id="DRR_PHONENUMBER" type="text" name="DRR_PHONENUMBER" />
            </StyledCol>
          </StyledRow>
          <StyledRow>
            <StyledCol>
              <Label htmlFor="DRR_SITENAME">
                Site Contact Name <span>- Optional</span>
              </Label>
              <Input id="DRR_SITENAME" type="text" name="DRR_SITENAME" />
            </StyledCol>
            <StyledCol>
              <Label htmlFor="DRR_SITENUMBER">
                Site Contact Number <span>- Optional</span>
              </Label>
              <Input id="DRR_SITENUMBER" type="text" name="DRR_SITENUMBER" />
            </StyledCol>
          </StyledRow>
          <StyledRow>
            <StyledCol>
              <P>Has your equipment broken down?</P>
              <InputGroup name="DRR_BREAKDOWN">
                <Input name="DRR_BREAKDOWN" type="radio" value="YES">
                  Yes
                </Input>
                <Input name="DRR_BREAKDOWN" type="radio" value="NO">
                  No
                </Input>
              </InputGroup>
            </StyledCol>
          </StyledRow>
          <StyledRow>
            <StyledCol>
              <P>Are you waiting on the side of the road?</P>
              <InputGroup name="DRR_WAITINGSIDEROAD">
                <Input name="DRR_WAITINGSIDEROAD" type="radio" value="YES">
                  Yes
                </Input>
                <Input name="DRR_WAITINGSIDEROAD" type="radio" value="NO">
                  No
                </Input>
              </InputGroup>
            </StyledCol>
          </StyledRow>
          <StyledRow>
            <StyledCol>
              <P>Are you carrying Dangerous Goods?</P>
              <InputGroup name="DRR_DG">
                <Input name="DRR_DG" type="radio" value="YES">
                  Yes
                </Input>
                <Input name="DRR_DG" type="radio" value="NO">
                  No
                </Input>
              </InputGroup>
            </StyledCol>
          </StyledRow>
          <StyledRow>
            <StyledCol>
              <P>Is the location safe?</P>
              <InputGroup name="DRR_LOCATIONSAFETY">
                <Input name="DRR_LOCATIONSAFETY" type="radio" value="YES">
                  Yes
                </Input>
                <Input name="DRR_LOCATIONSAFETY" type="radio" value="NO">
                  No
                </Input>
              </InputGroup>
            </StyledCol>
          </StyledRow>
          <StyledRow>
            <StyledCol>
              <P>Is the equipment loaded?</P>
              <InputGroup name="DRR_LOADED">
                <Input name="DRR_LOADED" type="radio" value="YES">
                  Yes
                </Input>
                <Input name="DRR_LOADED" type="radio" value="NO">
                  No
                </Input>
              </InputGroup>
            </StyledCol>
          </StyledRow>
          <Rule />
          <H3 mb="2rem">List all Defects</H3>
          <Alert mb="2rem" type="info">
            If you are logging a P1 DRR (including after hours) or unsure of priority, please also remember to phone
            1800 314 612.
          </Alert>
          <div ref={setDefectContainer} />
          <SubmitButton alignSelf="flex-end" width={[1, 1, 1 / 2, 1 / 3]} disabled={!isValid}>
            Submit Request
          </SubmitButton>
        </StyledForm>
        <DefectForm container={defectContainer} ref={defectRef} setValid={setValid} />
      </Flex>
    </Wrapper>
  );
};

const Rule = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.borderColorOne};
  margin-bottom: 3rem;
`;

const StyledForm = styled(Form)`
  flex: 1;
`;

const StyledCol = styled(Col).attrs(() => ({ width: 1 }))``;

const StyledRow = styled(Row).attrs(() => ({ mb: '2.5rem' }))``;

const Wrapper = styled(Container)`
  padding: ${({ theme }) => theme.space.xl} ${({ theme }) => theme.space.sm};
  min-height: calc(100vh - ${({ theme }) => theme.global.headerHeight});
`;

const ObservedRaiseDefect = observer(RaiseDefect);

export { ObservedRaiseDefect as RaiseDefect };
