import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useRootStore } from '../../store-provider/lib';

const StatusLabel = ({ label }) => {
  const { CaseStore } = useRootStore();

  const { caseTypesCount, isFetching } = CaseStore;

  let apiLabel = label;

  // the label may render as Awaiting BevChain, but the caseTypesCount object will require 'Awaiting Linfox' for the lookup.
  if (label === 'Awaiting BevChain') {
    apiLabel = 'Awaiting Linfox';
  }

  const { [apiLabel]: count = 0 } = caseTypesCount;

  const displayCount = !isFetching && label !== 'Resolved';

  return (
    <>
      {label} {displayCount && <Count>{count}</Count>}
    </>
  );
};

const Count = styled.span`
  height: 1.2rem;
  line-height: 1.2rem;
  margin-left: 0.5rem;
  padding: 0 0.5rem;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.6);
  font-weight: bold;
`;

StatusLabel.propTypes = {
  label: PropTypes.string.isRequired,
};

const ObservedStatusLabel = observer(StatusLabel);
export { ObservedStatusLabel as StatusLabel };
