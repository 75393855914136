import PropTypes from 'prop-types';

import { colors } from '../../../constants/colors';

const CrossWithBackground = ({ backgroundColor, fill, ...rest }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill={fill} {...rest}>
    <path
      d="M19.07 4.92996C18.1476 3.97486 17.0441 3.21303 15.8241 2.68894C14.604 2.16485 13.2918 1.88899 11.964 1.87745C10.6362 1.86591 9.31944 2.11893 8.09048 2.62174C6.86151 3.12455 5.745 3.86708 4.80607 4.80601C3.86714 5.74493 3.12461 6.86145 2.6218 8.09042C2.11899 9.31938 1.86598 10.6362 1.87751 11.964C1.88905 13.2918 2.16491 14.604 2.689 15.824C3.21309 17.044 3.97492 18.1475 4.93002 19.07C5.85249 20.0251 6.95593 20.7869 8.17597 21.311C9.39601 21.8351 10.7082 22.1109 12.036 22.1225C13.3638 22.134 14.6806 21.881 15.9096 21.3782C17.1385 20.8754 18.255 20.1328 19.194 19.1939C20.1329 18.255 20.8754 17.1385 21.3782 15.9095C21.881 14.6805 22.1341 13.3637 22.1225 12.0359C22.111 10.7082 21.8351 9.39595 21.311 8.17591C20.7869 6.95587 20.0251 5.85243 19.07 4.92996Z"
      fill={backgroundColor}
    />
    <path d="M15.3846 8.07106C15.5065 8.12183 15.6171 8.19623 15.71 8.28995C15.8037 8.38292 15.8781 8.49352 15.9289 8.61538C15.9797 8.73724 16.0058 8.86794 16.0058 8.99995C16.0058 9.13197 15.9797 9.26267 15.9289 9.38453C15.8781 9.50639 15.8037 9.61699 15.71 9.70995L13.41 12L15.71 14.29C15.8037 14.3829 15.8781 14.4935 15.9289 14.6154C15.9797 14.7372 16.0058 14.8679 16.0058 15C16.0058 15.132 15.9797 15.2627 15.9289 15.3845C15.8781 15.5064 15.8037 15.617 15.71 15.71C15.6171 15.8037 15.5065 15.8781 15.3846 15.9288C15.2627 15.9796 15.132 16.0058 15 16.0058C14.868 16.0058 14.7373 15.9796 14.6154 15.9288C14.4936 15.8781 14.383 15.8037 14.29 15.71L12 13.41L9.71002 15.71C9.61705 15.8037 9.50645 15.8781 9.38459 15.9288C9.26273 15.9796 9.13203 16.0058 9.00002 16.0058C8.868 16.0058 8.7373 15.9796 8.61544 15.9288C8.49358 15.8781 8.38298 15.8037 8.29002 15.71C8.19629 15.617 8.12189 15.5064 8.07112 15.3845C8.02036 15.2627 7.99422 15.132 7.99422 15C7.99422 14.8679 8.02036 14.7372 8.07112 14.6154C8.12189 14.4935 8.19629 14.3829 8.29002 14.29L10.59 12L8.29002 9.70995C8.10171 9.52165 7.99592 9.26626 7.99592 8.99995C7.99592 8.73365 8.10171 8.47826 8.29002 8.28995C8.47832 8.10165 8.73371 7.99586 9.00002 7.99586C9.26632 7.99586 9.52171 8.10165 9.71002 8.28995L12 10.59L14.29 8.28995C14.383 8.19623 14.4936 8.12183 14.6154 8.07106C14.7373 8.02029 14.868 7.99416 15 7.99416C15.132 7.99416 15.2627 8.02029 15.3846 8.07106Z" />
  </svg>
);

CrossWithBackground.propTypes = {
  backgroundColor: PropTypes.string,
  fill: PropTypes.string,
};

CrossWithBackground.defaultProps = {
  backgroundColor: colors.black,
  fill: colors.white,
};

export { CrossWithBackground };
