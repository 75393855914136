import { camelCase, isEmpty, pick } from 'lodash';

import { formSectionMapping } from '../form-section-mapping';

const makeIsBookingSectionAvailable = (fields) => (id) => {
  const keys = formSectionMapping[camelCase(id)];

  const sectionFields = pick(fields, keys);

  return !isEmpty(sectionFields);
};

export { makeIsBookingSectionAvailable };
