import { flow, getEnv, types } from 'mobx-state-tree';

const SUCCESS_MESSAGE = 'Contract Contact Permissions deleted Successfully';

const Permission = types
  .model('Permission', {
    isActive: types.optional(types.boolean, false),
    ContractContactID: types.maybe(types.string),
    PermissionID: types.string,
    PermissionItemID: types.string,
    ContractContactPermissionID: types.maybe(types.string),
  })
  .actions((self) => {
    const { api } = getEnv(self);

    return {
      disable: flow(function* () {
        const response = yield api.del(
          `user/permission?contractContactPermissionsID=${self.ContractContactPermissionID}`
        );

        const {
          data: {
            message: { message },
          },
        } = response;

        if (message === SUCCESS_MESSAGE) {
          self.isActive = false;
          delete self.ContractContactPermissionID;
        } else {
          // TODO: work out how to access ToastStore within this store; as this is a nested child, it is not as simple as calling `getRoot`.
          // ToastStore.addToast({ variant: 'error', description: 'There was an error removing this permission.' });
        }
      }),
      enable: flow(function* () {
        const response = yield api.post('user/permission', {
          body: { contractContactID: self.ContractContactID, permissionID: self.PermissionID },
        });

        const {
          data: {
            message: { message },
          },
        } = response;

        if (message) {
          self.ContractContactPermissionID = message;
          self.isActive = true;
        } else {
          // TODO: work out how to access ToastStore within this store; as this is a nested child, it is not as simple as calling `getRoot`.
          // ToastStore.addToast({ variant: 'error', description: 'There was an error adding this permission.' });
        }
      }),
    };
  });

export { Permission };
