import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { backgroundStyle, borderStyle, cursorStyle, focusStyle, StyledInput } from '../styles';
import { Bullet } from '../../../icons';
import { Flex } from '../../../grid';
import { UncontrolledRadioButton } from './uncontrolled';
import { useFormState } from '../..';
import { useGroupContext } from '../../input-group';

const RadioButton = ({ children, disabled, name, validation, value }) => {
  const { register } = useFormState();

  const [hasFocus, setFocus] = useState(false);
  const [hasHover, setHover] = useState(false);

  const [selected, setSelected] = useGroupContext();

  const ref = validation ? register(validation) : register;

  const id = `${name}-${value}`;

  return (
    <RadioContainer
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <StyledRadioButton
        disabled={disabled}
        hasFocus={hasFocus}
        hasHover={hasHover}
        isActive={value === selected}
        onClick={() => setSelected(value)}
      >
        <StyledInput id={id} disabled={disabled} name={name} ref={ref} value={value} type="radio" />
        <Bullet />
      </StyledRadioButton>
      <Label disabled={disabled} htmlFor={id} onClick={() => setSelected(value)}>
        {children}
      </Label>
    </RadioContainer>
  );
};

const RadioContainer = styled(Flex)`
  align-items: center;
  margin-bottom: ${({ theme }) => theme.space.xs};
  margin-right: ${({ theme }) => theme.space.md};
  position: relative;
  width: max-content;
`;

const StyledRadioButton = styled(Flex)`
  ${backgroundStyle};
  ${focusStyle};
  ${borderStyle};
  ${cursorStyle};

  align-items: center;
  justify-content: center;
  width: ${({ theme }) => theme.space.sm};
  height: ${({ theme }) => theme.space.sm};
  border-radius: 100%;
  position: relative;

  svg {
    display: ${({ isActive }) => (isActive ? 'block' : 'none')};
    height: 0.75em;
    width: 0.75em;

    path {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`;

const Label = styled.label`
  color: ${({ theme }) => theme.colors.greyEight};
  padding-left: ${({ theme }) => theme.space.xs};

  ${cursorStyle};
`;

RadioButton.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  validation: PropTypes.shape(),
  value: PropTypes.string.isRequired,
};

RadioButton.defaultProps = {
  disabled: false,
  validation: null,
};

RadioButton.displayName = 'RadioButton';

export { RadioButton, UncontrolledRadioButton };
