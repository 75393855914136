import { useCallback } from 'react';
import PropTypes from 'prop-types';

import { useBookingState } from '../../booking-context';

const SectionPortalContainer = ({ id }) => {
  const { registerSectionsSummary } = useBookingState();

  const mountSection = useCallback(
    (node) => {
      registerSectionsSummary({ [id]: node });
    },
    [registerSectionsSummary, id]
  );

  return <div ref={mountSection} />;
};

SectionPortalContainer.propTypes = {
  id: PropTypes.string.isRequired,
};

export { SectionPortalContainer };
