// Display a maximum of two decimal places.
// Remove all trailing 0s from the number.
// If the number is an integer (eg .00), remove the decimal place also.
const formatNumber = (value) => {
  const stringValue = value.toFixed(2);

  const formatted = stringValue.replace(/([.])?0+$/, '');

  return Number(formatted);
};

const useCalculateCargoMetrics = (items) => {
  const totals = items.reduce(
    (acc, { itemGrossWeight = 0, itemQty = 0, itemQtyText = 0, itemVolume = 0, itemVolumeText = 0 }) => {
      const quantity = itemQty || itemQtyText;
      const volume = itemVolume || itemVolumeText;
      const weight = itemGrossWeight;

      return {
        quantity: acc.quantity + Number(quantity),
        volume: acc.volume + Number(volume) * 100,
        weight: acc.weight + Number(weight) * 100,
      };
    },
    { quantity: 0, volume: 0, weight: 0 }
  );

  const { quantity, volume, weight } = totals;

  return { quantity, volume: formatNumber(volume / 100), weight: formatNumber(weight / 100) };
};

export { useCalculateCargoMetrics };
