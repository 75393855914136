import { useCallback, useEffect, useRef } from 'react';

import { useBookingState } from '../../components/booking-context';

const getDimensions = (element) => {
  const { height } = element.getBoundingClientRect();
  const { offsetTop } = element;
  const offsetBottom = offsetTop + height;

  return {
    offsetTop,
    offsetBottom,
  };
};

const useFormScroll = (offset) => {
  const wrapperRef = useRef();

  const sectionNodes = useRef([]);

  const addSection = (node) => {
    sectionNodes.current.push(node);
  };

  const { visibleSection, setVisibleSection } = useBookingState();

  // Determine which section is active
  useEffect(() => {
    const wrapperElement = wrapperRef.current;

    const handleScroll = () => {
      const scrollPosition = wrapperElement.scrollTop + offset;

      const selected = sectionNodes.current.find((node) => {
        if (node) {
          const { offsetBottom, offsetTop } = getDimensions(node);
          return scrollPosition > offsetTop && scrollPosition < offsetBottom;
        }

        return false;
      });

      if (selected && selected.getAttribute('id') !== visibleSection) {
        setVisibleSection(selected.getAttribute('id'));
      } else if (!selected && visibleSection) {
        setVisibleSection(undefined);
      }
    };

    handleScroll();

    wrapperElement.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      wrapperElement.removeEventListener('scroll', handleScroll, { passive: true });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const register = useCallback((node) => {
    if (node) {
      addSection(node);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [wrapperRef, register];
};

export { useFormScroll };
