import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const DownloadableWrapper = ({ isCancelable, children, onClick }) => {
  if (isCancelable) {
    return <BasicWrapper>{children}</BasicWrapper>;
  }

  return (
    <Anchor type="button" onClick={onClick}>
      {children}
    </Anchor>
  );
};

const AttachmentStyles = css`
  height: 2rem;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  border: solid 1px ${({ theme }) => theme.colors.greyTwo};
  background-color: ${({ theme }) => theme.colors.greyOne};
  border-radius: 4px;
`;

const Anchor = styled.button`
  ${AttachmentStyles}
  text-decoration: none;
  color: inherit;

  :hover {
    border: solid 1px ${({ theme }) => theme.colors.greyThree};
    background-color: ${({ theme }) => theme.colors.greyTwo};
  }
`;

const BasicWrapper = styled.span`
  ${AttachmentStyles}
`;

DownloadableWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  isCancelable: PropTypes.bool,
};

DownloadableWrapper.defaultProps = {
  isCancelable: false,
};

export { DownloadableWrapper };
