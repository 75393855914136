import { down } from 'styled-breakpoints';
import { truncate } from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box } from '../grid';
import { colors } from '../../constants/colors';
import { LoadingIndicator } from '../icons';
import { ShowOn } from '../show-on';
import { TextButton } from '../text-button';
import { userPropTypes } from '../../prop-types';

const UserInformation = ({ children, login, toggleUserMenu, user, isFetchingUser }) => {
  if (user) {
    const { FullName } = user;

    return (
      <>
        <ShowOn screenSize="mobile">
          <UserLink>{truncate(FullName, { length: 20 })}</UserLink>
        </ShowOn>
        <ShowOn screenSize="desktop">
          <UserLink onClick={toggleUserMenu}>
            {FullName}
            {children}
          </UserLink>
        </ShowOn>
      </>
    );
  }

  if (isFetchingUser) {
    return (
      <UserLink>
        <Box display="inline-block" width="20px">
          <LoadingIndicator color={colors.white} />
        </Box>
      </UserLink>
    );
  }

  return (
    <TextButton width="inherit" onClick={login}>
      Login
    </TextButton>
  );
};

const UserLink = styled.span`
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  z-index: 1;

  ${down('xs')} {
    width: 100px;
    max-width: 100px;
    overflow-wrap: break-word;
    display: block;
    text-align: right;
  }
`;

UserInformation.propTypes = {
  children: PropTypes.node.isRequired,
  login: PropTypes.func.isRequired,
  isFetchingUser: PropTypes.bool,
  toggleUserMenu: PropTypes.func.isRequired,
  user: userPropTypes,
};

UserInformation.defaultProps = {
  isFetchingUser: false,
  user: null,
};

export { UserInformation };
