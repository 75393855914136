import { useEffect } from 'react';

import { ErrorMessage } from '../error-message';
import { useComponent } from '../ui-library-provider';

const PageNotFound = () => {
  const { logger } = useComponent();

  useEffect(() => {
    logger.info(`404 page not found`, { url: window.location.href });
  }, [logger]);

  return <ErrorMessage>Page Not Found</ErrorMessage>;
};

export { PageNotFound };
