import PropTypes from 'prop-types';

const shipmentPropType = PropTypes.shape({
  shipmentId: PropTypes.string.isRequired,
  billToCustomerCode: PropTypes.string.isRequired,
  customerReference: PropTypes.string,
  shipmentStatus: PropTypes.string,
  plannedPickupDateTimeFrom: PropTypes.string,
  plannedPickupDateTimeTo: PropTypes.string,
  actualPickupDateTime: PropTypes.string,
  plannedDeliveryDateTimeFrom: PropTypes.string,
  plannedDeliveryDateTimeTo: PropTypes.string,
  actualDeliveryDateTime: PropTypes.string,
  pickupLocation: PropTypes.shape({
    name: PropTypes.string,
    street: PropTypes.string,
    suburb: PropTypes.string,
    postcode: PropTypes.string,
  }),
  deliveryLocation: PropTypes.shape({
    name: PropTypes.string,
    street: PropTypes.string,
    suburb: PropTypes.string,
    postcode: PropTypes.string,
  }),
  whenProcessed: PropTypes.string,
  milestones: PropTypes.instanceOf(Array),
});

export { shipmentPropType };
