import PropTypes from 'prop-types';

import { DefectItem } from './defect-item';
import { Flex } from '../grid';
import { fontSize } from '../../constants';

const DefectStatusList = ({ defects, success }) => (
  <>
    <Flex mb="1rem" fontSize={fontSize.xSmall}>
      <Flex flex={7}>Defect category</Flex>
      {success && (
        <Flex flex={3} pr="1rem" whiteSpace="nowrap">
          DRR Number
        </Flex>
      )}
    </Flex>
    {defects.map(({ viewTargetDefectNumber, defect }) => (
      <DefectItem
        key={viewTargetDefectNumber || `${defect.faultCategory}-${defect.defectNumber}`}
        targetDefectNumber={viewTargetDefectNumber}
        defect={defect}
        success={success}
      />
    ))}
  </>
);

DefectStatusList.propTypes = {
  defects: PropTypes.arrayOf(
    PropTypes.shape({
      defect: PropTypes.shape({
        defectNumber: PropTypes.string,
        faultCategory: PropTypes.string,
      }),
      targetDefectNumber: PropTypes.string,
    })
  ).isRequired,
  success: PropTypes.bool.isRequired,
};

export { DefectStatusList };
