import PropTypes from 'prop-types';
import styled from 'styled-components';

import { A, H2, P } from '../type';
import { Email } from '../icons';
import { Flex } from '../grid';

const ContactDetails = ({ contactEmail }) => (
  <Wrapper flexWrap="wrap" flexDirection="column">
    <H2>Need help?</H2>
    <P>Contact our support team</P>
    <Contact alignItems="center">
      <Email />
      <A href={`mailto:${contactEmail}`}>{contactEmail}</A>
    </Contact>
  </Wrapper>
);

const Wrapper = styled(Flex)`
  padding-right: ${({ theme }) => theme.space.sm};
  margin-top: ${({ theme }) => theme.space.sm};

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.interactionFive};

    :hover,
    :focus {
      text-decoration: underline;
    }
  }
`;

const Contact = styled(Flex)`
  padding-right: ${({ theme }) => theme.space.sm};
  margin-top: 8px;
  color: ${({ theme }) => theme.greyFive};

  svg {
    width: 1rem;
    height: 1rem;
    margin-right: ${({ theme }) => theme.space.xxs};

    > * {
      fill: ${({ theme }) => theme.colors.interactionFive};
    }
  }
`;

ContactDetails.propTypes = {
  contactEmail: PropTypes.string,
};

ContactDetails.defaultProps = {
  contactEmail: 'portalsupport@linfox.com',
};

export { ContactDetails };
