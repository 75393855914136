import PropTypes from 'prop-types';

const userPropType = PropTypes.shape({
  ContactID: PropTypes.string.isRequired,
  FullName: PropTypes.string.isRequired,
  EmailAddress: PropTypes.string.isRequired,
  Account: PropTypes.string.isRequired,
  Contact: PropTypes.instanceOf(Array).isRequired,
});

export { userPropType };
