import 'react-day-picker/lib/style.css';
import { observer } from 'mobx-react';

import { Filter, FilterBar, icons } from '../ui-library';
import { useFeatureFlag } from '../../hooks/use-feature-flag';
import { useRootStore } from '../store-provider/lib';

const { Calendar, Location, Status } = icons;

const ShipmentFilterBar = () => {
  const { ShipmentStore } = useRootStore();

  const {
    activeParameters,
    dateDefaultValue,
    dateFilterLabel,
    formatDateFrom,
    formatDateTo,
    keyword,
    setParameters,
    clearFilters,
    isFiltersActive,
  } = ShipmentStore;

  const searchProps = {
    keyword,
    placeholder: 'Search shipments by Shipment ID or Reference #',
    onSubmit: setParameters,
  };

  const enableLastMile = useFeatureFlag('LAST_MILE');

  const statusOptions = enableLastMile
    ? [
        { key: 'Every Status', label: 'Every Status' },
        { key: 'Created', label: 'Created' },
        { key: 'Processing', label: 'Processed' },
        { key: 'In Transit', label: 'In Transit' },
        { key: 'Last Mile', label: 'Last Mile' },
        { key: 'Delivered', label: 'Delivered' },
        { key: 'Cancelled', label: 'Cancelled' },
      ]
    : [
        { key: 'Every Status', label: 'Every Status' },
        { key: 'Created', label: 'Created' },
        { key: 'Processing', label: 'Processed' },
        { key: 'In Transit', label: 'In Transit' },
        { key: 'Delivered', label: 'Delivered' },
        { key: 'Cancelled', label: 'Cancelled' },
      ];

  return (
    <FilterBar searchProps={searchProps} isFiltersActive={isFiltersActive} clearFilters={clearFilters}>
      <Filter
        id="date"
        type="date"
        defaultChipLabel={dateFilterLabel}
        isActive
        chipIcon={<Calendar />}
        defaultValues={dateDefaultValue}
        onChange={({ date: { day, from, to } }) => {
          setParameters({
            day,
            dateTo: formatDateTo(to),
            dateFrom: formatDateFrom(from),
          });
        }}
      />
      <Filter
        id="location"
        type="input"
        defaultChipLabel="All Locations"
        chipIcon={<Location />}
        data={{ placeholder: 'Enter Suburb or Postcode' }}
        onChange={({ location }) => setParameters({ location })}
        defaultValues={{ location: activeParameters.location }}
      />
      <Filter
        id="shipmentStatus"
        type="radio"
        defaultChipLabel="Every Status"
        chipIcon={<Status />}
        data={{
          options: statusOptions,
        }}
        onChange={({ shipmentStatus }) => {
          if (shipmentStatus === 'Every Status') {
            setParameters({ shipmentStatus: '' });
            return;
          }

          setParameters({ shipmentStatus });
        }}
        resetKey="Every Status"
        defaultValues={{ shipmentStatus: activeParameters.shipmentStatus || 'Every Status' }}
      />
    </FilterBar>
  );
};

const ObservedShipmentFilterBar = observer(ShipmentFilterBar);
export { ObservedShipmentFilterBar as ShipmentFilterBar };
