import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Flex } from '../grid';
import { LoadingIndicator } from '../icons/loading-indicator';
import { useAsyncOnClick } from '../../hooks/use-async-on-click';

const Switch = ({ disabled, isActive, onClick }) => {
  const [handleClick, isFetching] = useAsyncOnClick(onClick);

  return (
    <button disabled={disabled || isFetching} type="button" onClick={handleClick} aria-label="switch">
      {!isFetching && <SwitchButton isActive={isActive} disabled={disabled} />}
      {isFetching && (
        <LoadingWrapper>
          <StyledLoadingIndicator />
        </LoadingWrapper>
      )}
    </button>
  );
};

const LoadingWrapper = styled(Flex)`
  position: relative;
  width: 2.5rem;
  height: 1.5rem;
  border-radius: 1.5rem;
  justify-content: center;
`;

const StyledLoadingIndicator = styled(LoadingIndicator).attrs(({ theme }) => ({
  color: theme.colors.interactionFive,
}))``;

const SwitchButton = styled.div`
  position: relative;
  width: 2.5rem;
  height: 1.5rem;
  border-radius: 1.5rem;
  background-color: ${({ theme }) => theme.colors.greyFour};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  :after {
    content: '';
    width: 1rem;
    height: 1rem;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    transition: left ease-out 150ms;
  }

  :hover {
    opacity: 0.9;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${({ theme }) => theme.colors.interactionFive};

      :after {
        left: 1.25rem;
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;

      :hover {
        opacity: 0.4;
      }
    `}
`;

Switch.propTypes = {
  disabled: PropTypes.bool,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
};

Switch.defaultProps = {
  disabled: false,
  isActive: false,
  onClick: null,
};

export { Switch };
