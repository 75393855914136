import { isEmpty, upperCase } from 'lodash';

const EXPECTED_EMPTY_CUSTOMER_REFERENCE_VALUE = 'NUL';

const renderCustomerReference = (customerReference) => {
  const isMissing =
    isEmpty(customerReference) || upperCase(customerReference) === EXPECTED_EMPTY_CUSTOMER_REFERENCE_VALUE;

  if (isMissing) {
    return 'N/A';
  }

  return customerReference;
};

export { renderCustomerReference };
