import { flow, getEnv, types } from 'mobx-state-tree';
import { isEmpty } from 'lodash';

import { Permission } from '../permission';

const SUCCESS_MESSAGE = 'Contract Contact deleted Successfully';

const Contract = types
  .model('Contract', {
    ContactID: types.string,
    ContractID: types.string,
    ContractContactID: types.maybe(types.string),
    ContractName: types.string,
    isActive: types.optional(types.boolean, false),
    permissions: types.optional(types.array(Permission), []),
  })
  .actions((self) => {
    const { api } = getEnv(self);

    return {
      disable: flow(function* () {
        const response = yield api.del(`user/contract?contractContactID=${self.ContractContactID}`);

        const {
          data: {
            message: { message },
          },
        } = response;

        if (message === SUCCESS_MESSAGE) {
          self.isActive = false;
          delete self.ContractContactID;
          self.permissions = [];
        } else {
          // TODO: work out how to access ToastStore within this store; as this is a nested child, it is not as simple as calling `getRoot`.
          // ToastStore.addToast({ variant: 'error', description: 'There was an error removing this contract.' });
        }
      }),
      enable: flow(function* () {
        const response = yield api.post('user/contract', {
          body: { contactID: self.ContactID, contractID: self.ContractID },
        });

        const {
          data: {
            message: { message },
          },
        } = response;

        if (message) {
          self.ContractContactID = message;

          yield self.fetchContractPermissions();

          self.isActive = true;
        } else {
          // TODO: work out how to access ToastStore within this store; as this is a nested child, it is not as simple as calling `getRoot`.
          // ToastStore.addToast({ variant: 'error', description: 'There was an error adding this contract.' });
        }
      }),
      fetchContractPermissions: flow(function* () {
        const { data, status } = yield api.get(`user/contractPermissions?contractId=${self.ContractID}`);

        if (status === 200) {
          const { permissions: availablePermissions } = data;

          const permissions = availablePermissions.map((permission) => {
            const activePermission = self.permissions.find(
              ({ PermissionID }) => PermissionID === permission.PermissionID
            );

            if (isEmpty(activePermission)) {
              return { ...permission, ContractContactID: self.ContractContactID, isActive: false };
            }

            return { ...activePermission, ContractContactID: self.ContractContactID };
          });

          self.permissions = permissions;
        } else {
          self.permissions = [];
        }
      }),
    };
  });

export { Contract };
