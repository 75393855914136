import PropTypes from 'prop-types';

import { colors } from '../../../constants/colors';

const InfoWithBackground = ({ backgroundColor, fill, ...rest }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill={fill} {...rest}>
    <path
      d="M12 22C13.9778 22 15.9112 21.4135 17.5557 20.3147C19.2002 19.2159 20.4819 17.6541 21.2388 15.8268C21.9957 13.9996 22.1937 11.9889 21.8079 10.0491C21.422 8.10929 20.4696 6.32746 19.0711 4.92893C17.6725 3.53041 15.8907 2.578 13.9509 2.19215C12.0111 1.80629 10.0004 2.00433 8.17317 2.7612C6.34591 3.51808 4.78412 4.79981 3.68531 6.4443C2.58649 8.08879 2 10.0222 2 12C2 13.3132 2.25866 14.6136 2.7612 15.8268C3.26375 17.0401 4.00035 18.1425 4.92893 19.0711C5.85752 19.9997 6.95991 20.7362 8.17316 21.2388C9.38642 21.7413 10.6868 22 12 22Z"
      fill={backgroundColor}
    />
    <path d="M12.8485 17.6485C12.6235 17.8736 12.3183 18 12 18C11.6818 18 11.3765 17.8736 11.1515 17.6485C10.9265 17.4235 10.8 17.1183 10.8 16.8L10.8 12.0001C10.8 11.6819 10.9265 11.3767 11.1515 11.1516C11.3765 10.9266 11.6818 10.8002 12 10.8002C12.3183 10.8002 12.6235 10.9266 12.8485 11.1516C13.0736 11.3767 13.2 11.6819 13.2 12.0001L13.2 16.8C13.2 17.1183 13.0736 17.4235 12.8485 17.6485Z" />
    <path d="M11.004 7.87224C10.9586 7.80534 10.9223 7.73271 10.896 7.65624C10.8531 7.58963 10.8207 7.51677 10.8 7.44025C10.7941 7.36036 10.7941 7.28014 10.8 7.20025C10.8019 7.04344 10.8345 6.88853 10.896 6.74426C10.9499 6.59535 11.0359 6.46011 11.1479 6.34813C11.2599 6.23615 11.3951 6.15017 11.544 6.09628C11.6877 6.03279 11.843 6 12 6C12.1571 6 12.3124 6.03279 12.456 6.09628C12.6049 6.15017 12.7401 6.23615 12.8521 6.34813C12.9641 6.46011 13.0501 6.59535 13.104 6.74426C13.1655 6.88853 13.1981 7.04344 13.2 7.20025C13.2009 7.35818 13.1706 7.51473 13.1109 7.66093C13.0512 7.80713 12.9632 7.94011 12.852 8.05223C12.7354 8.15823 12.6014 8.24346 12.456 8.30423C12.2375 8.39612 11.9966 8.42123 11.7638 8.37638C11.5311 8.33153 11.3168 8.21874 11.148 8.05223L11.004 7.87224Z" />
  </svg>
);

InfoWithBackground.propTypes = {
  backgroundColor: PropTypes.string,
  fill: PropTypes.string,
};

InfoWithBackground.defaultProps = {
  backgroundColor: colors.black,
  fill: colors.white,
};

export { InfoWithBackground };
