import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ArrowTail, Search } from '../../icons';
import { Flex } from '../../grid';
import { Form, SubmitButton } from '../../form';
import { Input } from '../../form/input';
import { TextButton } from '../../text-button';
import { useOverlay } from '../../overlay';

const ESCAPE_KEY = 27;

const onKeyDown = (callback) => (event) => {
  if (event.keyCode === ESCAPE_KEY) {
    callback();
  }
};

const SearchForm = ({ keyword, placeholder, onCancel, onSubmit }) => {
  const [hasFocus, setFocus] = useState(false);

  const ref = useOverlay(() => setFocus(false), hasFocus);

  let timeout;

  const onBlur = () => {
    timeout = setTimeout(() => {
      if (ref.current) {
        setFocus(false);
      }
    });
  };

  const onFocus = () => {
    clearTimeout(timeout);
    setFocus(true);
  };

  const handleOnSubmit = (value) => {
    onSubmit(value);
    setFocus(false);
  };

  return (
    <StyledForm
      defaultValues={{ keyword }}
      onBlur={onBlur}
      onFocus={onFocus}
      onKeyDown={onKeyDown(onCancel)}
      onSubmit={handleOnSubmit}
      ref={ref}
    >
      <Flex alignItems="center">
        <SearchIcon />
        <SearchWrapper>
          <SearchField
            autoCapitalize="off"
            autoComplete="off"
            autoCorrect="off"
            autoFocus={!keyword}
            mb={0}
            name="keyword"
            placeholder={placeholder}
            spellcheck="false"
            validation={{
              required: 'A search query is required.',
              minLength: {
                value: 3,
                message: 'Search query must be longer than 3 characters.',
              },
              maxLength: {
                value: 120,
                message: 'Search query must be less than 120 characters.',
              },
            }}
          />
        </SearchWrapper>
      </Flex>
      <TextButton variant="secondary" onClick={onCancel}>
        Cancel
      </TextButton>
      <SubmitButton aria-label="Submit">
        <ArrowTail />
      </SubmitButton>
    </StyledForm>
  );
};

const SearchWrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr max-content;
  grid-column-gap: 2rem;
`;

const SearchField = styled(Input).attrs(() => ({ type: 'text' }))`
  box-shadow: none;
  caret-color: ${({ theme }) => theme.colors.interactionSix};
  height: ${({ theme }) => theme.space.lg};
  padding-left: ${({ theme }) => theme.space.lg};
`;

const SearchIcon = styled(Search)`
  width: 1rem;
  height: 1rem;
  margin-right: 0;
  margin-left: calc(1rem + 1px);
  position: absolute;
  z-index: 1;
`;

const StyledForm = styled(Form)`
  align-items: center;
  display: grid;
  grid-column: span 2;
  grid-auto-flow: column;
  grid-template-columns: 1fr max-content max-content;
  grid-column-gap: ${({ theme }) => theme.space.xxs};

  * + * {
    margin-left: initial;
    width: 100%;
  }
`;

SearchForm.propTypes = {
  keyword: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export { SearchForm };
