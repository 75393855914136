// Not the most ideal location, as everything else in the constants dir
// is not linfox specific knowledge
const contractPrivileges = {
  admin: 'eDRR - Admin',
  booking: 'booking',
  caseManagement: 'case',
  defect: 'eDRR - User',
  track: 'track',
};

const contractPrivilegeNames = {
  [contractPrivileges.admin]: 'Administrator',
  [contractPrivileges.booking]: 'Booking',
  [contractPrivileges.caseManagement]: 'Cases',
  [contractPrivileges.defect]: 'Defect',
  [contractPrivileges.track]: 'Track & Trace',
};

export { contractPrivilegeNames, contractPrivileges };
