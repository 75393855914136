import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Box } from '../grid';
import { Chip } from '../chip';
import { FilterContext } from './filter-provider';
import { FilterPanel } from './filter-panel';
import { useOverlay } from '../overlay';

const FILTER_PANEL_PADDING = 4;

const Filter = ({
  id,
  children,
  defaultChipLabel,
  chipIcon,
  defaultValues,
  data,
  type,
  resetKey,
  onChange,
  isActive,
}) => {
  const { activeFilterPanel, setIsInactiveFilters, isInactiveFilters, closeFilterPanel, toggleIsOpen } =
    useContext(FilterContext);

  const isOpen = activeFilterPanel === id;

  const triggerClose = () => {
    if (isOpen) closeFilterPanel();
  };

  const ref = useOverlay(triggerClose, isOpen, { isPortal: true });

  const [chipLabel, setChipLabel] = useState(defaultChipLabel);

  const valueComparison = JSON.stringify(defaultValues);

  // update the chip label if the value changes
  useEffect(() => {
    if (type === 'radio') {
      const { options = [] } = data;

      const selected = options.find(({ key }) => key === defaultValues[id]);

      if (selected) {
        setChipLabel(selected.label);
      } else {
        setChipLabel(defaultChipLabel);
      }
    } else {
      const value = defaultValues[id];

      if (value) {
        setChipLabel(value);
      } else {
        setChipLabel(defaultChipLabel);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueComparison]);

  const handleOnSubmit = (values) => {
    onChange(values);
    triggerClose();
  };

  const getOffset = (target) => {
    if (ref.current) {
      return ref.current.getBoundingClientRect()[target];
    }

    return 0;
  };

  const onChipLabelClick = () => {
    toggleIsOpen(id);

    if (isInactiveFilters) {
      setIsInactiveFilters(false);
    }
  };

  // Do not display the filter if this is a radio filter, and there are 0 or 1 options.
  if (type === 'radio') {
    const { options = [] } = data;

    if (options.length < 2) return null;
  }

  return (
    <Box position="relative" ref={ref}>
      <Chip icon={chipIcon} isActive={isActive || defaultChipLabel !== chipLabel} onClick={onChipLabelClick}>
        {children ? children({ label: chipLabel, value: defaultValues }) : chipLabel}
      </Chip>
      <FilterPanel
        leftOffset={getOffset('left')}
        topOffset={getOffset('bottom') + FILTER_PANEL_PADDING}
        isVisible={isOpen}
        name={id}
        type={type}
        data={data}
        defaultValues={defaultValues}
        onSubmit={handleOnSubmit}
        onCancel={triggerClose}
        resetKey={resetKey}
      />
    </Box>
  );
};

Filter.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.func,
  defaultChipLabel: PropTypes.string,
  chipIcon: PropTypes.element,
  data: PropTypes.shape({
    options: PropTypes.arrayOf(PropTypes.shape({ key: PropTypes.string, label: PropTypes.string })),
  }),
  defaultValues: PropTypes.shape({}),
  type: PropTypes.string,
  resetKey: PropTypes.string,
  onChange: PropTypes.func,
  // NOTE: isActive means the filter is always highlighted yellow even when matches default value
  isActive: PropTypes.bool,
};

Filter.defaultProps = {
  defaultChipLabel: '',
  children: null,
  chipIcon: null,
  data: {},
  defaultValues: {},
  type: 'date',
  resetKey: '',
  onChange: () => null,
  isActive: false,
};

export { Filter };
