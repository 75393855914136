import PropTypes from 'prop-types';

const Cross = ({ fill, ...rest }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...rest} fill={fill}>
    <path d="M17.6569 16.2426L13.4142 12L17.6569 7.75735C17.8444 7.56981 17.9497 7.31546 17.9497 7.05024C17.9497 6.78502 17.8444 6.53067 17.6569 6.34313C17.4693 6.1556 17.215 6.05024 16.9497 6.05024C16.6845 6.05024 16.4302 6.1556 16.2426 6.34313L12 10.5858L7.75736 6.34313C7.56982 6.1556 7.31547 6.05024 7.05025 6.05024C6.78504 6.05024 6.53068 6.1556 6.34315 6.34313C6.15561 6.53067 6.05025 6.78502 6.05025 7.05024C6.05025 7.31546 6.15561 7.56981 6.34315 7.75735L10.5858 12L6.34315 16.2426C6.15561 16.4302 6.05025 16.6845 6.05025 16.9497C6.05025 17.215 6.15561 17.4693 6.34315 17.6568C6.53068 17.8444 6.78504 17.9497 7.05025 17.9497C7.31547 17.9497 7.56982 17.8444 7.75736 17.6568L12 13.4142L16.2426 17.6568C16.4302 17.8444 16.6845 17.9497 16.9497 17.9497C17.215 17.9497 17.4693 17.8444 17.6569 17.6568C17.8444 17.4693 17.9497 17.215 17.9497 16.9497C17.9497 16.6845 17.8444 16.4302 17.6569 16.2426Z" />
  </svg>
);

Cross.propTypes = {
  fill: PropTypes.string,
};

Cross.defaultProps = {
  fill: '#fff',
};

export { Cross };
