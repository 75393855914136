import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box } from '../ui-library';
import { SkeletonRow } from '../skeleton-row';
import { useTemplateState } from '../template-context';

const BookingLayout = ({ children }) => {
  const { fields } = useTemplateState();

  if (isEmpty(fields))
    return (
      <BookingGrid>
        <Box backgroundColor="greyOne" />
        <Box p={4} maxWidth="900px" data-testid="booking-loading-skeleton">
          <SkeletonRow width="50%" height="2.5rem" isHeader mb={4} />

          <SkeletonRow width="30%" height="2.5rem" isHeader mb={2} />
          <SkeletonRow width="100%" height="7rem" mb={3} />

          <SkeletonRow width="30%" height="2.5rem" isHeader mb={2} />
          <SkeletonRow width="100%" height="7rem" mb={3} />

          <SkeletonRow width="30%" height="2.5rem" isHeader mb={2} />
          <SkeletonRow width="20%" height="2.5rem" mb={1} />
          <SkeletonRow width="60%" height="2.5rem" mb={1} />
          <SkeletonRow width="40%" height="2.5rem" mb={1} />
          <SkeletonRow width="60%" height="2.5rem" mb={1} />
          <SkeletonRow width="40%" height="2.5rem" mb={1} />
        </Box>
      </BookingGrid>
    );

  return <BookingGrid>{children}</BookingGrid>;
};

const BookingGrid = styled.div`
  display: grid;
  grid-template-columns: minmax(300px, 440px) minmax(800px, 1fr);
  height: 100%;
`;

BookingLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export { BookingLayout };
