import { grid } from 'styled-system';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { DangerousGoods } from '../dangerous-goods';
import { DynamicFormInput } from '../../../dynamic-form-input';
import { form } from '../../../ui-library';
import { LabelledInput } from '../../../labelled-input';
import { useHydrateItem } from '../../../../hooks/use-hydrate-item';
import { useTemplateState } from '../../../template-context';
import { useVolumeCalculator } from '../use-volume-calculator';

const { useFormState } = form;

const ItemForm = ({ activeItem }) => {
  const { apiFields } = useTemplateState();

  const { watch } = useFormState();

  useHydrateItem(activeItem ? { items: [activeItem] } : null);

  useVolumeCalculator();

  const itemDimsUOM = watch(apiFields.itemDimsUOM);
  const itemVolumeUOM = watch(apiFields.itemVolumeUOM);
  const itemWeightUOM = watch(apiFields.itemWeightUOM);

  return (
    <>
      <DynamicFormInput name="itemVolumeUOM" hidden />
      <DynamicFormInput name="itemWeightUOM" hidden />
      <DynamicFormInput name="itemDimsUOM" hidden />
      <DynamicFormInput
        name="itemQty"
        render={({ validation, ...props }) => (
          <LabelledInput validation={{ ...validation, valueAsNumber: true }} {...props} />
        )}
      />
      <DynamicFormInput name="itemQtyText" render={(props) => <LabelledInput {...props} />} />
      <DynamicFormInput
        name="itemType"
        render={(props) => (
          <Cell gridColumn="span 2">
            <LabelledInput {...props} />
          </Cell>
        )}
      />
      <DynamicFormInput
        name="itemProduct"
        render={(props) => (
          <Cell gridColumn="span 2">
            <LabelledInput {...props} />
          </Cell>
        )}
      />
      <DynamicFormInput
        name="itemCargo"
        render={(props) => (
          <Cell gridColumn="span 2">
            <LabelledInput {...props} />
          </Cell>
        )}
      />
      <DynamicFormInput
        name="itemHeight"
        render={({ label, ...props }) => <LabelledInput label={`${label} (${itemDimsUOM})`} {...props} />}
      />
      <DynamicFormInput
        name="itemLength"
        render={({ label, ...props }) => <LabelledInput label={`${label} (${itemDimsUOM})`} {...props} />}
      />
      <DynamicFormInput
        name="itemWidth"
        render={({ label, ...props }) => <LabelledInput label={`${label} (${itemDimsUOM})`} {...props} />}
      />
      <>
        {/* NB: depending on the template, item volume will either be calculated as L x W x H (eg `itemVolume`)
            or will be manually entered by the user (eg `itemVolumeText`). These fields should never appear in the same
            template.
        */}
        <DynamicFormInput
          name="itemVolume"
          render={({ label, ...props }) => <LabelledInput label={`${label} (${itemVolumeUOM})`} {...props} />}
        />
        <DynamicFormInput
          name="itemVolumeText"
          render={({ label, ...props }) => <LabelledInput label={`${label} (${itemVolumeUOM})`} {...props} />}
        />
      </>
      <DynamicFormInput
        name="itemGroWeight"
        render={({ label, ...props }) => <LabelledInput label={`${label} (${itemWeightUOM})`} {...props} />}
      />
      <Cell gridColumn="span 2" />
      <DynamicFormInput
        name="itemDescription"
        render={(props) => (
          <Cell gridColumn="span 4">
            <LabelledInput {...props} />
          </Cell>
        )}
      />
      <DynamicFormInput
        name="containerNumber"
        render={(props) => (
          <Cell gridColumn="span 2">
            <LabelledInput {...props} />{' '}
          </Cell>
        )}
      />
      <DynamicFormInput
        name="itemBookingLineItemID"
        render={(props) => (
          <Cell gridColumn="span 2">
            <LabelledInput {...props} />
          </Cell>
        )}
      />
      <Cell gridColumn="span 2" />
      <DynamicFormInput
        name="itemReference"
        render={(props) => (
          <Cell gridColumn="span 4">
            <LabelledInput {...props} />
          </Cell>
        )}
      />
      <DynamicFormInput
        name="itemDocketNum"
        render={(props) => (
          <Cell gridColumn="span 4">
            <LabelledInput {...props} />
          </Cell>
        )}
      />
      <DynamicFormInput
        name="shippingReference"
        render={(props) => (
          <Cell gridColumn="span 4">
            <LabelledInput {...props} />
          </Cell>
        )}
      />
      <DynamicFormInput
        name="containerReference"
        render={(props) => (
          <Cell gridColumn="span 4">
            <LabelledInput {...props} />
          </Cell>
        )}
      />
      <DynamicFormInput
        name="erncrn"
        render={(props) => (
          <Cell gridColumn="span 2">
            <LabelledInput {...props} />
          </Cell>
        )}
      />
      <DynamicFormInput
        name="seal"
        render={(props) => (
          <Cell gridColumn="span 2">
            <LabelledInput {...props} />
          </Cell>
        )}
      />
      <DynamicFormInput
        name="itemSSCC"
        render={(props) => (
          <Cell gridColumn="span 2">
            <LabelledInput {...props} />
          </Cell>
        )}
      />
      <DynamicFormInput name="itemTemperature" render={(props) => <LabelledInput {...props} />} />
      <DynamicFormInput name="batchNumber" render={(props) => <LabelledInput {...props} />} />
      <DynamicFormInput name="expiryDate" render={(props) => <LabelledInput {...props} />} />
      <DynamicFormInput name="itemExpiryValidate" render={(props) => <LabelledInput {...props} />} />
      <DynamicFormInput name="minLifeDays" render={(props) => <LabelledInput {...props} />} />
      <DynamicFormInput name="outOfGauge" render={(props) => <LabelledInput {...props} />} />
      <DynamicFormInput name="ecncrn" render={(props) => <LabelledInput {...props} />} />
      <DangerousGoods />
    </>
  );
};

ItemForm.propTypes = {
  activeItem: PropTypes.shape(),
};

ItemForm.defaultProps = {
  activeItem: null,
};

const Cell = styled.div`
  ${grid};
`;

export { ItemForm };
