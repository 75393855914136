import { get } from 'lodash';

import { Box, Flex, form } from '../../../ui-library';
import { useCalculateCargoMetrics } from '../../../../hooks/use-calculate-cargo-metrics';
import { useTemplateState } from '../../../template-context';

const { useFormState } = form;

const CargoSectionSummary = () => {
  const { watch } = useFormState();
  const { apiFields } = useTemplateState();

  const values = watch();
  const { items = [] } = values;

  const itemVolumeUOM = get(values, apiFields.itemVolumeUOM);
  const itemWeightUOM = get(values, apiFields.itemWeightUOM);

  const { quantity, weight, volume } = useCalculateCargoMetrics(items);

  const showLabel = !!quantity || !!weight || !!volume;

  if (!showLabel) {
    return null;
  }

  return (
    <Flex>
      <Box mr={3}>{quantity} items</Box>
      {!!itemWeightUOM && (
        <Box mr={3}>
          {weight} {itemWeightUOM}
        </Box>
      )}
      {!!itemVolumeUOM && (
        <Box>
          {volume} {itemVolumeUOM}
        </Box>
      )}
    </Flex>
  );
};

export { CargoSectionSummary };
