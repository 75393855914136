import { autorun, reaction } from 'mobx';
import { flow, getEnv, getRoot, types } from 'mobx-state-tree';
import { get } from 'lodash';

import { Booking } from '../../models/booking';
import { filterParameters } from '../../utils/filter-parameters';

const BookingStore = types
  .model('BookingStore', {
    availableTemplates: types.optional(types.frozen({}), []),
    bookings: types.optional(types.array(Booking), []),
    documents: types.optional(types.array(types.frozen({})), []),
    hasFetched: false, // used to control the initial data load
    isFetching: false,
    fetchingTemplates: true,
    limit: types.optional(types.number, 25),
    parameters: types.optional(
      types.model('Parameters', {
        bookingStatus: types.optional(types.string, ''),
        createdBy: types.optional(types.string, ''),
        keyword: types.optional(types.string, ''),
        location: types.optional(types.string, ''),
      }),
      {}
    ),
    selectedBooking: types.maybeNull(types.string),
    start: types.optional(types.number, 0),
    totalResults: types.optional(types.number, 0),
  })
  .actions((self) => {
    const { ToastStore } = getRoot(self);
    const { api, logger, setQueryString } = getEnv(self);

    autorun(() => {
      self.getAvailableTemplates();
    });

    // When any parameters change, clear the current state, and begin from the first page.
    reaction(
      () => self.activeParameters,
      async () => {
        await self.loadBookings();
      }
    );

    reaction(
      () => self.start,
      async (start) => {
        await self.loadBookings({ start, clear: false });
      }
    );

    return {
      selectBooking: (id) => {
        self.selectedBooking = id;
      },
      deselectBooking: () => {
        self.selectedBooking = null;
      },
      loadBookings: flow(function* (args = { clear: true }) {
        const { clear, ...overrides } = args;

        self.setQueryString(overrides);

        // only load bookings if a current fetch is not in progress
        if (self.fetching) return;

        self.isFetching = true;

        if (clear) {
          self.bookings = [];
          self.totalResults = 0;
          self.start = 0;
        }

        const params = new URLSearchParams({ ...self.activeParameters, limit: self.limit, start: self.start });

        const response = yield api.get(`booking/bookings?${params.toString()}`);

        if (response.status !== 200) {
          ToastStore.addToast({ variant: 'error', description: 'There was an error fetching bookings.' });

          self.totalResults = 0;
          self.bookings = [];

          self.hasFetched = true;
          self.isFetching = false;
        }

        const {
          data: { data: bookings = [], pagination },
        } = response;

        self.totalResults = get(pagination, 'total', 0);
        self.bookings = [...self.bookings, ...bookings];

        self.hasFetched = true;
        self.isFetching = false;
      }),
      loadBookingDetail: flow(function* (bookingId) {
        if (!bookingId) {
          return { data: null };
        }

        const response = yield api.get(`booking/booking/${bookingId}`, {
          acquireSilently: true,
          restricted: false,
        });

        const {
          data: { data, errors = null },
        } = response;

        if (!data || errors) {
          logger.error('Error occurred fetching booking detail.', JSON.stringify(errors));
        }

        const fields = get(data, 'templateData.templateJSON.fields');

        self.documents = get(data, 'attachments') || [];

        return { data, fields };
      }),
      fetchAttachment: flow(function* ({ bookingID, name, bookingDocumentID }) {
        try {
          const downloadURL = yield api.stateMachine('booking/attachment', {
            body: {
              bookingID,
              name,
              bookingDocumentID,
            },
          });

          ToastStore.addToast({ variant: 'success', description: 'Fetching attachment...' });

          return downloadURL;
        } catch (error) {
          logger.error('Error downloading file.', { error, bookingID, name, bookingDocumentID });

          throw new Error(`${name} could not be downloaded at this time.`);
        }
      }),
      saveLocation: flow(function* ({ contractID, serviceID, ...data }) {
        try {
          yield api.post('booking/location/new', {
            body: {
              contractID,
              serviceID,
              ...data,
            },
          });

          return true;
        } catch (error) {
          logger.error('Error saving location.', { error, contractID, serviceID, ...data });

          return false;
        }
      }),
      uploadAttachment: flow(function* ({ documentType, file = [] }) {
        const bookingID = self.selectedBooking;

        if (!bookingID) {
          throw new Error('Booking must be selected.');
        }

        if (!file) {
          throw new Error('File must be selected.');
        }

        const fileName = file.name.replaceAll(' ', '_');

        const {
          data: { message },
        } = yield api.post('attachment/new', {
          body: { fileName, fileType: file.type },
        });

        const { key, uploadURL } = message;

        const upload = yield fetch(uploadURL, {
          method: 'PUT',
          body: file,
        });

        if (upload.status === 200) {
          const data = yield api.stateMachine('booking/attachment/new', {
            body: {
              key,
              contentType: file.type,
              name: fileName,
              bookingID,
              documentType,
            },
          });

          yield self.loadBookingSupportingDocuments(bookingID);

          const { error = false } = data;

          if (error) {
            logger.error(error);

            const { Cause } = error;

            const { errorMessage } = JSON.parse(Cause);

            ToastStore.addToast({ variant: 'error', description: errorMessage });
          }
        }
      }),
      fetchTemplateFile: flow(function* ({ bookingID, templateID }) {
        try {
          const downloadURL = yield api.stateMachine(`booking/${bookingID}/templates/${templateID}`, { method: 'GET' });

          ToastStore.addToast({ variant: 'success', description: 'Fetching document...' });

          return downloadURL;
        } catch (error) {
          logger.error('Error downloading file.', { error, bookingID, templateID });

          ToastStore.addToast({ variant: 'error', description: 'An error occurred fetching this document.' });

          return null;
        }
      }),
      loadBookingSupportingDocuments: flow(function* (bookingId) {
        if (!bookingId) {
          throw new Error('Booking must be selected.');
        }

        const response = yield api.get(`booking/attachments/${bookingId}`, {
          acquireSilently: true,
          restricted: false,
        });

        const {
          data: { data = [] },
        } = response;

        self.documents = data;
      }),
      getAvailableTemplates: flow(function* () {
        const { data, status } = yield api.get('booking/templates');

        if (status !== 200) {
          logger.error('Failed to load services', { data });
        } else {
          const { data: availableTemplates = [] } = data;

          self.availableTemplates = availableTemplates;
        }

        self.fetchingTemplates = false;
      }),
      saveBooking: flow(function* (data) {
        const bookingID = get(data, 'bookingID');

        if (bookingID) {
          const bookingSaved = yield self.updateBooking(data);

          if (bookingSaved) return bookingID;

          return null;
        }

        return yield self.createBooking(data);
      }),
      deleteAttachment: flow(function* ({ bookingID, bookingDocumentID }) {
        const response = yield api.del(`booking/${bookingID}/documents/${bookingDocumentID}`);

        if (response.status !== 204) {
          ToastStore.addToast({
            variant: 'error',
            description: 'Attachment could not be deleted. Please try again or contact support.',
          });

          logger.error('Failed to delete attachment');
        } else {
          yield self.loadBookingSupportingDocuments(bookingID);

          ToastStore.addToast({
            variant: 'success',
            description: 'Attachment deleted.',
          });
        }

        return true;
      }),
      deleteBooking: flow(function* (bookingID) {
        const response = yield api.del(`booking/${bookingID}`);

        if (response.status !== 204) {
          ToastStore.addToast({
            variant: 'error',
            description: 'Booking could not be deleted. Please try again or contact support.',
          });

          logger.error('Failed to delete booking');
        } else {
          ToastStore.addToast({
            variant: 'success',
            description: 'Booking deleted.',
          });
        }

        return true;
      }),
      createBooking: flow(function* (data) {
        const response = yield api.post('booking', { body: data });

        if (response.status !== 200) {
          ToastStore.addToast({
            variant: 'error',
            description: 'Booking could not be created. Please try again or contact support.',
          });

          logger.error('Failed to create booking');

          return null;
        }

        ToastStore.addToast({
          variant: 'success',
          description: 'Booking created.',
        });

        return response.data.message.data.bookingID;
      }),
      duplicateBooking: flow(function* (data) {
        const bookingID = get(data, 'bookingID');

        if (!bookingID) {
          logger.error('Booking ID required to duplicate booking');
          return null;
        }

        const response = yield api.post(`booking/copy`, { body: data });

        if (response.status !== 200) {
          ToastStore.addToast({
            variant: 'error',
            description: 'Booking could not be duplicated. Please try again or contact support.',
          });

          logger.error('Failed to duplicate booking');

          return null;
        }

        ToastStore.addToast({
          variant: 'success',
          description: 'Booking duplicated.',
        });

        return response.data.message.data.bookingID;
      }),
      updateBooking: flow(function* (data, showSuccessToast = true) {
        const { bookingID = null, items = [] } = data;

        // update `lineItemID` to be increments of 10
        const itemsWithLineItemIDs = items.map((item, index) => ({
          ...item,
          lineItemID: ((index + 1) * 10).toString(),
        }));

        if (!bookingID) {
          logger.error('Booking ID required to update booking');
          return false;
        }

        const response = yield api.post(`booking/${bookingID}`, {
          body: {
            ...data,
            items: itemsWithLineItemIDs,
          },
        });

        if (response.status !== 200) {
          ToastStore.addToast({
            variant: 'error',
            description: 'Booking could not be saved. Please try again or contact support.',
          });

          logger.error('Failed to update booking');

          return false;
        }

        if (showSuccessToast) ToastStore.addToast({ variant: 'success', description: 'Booking saved.' });

        return true;
      }),
      submitBooking: flow(function* (data) {
        const bookingSubmitted = yield self.updateBooking({ ...data, bookingStatus: 'Submitted' }, false);

        ToastStore.addToast({
          variant: 'success',
          description: 'Booking request submitted.',
          linkTo: '/bookings',
          linkLabel: 'Show all bookings',
        });

        return bookingSubmitted;
      }),
      loadMore: () => {
        self.start += self.limit;
      },
      setParameters: (parameters = {}) => {
        self.parameters = { ...self.parameters, ...parameters };
      },
      setQueryString: (overrides = {}) => {
        setQueryString({ ...self.activeParameters, ...overrides });
      },
      clearFilters: () => {
        self.parameters = {
          bookingStatus: '',
          createdBy: '',
          location: '',
        };
      },
    };
  })
  .views((self) => ({
    get activeParameters() {
      if (self.parameters.keyword) {
        return { keyword: self.parameters.keyword };
      }

      return filterParameters(self.parameters);
    },
    get keyword() {
      return self.parameters.keyword;
    },
    get isFiltersActive() {
      const { bookingStatus, createdBy, location } = self.parameters;

      const parametersHaveValue = !!bookingStatus || !!createdBy || !!location;
      return parametersHaveValue;
    },
  }));

export { BookingStore };
