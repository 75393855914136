import PropTypes from 'prop-types';
import styled from 'styled-components';

import { breakpoints, grid } from '../../constants';
import { Flex } from './flex';

const toPercent = (value) => `${value * 100}%`;

const Col = ({ className, offset, ...rest }) => (
  <StyledCol {...rest} className={className} offset={offset ? offset.map(toPercent) : []} />
);

const StyledCol = styled(Flex)`
  flex-direction: column;

  margin-left: ${({ offset }) => (offset ? offset[0] : 0)};
  padding-left: ${grid.gutterHalf};
  padding-right: ${grid.gutterHalf};

  @media (min-width: ${breakpoints.sm}) {
    margin-left: ${({ offset }) => offset[1]};
  }

  @media (min-width: ${breakpoints.md}) {
    margin-left: ${({ offset }) => offset[2]};
  }

  @media (min-width: ${breakpoints.lg}) {
    margin-left: ${({ offset }) => offset[3]};
  }

  @media (min-width: ${breakpoints.xl}) {
    margin-left: ${({ offset }) => offset[4]};
  }
`;

Col.propTypes = {
  className: PropTypes.string,
  offset: PropTypes.arrayOf(PropTypes.number),
};

Col.defaultProps = {
  className: null,
  offset: null,
};

export { Col };
