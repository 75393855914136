import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { icons } from '../../../ui-library';

const { LoadingIndicator } = icons;

const Action = ({ label, onClick }) => {
  const [isFetching, setIsFetching] = useState(false);

  const handleClick = async () => {
    if (!isFetching) {
      setIsFetching(true);

      await onClick();

      setIsFetching(false);
    }
  };

  return (
    <StyledAction as="button" onClick={handleClick} color="greyEight">
      {isFetching ? <LoadingIndicator /> : label}
    </StyledAction>
  );
};

Action.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

const StyledAction = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xSmall};
`;

export { Action };
