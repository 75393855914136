import { addMinutes, format, startOfToday } from 'date-fns';
import PropTypes from 'prop-types';

import { form } from '../ui-library';
import { formatTemplateType } from '../../utils/format-template-type';
import { useHydrateFormValue } from '../booking-form/use-hydrate-form-value';
import { useTemplateState } from '../template-context';
import { useValidationRules } from './use-validation-rules';

const { Input, useFormState } = form;

const getDefaultValue = (field = {}) => {
  const { default: defaultValue = null, type } = field;

  switch (type) {
    case 'Checkbox':
      return !!defaultValue;
    case 'Select':
      // Select fields cannot have a null default value.
      // This will trigger the 'Please select a widget' placeholder text to display.
      // (Unless a default option is provided, selecting an option must be an explicit action --
      // do not default to the first option.)
      return defaultValue || '';
    default:
      return defaultValue;
  }
};

const DynamicFormInput = ({ hidden, name, render, showHiddenField, prepopulateField }) => {
  const { watch } = useFormState();

  const { normalisedFields } = useTemplateState();

  const { [name]: field } = normalisedFields;

  const {
    name: label,
    type: templateType,
    visible,
    apiField,
    validations,
    dependenton,
    options: fieldOptions = [],
  } = field || {};

  const defaultValue = getDefaultValue(field);

  useHydrateFormValue(apiField, defaultValue, prepopulateField);

  const validation = useValidationRules(validations, templateType);

  if (!field) return null;

  // Does a `required` validation rule exist?
  const { required = false } = validation;

  // if a templateType is Literal, it should not be editable.
  const readOnly = templateType === 'Literal';

  // Multi-Select fields use the standard Select component, but pass through an `isMulti` prop
  const isMulti = templateType === 'Multi-Select';

  // Some fields should only appear if another field is active (eg: dangerous goods).
  if (dependenton) {
    const { [dependenton]: dependentField } = normalisedFields;

    if (!dependentField) return <Input name={apiField} type="hidden" />;

    const dependentValue = watch(dependentField.apiField);

    if (!dependentValue) return <Input name={apiField} type="hidden" />;
  }

  const type = formatTemplateType(templateType);

  let options = fieldOptions.map(({ text, value }) => ({ label: text, value }));

  if (type === 'datetimepicker') {
    const startDate = startOfToday();
    const interval = 60;

    options = Array.from({ length: 24 * (60 / interval) }, (_, i) => {
      const nextTime = addMinutes(startDate, interval * i);

      const formatted = format(nextTime, 'h:mm a');

      return { label: formatted, value: formatted };
    });
  }

  // visible: false is set by the template, and by default, these fields should display as a hidden input
  // `showHiddenField` overrides this to display the field in the UI
  // `hidden` overrides any template settings, to always hide the field
  if ((visible === 'false' && !showHiddenField) || hidden) {
    return <Input name={apiField} type="hidden" />;
  }

  return render({ label, isMulti, name: apiField, options, readOnly, required: !!required, type, validation });
};

DynamicFormInput.propTypes = {
  hidden: PropTypes.bool,
  name: PropTypes.string.isRequired,
  render: PropTypes.func,
  showHiddenField: PropTypes.bool,
  prepopulateField: PropTypes.bool,
};

DynamicFormInput.defaultProps = {
  hidden: false,
  render: () => null,
  showHiddenField: false,
  prepopulateField: true,
};

export { DynamicFormInput };
