import PropTypes from 'prop-types';
import styled from 'styled-components';

const Badge = ({ children, color, backgroundColor, border }) => (
  <BadgeWrapper {...{ color, backgroundColor, border }}>{children}</BadgeWrapper>
);

const BadgeWrapper = styled.div`
  background-color: ${({ backgroundColor, theme }) => backgroundColor || theme.colors.black};
  border-radius: 6px;
  padding: ${({ theme }) => theme.space.xxs};
  color: ${({ color, theme }) => color || theme.colors.white};
  border: ${({ border }) => border};
  display: inline-flex;
  font-size: ${({ theme }) => theme.fontSize.xSmall};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

Badge.propTypes = {
  backgroundColor: PropTypes.string,
  border: PropTypes.string,
  children: PropTypes.string.isRequired,
  color: PropTypes.string,
};

Badge.defaultProps = {
  backgroundColor: null,
  border: 'none',
  color: null,
};

export { Badge };
