import { isEmpty, map, uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ContentHeaderWrapper } from '../content-header-wrapper';
import { contractPrivilegeNames } from '../../constants';
import { DetailsListItem, DetailsTitle } from '../details-list';
import { H2 } from '../type';

const EMPTY_FIELD_PLACEHOLDER = '-';

const Profile = ({ contracts, emailAddress, fullName, organisation }) => (
  <>
    <ContentHeaderWrapper>
      <H2 m={0}>{fullName}</H2>
    </ContentHeaderWrapper>
    <div>
      <DetailsTitle>Profile</DetailsTitle>
      <DetailsListItem title="Email">{emailAddress}</DetailsListItem>
      <DetailsListItem title="Organisation">
        <DetailsBlock>{organisation || EMPTY_FIELD_PLACEHOLDER}</DetailsBlock>
      </DetailsListItem>
    </div>
    <div>
      <DetailsTitle>Contract Permissions</DetailsTitle>
      {isEmpty(contracts)
        ? EMPTY_FIELD_PLACEHOLDER
        : map(contracts, (contract) => (
            <div key={contract.contractId}>
              <DetailsTitle subtitle="true">{contract.name}</DetailsTitle>
              <DetailsListItem title="Permissions">
                {isEmpty(contract.privilege)
                  ? EMPTY_FIELD_PLACEHOLDER
                  : map(uniqBy(contract.privilege, 'name'), (privilege) => (
                      <DetailsBlock key={privilege.name}>
                        {privilege.name ? contractPrivilegeNames[privilege.name] : EMPTY_FIELD_PLACEHOLDER}
                      </DetailsBlock>
                    ))}
              </DetailsListItem>
            </div>
          ))}
    </div>
  </>
);

const DetailsBlock = styled.div`
  & + & {
    margin-top: 4px;
  }

  a {
    color: ${({ theme }) => theme.colors.interactionFive};
  }
`;

Profile.propTypes = {
  contracts: PropTypes.arrayOf(
    PropTypes.shape({
      ContactID: PropTypes.string,
      ContractID: PropTypes.string,
      ContractContactID: PropTypes.string,
      ContractName: PropTypes.string,
      isActive: PropTypes.bool,
      permissions: PropTypes.arrayOf(PropTypes.shape({})),
    })
  ).isRequired,
  emailAddress: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  organisation: PropTypes.string.isRequired,
  contractPrivilegeNames: PropTypes.shape({}).isRequired,
};

export { Profile };
