import { grid } from 'styled-system';
import styled from 'styled-components';

import { BookingFormRow } from '../booking-form-row';
import { DynamicFormInput } from '../../dynamic-form-input';
import { formSectionMapping } from '../../../utils/form-section-mapping';
import { LabelledInput } from '../../labelled-input';

const RailTransport = () => (
  <BookingFormRow>
    <Wrapper>
      {formSectionMapping.railTransport.map((field) => (
        <DynamicFormInput
          key={field}
          name={field}
          render={(props) => (
            <Cell>
              <LabelledInput {...props} />
            </Cell>
          )}
        />
      ))}
    </Wrapper>
  </BookingFormRow>
);

const Wrapper = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(2, 1fr);
  padding-bottom: 2rem;
`;

const Cell = styled.div`
  ${grid};
`;

export { RailTransport };
