import { types } from 'mobx-state-tree';

const Contact = types.model('Contact', {
  contactName: types.maybeNull(types.string),
});

const Location = types.model('Location', {
  address1: types.maybeNull(types.string),
  address2: types.maybeNull(types.string),
  country: types.maybeNull(types.string),
  locationName: types.maybeNull(types.string),
  postcode: types.maybeNull(types.string),
  state: types.maybeNull(types.string),
  suburb: types.maybeNull(types.string),
});

const Booking = types.model('Booking', {
  bookingID: types.maybeNull(types.string),
  bookingNumber: types.maybeNull(types.string),
  bookingStatus: types.maybeNull(types.string),
  bookingTemplateID: types.maybeNull(types.string),
  bookingTemplateName: types.maybeNull(types.string),
  bookingTemplateVersion: types.maybeNull(types.string),
  commodityCode: types.maybeNull(types.string),
  commodityID: types.maybeNull(types.string),
  commodityName: types.maybeNull(types.string),
  contractCode: types.maybeNull(types.string),
  contractID: types.maybeNull(types.string),
  contractName: types.maybeNull(types.string),
  createdBy: types.maybeNull(types.string),
  createdByAccount: types.maybeNull(types.string),
  creationDateTime: types.maybeNull(types.string),
  customerReferenceNumber: types.maybeNull(types.string),
  dangerousGoodsIndicator: types.maybeNull(types.boolean),
  deliveryDetails: types.maybeNull(
    types.model('DeliveryDetails', {
      contact: Contact,
      deliveryFromDateTime: types.maybeNull(types.string),
      deliveryInstruction: types.maybeNull(types.string),
      deliveryToDateTime: types.maybeNull(types.string),
      location: Location,
    })
  ),
  modifiedDateTime: types.maybeNull(types.string),
  overDimensionalFlag: types.maybeNull(types.boolean),
  pickupDetails: types.maybeNull(
    types.model('PickupDetails', {
      contact: Contact,
      location: Location,
      pickupFromDateTime: types.maybeNull(types.string),
      pickUpInstructions: types.maybeNull(types.string),
      pickupToDateTime: types.maybeNull(types.string),
    })
  ),
  serviceCode: types.maybeNull(types.string),
  serviceID: types.maybeNull(types.string),
  serviceName: types.maybeNull(types.string),
  shipmentNumber: types.maybeNull(types.string),
  urgentFlag: types.maybeNull(types.boolean),
});

export { Booking };
