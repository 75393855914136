import { useEffect } from 'react';

import { useBookingState } from '../../booking-context';
import { useRootStore } from '../../store-provider/lib';

const useSelectBooking = () => {
  const { bookingData } = useBookingState();

  const {
    BookingStore: { selectBooking, deselectBooking },
  } = useRootStore();

  const { bookingID } = bookingData;

  // Select a booking on page load, and deselect when we navigate away.
  useEffect(() => {
    selectBooking(bookingID);

    return deselectBooking;
  }, [bookingID, deselectBooking, selectBooking]);
};

export { useSelectBooking };
