import PropTypes from 'prop-types';

import { DetailsList, Flex, Switch } from '../../ui-library';

const { DetailsListItem } = DetailsList;

const TogglePermission = ({ isActive, title, onClick }) => (
  <DetailsListItem title={title}>
    <Flex justifyContent="flex-end">
      <Switch isActive={isActive} onClick={onClick} />
    </Flex>
  </DetailsListItem>
);

TogglePermission.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export { TogglePermission };
