import { createGlobalStyle, css } from 'styled-components';

import { mobileOnly } from '../../utils/responsive-styles';

const DisableScroll = createGlobalStyle`
${({ isOpen = false }) =>
  isOpen &&
  css`
    html,
    body {
      ${mobileOnly} {
        overflow: hidden;
        height: 100%;
        width: 100%;
        position: fixed;
      }
    }
  `}
`;

export { DisableScroll };
