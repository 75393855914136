import styled, { css, keyframes } from 'styled-components';

import { Box } from '../ui-library';

const load = keyframes`
  0%, 100% {
    background-position: right;
  }

  50% {
    background-position: left;
  }
`;

const SkeletonRow = styled(Box)`
  background-color: ${({ theme, isHeader }) => (isHeader ? theme.colors.greyThree : theme.colors.greyTwo)};
  border-radius: 4px;

  ${({ isHeader = false }) =>
    isHeader &&
    css`
      background: linear-gradient(
        to right,
        ${({ theme }) => `${theme.colors.greyThree}, ${theme.colors.greyTwo}, ${theme.colors.greyThree}`}
      );
      background-size: 200%;
      background-position: left;
      animation: ${load} 1.5s ease-out 0s infinite normal;
    `}
`;

export { SkeletonRow };
