import { isArray } from 'lodash';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import { constants, LoadMoreButton, ShowOn, Table } from '../ui-library';
import { useRootStore } from '../store-provider/lib';
import { UserRow } from './user-row';

const { TableRow, TableBody, TableCell, TableLegend, TableLoading, NoResults } = Table;

const UserListing = () => {
  const { UserStore } = useRootStore();

  const { totalResults, isFetching, limit, loadMore, users = [] } = UserStore;

  const showUserData = users && users.length && isArray(users);

  return (
    <Wrapper>
      <ShowOn screenSize="desktop">
        <TableLegend compact="true">
          <TableCell>Name</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>Organisation</TableCell>
          <TableCell>Contracts</TableCell>
        </TableLegend>
      </ShowOn>
      <TableBody>
        {showUserData ? users.map((item) => <UserRow user={item} key={item.ContactID} />) : null}
        {isFetching && <TableLoading compact="true" cols={4} rows={limit} />}
        {!showUserData && !isFetching && <NoResults>No users found</NoResults>}
      </TableBody>
      <LoadMoreButton totalResults={totalResults} count={users.length} onSubmit={loadMore}>
        Load more users
      </LoadMoreButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${TableRow} {
    min-height: 52px;
    padding: 0.5rem 1.5rem;

    @media (min-width: ${constants.breakpoints.md}) {
      grid-template-columns: 1fr 1fr 1fr 20%;
    }
  }
`;

const ObservedUserListing = observer(UserListing);
export { ObservedUserListing as UserListing };
