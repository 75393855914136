import { types } from 'mobx-state-tree';

import Privilege from '../privilege';

const Contract = types.model('Contract', {
  billToCustomerCode: types.maybeNull(types.string),
  contractId: types.string,
  name: types.string,
  privilege: types.array(Privilege),
});

export { Contract };
