import styled, { css } from 'styled-components';

const AStyles = css`
  font-family: ${({ theme }) => theme.global.baseFontFamily};
  font-size: inherit;
  line-height: inherit;
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  letter-spacing: normal;
  margin: 0.5rem 0;
`;

const A = styled.a`
  ${AStyles}
`;

export { A, AStyles };
