import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { constants, form, H4, icons, P } from '../../ui-library';

const { useFormState } = form;

const { InfoWithBackground } = icons;

const BookingInstructions = ({ label, name }) => {
  const { control } = useFormState();

  return (
    <Controller
      control={control}
      name={name}
      render={({ value }) => {
        if (!value) return null;

        return (
          <Container>
            <InfoWithBackground backgroundColor={constants.colors.interactionSix} />
            <Header>{label}</Header>
            <div />
            <P>{value}</P>
          </Container>
        );
      }}
    />
  );
};

const Container = styled.div`
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.greyThree};
  display: grid;
  grid-gap: 1rem;
  margin-bottom: 2rem;
  margin-left: 4rem;
  padding: 1.5rem;
  grid-template: repeat(2, max-content) / max-content 1fr;
`;

const Header = styled(H4)`
  color: ${({ theme }) => theme.colors.interactionSix};
  margin: 0;
`;

BookingInstructions.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export { BookingInstructions };
