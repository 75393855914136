import * as yup from 'yup';
import { createPortal } from 'react-dom';
import { createRef, forwardRef, useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Col, Container, form, H4, responsiveStyles, Row, TextButton } from '../ui-library';
import { IndividualDefect } from './individual-defect';

const { mobileOnly } = responsiveStyles;

const { Form } = form;

const validationSchema = yup.object().shape({
  faultCategory: yup.string().required('Fault Category is required.'),
  DRR_REPAIRCODE: yup.string().required('Priority is required.'),
  faultDescription: yup
    .string()
    .max(255, 'Description can be no longer than 255 characters.')
    .required('Description is required.'),
});

const DefectForm = forwardRef(({ container, setValid }, ref) => {
  const initialDefect = useRef();

  const [defectRefs, setDefectRefs] = useState([initialDefect]);

  const raiseNewDefect = () => {
    const newRef = createRef(null);

    setDefectRefs([...defectRefs, newRef]);
  };

  useImperativeHandle(ref, () => ({
    getValues: () => defectRefs.map((defect) => defect.current.getValues()),
  }));

  return (
    !!container &&
    createPortal(
      <>
        {defectRefs.map((defect, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Wrapper flex={1} flexDirection="column" key={index} py="1rem" mb="3rem">
            <Header mb="2rem">Defect #{index + 1}</Header>
            <Form mode="onChange" ref={defect} width="100%" validationSchema={validationSchema}>
              <IndividualDefect number={index + 1} setValid={setValid} />
              {index + 1 === defectRefs.length && (
                <StyledRow mb="1rem">
                  <StyledCol>
                    <TextButton
                      alignSelf="center"
                      disabled={defect.current && !defect.current.isValid}
                      variant="secondary"
                      mt="1rem"
                      onClick={raiseNewDefect}
                    >
                      Add Another Defect
                    </TextButton>
                  </StyledCol>
                </StyledRow>
              )}
            </Form>
          </Wrapper>
        ))}
      </>,
      container
    )
  );
});

DefectForm.displayName = 'DefectForm';

DefectForm.propTypes = {
  container: PropTypes.shape(),
  setValid: PropTypes.func,
};

DefectForm.defaultProps = {
  container: null,
  setValid: () => null,
};

const StyledCol = styled(Col).attrs(() => ({ width: 1 }))``;

const StyledRow = styled(Row).attrs(() => ({ width: 1 }))``;

const Header = styled(H4)`
  color: ${({ theme }) => theme.colors.greyFive};
  text-transform: uppercase;
`;

const Wrapper = styled(Container)`
  background-color: ${({ theme }) => theme.colors.greyOne};

  ${mobileOnly} {
    width: auto;
    margin-left: -${({ theme }) => theme.space.sm};
    margin-right: -${({ theme }) => theme.space.sm};
  }
`;

export { DefectForm };
