import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Flex } from '../grid';
import { hideOnMobile } from '../../utils/responsive-styles';
import { NavigationLink } from './navigation-link';
import { navigationRoutesPropType } from '../../prop-types';

const DesktopNavigation = ({ navigationRoutes, getIsCurrentRoute }) => (
  <NavigationContainer data-testid="desktop-navigation">
    {navigationRoutes.map(({ to, label, privilegeName }) => {
      const isActive = getIsCurrentRoute(to);
      return (
        <NavigationLink key={privilegeName} to={to} privilegeName={privilegeName} isActive={isActive}>
          {label}
        </NavigationLink>
      );
    })}
  </NavigationContainer>
);

const NavigationContainer = styled(Flex)`
  ${hideOnMobile}
  flex: 1;
  z-index: 1;
`;

DesktopNavigation.propTypes = {
  getIsCurrentRoute: PropTypes.func.isRequired,
  navigationRoutes: navigationRoutesPropType.isRequired,
};

export { DesktopNavigation };
