import { find, get, groupBy, includes, indexOf, map, partition, reject, replace, values } from 'lodash';
import { format, parseISO } from 'date-fns';

const transformCaseDetailValue = (apiData, type, apiField) => {
  const value = get(apiData, apiField);
  if (type === 'Date') {
    return value ? format(parseISO(value), 'd LLL, h:mm aa') : '';
  }

  if (type === 'Checkbox') {
    switch (value) {
      case false:
        return 'No';
      case true:
        return 'Yes';
      default:
        // Depending on the API, this value may already be a string.
        // TODO: transforn this in the lambda.
        return value;
    }
  }

  return value;
};

const LINKED_API_FIELDS_MAP = {
  contractID: 'contractName',
  caseType: 'caseTypeName',
  casePriority: 'casePriorityName',
  freightClass: 'freightClassName',
  deliveryState: 'deliveryStateName',
  primaryContact: 'primaryContactName',
  secondaryContact: 'secondaryContactName',
};

const FIELD_IDS_IN_GROUPS = [
  // ['correctiveActions', 'resolutionDateTime'],
  ['contractName', 'caseNumber', 'createdOn'], // createdOn is not available in the mapping or fields response
  ['caseType', 'caseTitle', 'description', 'casePriority'], // "Reason" shown in design not available.
  ['orderNumber', 'deliveryDate', 'site', 'keyContact', 'secondaryContact'], // deliveryDate is not available in the mapping or fields response.
];

const getGroupIndex = ({ id }) =>
  indexOf(
    FIELD_IDS_IN_GROUPS,
    find(FIELD_IDS_IN_GROUPS, (group) => includes(group, id))
  );

const groupFields = (fields) => values(groupBy(fields, (field) => getGroupIndex(field)));

const transformCaseDetails = (data) => {
  const mappedFields = map(get(data, 'fields'), ({ apiField, type, ...rest }) => {
    const apiFieldKey = replace(apiField, 'caseDetails.', '');
    const linkedApiField = LINKED_API_FIELDS_MAP[apiFieldKey] || apiFieldKey;
    return {
      apiField: linkedApiField,
      type,
      ...rest,
      value: transformCaseDetailValue(get(data, 'caseDetails'), type, linkedApiField),
    };
  });

  const fields = mappedFields.filter((field) => {
    // always return `secondaryContact`, regardless of visibility
    if (field.id === 'secondaryContact') {
      return true;
    }

    if (field.visible === 'False') return false;

    return true;
  });

  const resolutionFields = ['correctiveActions', 'resolutionDateTime'];
  const [statusFields, otherFields] = partition(fields, ({ id }) => includes(resolutionFields, id));
  const fieldsWithoutStatus = reject(otherFields, ({ id }) => id === 'status');
  const [additionalReferencesFields, moreOtherFields] = partition(fieldsWithoutStatus, ({ id }) =>
    includes(id, 'additionalReference')
  );
  const [skuDetailsFields, yetMoreOtherFields] = partition(moreOtherFields, ({ apiField }) =>
    includes(apiField, 'skuDetails[0]')
  );

  return {
    fields: data.fields,
    statusFields,
    additionalReferencesFields,
    skuDetailsFields,
    groupedFields: groupFields(yetMoreOtherFields),
  };
};

export { transformCaseDetails };
