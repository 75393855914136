import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Flex } from '../grid';
import { Link } from '../link';

const MobileCard = ({ cardId, status, backgroundColor, children, to }) => (
  <Card as={Link} to={to}>
    <StatusBar backgroundColor={backgroundColor}>
      <span>{cardId}</span>
      {status && <span>{status}</span>}
    </StatusBar>
    {children}
  </Card>
);

const Card = styled.div`
  display: block;
  text-decoration: none;
  background: white;
  border-radius: 6px;
  margin-bottom: ${({ theme }) => theme.space.xs};
  box-shadow: 12px 8px 24px rgba(217, 217, 217, 0.5), -12px 8px 24px rgba(217, 217, 217, 0.5);
  margin-left: -${({ theme }) => theme.space.xxs};
  margin-right: -${({ theme }) => theme.space.xxs};
  overflow: hidden;
`;

const StatusBar = styled(Flex)`
  border-radius: 6px 6px 0 0;
  line-height: ${({ theme }) => theme.space.lg};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: 11px;
  text-transform: uppercase;
  justify-content: space-between;
  padding: 0 ${({ theme }) => theme.space.xs};
  background-color: ${({ backgroundColor, theme }) => backgroundColor || theme.colors.greyNine};
  color: ${({ backgroundColor, theme }) => (backgroundColor ? theme.colors.interactionNine : theme.colors.white)};
`;

MobileCard.propTypes = {
  children: PropTypes.node.isRequired,
  cardId: PropTypes.string,
  status: PropTypes.string,
  backgroundColor: PropTypes.string,
  to: PropTypes.string.isRequired,
};

MobileCard.defaultProps = {
  cardId: null,
  status: null,
  backgroundColor: null,
};

export { MobileCard };
