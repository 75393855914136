import { observer } from 'mobx-react';
import styled from 'styled-components';

import { BookingRow } from './booking-row';
import { constants, LoadMoreButton, ShowOn, Table } from '../ui-library';
import { useRootStore } from '../store-provider/lib';

const { TableBody, TableRow, TableLegend, TableCell, TableLoading, NoResults } = Table;

const BookingListing = () => {
  const { BookingStore } = useRootStore();

  const { bookings, totalResults, loadMore, isFetching } = BookingStore;

  const showBookingData = bookings.length > 0;

  return (
    <Wrapper>
      <ShowOn screenSize="desktop">
        <TableLegend>
          <TableCell mx="auto">Status</TableCell>
          <TableCell pl="1rem">Booking ID</TableCell>
          <TableCell>Customer Ref #</TableCell>
          <TableCell>Created By</TableCell>
          <TableCell>Pick Up</TableCell>
          <TableCell>Destination</TableCell>
        </TableLegend>
      </ShowOn>
      <TableBody>
        {showBookingData ? bookings.map((item) => <BookingRow data={item} key={item.bookingID} />) : null}
        {!showBookingData && !isFetching && <NoResults>No bookings found</NoResults>}
        {isFetching && <TableLoading cols={6} />}
      </TableBody>
      <LoadMoreButton totalResults={totalResults} count={bookings.length} isFetching={isFetching} onSubmit={loadMore}>
        Load more bookings
      </LoadMoreButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${TableRow} {
    @media (min-width: ${constants.breakpoints.md}) {
      grid-template-columns: 1.25fr 1.25fr 1.25fr 1.75fr repeat(2, 2.5fr);
    }
  }
`;

const ObservedBookingListing = observer(BookingListing);
export { ObservedBookingListing as BookingListing };
