import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colors } from '../../../constants/colors';

const variantOptions = {
  primary: colors.greyNine,
  secondary: colors.greyNine,
  tertiary: colors.interactionSix,
  secondaryBookings: colors.greyNine,
  tertiaryBookings: colors.greyNine,
  destructive: colors.white,
  iconOnly: colors.interactionSix,
};

const LoadingIndicator = ({ size, borderWidth, color, variant, ...rest }) => {
  const colorVariant = variant ? variantOptions[variant] : color;

  return (
    <StyledLoadingIndicator
      data-testid="loader"
      size={size}
      borderWidth={borderWidth}
      color={colorVariant}
      variant={variant}
      {...rest}
    />
  );
};

const StyledLoadingIndicator = styled.div`
  animation: 500ms linear 0s infinite normal none running rotate;
  border: solid ${({ borderWidth }) => borderWidth};
  border-radius: 100px;
  border-color: ${({ color }) => `${color} ${color}`} transparent transparent;
  height: ${({ size }) => size};
  width: ${({ size }) => size};

  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }
`;

LoadingIndicator.propTypes = {
  borderWidth: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'tertiary',
    'secondaryBookings',
    'tertiaryBookings',
    'destructive',
  ]),
};

LoadingIndicator.defaultProps = {
  borderWidth: '2px',
  color: colors.black,
  size: '1rem',
  variant: null,
};

export { LoadingIndicator };
