import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BookingFormRow } from '../../booking-form-row';
import { Box, Flex, icons } from '../../../ui-library';
import { DottedDivider } from '../../dotted-divider';
import { DynamicFormInput } from '../../../dynamic-form-input';
import { IconWrapper } from '../icon-wrapper';
import { LabelledInput } from '../../../labelled-input';

const { LocationMarker } = icons;

const CustomAddress = ({ display, locationKey }) => (
  <AddressFields visible={display}>
    <BookingFormRow
      isInputRow
      icon={
        <IconWrapper>
          <LocationMarker />
        </IconWrapper>
      }
      iconHeight="20px"
    >
      <LocationPickerRow>
        <DynamicFormInput name={`${locationKey}LocationName`} render={(props) => <LabelledInput {...props} />} />
      </LocationPickerRow>
    </BookingFormRow>
    <BookingFormRow>
      <Flex>
        <DynamicFormInput
          name={`${locationKey}Address1`}
          render={(props) => (
            <Flex flexDirection="column" flex={1} mr={3}>
              <LabelledInput {...props} />
            </Flex>
          )}
        />
        <DynamicFormInput
          name={`${locationKey}Address2`}
          render={(props) => (
            <Flex flexDirection="column" flex={1} mr={3}>
              <LabelledInput {...props} />
            </Flex>
          )}
        />
      </Flex>
    </BookingFormRow>
    <BookingFormRow>
      <Flex>
        <DynamicFormInput
          name={`${locationKey}Suburb`}
          render={(props) => (
            <Box width={275} mr={3}>
              <LabelledInput {...props} />
            </Box>
          )}
        />
        <DynamicFormInput
          name={`${locationKey}State`}
          render={(props) => (
            <Box width={200} mr={3}>
              <LabelledInput {...props} />
            </Box>
          )}
        />
        <DynamicFormInput
          name={`${locationKey}PostCode`}
          render={(props) => (
            <Box width={200}>
              <LabelledInput {...props} />
            </Box>
          )}
        />
        <DynamicFormInput name={`${locationKey}Country`} />
        <DynamicFormInput name={`${locationKey}LocationType`} />
        <DynamicFormInput name={`${locationKey}LocationCode`} />
      </Flex>
    </BookingFormRow>
    <BookingFormRow>
      <Flex>
        <DynamicFormInput
          name={`${locationKey}Latitude`}
          render={(props) => (
            <Box width={130} mr={3}>
              <LabelledInput {...props} />
            </Box>
          )}
        />
        <DynamicFormInput
          name={`${locationKey}Longitude`}
          render={(props) => (
            <Box width={130} mr={3}>
              <LabelledInput {...props} />
            </Box>
          )}
        />
      </Flex>
      <DottedDivider mt={4} />
    </BookingFormRow>
  </AddressFields>
);

const AddressFields = styled.div`
  display: ${({ visible }) => (visible ? 'grid' : 'none')};
`;

const LocationPickerRow = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-column-gap: 3rem;
`;

CustomAddress.propTypes = {
  display: PropTypes.bool.isRequired,
  locationKey: PropTypes.oneOf(['pickUp', 'delivery']).isRequired,
};

export { CustomAddress };
