import styled from 'styled-components';

import { AddressInformation } from './address-information';
import { BookingDetails } from './booking-details';
import { Box, icons } from '../ui-library';
import { CargoListing } from './cargo-listing';
import { DIVIDER_HEIGHT, SectionFormWrapper } from './section-form-wrapper';
import { RailTransport } from './rail-transport';
import { Schedule } from './schedule';
import { SpecialEquipment } from './special-equipment';
import { SupportingDocuments } from './supporting-documents';
import { useFormScroll } from '../../hooks/use-form-scroll';
import { useSelectBooking } from './use-select-booking';

const { Clipboard, PickUp, Paperclip, Toolbox, Delivery, Cargo, Clock, Rail } = icons;

const HEADER_OFFSET = 136;

const BookingForm = () => {
  const offset = HEADER_OFFSET + DIVIDER_HEIGHT;

  const [wrapperRef, register] = useFormScroll(offset);

  useSelectBooking();

  return (
    <FormWrapper ref={wrapperRef}>
      <SectionFormWrapper id="bookingDetails" title="Booking Details" sectionIcon={<Clipboard />} ref={register}>
        <BookingDetails />
      </SectionFormWrapper>

      <SectionFormWrapper id="schedule" title="Schedule" sectionIcon={<Clock />} ref={register}>
        <Schedule />
      </SectionFormWrapper>

      <SectionFormWrapper id="pickUp" title="Pick Up" sectionIcon={<PickUp />} ref={register}>
        <AddressInformation isPickUp newLocationField="newPickUpLocation" />
      </SectionFormWrapper>

      <SectionFormWrapper id="delivery" title="Delivery" sectionIcon={<Delivery />} ref={register}>
        <AddressInformation newLocationField="newDeliveryLocation" />
      </SectionFormWrapper>

      <SectionFormWrapper id="railTransport" title="Rail Transport" sectionIcon={<Rail />} ref={register}>
        <RailTransport />
      </SectionFormWrapper>

      <SectionFormWrapper id="cargo" title="Cargo" sectionIcon={<Cargo />} maxWidth="1280px" ref={register}>
        <CargoListing />
      </SectionFormWrapper>

      <SectionFormWrapper id="specialEquipment" title="Special Equipment" sectionIcon={<Toolbox />} ref={register}>
        <SpecialEquipment />
      </SectionFormWrapper>

      <SectionFormWrapper
        id="supportingDocuments"
        title="Supporting Documents"
        sectionIcon={<Paperclip />}
        ref={register}
        showDivider={false}
      >
        <SupportingDocuments />
      </SectionFormWrapper>

      {/* Hacky solution to allow the bottom section to be highlighted */}
      <ScrollOffset />
    </FormWrapper>
  );
};

const FormWrapper = styled(Box)`
  height: calc(100vh - ${`${HEADER_OFFSET}px`});
  overflow-y: auto;
`;

const ScrollOffset = styled(Box)`
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  height: 60vh;
`;

export { BookingForm };
