import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Alert } from '../alert';
import { ALERT_TYPES } from '../../constants';
import { Box } from '../grid';

const MaintenanceMessage = ({ message }) => {
  const [isVisible, setIsVisible] = useState(!!message);

  if (!isVisible) {
    return null;
  }

  return (
    <Background>
      <Wrapper>
        <Alert type={ALERT_TYPES.GENERAL} onClose={() => setIsVisible(false)}>
          {message}
        </Alert>
      </Wrapper>
    </Background>
  );
};

const Background = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryFour};
`;

const Wrapper = styled(Box)`
  max-width: ${({ theme }) => theme.global.pageMaxWidth};
  margin: 0 auto;
`;

MaintenanceMessage.propTypes = {
  message: PropTypes.string,
};

MaintenanceMessage.defaultProps = {
  message: null,
};

export { MaintenanceMessage };
