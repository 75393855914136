import { forwardRef } from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Chip = forwardRef(({ children, isActive, isSearchButton, icon, onClick, minWidth }, ref) => (
  <StyledChip
    type="button"
    ref={ref}
    isActive={isActive}
    onClick={onClick}
    isSearchButton={isSearchButton}
    minWidth={minWidth}
  >
    {icon && <StyledIcon>{icon}</StyledIcon>}
    {children}
  </StyledChip>
));

const StyledIcon = styled.div`
  margin-right: ${({ theme }) => theme.space.xxs};
  width: 1rem;
  height: 1rem;

  svg {
    width: 1rem;
    height: 1rem;
  }
`;

const StyledChip = styled.button`
  display: flex;
  align-items: center;
  line-height: 2rem;
  border: solid 1px ${({ theme }) => theme.colors.greyThree};
  border-radius: 1rem;
  color: ${({ theme }) => theme.colors.greyEight};
  font-size: ${({ theme }) => theme.fontSize.small};
  padding: 0 ${({ theme }) => theme.space.xs};
  white-space: nowrap;
  min-width: ${({ minWidth }) => minWidth};

  :focus {
    border-color: ${(props) => props.theme.colors.greyFour};
  }

  ${({ isActive, theme }) =>
    isActive &&
    css`
      background-color: ${theme.colors.primaryFour};
      border-color: ${theme.colors.primaryFour};
    `}

  ${({ isSearchButton, theme }) =>
    isSearchButton &&
    css`
      border-color: transparent;
      border-radius: 5px;
      background-color: ${theme.colors.greyTwo};
      color: ${theme.colors.greyFive};
      height: ${theme.space.lg};
      margin: 0;
    `}

  ${StyledIcon} {
    * {
      path {
        fill: ${({ isActive, theme }) => (isActive ? `${theme.colors.greySeven}` : `${theme.colors.greyFour}`)};
      }
    }
  }

  :hover {
    border-color: ${({ theme }) => theme.colors.greyFive};

    ${StyledIcon} {
      * {
        path {
          fill: ${({ theme }) => theme.colors.greySeven};
        }
      }
    }
  }
`;

Chip.propTypes = {
  children: PropTypes.node.isRequired,
  isActive: PropTypes.bool,
  isSearchButton: PropTypes.bool,
  icon: PropTypes.element,
  minWidth: PropTypes.string,
  onClick: PropTypes.func,
};

Chip.defaultProps = {
  icon: null,
  isActive: false,
  isSearchButton: false,
  minWidth: undefined,
  onClick: noop,
};

Chip.displayName = 'Chip';

export { Chip };
