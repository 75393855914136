import { get } from 'lodash';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import { AttachDocumentModal } from './attach-document-modal';
import { BookingFormRow } from '../booking-form-row';
import { ListItem } from '../list-item';
import { P } from '../../ui-library';
import { useBookingState } from '../../booking-context';
import { useSupportingDocuments } from '../../../hooks/use-supporting-documents';

const SupportingDocuments = () => {
  const { bookingData } = useBookingState();
  const { bookingID } = bookingData;

  const { documents, handleAttachmentDownload, handleAttachmentUpload, handleDeleteAttachment } =
    useSupportingDocuments(bookingID);

  const isEmptyState = documents.length === 0;

  return (
    <BookingFormRow>
      <Status mb={4}>
        {isEmptyState
          ? 'Currently there are 0 supporting documents attached to this booking request. '
          : `Documents attached (${documents.length}) `}
      </Status>
      <AttachDocumentModal onSubmit={handleAttachmentUpload} />
      <ItemWrapper>
        {documents.map((item) => {
          const documentType = get(item, 'documentType');
          const name = get(item, 'name');
          const bookingDocumentID = get(item, 'bookingDocumentID');

          return (
            <ListItem
              key={`document-${name}`}
              name={`${documentType} (${name})`}
              actions={[
                {
                  label: 'Download',
                  onClick: () =>
                    handleAttachmentDownload({
                      name,
                      bookingDocumentID,
                    }),
                },
                { label: 'Remove', onClick: () => handleDeleteAttachment(bookingDocumentID) },
              ]}
            />
          );
        })}
      </ItemWrapper>
    </BookingFormRow>
  );
};

const Status = styled(P)`
  font-size: ${({ theme }) => theme.fontSize.small};
`;

const ItemWrapper = styled.div`
  display: grid;
  grid-row-gap: 1rem;
  grid-auto-flow: row;
  margin-top: 1rem;
`;

const ObservedSupportingDocuments = observer(SupportingDocuments);
export { ObservedSupportingDocuments as SupportingDocuments };
