import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box, Flex } from '../grid';
import { colors } from '../../constants';
import { FileUpload } from '../file-upload';
import { Form, SubmitButton, useFormState } from '../form';
import { Input } from '../form/input';
import { Send } from '../icons';

const CommentForm = ({ onSubmit }) => {
  return (
    <StyledForm onSubmit={onSubmit} mode="onChange" defaultValues={{ attachments: [], message: null }}>
      <Box position="relative">
        <StyledInput
          aria-label="comment"
          id="description"
          type="textarea"
          // @TODO: Update to name="comment"
          name="message"
          placeholder="Type your reply here"
          rows="1"
          validation={{ required: 'A comment is required.' }}
        />
        <ButtonsWrapper>
          <SendComponent />
        </ButtonsWrapper>
      </Box>
      <Box mt="xs">
        <FileUpload name="attachments" canUploadMultiple />
      </Box>
    </StyledForm>
  );
};

const SendComponent = () => {
  const { isValid } = useFormState();
  return (
    <SubmitButton aria-label="send comment" variant="iconOnly">
      <Send fill={isValid ? colors.interactionSix : colors.greySix} />
    </SubmitButton>
  );
};

// @TODO: The horizontal margin should be coupled to Comment's horizontal spacing
// To keep them aligned
const StyledForm = styled(Form)`
  margin: 1rem 3rem 1rem 5rem;
`;

// Using `display: flex` to avoid extra height in Chrome
// which breaks the vertical centering of <SendComponent>
const StyledInput = styled(Input)`
  background-color: ${({ theme }) => theme.colors.greyOne};
  display: flex;
  padding: 12px;
`;

const ButtonsWrapper = styled(Flex)`
  position: absolute;
  right: 12px;
  bottom: 12px;
`;

CommentForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export { CommentForm };
