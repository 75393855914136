import { ifNotProp, ifProp } from 'styled-tools';
import styled, { css } from 'styled-components';

/**
 * cursorStyle
 *
 * Sets `cursor: pointer` on element.
 *
 * Sets `cursor: not-allowed` if element is disabled.
 *
 */
const cursorStyle = css`
  cursor: ${ifProp('disabled', 'not-allowed', 'pointer')};
`;

/**
 * focusStyle
 *
 * If `hasFocus` prop is set, adds a box-shadow around the element.
 *
 */
const focusStyle = ifProp(
  'hasFocus',
  css`
    box-shadow: 0 0 0 4px ${({ theme }) => theme.colors.interactionTwo};
  `
);

/**
 * borderStyle
 *
 * Sets a border around the element.
 *
 * Uses `isActive` prop to handle active state.
 *
 * Uses `hasHover` prop to handle hover state.
 *
 * `disabled` elements do not display a hover state.
 *
 */
const borderStyle = css`
  border: 1px solid ${({ theme }) => theme.colors.greyThree};
  border-radius: ${({ theme }) => theme.global.borderRadius};

  ${ifProp(
    'hasFocus',
    css`
      border-color: ${({ theme }) => theme.colors.interactionFour};
    `
  )};

  ${ifProp(
    'hasHover',
    ifNotProp(
      'disabled',
      css`
        border-color: ${({ theme }) => theme.colors.interactionFour};
      `
    )
  )}

  ${ifProp(
    'isActive',
    css`
      border-color: ${({ theme }) => theme.colors.interactionSix};
    `
  )};
`;

const backgroundStyle = css`
  background: ${({ isActive, theme }) => (isActive ? theme.colors.interactionFive : theme.colors.white)};

  ${ifProp(
    'disabled',
    css`
      background: ${({ theme }) => theme.colors.greyTwo}};

      ${ifProp(
        'isActive',
        css`
          background: ${({ theme }) => theme.colors.interactionThree};
        `
      )}
    `
  )};
`;

/**
 * sizeStyle
 *
 * Common padding and height for all inputs
 *
 */
const sizeStyle = css`
  padding: 0 ${({ theme }) => theme.space.xs};
  height: 2.875rem;
  width: 100%;
`;

const StyledInput = styled.input`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;

  ${cursorStyle};
`;

export { backgroundStyle, borderStyle, cursorStyle, focusStyle, sizeStyle, StyledInput };
