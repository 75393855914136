import styled from 'styled-components';

import { Box, constants, Content, H1, H4, P } from '../../components/ui-library';
import { Logo } from '../../components/logo';

const sections = [
  {
    heading: '1. Acceptance',
    body: (
      <>
        {`Linfox Australia Pty Ltd (ABN 47 004 718 647) and/or its related body corporates ('Linfox'), have created this
        Linfox Customer Portal ('LCP') to allow its customers, and vendors of its customers, to electronically submit
        transport booking requests to Linfox.`}
        <br />
        <br />
        {`Your use of the LCP is governed by these Terms of Use ('TOU'). You may only access or use the LCP if you agree
        to these TOU. If you do not agree, do not access or use the LCP. Linfox reserves the right to update these TOU
        at any time without notice to you. The most current version of these TOU can be found at the "Legal" hypertext
        link located at the bottom of the LCP web pages.`}
      </>
    ),
  },
  {
    heading: '2. Existing Agreements',
    body: (
      <>
        In relation to any transport booking requests submitted to Linfox via the LCP, such bookings are made pursuant
        to existing contractual arrangements between Linfox and its customers. The transport services performed by
        Linfox in response to any transport booking request are for the benefit of the relevant Linfox customer,
        performed pursuant to the existing contractual agreement with that Linfox customer.
        <br />
        <br />
        If you are a Linfox customer: these TOU do not govern the provision of transport services to you. They only
        govern your use of the LCP in relation to lodgement of any transport booking requests. For the avoidance of
        doubt, in the event of a conflict between these TOU and the logistics services contract between the parties, the
        logistics services contract will prevail.
        <br />
        <br />
        If you are a vendor of a Linfox customer: any transport booking requests which you lodge are submitted solely in
        your capacity as an authorised agent of the relevant Linfox customer. No contractual arrangements are formed
        between you and Linfox by the submission of any transport booking request.
      </>
    ),
  },
  {
    heading: '3. Account, Password and Security',
    body: (
      <>
        {`Linfox will issue (or may have already issued) you with a user name and corresponding password, which are your
        login credentials to utilise the LCP. You are entirely responsible for maintaining the confidentiality of your
        password, and for any and all activities that occur under your login credentials. You agree to notify Linfox
        immediately of any unauthorized use of your login credentials or any other breach of security. Linfox will not
        be liable for any losses you incur as a result of someone else using your login credentials, either with or
        without your knowledge. However, you (or your employer) may be held liable for losses incurred by Linfox or
        another party due to someone else using your login credentials. You may not use anyone else's login credentials
        at any time, except with that person’s express permission.`}
        <br />
        <br />
        Linfox may, at any time, for any reason, and without notice, cancel your login credentials (username and
        password) and remove your access to the LCP at its sole discretion.
      </>
    ),
  },
  {
    heading: '4. Intellectual Property',
    body: `Linfox retains all right, title and interest in and to the LCP and its content, including all copyrights,
        patents, trade secrets, trademarks and other intellectual property rights. Linfox reserves all rights not
        expressly granted. These TOU do not grant or imply any rights to any Linfox intellectual property.`,
  },
  {
    heading: '5. Changes to LCP',
    body: `Linfox reserves the right, in its sole discretion which may be exercised at any time, to change or modify the LCP, to add or remove functionality of the LCP, or to discontinue providing the LCP entirely. To the extent permitted by law, if you become dissatisfied with any such changes or modifications, your only recourse is to immediately cease using the LCP.`,
  },
  {
    heading: '6. Limitation of Liability',
    body: `To the fullest extent permitted by applicable law, in no event shall Linfox be liable for any direct, indirect, incidental, special, exemplary, consequential damages or damages for loss of business or other profits however caused and on any theory of liability, whether in contract, strict liability, or tort (including negligence or otherwise) arising out of, or in any way related to, your use of, or inability to use, or reliance on, the LCP, even if advised of the possibility of such damage.`,
  },
  {
    heading: '7. Indemnity',
    body: `You agree to indemnify, defend and hold Linfox harmless from and against any and all liability and costs (including reasonable legal fees) incurred in connection with or arising out of your use or misuse of the LCP, and your violation of these TOU, any applicable law or regulation, or the rights of any third parties related to the use of the LCP.`,
  },
  {
    heading: '8. Disclaimer of Warranties',
    body: `The LCP and its content are provided "as is". To the fullest extent permitted by applicable law, Linfox disclaims all warranties of any kind (express, implied or otherwise) regarding the LCP or its content, including but not limited to any implied warranties of merchantability, fitness for a particular purpose, and non-infringement. Linfox gives no warranty about the accuracy, reliability, completeness, timeliness, sufficiency or quality of the site or the content, nor that any particular content or functionality will continue to be made available. Linfox does not warrant that the LCP will operate without error or interruption, or that the webpages or its server is free of computer viruses or other harmful materials.`,
  },
  {
    heading: '9. Your Conduct',
    body: (
      <>
        {`You agree that all information or data of any kind, whether text, software, files, code, music or sound,
        photographs or graphics, video or other materials ('Content'), that is provided by you or your representatives
        and uploaded to the LCP shall be free from any viruses. Content which is objectionable may not be submitted to
        the LCP. Linfox will determine what amounts to "objectionable" Content, but it includes: sexually explicit
        materials; obscene, defamatory, violent or unlawful Content or profanity; Content that infringes on the rights
        of a third party (including intellectual property rights); Content that is deceptive or fraudulent; Content that
        promotes the use or sale of illegal or regulated substances, tobacco products, ammunition and/or firearms; and
        Content associated with gambling, including without limitation, any online casino, sports books, bingo or poker.`}
        <br />
        <br />
        {`You agree that you shall not, at any time, make any comments about Linfox or the LCP that could reasonably be
        expected to bring Linfox's name into disrepute or cause commercial harm to Linfox.`}
      </>
    ),
  },
  {
    heading: '11. Confidentiality',
    body: `All content of the LCP is proprietary information of Linfox and must not be modified, reproduced or disclosed to any third party without Linfox's prior written consent (which may be withheld at Linfox's sole discretion or given with conditions).`,
  },
  {
    heading: '12. Consent to Receive Emails',
    body: `Any personal information collected from you via the LCP will be held and used in accordance with Linfox's privacy policy.`,
  },
  {
    heading: '13. Miscellaneous',
    body: (
      <>
        <b>13.1</b> These TOU are governed by the laws of the State of Victoria, Australia.
        <br />
        <br />
        <b>13.2</b> In the event that these TOU conflict with any law under which any provision may be held invalid by a
        court with jurisdiction over the parties, such provision will be interpreted to reflect the original intentions
        of the parties in accordance with applicable law, and the remainder of these TOU will remain valid and intact.
        <br />
        <br />
        <b>13.3</b>{' '}
        {`The failure of either party to assert any right under this TOU shall not be considered a waiver of
        any that party's right and that right will remain in full force and effect.`}
        <br />
        <br />
        <b>13.4</b> You agree that any claim or cause arising out of the use of this LCP must be filed within one (1)
        year after such claim or cause arose, or the claim shall be forever barred.
        <br />
        <br />
        <b>13.5</b> Linfox may freely assign its rights and obligations under these TOU.
        <br />
        <br />
        <b>13.6</b> if any of these TOU is held to be unenforceable, the remaining TOU will remain unaffected and
        enforceable as though the unenforceable TOU had not been included.
        <br />
        <br />
        <b>13.7</b> Should you have any questions concerning these TOU, or if you desire to contact Linfox for any
        reason, please write to: Linfox Australia Pty Ltd, Legal Team, 55 English Street, Essendon Fields, Victoria,
        3014.
      </>
    ),
  },
];

const TermsAndConditions = () => (
  <Box
    backgroundColor="greyOne"
    height="100%"
    position="fixed"
    top="0"
    bottom="0"
    left="0"
    right="0"
    zIndex={10}
    overflowY="auto"
    py={3}
  >
    <Content maxWidth={constants.globalStyles.detailsMaxWidth}>
      <ImageWrapper position="relative">
        <Logo />
      </ImageWrapper>
      <H1 color="greyNine">Terms of Use</H1>
      <Box color="greyFour" mt={1} mb={3}>
        Linfox Customer Portal (LCP)
      </Box>
      {sections.map(({ heading, body }) => (
        <Box key={heading} mb={5}>
          <H4 as="h2" mb={2}>
            {heading}
          </H4>
          <P lineHeight="175%">{body}</P>
        </Box>
      ))}
    </Content>
  </Box>
);

const ImageWrapper = styled(Box)`
  img {
    position: absolute;
    left: -120px;
    width: 72px;
    top: 1rem;
  }
`;

export { TermsAndConditions };
