import { addDays, addHours, isSameDay, startOfDay, subHours } from 'date-fns';
import { useMemo } from 'react';

import { createTimeArray } from './create-time-array';
import { form } from '../../../ui-library';
import { useTemplateState } from '../../../template-context';

const { useFormState } = form;

// Fetch a `daysOffset` value if used in the validation schema.
const getOffset = (field) => {
  const { validations } = field;

  if (!validations) return 0;

  const compare = validations.filter(({ type }) => type === 'Compare');

  const offset = compare.find(({ daysOffset }) => !!daysOffset);

  if (!offset) return 0;

  return parseInt(offset.daysOffset || 0, 10);
};

// Returns {disabledFrom, disabledTo} arrays of dates, which should be disabled based on
// current date and the selected date (eg `to` cannot allow dates earlier than `from`)
// Returns {fromTimes, toTimes} arrays of times, which will limit the times available to select.
const useClampDateTimes = (from, to, earliestDate) => {
  const { apiFields, normalisedFields } = useTemplateState();

  const { watch } = useFormState();

  const fromDateTime = watch(apiFields[from]);
  const toDateTime = watch(apiFields[to]);

  const fromOffset = useMemo(() => getOffset(normalisedFields[from]), [normalisedFields, from]);
  const toOffset = useMemo(() => getOffset(normalisedFields[to]), [normalisedFields, to]);

  // `from` dates cannot be earlier than today, or later than `to`
  const disabledFrom = {
    before: addDays(earliestDate, fromOffset),
    after: toDateTime ? new Date(toDateTime) : undefined,
  };

  // `to` dates cannot be earlier than `from` (if set)
  const disabledTo = {
    before: (fromDateTime && new Date(fromDateTime)) || addDays(earliestDate, toOffset || fromOffset),
  };

  const fromTimes = useMemo(() => {
    if (!fromDateTime) return [];

    const fromDate = new Date(fromDateTime);
    const toDate = toDateTime ? new Date(toDateTime) : null;

    let endDate = toDate;

    // If `from` and `to` occur on the same day, force the user to select a time an hour before `to`.
    if (toDate && isSameDay(toDate, fromDate)) {
      endDate = subHours(toDate, 1);
    }

    return createTimeArray(60, startOfDay(fromDate), endDate);
  }, [fromDateTime, toDateTime]);

  const toTimes = useMemo(() => {
    if (!toDateTime) return [];

    const fromDate = new Date(fromDateTime);
    const toDate = new Date(toDateTime);

    let startDate = startOfDay(toDate);

    // If `from` and `to` occur on the same day, force the user to select a time an hour after `from`.
    if (isSameDay(toDate, fromDate)) {
      startDate = addHours(fromDate, 1);
    }

    return createTimeArray(60, startDate);
  }, [fromDateTime, toDateTime]);

  return { disabledFrom, disabledTo, fromTimes, toTimes };
};

export { useClampDateTimes };
