import PropTypes from 'prop-types';
import styled from 'styled-components';

import { form } from '../ui-library';
import { formatTemplateType } from '../../utils/format-template-type';
import { useTemplateState } from '../template-context';

const { Input } = form;

const AdditionalReferences = ({ render }) => {
  const { fields } = useTemplateState();

  // additional references will be defined as pairs of fields in the template response
  // one field will contain logic to dictate how the `name` of the field will be displayed
  // the second field will declare the type and validation for the `value` of the additional reference

  // Check if we have any fields beginning with `additionalReferences`
  const additionalReferences = fields.filter(({ id }) => id.startsWith('additionalReference'));

  if (!additionalReferences) return null;

  // Each additional reference will have a Name and a Value field
  // So the total count will need to be divided by 2 to account for this duplication.
  const additionalReferenceCount = additionalReferences.length / 2;

  return Array.from({ length: additionalReferenceCount }).map((element, index) => {
    const labelField = additionalReferences.find((field) => field.id === `additionalReferenceName${index}`);
    const inputField = additionalReferences.find((field) => field.id === `additionalReferenceValue${index}`);

    // We need to render two input fields: one will be hidden, and contain the `name` of the additionalReferenceName field as its value
    // The other will be rendered as an editable input field, with the display label taken from the additionalReferenceName field.

    const { options: fieldOptions = [] } = inputField;

    const options = fieldOptions.map(({ text, value }) => ({ label: text, value }));

    return (
      <Wrapper key={labelField.name}>
        <Input type="hidden" name={labelField.apiField} value={labelField.name} />
        {render({
          id: inputField.apiField,
          label: labelField.name,
          name: inputField.apiField,
          type: formatTemplateType(inputField.type),
          options,
        })}
      </Wrapper>
    );
  });
};

const Wrapper = styled.div`
  display: contents;
`;

AdditionalReferences.propTypes = {
  render: PropTypes.func.isRequired,
};

export { AdditionalReferences };
