import 'react-day-picker/lib/style.css';
import DayPicker from 'react-day-picker';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box } from '../grid';
import { mobileOnly } from '../../utils/responsive-styles';
import { Navbar } from './navbar';

const Calendar = ({ disabledDays, numberOfMonths, modifiers, onChange, setDefaultTime, value }) => (
  <Box>
    <StyledDayPicker
      disabledDays={disabledDays}
      numberOfMonths={numberOfMonths}
      selectedDays={value}
      modifiers={modifiers}
      onDayClick={(event, { disabled = false }) => {
        if (disabled) return;

        onChange(setDefaultTime(event));
      }}
      navbarElement={<Navbar />}
    />
  </Box>
);

const StyledDayPicker = styled(DayPicker)`
  .DayPicker-wrapper {
    padding-bottom: 0;
  }

  .DayPicker-Months {
    margin-top: ${({ theme }) => theme.space.xs};
  }

  .DayPicker-Month {
    margin: 0;

    & :not(:first-child) {
      margin-left: ${({ theme }) => theme.space.md};

      ${mobileOnly} {
        margin-left: 0;
        margin-bottom: ${({ theme }) => theme.space.md};
      }
    }
  }
  .DayPicker-Day {
    width: 36px;
    height: 36px;
    border-radius: 0 !important;
    color: ${({ theme }) => theme.colors.greyEight};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    font-size: ${({ theme }) => theme.fontSize.xSmall};
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--outside) {
    background-color: ${({ theme }) => theme.colors.interactionOne} !important;
    color: ${({ theme }) => theme.colors.interactionSix} !important;
  }

  .DayPicker-Day--start:not(.DayPicker-Day--outside),
  .DayPicker-Day--end:not(.DayPicker-Day--outside),
  .DayPicker-Day--day:not(.DayPicker-Day--outside) {
    background-color: ${({ theme }) => theme.colors.interactionFive} !important;
    color: ${({ theme }) => theme.colors.white} !important;
  }

  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    border: solid 1px ${({ theme }) => theme.colors.greyThree};
  }

  .DayPicker-Weekday {
    font-size: ${({ theme }) => theme.fontSize.xSmall};
    color: ${({ theme }) => theme.colors.greyFive};
  }

  .DayPicker-Caption {
    margin-bottom: ${({ theme }) => theme.space.xs};
  }
  .DayPicker-Caption > div {
    text-align: center;
    font-size: ${({ theme }) => theme.fontSize.base};
    color: ${({ theme }) => theme.colors.greyEight};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }
`;

Calendar.propTypes = {
  disabledDays: PropTypes.shape(),
  numberOfMonths: PropTypes.number,
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.shape({ before: PropTypes.instanceOf(Date), after: PropTypes.instanceOf(Date) }),
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  modifiers: PropTypes.shape(),
  setDefaultTime: PropTypes.func,
};

Calendar.defaultProps = {
  disabledDays: null,
  numberOfMonths: 2,
  modifiers: null,
  setDefaultTime: (x) => x,
};

export { Calendar };
