import { find, get } from 'lodash';
import { useState } from 'react';

import { constants } from '../ui-library';
import { deliveryStatuses } from '../../constants/statuses';
import { Map } from '../bing-map/map';
import { MapContextProvider } from '../bing-map/map-context';
import { ShipmentMilestone } from './shipment-milestone';
import { shipmentPropType } from '../../prop-types/shipment';
import { useFeatureFlag } from '../../hooks/use-feature-flag';
import { useHasPrivilege } from '../../hooks/use-has-privilege';
import { Wrapper } from './styles';

const { contractPrivileges } = constants;

const findMilestone = (milestoneKey, shipmentDetail) => {
  const { milestones = [] } = shipmentDetail;

  // handle cases where a milestone may be set to null.
  const availableMiletones = milestones.filter(Boolean);

  return find(availableMiletones, ({ status }) => status === milestoneKey);
};

const SHIPMENT_STATUS_INDEXES = {
  CREATED: 0,
  PROCESSING: 1,
  IN_TRANSIT: 2,
  LAST_MILE: 2,
  DELIVERED: 3,
};

/*
  Figuring out which milestones to show as "completed" is tricky.
  "shipmentStatus" can't be relied on, as it is absent for anonymous users, and changes to "cancelled" when a milestone is cancelled.
  "milestones" can't be relied on, as we've been told they may not always be present.
  The most reliable way is to infer "current" milestone from which fields are present where possible.

  We store this as a number, so we can easily compare whether a milestone is before or after the "current" one.
*/
const getCurrentMilestoneIndex = (shipmentDetail) => {
  if (shipmentDetail.actualDeliveryDateTime) {
    return SHIPMENT_STATUS_INDEXES.DELIVERED;
  }

  if (shipmentDetail.actualLastMile) {
    return SHIPMENT_STATUS_INDEXES.LAST_MILE;
  }

  if (shipmentDetail.actualPickupDateTime) {
    return SHIPMENT_STATUS_INDEXES.IN_TRANSIT;
  }

  if (
    shipmentDetail.shipmentStatus === deliveryStatuses.processing.key ||
    findMilestone(deliveryStatuses.processing.key, shipmentDetail)
  ) {
    return SHIPMENT_STATUS_INDEXES.PROCESSING;
  }

  return SHIPMENT_STATUS_INDEXES.CREATED;
};

const ShipmentMilestones = ({ shipmentDetail }) => {
  const [showMap, setShowMap] = useState(false);
  const [eta, setETA] = useState();

  const enableLastMile = useFeatureFlag('LAST_MILE');

  const shipmentIsInLastMile =
    enableLastMile &&
    (shipmentDetail.shipmentStatus === deliveryStatuses.lastMile.key ||
      findMilestone(deliveryStatuses.lastMile.key, shipmentDetail));

  const setTopGap = (statusInTransit) => {
    if (statusInTransit) {
      return '200px';
    }

    return '130px';
  };
  const setGap = setTopGap(shipmentIsInLastMile);

  const shipmentIsCancelled =
    shipmentDetail.shipmentStatus === deliveryStatuses.cancelled.key ||
    findMilestone(deliveryStatuses.cancelled.key, shipmentDetail);

  const hasAccessToTrackTrace = useHasPrivilege(contractPrivileges.track);

  const currentMilestoneIndex = getCurrentMilestoneIndex(shipmentDetail);

  const createdProps = {
    title: deliveryStatuses.created.label,
    actualDate: get(findMilestone(deliveryStatuses.created.key, shipmentDetail), 'eventDateTime'),
    index: 0,
    currentMilestoneIndex,
    isCancelled: shipmentIsCancelled,
  };

  const processingProps = {
    title: deliveryStatuses.processing.label,
    actualDate: get(findMilestone(deliveryStatuses.processing.key, shipmentDetail), 'eventDateTime'),
    index: 1,
    currentMilestoneIndex,
    isCancelled: shipmentIsCancelled,
  };

  const inTransitProps = {
    title: deliveryStatuses.inTransit.label,
    actualDate: shipmentDetail.actualPickupDateTime,
    estimatedStartDate: shipmentDetail.plannedPickupDateTimeFrom,
    estimatedEndDate: shipmentDetail.plannedPickupDateTimeTo,
    index: 2,
    currentMilestoneIndex,
    isCancelled: shipmentIsCancelled,
    isInTransit: true,
  };

  const lastMileProps = {
    title: deliveryStatuses.lastMile.label,
    actualDate: shipmentDetail.updatedLastTime,
    estimatedEndDate: shipmentDetail.eta,
    index: 2,
    currentMilestoneIndex,
    isCancelled: shipmentIsCancelled,
    isLastMile: true,
  };

  const shipmentIsDelivered = currentMilestoneIndex === SHIPMENT_STATUS_INDEXES.DELIVERED;

  const deliveredProps = {
    title: deliveryStatuses.delivered.label,
    actualDate: shipmentDetail.actualDeliveryDateTime,
    estimatedStartDate: shipmentDetail.plannedDeliveryDateTimeFrom,
    estimatedEndDate: shipmentDetail.plannedDeliveryDateTimeTo,
    index: 3,
    currentMilestoneIndex,
    isCancelled: shipmentIsCancelled,
    podID: shipmentIsDelivered && hasAccessToTrackTrace ? shipmentDetail.shipmentId : null,
  };

  const cancelledProps = {
    title: deliveryStatuses.cancelled.label,
    currentMilestoneIndex,
    index: 3,
    actualDate: get(findMilestone(deliveryStatuses.cancelled.key, shipmentDetail), 'eventDateTime'),
  };

  return (
    <Wrapper minHeight={shipmentIsInLastMile}>
      <MapContextProvider value={{ showMap, setShowMap, eta, setETA }}>
        {!showMap && (
          <>
            <ShipmentMilestone {...createdProps} />
            <ShipmentMilestone {...processingProps} />
            <ShipmentMilestone {...(shipmentIsInLastMile ? lastMileProps : inTransitProps)} />
            <ShipmentMilestone {...(shipmentIsCancelled ? cancelledProps : deliveredProps)} setGap={setGap} />
          </>
        )}
        {enableLastMile && <Map showMap />}
      </MapContextProvider>
    </Wrapper>
  );
};

ShipmentMilestones.propTypes = {
  shipmentDetail: shipmentPropType.isRequired,
};

export { ShipmentMilestones };
