import styled, { css } from 'styled-components';

const SmallStyles = css`
  font-family: ${({ theme }) => theme.global.baseFontFamily};
  font-size: ${({ theme }) => theme.fontSize.base};
  line-height: ${({ theme }) => theme.lineHeight.body.medium};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  margin: 0.5rem 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: ${({ theme }) => theme.fontSize.xSmall};
    line-height: ${({ theme }) => theme.lineHeight.body.small};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    font-size: ${({ theme }) => theme.fontSize.xxSmall};
    line-height: ${({ theme }) => theme.lineHeight.body.xSmall};
  }
`;

const Small = styled.p`
  ${SmallStyles};
`;

export { Small, SmallStyles };
