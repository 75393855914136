import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useCalculateCargoMetrics } from '../../../hooks/use-calculate-cargo-metrics';

const CargoListingsSummary = ({ items }) => {
  const { quantity, weight, volume } = useCalculateCargoMetrics(items);

  if (isEmpty(items)) {
    return null;
  }

  const itemWeightUOM = get(items, '[0].itemWeightUOM');
  const itemVolumeUOM = get(items, '[0].itemVolumeUOM');

  return (
    <SummaryWrapper>
      <div>Quantity: {quantity}</div>
      <div>
        Weight: {weight} {itemWeightUOM}
      </div>
      <div>
        Volume: {Math.round(volume) || 0} {itemVolumeUOM}
      </div>
    </SummaryWrapper>
  );
};

const SummaryWrapper = styled.div`
  display: grid;
  grid-column-gap: 2rem;
  grid-auto-flow: column;
  grid-auto-columns: fit-content(40%);
  color: ${({ theme }) => theme.colors.greySeven};
`;

CargoListingsSummary.propTypes = {
  items: PropTypes.shape({}).isRequired,
};

export { CargoListingsSummary };
