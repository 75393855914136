import { find } from 'lodash';
import { Link } from 'react-router-dom';

import { Box, constants, icons, MobileCard, ShowOn, StatusLabel, Table } from '../../ui-library';
import { deliveryStatuses } from '../../../constants/statuses';
import { formatDateEta } from '../../../utils/format-date';
import { getStatus } from '../../../utils/get-status';
import { PodDownloadLink } from '../../pod-download-link';
import { renderCustomerReference } from '../../../utils/render-customer-reference';
import { shipmentPropType } from '../../../prop-types';

const { TableRow, TableCell } = Table;
const { Download } = icons;
const { colors } = constants;

const ShipmentRow = ({ data }) => {
  const {
    shipmentId,
    customerReference,
    pickupLocation,
    plannedPickupDateTimeFrom,
    plannedPickupDateTimeTo,
    actualPickupDateTime,
    deliveryLocation,
    plannedDeliveryDateTimeFrom,
    plannedDeliveryDateTimeTo,
    actualDeliveryDateTime,
    shipmentStatus,
  } = data;

  const deliveryStatus = find(deliveryStatuses, ({ key }) => key === shipmentStatus) || {};
  const pickupLocationString = pickupLocation ? `${pickupLocation.suburb}, ${pickupLocation.state}` : '';
  const deliveryLocationString = deliveryLocation ? `${deliveryLocation.suburb}, ${deliveryLocation.state}` : '';
  const pickupEta = formatDateEta(plannedPickupDateTimeFrom, plannedPickupDateTimeTo, actualPickupDateTime);
  const deliveryEta = formatDateEta(plannedDeliveryDateTimeFrom, plannedDeliveryDateTimeTo, actualDeliveryDateTime);
  const shipmentIsDelivered = shipmentStatus === 'Delivered';

  return (
    <>
      <ShowOn screenSize="mobile">
        <MobileCard
          as={Link}
          to={`/track/${shipmentId}`}
          cardId={shipmentId}
          backgroundColor={deliveryStatus.backgroundColor}
          status={shipmentStatus}
        >
          <TableRow>
            <TableCell>Reference #</TableCell>
            <TableCell>{renderCustomerReference(customerReference)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Pickup</TableCell>
            <TableCell>
              <div>{pickupLocationString}</div>
              {pickupEta}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Delivery</TableCell>
            <TableCell>
              <div>{deliveryLocationString}</div>
              {deliveryEta}
            </TableCell>
          </TableRow>
        </MobileCard>
      </ShowOn>
      <ShowOn screenSize="desktop" position="relative">
        <TableRow as={Link} to={`/track/${shipmentId}`}>
          <TableCell>{shipmentId}</TableCell>
          <TableCell>{renderCustomerReference(customerReference)}</TableCell>
          <TableCell>
            <Box mb="4px" fontWeight={600}>
              {pickupLocationString}
            </Box>
            {pickupEta}
          </TableCell>
          <TableCell>
            <Box mb="4px" fontWeight={600}>
              {deliveryLocationString}
            </Box>
            {deliveryEta}
          </TableCell>
          <TableCell>
            <StatusLabel type="delivery" variant={getStatus(deliveryStatuses, shipmentStatus).variant}>
              {getStatus(deliveryStatuses, shipmentStatus).label}
            </StatusLabel>
          </TableCell>
          <TableCell>
            {shipmentIsDelivered && (
              <PodDownloadLink podID={shipmentId} variant="list">
                <Download fill={colors.interactionFive} />
              </PodDownloadLink>
            )}
          </TableCell>
        </TableRow>
      </ShowOn>
    </>
  );
};

ShipmentRow.propTypes = {
  data: shipmentPropType.isRequired,
};

export { ShipmentRow };
