// TODO: refactor this
import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { backgroundStyle, borderStyle, cursorStyle, focusStyle, StyledInput } from '../../styles';
import { Bullet } from '../../../../icons';
import { Flex } from '../../../../grid';

const UncontrolledRadioButton = ({ children, id, onClick, selected }) => {
  const [hasFocus, setFocus] = useState(false);
  const [hasHover, setHover] = useState(false);

  return (
    <Flex
      alignItems="center"
      position="relative"
      width="max-content"
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <StyledUncontrolledRadioButton hasFocus={hasFocus} hasHover={hasHover} isActive={selected} onClick={onClick}>
        <StyledInput id={id} type="radio" />
        <Bullet />
      </StyledUncontrolledRadioButton>
      <Label htmlFor={id} onClick={onClick}>
        {children}
      </Label>
    </Flex>
  );
};

const StyledUncontrolledRadioButton = styled(Flex)`
  ${backgroundStyle};
  ${focusStyle};
  ${borderStyle};
  ${cursorStyle};

  align-items: center;
  justify-content: center;
  width: ${({ theme }) => theme.space.sm};
  height: ${({ theme }) => theme.space.sm};
  border-radius: 100%;
  position: relative;

  svg {
    display: ${({ isActive }) => (isActive ? 'block' : 'none')};
    height: 0.75em;
    width: 0.75em;

    path {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`;

const Label = styled.label`
  color: ${({ theme }) => theme.colors.greyEight};
  padding-left: ${({ theme }) => theme.space.xs};

  ${cursorStyle};
`;

UncontrolledRadioButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  id: PropTypes.string.isRequired,
};

UncontrolledRadioButton.defaultProps = {
  selected: false,
};

UncontrolledRadioButton.displayName = 'UncontrolledRadioButton';

export { UncontrolledRadioButton };
