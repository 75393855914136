import { createContext, useContext, useEffect, useMemo, useReducer } from 'react';
import { some } from 'lodash';
import PropTypes from 'prop-types';

import { setQueryString } from '../../utils/set-query-string';
import { useCaseListingTemplateContext } from '../case-listing-template';
import { useHydrateFilterValues } from '../../hooks/use-hydrate-filter-values';

const FilterContext = createContext();

const { Provider } = FilterContext;

const filterReducer = (state, { filters = {} }) =>
  Object.keys(filters).reduce((acc, cur) => {
    if (filters[cur]) return { ...acc, [cur]: filters[cur] };

    return acc;
  }, {});

const FilterProvider = ({ children, defaultFilters, transformQueryParameters }) => {
  const { columns } = useCaseListingTemplateContext();

  const defaultValues = Object.keys(defaultFilters).reduce((acc, cur) => {
    // Determine the default value for the filter.
    // This may be dictated by the JSON template, the UI code.
    const templateDefaultValue = columns.find(({ apiField }) => apiField === cur)?.default;

    const defaultValue = templateDefaultValue || defaultFilters[cur];

    if (defaultValue) return { ...acc, [cur]: defaultValue };

    return acc;
  }, {});

  // Update filters to use query parameters.
  const requestFilters = useHydrateFilterValues(defaultValues);

  const [filters, dispatch] = useReducer(filterReducer, requestFilters);

  const setFilters = (newFilters) =>
    dispatch(new Proxy({ filters: { ...filters, ...newFilters } }, transformQueryParameters));

  const clearFilters = () => dispatch({ filters: defaultValues });

  const parameters = new URLSearchParams(filters);

  const isFiltersActive = some(Object.keys(filters), (key) => filters[key] !== defaultValues[key]);

  // Update the query string each time this component is rendered.
  useEffect(() => {
    setQueryString(filters);
  });

  // normalise the columns object
  const normalisedColumns = useMemo(
    () =>
      columns.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.id]: cur,
        }),
        {}
      ),
    [columns]
  );

  return (
    <Provider
      value={{ filters, clearFilters, defaultValues, isFiltersActive, normalisedColumns, parameters, setFilters }}
    >
      {children}
    </Provider>
  );
};

const useFilterContext = () => {
  const context = useContext(FilterContext);

  if (context === undefined) {
    throw new Error('Component must be used within a FilterProvider.');
  }

  return context || {};
};

FilterProvider.propTypes = {
  children: PropTypes.node.isRequired,
  defaultFilters: PropTypes.shape(),
  transformQueryParameters: PropTypes.shape(),
};

FilterProvider.defaultProps = {
  defaultFilters: {},
  transformQueryParameters: {},
};

export { FilterProvider, useFilterContext };
