import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { FilterPanelLayout } from './filter-panel-layout';
import { Form, ResetButton, SubmitButton } from '../form';
import { Input } from '../form/input';
import { InputGroup } from '../form/input-group';

const FilterTypeRadio = ({ defaultValues, options, name, resetKey, onSubmit, onCancel, onUpdateLabel }) => {
  const getOptionLabel = (key) => {
    if (!key) return '';

    const { label = '' } = options.find((opt) => opt.key === key) || {};

    return label;
  };

  const handleOnSubmit = (values) => {
    const key = values[name];
    const label = getOptionLabel(key);

    onSubmit(values);
    onUpdateLabel(label);
  };

  const handleOnReset = () => {
    const resetObj = { [name]: resetKey };
    // @TODO: test to see if this resetKey is working
    handleOnSubmit(resetObj);
  };

  const mount = () => {
    const label = getOptionLabel(defaultValues[name]);

    onUpdateLabel(label);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(mount, []);

  return (
    <Form name={name} onSubmit={handleOnSubmit} onReset={handleOnReset} defaultValues={defaultValues}>
      <FilterPanelLayout
        onCancel={onCancel}
        body={
          <InputGroup name={name} flexDirection="column">
            {options.map((option, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Input key={index} name={name} type="radio" value={option.key} id={option.key}>
                {option.label}
              </Input>
            ))}
          </InputGroup>
        }
        footer={
          <>
            <ResetButton variant="tertiary">Clear</ResetButton>
            <SubmitButton>Apply</SubmitButton>
          </>
        }
      />
    </Form>
  );
};

FilterTypeRadio.propTypes = {
  defaultValues: PropTypes.shape({}),
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  resetKey: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onUpdateLabel: PropTypes.func,
};

FilterTypeRadio.defaultProps = {
  defaultValues: {},
  options: [],
  resetKey: 'all',
  onSubmit: () => null,
  onCancel: () => null,
  onUpdateLabel: () => null,
};

export { FilterTypeRadio };
