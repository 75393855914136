import PropTypes from 'prop-types';

import { CaseTemplateOptionForm } from './case-template-option-form';
import { form, Modal, ModalFooterButtonWrapper } from '../ui-library';

const { Form, SubmitButton } = form;

const CaseCreateModal = ({ contracts, isOpen, closeModal, handleSubmit }) => {
  const handleOnSubmit = async (contract) => {
    closeModal();
    handleSubmit({ contract });
  };

  return (
    <Form mode="onSubmit" onSubmit={handleOnSubmit}>
      <Modal
        isOpen={isOpen}
        closeModal={closeModal}
        title="Create Case"
        footer={
          <ModalFooterButtonWrapper closeModal={closeModal}>
            <SubmitButton>Continue</SubmitButton>
          </ModalFooterButtonWrapper>
        }
      >
        <CaseTemplateOptionForm contracts={contracts} />
      </Modal>
    </Form>
  );
};

CaseCreateModal.propTypes = {
  contracts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

CaseCreateModal.defaultProps = {
  isOpen: false,
};

export { CaseCreateModal };
