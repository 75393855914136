import queryString from 'query-string';

import { ErrorMessage } from '../error-message';

const ServerError = () => {
  const { errorMessage } = queryString.parse(window.location.search);

  return <ErrorMessage>Error Occurred - {errorMessage}</ErrorMessage>;
};

export { ServerError };
