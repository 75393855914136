import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Flex } from '../grid';

const ProgressSteps = ({ labels, activeStepIndex }) => (
  <Wrapper justifyContent="space-between" textAlign="center">
    {labels.map((label, index) => {
      const isActive = activeStepIndex >= index;
      return (
        <Step key={label} isActive={isActive}>
          <span>{label}</span>
        </Step>
      );
    })}
  </Wrapper>
);

const Wrapper = styled(Flex)`
  margin-top: -${({ theme }) => theme.space.md};
  margin-bottom: ${({ theme }) => theme.space.md};
`;

const Step = styled.div`
  color: ${({ theme }) => theme.colors.greyFour};
  font-size: 11px;
  line-height: 32px;
  position: relative;
  width: 32%;

  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 3px;
    background: ${({ theme }) => theme.colors.greyThree};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${({ theme }) => theme.colors.interactionFive};

      :before {
        background: ${({ theme }) => theme.colors.interactionFive};
      }
    `}
`;

ProgressSteps.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  activeStepIndex: PropTypes.number.isRequired,
};

export { ProgressSteps };
