import styled, { css } from 'styled-components';

const fineStyles = css`
  font-family: ${({ theme }) => theme.global.baseFontFamily};
  font-size: ${({ theme }) => theme.fontSize.small};
  line-height: ${({ theme }) => theme.lineHeight.body.base};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  margin: 0.5rem 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: ${({ theme }) => theme.fontSize.xSmall};
    line-height: ${({ theme }) => theme.lineHeight.body.small};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: ${({ theme }) => theme.fontSize.xxSmall};
    line-height: ${({ theme }) => theme.lineHeight.body.xSmall};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    font-size: ${({ theme }) => theme.fontSize.xxxSmall};
    line-height: ${({ theme }) => theme.lineHeight.body.xxSmall};
  }
`;

const Fine = styled.p`
  ${fineStyles};
`;

export { Fine, fineStyles };
