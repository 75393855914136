import { Link } from '../../link';
import { MobileCard, ShowOn, Table } from '../../ui-library';
import { userPropType } from '../../../prop-types';

const { TableRow, TableCell } = Table;

const UserRow = ({ user }) => {
  const { ContactID, FullName, EmailAddress, Account, Contract } = user;

  return (
    <>
      <ShowOn screenSize="mobile">
        <MobileCard as={Link} to={`/users/${ContactID}`} cardId={FullName}>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>{EmailAddress}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Organisation</TableCell>
            <TableCell>{Account}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Contracts</TableCell>
            <TableCell>{Contract}</TableCell>
          </TableRow>
        </MobileCard>
      </ShowOn>
      <ShowOn screenSize="desktop">
        <TableRow compact="true" as={Link} to={`/users/${ContactID}`} data-testid={`user-${ContactID}`}>
          <TableCell>{FullName}</TableCell>
          <TableCell>{EmailAddress}</TableCell>
          <TableCell>{Account}</TableCell>
          <TableCell>{Contract}</TableCell>
        </TableRow>
      </ShowOn>
    </>
  );
};

UserRow.propTypes = {
  user: userPropType.isRequired,
};

export { UserRow };
