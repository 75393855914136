import PropTypes from 'prop-types';

import { SelectArrow } from '../../../../select-arrow';

const DropdownIndicator = ({ selectProps: { menuIsOpen } }) => <SelectArrow isActive={menuIsOpen} />;

DropdownIndicator.propTypes = {
  selectProps: PropTypes.shape({
    menuIsOpen: PropTypes.bool.isRequired,
  }).isRequired,
};

export { DropdownIndicator };
