import PropTypes from 'prop-types';

import { Button, H3, Modal, ModalFooterButtonWrapper } from '../../ui-library';

const DiscardChangesModal = ({ onDiscardChanges, closeModal, isModalOpen }) => (
  <Modal
    size="sm"
    isOpen={isModalOpen}
    closeModal={closeModal}
    footer={
      <ModalFooterButtonWrapper closeModal={closeModal}>
        <Button onClick={onDiscardChanges} variant="destructive">
          Discard
        </Button>
      </ModalFooterButtonWrapper>
    }
  >
    <>
      <H3 as="h1" my={0}>
        Discard unsaved changes?
      </H3>
    </>
  </Modal>
);

DiscardChangesModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  onDiscardChanges: PropTypes.func.isRequired,
};

export { DiscardChangesModal };
