import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Cross } from '../icons';
import { Flex } from '../grid';
import { mobileOnly } from '../../utils/responsive-styles';

const FilterPanelLayout = ({ title, body, footer, onCancel }) => (
  <Flex flexDirection="column" height={{ xs: '100vh', md: 'inherit' }}>
    <Header>
      <CloseContainer onClick={onCancel}>
        <Cross />
      </CloseContainer>
      <Title>{title}</Title>
    </Header>
    {body && <Body>{body}</Body>}
    {footer && <Footer>{footer}</Footer>}
  </Flex>
);

const Header = styled.div`
  display: none;

  ${mobileOnly} {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    display: block;
    border-bottom: solid 1px ${({ theme }) => theme.colors.borderColorOne};
    background: ${({ theme }) => theme.colors.greyOne};
    z-index: 3;
  }
`;

const Title = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-align: center;
  width: 100%;
  height: 3rem;
  line-height: 3rem;
`;

const CloseContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  width: 3rem;
  display: grid;
  place-items: center;

  path {
    fill: ${(props) => props.theme.colors.black};
  }
`;

const Body = styled.div`
  padding: ${({ theme }) => theme.space.xs};
  padding-bottom: 0;
  flex: 1;

  ${mobileOnly} {
    padding-bottom: ${({ theme }) => theme.space.xs};
  }
`;

const Footer = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.white};
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.space.xs};
  margin-top: 4px;
  z-index: 3;

  ${mobileOnly} {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    border-top: solid 1px ${({ theme }) => theme.colors.greyThree};
    height: 4.5rem;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);

    & > :first-child {
      display: none;
    }

    & > :last-child {
      width: 100%;
    }
  }
`;

FilterPanelLayout.propTypes = {
  title: PropTypes.string,
  body: PropTypes.node.isRequired,
  footer: PropTypes.node,
  onCancel: PropTypes.func.isRequired,
};

FilterPanelLayout.defaultProps = {
  title: 'Filter',
  footer: null,
};

export { FilterPanelLayout };
