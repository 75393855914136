import styled, { css } from 'styled-components';

import { styledSystemProps } from './font-styles';

const PStyles = css`
  font-family: ${({ theme }) => theme.global.baseFontFamily};
  font-size: ${({ theme }) => theme.fontSize.base};
  line-height: ${({ theme }) => theme.lineHeight.body.medium};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  margin: 0;
`;

const P = styled.p`
  ${PStyles};
  ${styledSystemProps}
`;

export { P, PStyles };
