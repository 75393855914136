import PropTypes from 'prop-types';

import { Box, form } from '../../ui-library';

const { Input, Label } = form;

const CaseTemplateOptionForm = ({ contracts }) => (
  <Box mb={4}>
    <Label htmlFor="contractID">Contract</Label>
    <Input
      id="contractId"
      type="select"
      name="contractId"
      isFetching={!contracts}
      options={contracts.map((contract) => ({ value: contract.contractId, label: contract.name }))}
      validation={{ required: 'Contract is required.' }}
    />
  </Box>
);

CaseTemplateOptionForm.propTypes = {
  contracts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export { CaseTemplateOptionForm };
