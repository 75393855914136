import { get } from 'lodash';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import { Alert, constants, Flex, form, H4, icons } from '../../ui-library';
import { BookingFormRow } from '../booking-form-row';
import { BookingInstructions } from '../booking-instructions';
import { DynamicFormInput } from '../../dynamic-form-input';
import { formatDate } from '../../../utils/format-date';
import { LabelledInput } from '../../labelled-input';
import { Literal } from '../literal';
import { ResetCheckbox } from './reset-checkbox';
import { useBookingState } from '../../booking-context';
import { useTemplateState } from '../../template-context';

const { Input } = form;
const { Info, InfoWithBackground, Profile } = icons;

const BookingDetails = () => {
  const { apiFields } = useTemplateState();
  const { bookingData } = useBookingState();

  const fields = Object.values(apiFields);

  // Alternative solution is to check if values exist with useFormState's watch but this triggers
  // a strange flashing behaviour
  const showSpecialOptionCheckboxes = ['dangerousGoodsIndicator', 'urgentFlag', 'overDimensionalFlag'].some((option) =>
    fields.includes(option)
  );

  return (
    <>
      <DynamicFormInput
        name="bookingWebInstructions"
        render={({ label, name }) => <BookingInstructions label={label} name={name} />}
      />
      <BookingFormRow
        mt={2}
        icon={
          <IconWrapper>
            <Info />
          </IconWrapper>
        }
        iconHeight="20px"
      >
        <H4 m={0} mb={2}>
          Overview
        </H4>

        <DynamicFormInput
          name="contract"
          showHiddenField
          render={({ label, name }) => (
            <DetailsRow>
              <Title>{label}</Title>
              <Description>
                <Literal name={name} />
              </Description>
            </DetailsRow>
          )}
        />
        <DynamicFormInput
          name="serviceName"
          showHiddenField
          render={({ label, name }) => (
            <DetailsRow>
              <Title>{label}</Title>
              <Description>
                <Literal name={name} />
              </Description>
            </DetailsRow>
          )}
        />
        <DynamicFormInput
          name="commodityName"
          showHiddenField
          render={({ label, name }) => (
            <DetailsRow>
              <Title>{label}</Title>
              <Description>
                <Literal name={name} />
              </Description>
            </DetailsRow>
          )}
        />
      </BookingFormRow>
      <BookingFormRow
        icon={
          <IconWrapper>
            <Profile />
          </IconWrapper>
        }
        iconHeight="20px"
        mt={2}
      >
        <H4 mt={0} mb={2}>
          Requester
        </H4>

        <DynamicFormInput
          name="bookingCreatedBy"
          render={({ label, name }) => (
            <DetailsRow>
              <Title>{label}</Title>
              <Description>
                <Literal name={name} />
              </Description>
            </DetailsRow>
          )}
        />
        <DynamicFormInput
          name="bookingCreatedOn"
          render={({ label, name }) => (
            <DetailsRow>
              <Title>{label}</Title>
              <Description>
                <Literal name={name} transform={formatDate} />
              </Description>
            </DetailsRow>
          )}
        />
        {get(bookingData, 'modifiedDateTime') && (
          <DetailsRow>
            <Title>Last edit</Title>
            <Description>{formatDate(bookingData.modifiedDateTime)}</Description>
          </DetailsRow>
        )}
      </BookingFormRow>
      {showSpecialOptionCheckboxes && (
        <BookingFormRow
          mt={3}
          mb="4rem"
          icon={<InfoWithBackground backgroundColor={constants.colors.successTen} />}
          iconHeight="22px"
          iconWidth="22px"
          iconColor={constants.colors.white}
        >
          <H4 mt="2px" mb={2}>
            Please select all that apply to this booking (prior to proceeding)
          </H4>
          <>
            <Flex flexDirection="column">
              <DynamicFormInput
                name="dangerousGoodsIndicator"
                render={({ label, name, type, ...props }) => (
                  <Input name={name} type={type} id="type-dangerous-goods" {...props}>
                    {label}
                  </Input>
                )}
              />
              <DynamicFormInput
                name="urgentFlag"
                render={({ label, name, type, ...props }) => (
                  <Input name={name} type={type} id="type-urgent" {...props}>
                    {label}
                  </Input>
                )}
              />
              <DynamicFormInput
                name="overDimensionalFlag"
                render={({ label, name, type, ...props }) => (
                  <Input name={name} type={type} id="type-overdimensional" {...props}>
                    {label}
                  </Input>
                )}
              />
              <ResetCheckbox />
            </Flex>
          </>
          <DynamicFormInput
            name="dangerousGoodsIndicatorMessage"
            render={({ name }) => (
              <Alert mt={3} type="error">
                <Literal name={name} />
              </Alert>
            )}
          />
        </BookingFormRow>
      )}
      <BookingFormRow>
        <DynamicFormInput name="customerReferenceNumber" render={(props) => <LabelledInput {...props} />} />
      </BookingFormRow>
    </>
  );
};

const DetailsRow = styled(Flex)`
  line-height: 1;
  padding: 0.5rem 1rem;

  &:nth-child(even) {
    background-color: ${({ theme }) => theme.colors.greyTwo};
  }
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colors.greyNine};
  width: 12rem;
`;

const Description = styled.div`
  color: ${({ theme }) => theme.colors.greySeven};
  flex: 1;
`;

const IconWrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background-color: ${({ theme }) => theme.colors.greyTwo};
  top: -6px;
  position: relative;
`;

const ObservedBookingDetails = observer(BookingDetails);

export { ObservedBookingDetails as BookingDetails };
