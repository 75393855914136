import { flow, getEnv, getRoot, types } from 'mobx-state-tree';
import { get } from 'lodash';

import { AccountDetail } from '../../models/account-detail';

const AccountStore = types
  .model('AccountStore', {
    accountDetails: types.optional(types.array(AccountDetail), []),
    isFetching: false,
  })
  .actions((self) => {
    const { api } = getEnv(self);
    const { AuthStore } = getRoot(self);

    return {
      loadAccount: flow(function* () {
        self.isFetching = true;

        const response = yield api.get(`user/account/${AuthStore.user.AccountID}`);

        const accountDetails = get(response, 'data.account');

        self.accountDetails = accountDetails;
        self.isFetching = false;
      }),
    };
  });

export { AccountStore };
