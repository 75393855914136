import { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const CaseListingTemplateContext = createContext();

const { Provider } = CaseListingTemplateContext;

const CaseListingTemplateProvider = ({ children, columns }) => {
  if (columns.length === 0) return null;

  return <Provider value={{ columns }}>{children}</Provider>;
};

const useCaseListingTemplateContext = () => {
  const context = useContext(CaseListingTemplateContext);

  return context;
};

CaseListingTemplateProvider.propTypes = {
  children: PropTypes.node.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

export { useCaseListingTemplateContext, CaseListingTemplateProvider };
