import PropTypes from 'prop-types';

const Cargo = ({ fill, ...rest }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill={fill} {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 2H22V22H2V2ZM4 20H8V16H4V20ZM8 14H4V10H8V14ZM4 8H8V4H4V8ZM14 20H10V16H14V20ZM10 14H14V10H10V14ZM14 8H10V4H14V8ZM16 20H20V16H16V20ZM20 14H16V10H20V14ZM16 8H20V4H16V8Z"
    />
  </svg>
);

Cargo.propTypes = {
  fill: PropTypes.string,
};

Cargo.defaultProps = {
  fill: '#000',
};

export { Cargo };
