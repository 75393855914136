import PropTypes from 'prop-types';

import { Link } from '../../link';

const Wrapper = ({ download, to, ...props }) => {
  if ((to && to.startsWith('http')) || download)
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a href={to} download={download} target="_blank" {...props} rel="noopener noreferrer" />;

  if (to) {
    // TODO: this functionality is not properly tested.
    return <Link to={to} {...props} />;
  }

  // eslint-disable-next-line react/button-has-type
  return <button {...props} />;
};

Wrapper.propTypes = {
  download: PropTypes.bool,
  to: PropTypes.string,
};

Wrapper.defaultProps = {
  download: false,
  to: null,
};

export { Wrapper };
