import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useRootStore } from '../../components/store-provider/lib';

const useHydrateBookingData = () => {
  const {
    BookingStore: { loadBookingDetail },
  } = useRootStore();

  const { bookingID } = useParams();

  const [bookingData, setBookingData] = useState();
  const [templateData, setTemplateData] = useState();

  // We need to fetch the template JSON from SWAG, as well as any completed fields if this is a draft booking.
  useEffect(() => {
    const fetchBookingData = async () => {
      const { data } = await loadBookingDetail(bookingID);

      const { templateData: templateResponse = {}, ...rest } = data;

      setBookingData(rest);

      const { templateJSON } = templateResponse;

      if (templateJSON) {
        setTemplateData(templateJSON);
      }
    };

    if (bookingID) {
      fetchBookingData();
    }
  }, [bookingID, loadBookingDetail]);

  return { bookingData, templateData };
};

export { useHydrateBookingData };
