import { flexbox, space } from 'styled-system';
import styled from 'styled-components';

const TableCell = styled.div`
  ${flexbox};
  ${space};

  display: grid;
  word-wrap: anywhere;
  font-weight: ${({ theme, bold }) => bold && theme.fontWeight.bold};

  ${({ truncate = false }) =>
    truncate &&
    `
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;

export { TableCell };
