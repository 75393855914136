import { useState } from 'react';

import { Confirmation, Permissions, UserDetailsForm } from '../../components/user-invite';
import { constants, Content, ContentHeaderWrapper, H1, ProgressSteps, usePageTitle } from '../../components/ui-library';
import { useRootStore } from '../../components/store-provider/lib';

const pageOptions = [UserDetailsForm, Permissions, Confirmation];

const progressLabels = ['General Details', 'Permissions', 'Completion'];

const UserInvite = () => {
  usePageTitle('Add User');

  const [stepIndex, setIndex] = useState(0);

  const { UserStore } = useRootStore();

  const { selectUser } = UserStore;

  const Component = pageOptions[stepIndex];

  const addAnotherUser = () => {
    setIndex(0);
    selectUser(null);
  };

  return (
    <Content maxWidth={constants.globalStyles.formMaxWidth}>
      <ContentHeaderWrapper>
        <H1>Add User</H1>
      </ContentHeaderWrapper>
      <ProgressSteps labels={progressLabels} activeStepIndex={stepIndex} />
      <Component nextPage={() => setIndex(stepIndex + 1)} addAnotherUser={addAnotherUser} />
    </Content>
  );
};

export { UserInvite };
