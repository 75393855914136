import PropTypes from 'prop-types';
import styled from 'styled-components';

import { constants, P, Placeholder, ShowOn, Table } from '../../ui-library';
import { formatDate } from '../../../utils/format-date';
import { Link } from '../../link';
import { StatusCell } from '../status-cell';
import { TimeWindow } from '../../time-window';

const { TableCell, TableRow } = Table;

const constructLocation = (values) => values.filter(Boolean).join(', ');

const BookingRow = ({ data }) => {
  const {
    bookingID,
    bookingNumber,
    bookingStatus,
    createdBy,
    createdByAccount,
    creationDateTime,
    customerReferenceNumber,
    deliveryDetails: { location: deliveryLocation, deliveryFromDateTime, deliveryToDateTime },
    modifiedDateTime,
    pickupDetails: { pickupToDateTime, pickupFromDateTime, location: pickupLocation },
    serviceName,
    shipmentNumber,
  } = data;

  const creationDate = formatDate(creationDateTime);
  const modifiedDate = formatDate(modifiedDateTime);

  const path = bookingStatus === 'Draft' ? `/bookings/draft/${bookingID}` : `/bookings/${bookingID}`;

  const hasPickUpLocation = pickupLocation.locationName || pickupLocation.suburb || pickupLocation.state;
  const hasDeliveryLocation = deliveryLocation.locationName || deliveryLocation.suburb || deliveryLocation.state;

  return (
    <>
      <ShowOn screenSize="desktop">
        <TableRow as={Link} to={path}>
          <BookingCell justifySelf="center">
            <StatusCell status={bookingStatus} time={modifiedDate} shipmentId={shipmentNumber} />
          </BookingCell>
          <BookingCell pl="1rem">
            <P>{bookingNumber}</P>
            <Secondary>{serviceName}</Secondary>
          </BookingCell>
          <BookingCell>{customerReferenceNumber || <Placeholder>Ref #</Placeholder>}</BookingCell>
          <BookingCell>
            <P>{createdBy}</P>
            <P>{createdByAccount}</P>
            <Secondary>{creationDate}</Secondary>
          </BookingCell>
          <BookingCell>
            {hasPickUpLocation ? (
              <>
                <P>{pickupLocation.locationName}</P>
                <P>{constructLocation([pickupLocation.suburb, pickupLocation.state])}</P>
              </>
            ) : (
              <Placeholder>Location</Placeholder>
            )}
            <Secondary>
              <TimeWindow from={pickupFromDateTime} to={pickupToDateTime} iconFill={constants.colors.greyFour} />
            </Secondary>
          </BookingCell>
          <BookingCell>
            {hasDeliveryLocation ? (
              <>
                <P>{deliveryLocation.locationName}</P>
                <P>{constructLocation([deliveryLocation.suburb, deliveryLocation.state])}</P>
              </>
            ) : (
              <Placeholder>Location</Placeholder>
            )}
            <Secondary>
              <TimeWindow from={deliveryFromDateTime} to={deliveryToDateTime} iconFill={constants.colors.greyFour} />
            </Secondary>
          </BookingCell>
        </TableRow>
      </ShowOn>
      {/* TODO: Mobile View */}
      {/* <ShowOn screenSize="mobile">
        <MobileCard as={Link} to={`/bookings/${BookingId}`} cardId={BookingId}>
          <TableRow>
            <BookingCell>Status</BookingCell>
            <StatusCell status={bookingStatus} time={LastEdited} />
          </TableRow>
          <TableRow>
            <BookingCell>Booking ID</BookingCell>
            <BookingCell subtitle={BookingType}>{BookingId}</BookingCell>
          </TableRow>
          <TableRow>
            <BookingCell>Customer Ref #</BookingCell>
            <BookingCell>{CustomerRef}</BookingCell>
          </TableRow>
          <TableRow>
            <BookingCell>Created By</BookingCell>
            <BookingCell subtitle={CreatedByDate}>
              {CreatedByName}
                {CreatedByCompany}
            </BookingCell>
          </TableRow>
          <TableRow>
            <BookingCell>Pick Up</BookingCell>
            <BookingCell subtitle={PickUpDate}>
              {PickUpLocationName}
                {PickUpLocation}
            </BookingCell>
          </TableRow>
          <TableRow>
            <BookingCell>Destination</BookingCell>
            <BookingCell subtitle={DestinationDate}>
              {DestinationLocationName}
                {DestinationLocation}
            </BookingCell>
          </TableRow>
        </MobileCard>
      </ShowOn> */}
    </>
  );
};

BookingRow.propTypes = {
  data: PropTypes.shape({
    bookingID: PropTypes.string,
    bookingNumber: PropTypes.string,
    bookingStatus: PropTypes.string,
    commodityName: PropTypes.string,
    createdBy: PropTypes.string,
    createdByAccount: PropTypes.string,
    creationDateTime: PropTypes.string,
    customerReferenceNumber: PropTypes.string,
    deliveryDetails: PropTypes.shape({
      deliveryFromDateTime: PropTypes.string,
      deliveryToDateTime: PropTypes.string,
      location: PropTypes.shape({
        address1: PropTypes.string,
        address2: PropTypes.string,
        country: PropTypes.string,
        locationName: PropTypes.string,
        postcode: PropTypes.string,
        state: PropTypes.string,
        suburb: PropTypes.string,
      }),
    }),
    modifiedDateTime: PropTypes.string,
    pickupDetails: PropTypes.shape({
      pickupToDateTime: PropTypes.string,
      pickupFromDateTime: PropTypes.string,
      location: PropTypes.shape({
        address1: PropTypes.string,
        address2: PropTypes.string,
        country: PropTypes.string,
        locationName: PropTypes.string,
        postcode: PropTypes.string,
        state: PropTypes.string,
        suburb: PropTypes.string,
      }),
    }),
    serviceName: PropTypes.string,
    shipmentNumber: PropTypes.string,
  }).isRequired,
};

const BookingCell = styled(TableCell)`
  ${P} {
    font-size: 14px;
    line-height: 1.4;
  }
`;

const Secondary = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.greyFour};
  margin-top: 0.5rem;
`;

export { BookingRow };
