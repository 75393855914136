import { PropTypes } from 'prop-types';
import styled, { css } from 'styled-components';

import { Box, Container } from '../grid';
import { globalStyles } from '../../constants';

const Content = ({ children, maxWidth, isListing, ...props }) => (
  <ContentBox isListing={isListing} {...props}>
    <ContentWrapper flexDirection="column" maxWidth={maxWidth}>
      {children}
    </ContentWrapper>
  </ContentBox>
);

const ContentBox = styled(Box)`
  ${({ isListing }) =>
    isListing &&
    css`
      background-color: ${({ theme }) => theme.global.backgroundColor};
      height: calc(100vh - ${({ theme }) => `${theme.global.headerHeight} - ${theme.global.filterHeight}`});
    `}
  overflow-y: scroll;
`;

const ContentWrapper = styled(Container)`
  flex: auto;
  padding: ${({ theme }) => theme.space.sm};
  padding-bottom: ${({ theme }) => theme.space.xxl};
  margin: 0 auto;
`;

Content.propTypes = {
  children: PropTypes.node.isRequired,
  isListing: PropTypes.bool,
  maxWidth: PropTypes.string,
};

Content.defaultProps = {
  isListing: false,
  maxWidth: globalStyles.pageMaxWidth,
};

export { Content };
