import PropTypes from 'prop-types';

const attachmentPropType = PropTypes.shape({
  AttachmentID: PropTypes.string,
  PortalCommentID: PropTypes.string,
  Url: PropTypes.string,
  FileName: PropTypes.string,
  FileSize: PropTypes.number,
});

export { attachmentPropType };
