import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button } from '../../button';
import { Flex } from '../../grid';

const ModalFooterButtonWrapper = ({ closeModal, children }) => (
  <ButtonWrapper>
    <Button onClick={closeModal} variant="tertiary" mr={2}>
      Cancel
    </Button>
    {children}
  </ButtonWrapper>
);

const ButtonWrapper = styled(Flex)`
  button {
    min-width: 120px;
  }
`;

ModalFooterButtonWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export { ModalFooterButtonWrapper };
