import PropTypes from 'prop-types';

import { colors } from '../../../constants';

const Messages = ({ fill, ...rest }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill={fill} {...rest}>
    <path d="M17 6H16V3C16 2.20435 15.6839 1.44129 15.1213 0.87868C14.5587 0.316071 13.7956 0 13 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V15C0.000985482 15.1974 0.0603878 15.3901 0.170721 15.5539C0.281054 15.7176 0.437381 15.845 0.62 15.92C0.738679 15.976 0.868823 16.0034 1 16C1.13161 16.0008 1.26207 15.9755 1.38391 15.9258C1.50574 15.876 1.61656 15.8027 1.71 15.71L4.52 12.89H6V14.33C6 15.1256 6.31607 15.8887 6.87868 16.4513C7.44129 17.0139 8.20435 17.33 9 17.33H15.92L18.29 19.71C18.3834 19.8027 18.4943 19.876 18.6161 19.9258C18.7379 19.9755 18.8684 20.0008 19 20C19.1312 20.0034 19.2613 19.976 19.38 19.92C19.5626 19.845 19.7189 19.7176 19.8293 19.5539C19.9396 19.3901 19.999 19.1974 20 19V9C20 8.20435 19.6839 7.44129 19.1213 6.87868C18.5587 6.31607 17.7956 6 17 6ZM6 9V10.89H4.11C3.97839 10.8892 3.84793 10.9145 3.72609 10.9642C3.60426 11.014 3.49344 11.0873 3.4 11.18L2 12.59V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H13C13.2652 2 13.5196 2.10536 13.7071 2.29289C13.8946 2.48043 14 2.73478 14 3V6H9C8.20435 6 7.44129 6.31607 6.87868 6.87868C6.31607 7.44129 6 8.20435 6 9ZM18 16.59L17 15.59C16.9074 15.4955 16.7969 15.4203 16.6751 15.3688C16.5532 15.3173 16.4223 15.2906 16.29 15.29H9C8.73478 15.29 8.48043 15.1846 8.29289 14.9971C8.10536 14.8096 8 14.5552 8 14.29V9C8 8.73478 8.10536 8.48043 8.29289 8.29289C8.48043 8.10536 8.73478 8 9 8H17C17.2652 8 17.5196 8.10536 17.7071 8.29289C17.8946 8.48043 18 8.73478 18 9V16.59Z" />
  </svg>
);

Messages.propTypes = {
  fill: PropTypes.string,
};

Messages.defaultProps = {
  fill: colors.greySeven,
};

export { Messages };
