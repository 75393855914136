import { useEffect, useRef } from 'react';

import { useMounted } from '../use-mounted';

const ESCAPE_KEY = 27;

const useClickOutside = (callback, trigger, isPortal = false) => {
  const mounted = useMounted();
  const ref = useRef();

  const runCallback = () => {
    if (mounted) {
      callback();
    }
  };

  const determineShouldRunCallback = (event) => {
    const portalElement = document.getElementById('portal');

    if (!portalElement || !ref.current?.contains) {
      return false;
    }

    const clickOutsideElement = !ref.current.contains(event.target);

    const portalWithChildren = portalElement && portalElement.hasChildNodes();
    const clickOutsideElementWithinPortal = !portalElement.contains(event.target) && clickOutsideElement;

    const clickOutsideElementNoPortal = clickOutsideElement && trigger && !isPortal;

    if ((portalWithChildren && clickOutsideElementWithinPortal) || clickOutsideElementNoPortal) {
      return true;
    }

    return false;
  };

  const handleClickOutside = (event) => {
    const shouldRunCallback = determineShouldRunCallback(event);

    if (shouldRunCallback) {
      runCallback();
    }
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === ESCAPE_KEY) {
      runCallback();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  return ref;
};

export { useClickOutside };
