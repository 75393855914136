import PropTypes from 'prop-types';
import styled from 'styled-components';

import { form, Modal, ModalFooterButtonWrapper } from '../ui-library';

const { SubmitButton } = form;

const ItemModal = ({ buttonText, children, isModalOpen, closeModal, title }) => (
  <Modal
    title={title}
    size="lg"
    isOpen={isModalOpen}
    isScrollable
    closeModal={closeModal}
    footer={
      <ModalFooterButtonWrapper closeModal={closeModal}>
        <SubmitButton>{buttonText}</SubmitButton>
      </ModalFooterButtonWrapper>
    }
  >
    <Wrapper>{isModalOpen && children}</Wrapper>
  </Modal>
);

const Wrapper = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(4, 1fr);
  padding-bottom: 2rem;
`;

ItemModal.propTypes = {
  children: PropTypes.node.isRequired,
  buttonText: PropTypes.string.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export { ItemModal };
