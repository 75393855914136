import { createContext, useContext, useState } from 'react';
import { flexbox, space } from 'styled-system';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ErrorLabel } from '../input/error-label';
import { Flex } from '../../grid';
import { useFormState } from '..';

const Container = styled(Flex)`
  ${flexbox};
  ${space};
`;

const GroupContext = createContext();

const useGroupContext = () => {
  const context = useContext(GroupContext);

  if (!context) {
    throw new Error('Input fields with `type="radio"` must be placed within an InputGroup.');
  }

  return context;
};

const InputGroup = ({ children, name, ...props }) => {
  const { getError, watch } = useFormState();

  const initialValue = watch(name);

  const [selected, setSelected] = useState(initialValue);

  const error = getError(name);

  return (
    <>
      <Container {...props}>
        <GroupContext.Provider value={[selected, setSelected]}>{children}</GroupContext.Provider>
      </Container>
      {error && <ErrorLabel>{error}</ErrorLabel>}
    </>
  );
};

InputGroup.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
};

export { InputGroup, useGroupContext };
