import { BaseStyles } from './base';
import { SpacingStyles } from './spacing';
import { TypographyStyles } from './typography';

const GlobalCSS = () => (
  <>
    <BaseStyles />
    <SpacingStyles />
    <TypographyStyles />
  </>
);

export { GlobalCSS };
