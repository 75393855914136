import { useEffect, useState } from 'react';

import { useRootStore } from '../../components/store-provider/lib';

const useFetchTemplate = (contractID) => {
  const { CaseStore } = useRootStore();

  const [templateData, setTemplateData] = useState(null);

  useEffect(() => {
    const fetchTemplateData = async () => {
      const templateJSON = await CaseStore.fetchCaseTemplate(contractID);

      setTemplateData(templateJSON);
    };

    fetchTemplateData();
  }, [CaseStore, contractID]);

  return templateData;
};

export { useFetchTemplate };
