import styled from 'styled-components';

const OptionList = styled.div`
  background: white;
  border-radius: ${({ theme }) => theme.global.borderRadius};
  box-shadow: 12px 8px 24px rgba(0, 0, 0, 0.1), -12px 8px 24px rgba(0, 0, 0, 0.1);
  display: block;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  min-height: 2rem;
  padding: ${({ theme }) => theme.space.xxs} ${({ theme }) => theme.space.sm};
  position: absolute;
  right: 0;
  top: ${({ theme }) => theme.space.lg};
  width: 300px;
`;

const Option = styled.div`
  box-shadow: inset 0px -1px 0px ${({ theme }) => theme.colors.greyTwo};
  color: ${({ isActive, theme }) => (isActive ? theme.colors.interactionSix : theme.colors.greySeven)};
  cursor: pointer;
  padding: ${({ theme }) => theme.space.xs} 0;

  &:hover {
    color: ${({ theme }) => theme.colors.interactionSix};
  }

  :last-of-type {
    box-shadow: none;
  }
`;

export { OptionList, Option };
