import { rgba } from 'polished';
import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Arrow } from '../icons';
import { Box, Flex } from '../grid';
import { Button } from '../button';
import { Option } from './option';
import { useClickOutside } from '../../hooks/use-click-outside';

const Dropdown = ({ options, title, isRightAligned }) => {
  const [isOpen, setIsOpen] = useState(false);

  const ref = useClickOutside(() => setIsOpen(false), isOpen);

  return (
    <Box position="relative">
      <Button onClick={() => setIsOpen(true)} variant="tertiary">
        <Flex alignItems="center">
          {title} <ArrowIcon />
        </Flex>
      </Button>
      <Wrapper ref={ref} isOpen={isOpen} right={isRightAligned && 0} zIndex={10}>
        {options.map(({ label, onClick }) => (
          <Option key={label} label={label} onClick={onClick} closeModal={() => setIsOpen(false)} />
        ))}
      </Wrapper>
    </Box>
  );
};

const ArrowIcon = styled(Arrow)`
  width: 10px;
  height: 10px;
  margin-left: 0.5rem;
`;

const Wrapper = styled(Flex)`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: absolute;
  top: calc(100% + 6px);
  width: 260px;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 0px 1px ${({ theme }) => theme.colors.greyThree},
    0px 0px 6px ${({ theme }) => rgba(theme.colors.greyFour, 0.5)};
  border: 1px solid ${({ theme }) => theme.colors.borderColorOne};
  padding: 0px 16px;
`;

Dropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
  isRightAligned: PropTypes.bool,
};

Dropdown.defaultProps = {
  isRightAligned: false,
};

export { Dropdown };
