import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ArrowLeft, ArrowRight } from '../icons';
import { Flex } from '../grid';

const Navbar = ({ onPreviousClick, onNextClick }) => (
  <StyledNavbar>
    <NavButton onClick={() => onPreviousClick()}>
      <ArrowLeft />
    </NavButton>
    <NavButton onClick={() => onNextClick()}>
      <ArrowRight />
    </NavButton>
  </StyledNavbar>
);

const StyledNavbar = styled(Flex)`
  position: absolute;
  top: -${({ theme }) => theme.space.xxs};
  left: 0;
  right: 0;
  justify-content: space-between;
`;

const NavButton = styled.div`
  cursor: pointer;
  display: grid;
  place-items: center;
  border: 1px solid ${({ theme }) => theme.colors.greyThree};
  background-color: #fff;
  color: ${({ theme }) => theme.colors.greyFive};
  border-radius: 3px;
  width: 40px;
  height: 32px;
  line-height: 1;
  font-size: ${({ theme }) => theme.fontSize.large};

  &:hover {
    border-color: ${({ theme }) => theme.colors.greyFour};
  }

  svg > * {
    fill: ${({ theme }) => theme.colors.greySix};
  }
`;

Navbar.propTypes = {
  onPreviousClick: PropTypes.func,
  onNextClick: PropTypes.func,
};

Navbar.defaultProps = {
  onPreviousClick: () => null,
  onNextClick: () => null,
};

export { Navbar };
