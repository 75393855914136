import PropTypes from 'prop-types';

const DoubleArrowRight = ({ fill, ...rest }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill={fill} {...rest} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.1725 11.5825L6.2925 15.4625C5.9025 15.8525 5.9125 16.4925 6.2925 16.8825C6.6825 17.2725 7.3125 17.2725 7.7025 16.8825L12.2925 12.2925C12.6825 11.9025 12.6825 11.2725 12.2925 10.8825L7.7025 6.2925C7.3125 5.9025 6.6825 5.9025 6.2925 6.2925C5.9025 6.6825 5.9025 7.3125 6.2925 7.7025L10.1725 11.5825Z"
      fill="#49525D"
    />
    <path
      d="M16.1725 11.5825L12.2925 15.4625C11.9025 15.8525 11.9125 16.4925 12.2925 16.8825C12.6825 17.2725 13.3125 17.2725 13.7025 16.8825L18.2925 12.2925C18.6825 11.9025 18.6825 11.2725 18.2925 10.8825L13.7025 6.2925C13.3125 5.9025 12.6825 5.9025 12.2925 6.2925C11.9025 6.6825 11.9025 7.3125 12.2925 7.7025L16.1725 11.5825Z"
      fill="#49525D"
    />
  </svg>
);

DoubleArrowRight.propTypes = {
  fill: PropTypes.string,
};

DoubleArrowRight.defaultProps = {
  fill: '#fff',
};

export { DoubleArrowRight };
