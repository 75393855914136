import PropTypes from 'prop-types';
import styled from 'styled-components';

// import { analyticsCategory } from '../../constants/analytics';
// import { logNonInteractionEvent } from '../../utils/tracking';
import { ContactDetails } from '../contact-details';
import { Flex } from '../grid';
import { H1, P } from '../type';

const NotFound = ({ type, identifier }) => {
  // @TODO: tracking event
  // logNonInteractionEvent(analyticsCategory.GLOBAL, `NotFound:${type}`, identifier);

  return (
    <Content flexDirection="column" justifyContent="center" mx="auto">
      <H1>{type} not found</H1>
      <P>
        Unfortunately we could not find the {type} {identifier && `(${identifier})`} you were looking for.
      </P>
      <hr />
      <ContactDetails />
    </Content>
  );
};

const Content = styled(Flex)`
  min-height: 80vh;
  max-width: ${({ theme }) => theme.global.detailsMaxWidth};

  hr {
    border: 0px;
    margin: 1.5rem 0;
    height: 1px;
    background: rgba(146, 152, 157, 0.25);
  }
`;

NotFound.propTypes = {
  type: PropTypes.string,
  identifier: PropTypes.string,
};

NotFound.defaultProps = {
  type: 'Page',
  identifier: null,
};

export { NotFound };
