import styled from 'styled-components';

import { Flex } from '../../../ui-library';

const IconWrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background-color: ${({ theme }) => theme.colors.greyTwo};
`;

export { IconWrapper };
