import { Route, Switch } from 'react-router-dom';

import { BookingCreate } from '../pages/booking-create';
import { BookingDetail } from '../pages/booking-detail';
import { Bookings } from '../pages/bookings';
import { CaseRouter } from './case';
import { constants, PageNotFound, ServerError } from '../components/ui-library';
import { DefectStatus } from '../pages/defect-status';
import { Home } from '../pages/home';
import { Privilege } from '../components/privilege';
import { Profile } from '../pages/profile';
import { RaiseDefect } from '../pages/raise-defect';
import { TermsAndConditions } from '../pages/terms-and-conditions';
import { TrackAndTrace } from '../pages/track-and-trace';
import { TrackAndTraceDetail } from '../pages/track-and-trace-detail';
import { useFeatureFlag } from '../hooks/use-feature-flag';
import { UserInvite } from '../pages/user-invite';
import { UserProfile } from '../pages/user-profile';
import { Users } from '../pages/users';

const {
  contractPrivileges: { admin, booking, caseManagement, defect, track },
} = constants;

const Routes = () => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route exact path="/error" component={ServerError} />
    <Route exact path="/terms-of-use" component={TermsAndConditions} />
    <Route exact path="/(users)?/profile" component={Profile} key="/profile" />

    {useFeatureFlag('USER_MANAGEMENT') && (
      <Route path="/users">
        <Privilege privilegeName={admin} fallback={<PageNotFound />}>
          <Switch>
            <Route exact path="/users" component={Users} key="/users" />
            <Route exact path="/users/invite" component={UserInvite} key="/users/invite" />
            <Route exact path="/users/:userId" component={UserProfile} key="/users/:userId" />
          </Switch>
        </Privilege>
      </Route>
    )}

    {useFeatureFlag('ONLINE_BOOKINGS') && (
      <Route path="/bookings">
        <Privilege privilegeName={booking} fallback={<PageNotFound />}>
          <Switch>
            <Route exact path="/bookings" component={Bookings} />
            <Route exact path="/bookings/draft/:bookingID?" component={BookingCreate} />
            <Route exact path="/bookings/review/:bookingID?" component={BookingDetail} />
            <Route exact path="/bookings/:bookingID" component={BookingDetail} />
          </Switch>
        </Privilege>
      </Route>
    )}

    {useFeatureFlag('CASE_MANAGEMENT') && (
      <Route path="/cases">
        <Privilege privilegeName={caseManagement} fallback={<PageNotFound />}>
          <CaseRouter />
        </Privilege>
      </Route>
    )}

    {useFeatureFlag('DEFECTS') && (
      <Route path="/defects">
        <Privilege privilegeName={defect} fallback={<PageNotFound />}>
          <Route exact path="/defects/new" component={RaiseDefect} />
          <Route exact path="/defects/status" component={DefectStatus} />
        </Privilege>
      </Route>
    )}

    {useFeatureFlag('TRACK_AND_TRACE') && (
      <>
        <Route exact path="/track/:shipmentId" component={TrackAndTraceDetail} />
        <Route path="/track">
          <Privilege privilegeName={track} fallback={<PageNotFound />}>
            <Route exact path="/track" component={TrackAndTrace} />
          </Privilege>
        </Route>
      </>
    )}

    <Route path="*" component={PageNotFound} />
  </Switch>
);

export { Routes };
