import PropTypes from 'prop-types';
import styled from 'styled-components';

const FilterBarWrapper = ({ children }) => (
  <ControlContainer>
    <Wrapper>{children}</Wrapper>
  </ControlContainer>
);

const ControlContainer = styled.div`
  box-shadow: inset 0px -1px 0px ${({ theme }) => theme.colors.borderColorOne};
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 3;
`;

const Wrapper = styled.div`
  align-items: center;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.5rem;
  grid-template-columns: max-content;
  height: 3.5rem;
  margin: 0 auto;
  max-width: ${({ theme }) => theme.global.pageMaxWidth};
  padding: 0 2rem 0 1rem;
  width: 100%;
`;

FilterBarWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export { FilterBarWrapper };
