import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Flex } from '../grid';
import { Privilege } from '../privilege';
import { QuickLink } from './quick-link';

const QuickLinks = ({ links }) => (
  <StyledQuickLinks flex={1} flexDirection="column">
    {links.map(
      ({ enabled = true, heading, description, linkTo, Icon, privilegeName }) =>
        enabled && (
          <Privilege key={linkTo} name={privilegeName}>
            <QuickLink heading={heading} description={description} linkTo={linkTo} Icon={Icon} />
          </Privilege>
        )
    )}
  </StyledQuickLinks>
);

const StyledQuickLinks = styled(Flex)`
  padding: ${({ theme }) => theme.space.xl} 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: ${({ theme }) => theme.space.xxl} 0;
  }
`;

QuickLinks.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      enabled: PropTypes.bool,
      privilegeName: PropTypes.string.isRequired,
      Icon: PropTypes.node.isRequired,
      heading: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      linkTo: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export { QuickLinks };
