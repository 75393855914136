import { types } from 'mobx-state-tree';

import { Defect } from '../defect';

const DefectStatus = types
  .model('DefectStatus', {
    defect: types.reference(Defect),
    targetDefectNumber: types.maybeNull(types.string),
    targetStatus: types.maybeNull(types.string),
  })
  .views((self) => ({
    get viewTargetDefectNumber() {
      if (!self.targetDefectNumber) return null;

      // strip leading zeroes from targetDefectNumber
      return self.targetDefectNumber.replace(/^[0]+/, '');
    },
  }));

export { DefectStatus };
