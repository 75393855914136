import { useTransition } from 'react-spring';
import PropTypes from 'prop-types';

import { CloseButton, Divider, InnerPanel, Overlay, Panel, TopPanel } from './styles';
import { Cross, Profile, SignOut } from '../../icons';
import { DisableScroll } from '../../disable-scroll';
import { NavigationLink } from '../navigation-link';
import { navigationRoutesPropType } from '../../../prop-types';
import { StyledLink } from '../styled-link';

const PROFILE_ROUTE = '/profile';

const Drawer = ({ getIsCurrentRoute, navigationRoutes, isOpen, closeDrawer, logout }) => {
  const transitions = useTransition(isOpen, {
    from: { transform: 'translateX(-1000px)' },
    enter: { transform: 'translateX(0px)' },
    leave: { transform: 'translateX(-1000px)' },
  });

  const wrapInTransition = (content) => {
    return transitions(
      (props, item, key) =>
        item && (
          <Panel key={key} style={props}>
            {content}
          </Panel>
        )
    );
  };

  return (
    <>
      <DisableScroll isOpen={isOpen} />
      {isOpen && <Overlay onClick={closeDrawer} data-testid="overlay" />}

      {wrapInTransition(
        <div data-testid="mobile-navigation-drawer">
          <TopPanel>
            <CloseButton onClick={closeDrawer} data-testid="close-button">
              <Cross />
            </CloseButton>
          </TopPanel>
          <InnerPanel>
            {navigationRoutes.map(({ to, icon, label, privilegeName }) => (
              <NavigationLink
                key={privilegeName}
                to={to}
                privilegeName={privilegeName}
                onClick={closeDrawer}
                isActive={getIsCurrentRoute(to)}
              >
                {icon} {label}
              </NavigationLink>
            ))}
          </InnerPanel>
          <Divider />
          <InnerPanel>
            <NavigationLink to={PROFILE_ROUTE} onClick={closeDrawer} isActive={getIsCurrentRoute(PROFILE_ROUTE)}>
              <Profile />
              Profile
            </NavigationLink>
            <StyledLink
              to="#"
              onClick={() => {
                logout();
                closeDrawer();
              }}
            >
              <SignOut />
              Logout
            </StyledLink>
          </InnerPanel>
        </div>
      )}
    </>
  );
};

Drawer.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
  getIsCurrentRoute: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  navigationRoutes: navigationRoutesPropType.isRequired,
};

export { Drawer };
