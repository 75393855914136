import { map, noop } from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Link } from '../link';
import { RightChevron } from '../icons';

const Breadcrumbs = ({ entry, margin }) => {
  const isSingleEntryOnly = !entry.title;

  return (
    <StyledBreadcrumbs margin={margin}>
      {entry.ancestors &&
        map(entry.ancestors, ({ onClick = noop, title, url }, index) => (
          <li key={index}>
            <StyledCrumbLink to={url} onClick={onClick}>
              {title}
            </StyledCrumbLink>
            <CrumbSeparator>{!isSingleEntryOnly && <StyledRightChevron />}</CrumbSeparator>
          </li>
        ))}
      <li>{entry.title}</li>
    </StyledBreadcrumbs>
  );
};

const StyledBreadcrumbs = styled.ul`
  color: ${({ theme }) => theme.colors.interactionSix};
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.small};
  line-height: 1rem;
  list-style: none;
  margin: ${({ margin }) => margin};
  padding: 0;
`;

const StyledCrumbLink = styled(Link)`
  text-decoration: none;
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  :hover {
    color: ${({ theme }) => theme.colors.interactionSeven};
    text-decoration: underline;
  }
`;

const StyledRightChevron = styled(RightChevron)`
  margin-top: 1px;
  fill: ${({ theme }) => theme.colors.black};
`;

const CrumbSeparator = styled.span`
  padding: 0 ${({ theme }) => theme.space.x1};
`;

Breadcrumbs.propTypes = {
  entry: PropTypes.shape({
    title: PropTypes.string,
    ancestors: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
      })
    ),
  }).isRequired,
  margin: PropTypes.string,
};

Breadcrumbs.defaultProps = {
  margin: '0',
};

export { Breadcrumbs };
