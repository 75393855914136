import { createContext, useContext } from 'react';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';

import { theme as coreTheme } from '../../constants';

const ComponentContext = createContext();
const ComponentProvider = ComponentContext.Provider;
const ComponentConsumer = ComponentContext.Consumer;
const useComponent = () => useContext(ComponentContext);

// eslint-disable-next-line jsx-a11y/anchor-has-content
const DefaultLinkComponent = (props) => <a {...props} style={{ cursor: 'pointer' }} />;
const DefaultPrivilegeComponent = (props) => <div {...props} />;

const UiLibraryProvider = ({ branding, children, theme, PrivilegeComponent, LinkComponent, logger }) => {
  return (
    <ThemeProvider theme={{ ...theme, branding }}>
      <ComponentProvider value={{ LinkComponent, PrivilegeComponent, logger }}>{children}</ComponentProvider>
    </ThemeProvider>
  );
};

UiLibraryProvider.propTypes = {
  branding: PropTypes.oneOf(['linfox', 'bevChain']),
  children: PropTypes.node.isRequired,
  LinkComponent: PropTypes.func,
  logger: PropTypes.shape({
    error: PropTypes.func,
    info: PropTypes.func,
  }),
  PrivilegeComponent: PropTypes.func,
  theme: PropTypes.shape({}),
};

UiLibraryProvider.defaultProps = {
  branding: 'linfox',
  LinkComponent: DefaultLinkComponent,
  logger: null,
  PrivilegeComponent: DefaultPrivilegeComponent,
  theme: coreTheme,
};

export { UiLibraryProvider, useComponent, ComponentConsumer };
