import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

import { mobileOnly } from '../../../utils/responsive-styles';

const MilestonesLoader = () => {
  const milestones = 4;
  return (
    <>
      <StyledRow data-testid="milestone-loader">
        <LoadBar />
      </StyledRow>
      <MileStones>
        {[...Array(milestones)].map((e, r) => (
          // eslint-disable-next-line react/no-array-index-key
          <MileStone key={`row-${r}`}>
            <LoadBar />
            <Circle />
            <LoadBar />
          </MileStone>
        ))}
      </MileStones>
    </>
  );
};

const DetailsLoading = ({ rows, showMilestones, ...props }) => (
  <>
    {showMilestones && <MilestonesLoader />}
    <StyledRow data-testid="details-loader">
      <LoadBar />
    </StyledRow>
    {[...Array(rows)].map((e, r) => (
      // eslint-disable-next-line react/no-array-index-key
      <StyledRow {...props} key={`row-${r}`}>
        <LoadBar />
        <LoadBar />
        <LoadBar />
      </StyledRow>
    ))}
  </>
);

const LoadBar = styled.div`
  background: ${({ theme }) => theme.colors.greyTwo};
  border-radius: ${({ theme }) => theme.rem.sm};
  height: ${({ theme }) => theme.rem.sm};
  width: 100%;
`;

const animateHorz = keyframes`
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
`;

const StyledRow = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colors.white};
  border-bottom: solid 1px ${({ theme }) => theme.colors.greyOne};
  padding: ${({ theme }) => theme.space.sm} 0;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-column-gap: 8%;

  :before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    animation-name: ${animateHorz};
    animation-duration: 2.5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    background: linear-gradient(to right, #bbbbbb 2%, #666666 18%, #bbbbbb 33%);
    background-size: 50%;
    mix-blend-mode: overlay;
  }
`;

const Circle = styled.span`
  width: 30px;
  height: 30px;
  border-radius: 40px;
  background: ${({ theme }) => theme.colors.greyTwo};
  margin: ${({ theme }) => theme.space.md} ${({ theme }) => theme.space.sm};
`;

const MileStones = styled(StyledRow)`
  margin-top: ${({ theme }) => theme.space.md};
  margin-bottom: ${({ theme }) => theme.space.xl};
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 2%;
  border-bottom: 0px;

  ${mobileOnly} {
    display: block;
    margin: ${({ theme }) => theme.space.sm} 0;
    padding: 0;
  }
`;

const MileStone = styled.div`
  height: 255px;
  border: solid 1px ${({ theme }) => theme.colors.greyTwo};
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  > div {
    width: 60%;
  }

  ${mobileOnly} {
    height: 60px;
    flex-direction: row;
    justify-content: start;
    margin-bottom: ${({ theme }) => theme.space.xxs};

    & > :first-child {
      display: none;
    }
  }
`;

DetailsLoading.propTypes = {
  rows: PropTypes.number,
  showMilestones: PropTypes.bool,
};

DetailsLoading.defaultProps = {
  rows: 10,
  showMilestones: false,
};

export { DetailsLoading };
