import { up } from 'styled-breakpoints';
import styled from 'styled-components';

const TableBody = styled.div`
  ${up('md')} {
    border-left: 1px solid ${({ theme }) => theme.colors.borderColorFour};
    border-right: 1px solid ${({ theme }) => theme.colors.borderColorFour};
  }
`;

export { TableBody };
