import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Flex } from '../grid';
import { H1 } from '../type';

const ErrorMessage = ({ children }) => (
  <Wrapper justifyContent="center" alignItems="center" width="100%">
    <H1>{children}</H1>
  </Wrapper>
);

const Wrapper = styled(Flex)`
  margin-top: calc(50vh - 72px);
`;

ErrorMessage.propTypes = {
  children: PropTypes.node.isRequired,
};

export { ErrorMessage };
