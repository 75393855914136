import styled from 'styled-components';

import { P } from '../ui-library';

const EmptyResponse = styled(P)`
  color: ${({ theme }) => theme.colors.greyFour};

  &::after {
    content: 'None provided';
  }
`;

export { EmptyResponse };
