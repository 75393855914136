import { types } from 'mobx-state-tree';

const Toast = types.model('Toast', {
  variant: types.string,
  description: types.string,
  linkTo: types.maybeNull(types.string),
  linkLabel: types.maybeNull(types.string),
});

const ToastStore = types
  .model('ToastStore', {
    toasts: types.optional(types.array(Toast), []),
  })
  .actions((self) => {
    return {
      addToast: (toast) => {
        self.toasts.push(toast);

        setTimeout(self.removeStaleToast, 5000);
      },
      removeStaleToast: () => {
        self.toasts.shift();
      },
    };
  });

export { ToastStore };
