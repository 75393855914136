import { get, isFunction } from 'lodash';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Checkbox, constants, Table } from '../ui-library';
import { DetailField } from '../detail-field';
import { DetailProvider } from '../detail-context';

const VARIANT_A_HEADINGS = ['Qty', 'Type', 'Dimensions (L X W X H)', 'Weight', 'Volume', 'Description', 'Reference'];
const VARIANT_B_HEADINGS = ['Qty', 'Type', 'Description', 'Height', 'Weight', 'Out of Gauge', 'Container'];

const { TableBody, TableRow, TableLegend, TableCell } = Table;

const CargoTable = ({ items, onClick, normalisedFields, SelectAllButton, selectedItems, toggleItem }) => {
  const layoutVariant = get(normalisedFields, 'cargoTableVariant.default', 'A');

  const tableHeadings = layoutVariant === 'A' ? VARIANT_A_HEADINGS : VARIANT_B_HEADINGS;

  const isEditable = SelectAllButton && isFunction(toggleItem);

  return (
    <TableWrapper variant={layoutVariant}>
      <TableLegend>
        <TableCell>{isEditable && SelectAllButton}</TableCell>
        {tableHeadings.map((heading) => (
          <TableCell key={heading}>{heading}</TableCell>
        ))}
      </TableLegend>
      <TableBody>
        {/* General Freight layout */}
        {/* eslint-disable react/no-array-index-key  */}
        {layoutVariant === 'A' &&
          items.map((item, index) => {
            const {
              itemQty,
              itemQtyText,
              itemLength,
              itemWidth,
              itemHeight,
              itemGrossWeight,
              itemVolume,
              itemVolumeText,
              itemVolumeUOM,
              itemWeightUOM,
              itemDescription,
              itemReference,
            } = item;

            return (
              <DetailProvider key={`cargoRow${index}`} value={{ data: { items: [item] }, normalisedFields }}>
                <StyledTableRow
                  isEditable={isEditable}
                  onClick={() => {
                    if (isEditable) onClick(index);
                  }}
                >
                  <TableCell>
                    {isEditable && (
                      <Checkbox value={selectedItems.includes(index)} onChange={() => toggleItem(index)} size="sm" />
                    )}
                  </TableCell>
                  <TableCell>{itemQty || itemQtyText}</TableCell>
                  {/* Even though the column title is type, Linfox has specified to show the itemProduct, not the itemType */}
                  <TableCell>
                    <DetailField field="itemProduct" />
                  </TableCell>
                  <TableCell>
                    {itemLength && itemWidth && itemHeight && `${itemLength} x ${itemWidth} x ${itemHeight}`}
                  </TableCell>
                  <TableCell>
                    {itemGrossWeight} {itemWeightUOM}
                  </TableCell>
                  <TableCell>
                    {itemVolume || itemVolumeText} {itemVolumeUOM}
                  </TableCell>
                  <TableCell>{itemDescription}</TableCell>
                  <TableCell>{itemReference}</TableCell>
                </StyledTableRow>
              </DetailProvider>
            );
          })}
        {layoutVariant === 'B' &&
          items.map((item, index) => {
            const {
              itemQty,
              itemQtyText,
              itemHeight,
              itemDimsUOM,
              itemGrossWeight,
              itemWeightUOM,
              itemDescription,
              outOfGauge,
              containerNumber,
            } = item;

            return (
              <DetailProvider key={`cargoRow${index}`} value={{ data: { items: [item] }, normalisedFields }}>
                <StyledTableRow
                  isEditable={isEditable}
                  onClick={() => {
                    if (isEditable) onClick(index);
                  }}
                >
                  <TableCell>
                    {isEditable && (
                      <Checkbox value={selectedItems.includes(index)} onChange={() => toggleItem(index)} size="sm" />
                    )}
                  </TableCell>
                  <TableCell>{itemQty || itemQtyText}</TableCell>
                  <TableCell>
                    <DetailField field="itemProduct" />
                  </TableCell>
                  <TableCell>{itemDescription}</TableCell>
                  <TableCell>
                    {itemHeight} {itemDimsUOM}
                  </TableCell>
                  <TableCell>
                    {itemGrossWeight} {itemWeightUOM}
                  </TableCell>
                  <TableCell>{outOfGauge ? 'Yes' : 'No'}</TableCell>
                  <TableCell>{containerNumber}</TableCell>
                </StyledTableRow>
              </DetailProvider>
            );
          })}
      </TableBody>
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  margin: 0 -0.5rem;

  @media (min-width: ${constants.breakpoints.xl}) {
    margin: 0;
  }

  ${TableRow} {
    ${({ layoutVariant }) =>
      layoutVariant === 'A'
        ? css`
            grid-template-columns: repeat(2, 2rem) repeat(2, minmax(5rem, 7rem)) repeat(2, 1fr) 5rem;
          `
        : css`
            grid-template-columns: repeat(2, 2rem) 12rem 10rem 5rem 6rem 1fr 8rem;
          `}
  }

  ${TableLegend} {
    padding-left: ${({ theme }) => theme.space.xs};
    padding-right: ${({ theme }) => theme.space.xs};
    background: transparent;
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderColorOne};
  }

  ${TableBody} {
    box-shadow: none;
    border: 1px solid ${({ theme }) => theme.colors.borderColorOne};
    border-width: 0 1px;
    max-height: 50vh;
    overflow-y: auto;
  }
`;

const StyledTableRow = styled(TableRow)`
  min-height: 52px;
  padding: ${({ theme }) => theme.space.xxs} ${({ theme }) => theme.space.xs};
  white-space: nowrap;
  background: transparent;
  cursor: ${({ isEditable }) => isEditable && 'pointer'};

  & > * {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &:nth-child(odd) {
    background: ${({ theme }) => theme.colors.greyOne};
  }

  &:hover {
    background: ${({ theme }) => theme.colors.greyTwo};
  }
`;

CargoTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  normalisedFields: PropTypes.shape({}).isRequired,
  onClick: PropTypes.func,
  SelectAllButton: PropTypes.node,
  selectedItems: PropTypes.arrayOf(PropTypes.shape({})),
  toggleItem: PropTypes.func,
};

CargoTable.defaultProps = {
  onClick: () => null,
  SelectAllButton: null,
  selectedItems: null,
  toggleItem: null,
};

export { CargoTable };
