import { variant as styledVariant } from 'styled-system';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box } from '../grid/box';
import { Flex } from '../grid/flex';
import { statusColors } from '../../constants/colors';

const StatusLabel = ({ children, variant, ...props }) => {
  return (
    <StatusLabelWrapper variant={variant} {...props}>
      <Box>{children}</Box>
    </StatusLabelWrapper>
  );
};

const variants = {
  booking: {
    Accepted: {
      color: 'interactionNine',
      backgroundColor: 'successOne',
    },
    Draft: {
      color: 'greySix',
      backgroundColor: 'greyTwo',
    },
    Submitted: {
      color: 'interactionTen',
      backgroundColor: 'interactionOne',
    },
    Cancelled: {
      color: 'dangerEight',
      backgroundColor: 'dangerOne',
    },
  },
  case: {
    awaitingYou: {
      backgroundColor: 'interactionOne',
      color: 'interactionTen',
    },
    awaitingLinfox: {
      backgroundColor: 'primaryOne',
      color: 'interactionTen',
    },
  },
  delivery: {
    created: {
      color: statusColors.defaultColor,
      backgroundColor: statusColors.defaultBackground,
    },
    processing: {
      color: statusColors.processedColor,
      backgroundColor: statusColors.processedBackground,
    },
    inTransit: {
      color: statusColors.defaultColor,
      backgroundColor: statusColors.inTransitBackground,
    },
    lastMile: {
      color: statusColors.defaultColor,
      backgroundColor: statusColors.lastMileBackground,
    },
    delivered: {
      color: statusColors.defaultColor,
      backgroundColor: statusColors.deliveredBackground,
    },
    cancelled: {
      color: statusColors.cancelledColor,
      backgroundColor: statusColors.cancelledBackground,
    },
  },
};

const StatusLabelWrapper = styled(Flex)`
  ${({ type }) => styledVariant({ variants: variants[type] })};

  border-radius: 100px;
  padding: ${({ theme }) => `${theme.space.xxs} ${theme.space.xs}`};
  min-width: 116px;
  width: max-content;
  font-size: ${({ theme }) => theme.fontSize.xSmall};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  justify-content: center;
  align-items: center;
`;

StatusLabel.propTypes = {
  children: PropTypes.node.isRequired,
  // Processed is added valid value.
  // See https://github.com/inlight-media/linfox/pull/410#discussion_r636589407
  variant: PropTypes.oneOf([
    'Processed',
    ...Object.keys(variants.booking),
    ...Object.keys(variants.case),
    ...Object.keys(variants.delivery),
  ]).isRequired,
};

export { StatusLabel };
