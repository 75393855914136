import { get, isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  AttachmentsList,
  Box,
  Breadcrumbs,
  CommentForm,
  CommentsList,
  constants,
  DetailsList,
  Flex,
  Grid,
  H2,
  icons,
  NotFound,
  Toggle,
  useMounted,
  usePageTitle,
} from '../../components/ui-library';
import { CaseDetails } from './case-details';
import { HeaderBar } from '../../components/header-bar';
import { logger } from '../../utils/logger';
import { TemplateContextProvider } from '../../components/template-context';
import { useRootStore } from '../../components/store-provider/lib';

const { Messages, Paperclip } = icons;
const { DetailsLoading } = DetailsList;

const toggleOptions = [
  {
    label: 'Comments',
    key: 'comments',
    count: '',
    icon: <Messages />,
  },
  {
    label: 'Attachments',
    key: 'attachments',
    count: '',
    icon: <Paperclip />,
  },
];

const CaseDetail = () => {
  // @TODO: Refactor into useReducer
  const [data, setData] = useState();
  const [caseDetails, setCaseDetails] = useState();
  const [comments, setComments] = useState([]);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [panelToShow, setPanelToShow] = useState('comments');
  const [reload, setReload] = useState(true);
  const [fields, setFields] = useState([]);
  const [statusFields, setStatusFields] = useState([]);
  const [groupedFields, setGroupedFields] = useState([]);

  const mounted = useMounted();
  const { caseID } = useParams();
  const { CaseStore, AuthStore } = useRootStore();
  const { fullName } = AuthStore;

  const pageTitle = get(caseDetails, 'caseNumber', 'Fetching');
  usePageTitle(`${pageTitle} - Cases`);

  const breadCrumbTrail = {
    title: pageTitle,
    ancestors: [
      {
        title: 'All Cases',
        url: '/cases',
      },
    ],
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await CaseStore.fetchCaseDetail(caseID);

      if (mounted && reload) {
        setData(response.data);
        setCaseDetails(response.caseDetails);
        setFields(response.fields);
        setGroupedFields(response.groupedFields);
        setComments(response.comments);
        setHasLoaded(true);
        setReload(false);
        setStatusFields(response.statusFields);
      }
    };

    fetchData();
  }, [CaseStore, caseID, mounted, reload]);

  const handleAttachmentDownload = async (attachment) => {
    try {
      const url = await CaseStore.fetchAttachment(attachment);

      if (url) {
        const a = document.createElement('a');
        a.href = url;
        // @TODO: Need to test that this works with real files from the API.
        a.download = true;
        a.target = '_blank';
        a.rel = 'noopener noreferrer';
        a.click();
      }
    } catch (error) {
      // eslint-disable-next-line no-alert
      logger.error(error);
    }
  };

  const postComment = async (values, event, reset) => {
    const updatedComments = await CaseStore.postComment({ caseID, ...values });
    if (updatedComments) {
      setComments(updatedComments);
    }

    // clear comment form state
    reset();
  };

  const attachments = useMemo(() => comments.reduce((acc, cur) => [...acc, ...cur.documents], []), [comments]);

  // @TODO: HeaderBar is 66px - should match constants.globalStyles.filterHeight (60px) which is used on other pages.
  const HEADER_BAR_HEIGHT = '66px';
  const columnHeightStyleRule = `calc(100vh - ${constants.globalStyles.headerHeight} - ${HEADER_BAR_HEIGHT}) `;
  const showCommentsPanel = useCallback(() => setPanelToShow('comments'), []);
  return (
    <>
      <HeaderBar>
        <Breadcrumbs entry={breadCrumbTrail} margin="0" />
        {hasLoaded && (
          <Toggle options={toggleOptions} selectedKey={panelToShow} onChange={(key) => setPanelToShow(key)} />
        )}
      </HeaderBar>
      {/* // 👇 Should really be using <Content> from the UI Library here but it's causing scrolling issues */}
      <Box maxWidth={constants.globalStyles.pageMaxWidth} m="0 auto">
        {!hasLoaded && <DetailsLoading />}
        {hasLoaded && !data && <NotFound type="Case" identifier={caseID} />}
        {data && (
          <Grid gridTemplateColumns="30% 70%">
            <Box backgroundColor="greyOne" height={columnHeightStyleRule} overflowY="auto">
              {caseDetails && groupedFields && (
                <TemplateContextProvider value={{ templateData: { fields } }}>
                  <CaseDetails
                    skuDetails={caseDetails.skuDetails ?? []}
                    additionalReferences={caseDetails.additionalReferences ?? []}
                    groupedFields={groupedFields}
                    statusFields={statusFields}
                    statusName={caseDetails?.statusName}
                  />
                </TemplateContextProvider>
              )}
            </Box>
            <Flex
              borderLeftWidth="1px"
              borderLeftStyle="solid"
              borderLeftColor="borderColorOne"
              flexDirection="column"
              height={columnHeightStyleRule}
            >
              <Box flex="1" overflowY="auto" px="x2">
                {panelToShow === 'comments' && (
                  <>
                    {comments && (
                      <CommentsList comments={comments} handleAttachmentDownload={handleAttachmentDownload} />
                    )}
                  </>
                )}
                {panelToShow === 'attachments' && (
                  <>
                    {!isEmpty(attachments) && <H2 my="sm">Attachments</H2>}
                    <AttachmentsList
                      attachments={attachments}
                      handleAttachmentDownload={handleAttachmentDownload}
                      showCommentsPanel={showCommentsPanel}
                    />
                  </>
                )}
              </Box>
              {caseDetails?.statusName !== 'Resolved' && (
                <Box borderTopWidth="1px" borderTopColor="borderColorOne" borderTopStyle="solid" flex="0">
                  <CommentForm
                    name={fullName}
                    onSubmit={postComment}
                    handleAttachmentDownload={handleAttachmentDownload}
                  />
                </Box>
              )}
            </Flex>
          </Grid>
        )}
      </Box>
    </>
  );
};

const ObservedCaseDetail = observer(CaseDetail);
export { ObservedCaseDetail as CaseDetail };
