import PropTypes from 'prop-types';

const Truck = ({ stroke, ...rest }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...rest} stroke={stroke}>
    <path d="M3 9.5C3 9.22386 3.22386 9 3.5 9H4V10H3.5C3.22386 10 3 9.77614 3 9.5Z" />
    <path d="M19.25 8.33399C19.4074 8.33399 19.5556 8.40808 19.65 8.53399L21.8 11.4007C21.9298 11.5737 22 11.7843 22 12.0007V14.834C22 15.3863 21.5523 15.834 21 15.834H20.3333C20.3333 17.2173 19.2167 18.334 17.8333 18.334C16.45 18.334 15.3333 17.2173 15.3333 15.834H10.3333C10.3333 17.2173 9.21667 18.334 7.83334 18.334C6.45001 18.334 5.33334 17.2173 5.33334 15.834H4.50006C4.22392 15.834 4.00006 15.6101 4.00006 15.334V12H7.50001C7.77615 12 8.00001 11.7761 8.00001 11.5C8.00001 11.2239 7.77615 11 7.50001 11H4.00006V10H12.5C12.7761 10 13 9.77614 13 9.5C13 9.22386 12.7761 9 12.5 9H4.00006V8H10.5C10.7761 8 11 7.77614 11 7.5C11 7.22386 10.7761 7 10.5 7H4.00006V6.66732C4.00006 5.75065 4.41667 5.00065 5.33334 5.00065H15.5833C16.1356 5.00065 16.5833 5.44837 16.5833 6.00065V8.33399H19.25ZM6.58334 15.834C6.58334 16.5257 7.14167 17.084 7.83334 17.084C8.52501 17.084 9.08334 16.5257 9.08334 15.834C9.08334 15.1423 8.52501 14.584 7.83334 14.584C7.14167 14.584 6.58334 15.1423 6.58334 15.834ZM19.1555 9.62572C19.1108 9.54833 19.0283 9.50065 18.9389 9.50065H17V11.6673H19.9005C20.0929 11.6673 20.2132 11.4591 20.117 11.2924L19.1555 9.62572ZM16.5833 15.834C16.5833 16.5257 17.1417 17.084 17.8333 17.084C18.525 17.084 19.0833 16.5257 19.0833 15.834C19.0833 15.1423 18.525 14.584 17.8333 14.584C17.1417 14.584 16.5833 15.1423 16.5833 15.834Z" />
    <path d="M2 11.5C2 11.2239 2.22386 11 2.5 11H4V12H2.5C2.22386 12 2 11.7761 2 11.5Z" />
    <path d="M2 7.5C2 7.22386 2.22386 7 2.5 7H4V8H2.5C2.22386 8 2 7.77614 2 7.5Z" />
  </svg>
);

Truck.propTypes = {
  stroke: PropTypes.string,
};

Truck.defaultProps = {
  stroke: '#fff',
};

export { Truck };
