const lineHeight = {
  heading: {
    xLarge: '2rem',
    large: '1.5rem',
    medium: '1.25rem',
    base: '1rem',
    small: '0.833rem',
    xSmall: '0.75rem',
    xxSmall: '0.666rem',
    xxxSmall: '0.583rem',
    xxxxSmall: '0.5rem',
  },
  body: {
    xLarge: '2rem',
    large: '1.5rem',
    medium: '1.25rem',
    base: '1rem',
    small: '0.833rem',
    xSmall: '0.75rem',
    xxSmall: '0.666rem',
    xxxSmall: '0.583rem',
    xxxxSmall: '0.5rem',
  },
};

export { lineHeight };
