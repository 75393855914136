import 'react-day-picker/lib/style.css';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Content, usePageTitle } from '../../components/ui-library';
import { ShipmentFilterBar } from '../../components/shipment-filter-bar';
import { ShipmentListing } from '../../components/shipment-listing';
import { useRootStore } from '../../components/store-provider/lib';

const TrackAndTrace = () => {
  usePageTitle('Track and Trace');
  const { ShipmentStore } = useRootStore();
  const { search } = useLocation();

  const { hasFetched, setParameters, loadShipments, setQueryString } = ShipmentStore;

  useEffect(() => {
    if (!hasFetched) {
      const params = new URLSearchParams(search);

      const initialKeyword = params.has('keyword') ? params.get('keyword') : '';
      const initialShipmentStatus = params.has('shipmentStatus') ? params.get('shipmentStatus') : '';
      const initialDateFrom = params.has('dateFrom') ? params.get('dateFrom') : '';
      const initialDateTo = params.has('dateTo') ? params.get('dateTo') : '';
      const initialLocation = params.has('location') ? params.get('location') : '';
      const initialDay = params.has('day') ? params.get('day') : '';

      setParameters({
        keyword: initialKeyword,
        shipmentStatus: initialShipmentStatus,
        dateFrom: initialDateFrom,
        dateTo: initialDateTo,
        location: initialLocation,
        day: initialDay,
      });
    } else {
      setQueryString();
    }
  }, [hasFetched, loadShipments, search, setParameters, setQueryString]);

  return (
    <>
      <ShipmentFilterBar />
      <Content isListing>
        <ShipmentListing />
      </Content>
    </>
  );
};

const ObservedTrackAndTrace = observer(TrackAndTrace);
export { ObservedTrackAndTrace as TrackAndTrace };
