import PropTypes from 'prop-types';

const Toolbox = ({ fill, ...rest }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill={fill} {...rest}>
    <path d="M22 11.0176C22 10.6859 21.8684 10.3684 21.634 10.1336L19.866 8.36562C19.6316 8.13125 19.3137 7.99961 18.982 7.99961H17V4.875C17 3.83945 16.1605 3 15.125 3H8.875C7.83945 3 7 3.83945 7 4.875V8H5.01797C4.68633 8 4.36836 8.13164 4.13398 8.36602L2.36602 10.134C2.13164 10.3684 2 10.6863 2 11.018L2.00039 13.625L2 19.25C2 19.9402 2.55977 20.5 3.25 20.5H20.75C21.4402 20.5 22 19.9402 22 19.25V11.0176ZM8.875 4.875H15.125V8H8.875V4.875ZM3.875 11.2766L5.27656 9.875H18.723L20.125 11.2766L20.1254 13.625H16.6875V13C16.6875 12.6547 16.4078 12.375 16.0625 12.375H15.4375C15.0922 12.375 14.8125 12.6547 14.8125 13V13.625H9.1875V13C9.1875 12.6547 8.90781 12.375 8.5625 12.375H7.9375C7.59219 12.375 7.3125 12.6547 7.3125 13V13.625H3.87539L3.875 11.2766ZM20.125 18.625H3.875V15.5H7.3125V16.125C7.3125 16.4703 7.59219 16.75 7.9375 16.75H8.5625C8.90781 16.75 9.1875 16.4703 9.1875 16.125V15.5H14.8125V16.125C14.8125 16.4703 15.0922 16.75 15.4375 16.75H16.0625C16.4078 16.75 16.6875 16.4703 16.6875 16.125V15.5H20.125V18.625Z" />
  </svg>
);

Toolbox.propTypes = {
  fill: PropTypes.string,
};

Toolbox.defaultProps = {
  fill: '#000',
};

export { Toolbox };
