import { isNil } from 'lodash';
import { space } from 'styled-system';
import { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { backgroundStyle, borderStyle, focusStyle, sizeStyle } from '../styles';
import { ErrorLabel } from '../error-label';
import { useFormState } from '../..';

const Double = ({ name, placeholder, readOnly, showErrorPoppedOut, validation, ...props }) => {
  const [hasFocus, setFocus] = useState(false);
  const [hasHover, setHover] = useState(false);

  const { getError, register } = useFormState();

  const error = getError(name);

  const ref = register({
    ...validation,
    setValueAs: (value) => {
      if (value) {
        const number = Number(value);

        if (number === 0) return null;

        if (Number.isNaN(number)) return null;

        return number;
      }

      return null;
    },
  });

  return (
    <>
      <StyledDouble
        aria-label={placeholder}
        hasError={!isNil(error)}
        hasFocus={hasFocus}
        hasHover={hasHover}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        name={name}
        placeholder={placeholder}
        readOnly={readOnly}
        type="text"
        {...props}
        ref={ref}
      />
      {error && <ErrorLabel showPoppedOut={showErrorPoppedOut}>{error}</ErrorLabel>}
    </>
  );
};

const StyledDouble = styled.input`
  ${backgroundStyle};
  ${borderStyle};
  ${focusStyle};
  ${sizeStyle};
  ${space};

  font-size: ${({ theme }) => theme.fontSize.base};

  ${({ hasError, theme }) =>
    hasError &&
    css`
      border-color: ${theme.colors.dangerSeven};
    `}

  ${({ disabled, theme }) =>
    disabled &&
    css`
      border-color: ${theme.colors.greyTwo};
    `}

  &::placeholder {
    color: ${({ theme }) => theme.colors.greyFour};
    opacity: 1;
  }
`;

Double.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  showErrorPoppedOut: PropTypes.bool,
  validation: PropTypes.shape(),
  value: PropTypes.string,
};

Double.defaultProps = {
  placeholder: '',
  readOnly: false,
  showErrorPoppedOut: false,
  validation: null,
  value: undefined,
};

export { Double };
