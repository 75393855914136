import { addSeconds, format } from 'date-fns';
import { useEffect } from 'react';

import { MapWrapper } from '../../shipment-milestones/styles';
import { ShowMapComponent } from '..';
import { useMapState } from '../map-context';

const loadError = () => {
  // handle error
};

const Map = () => {
  const { eta, setETA, showMap } = useMapState();

  const absoluteETA = (etaSeconds = 0) => {
    const currentTime = new Date();
    const expectedTime = addSeconds(currentTime, etaSeconds);

    const timeToDisplay = format(expectedTime, 'h:mm a');

    return timeToDisplay;
  };

  useEffect(() => {
    window.loadMapScenario = () => {
      const map = new window.Microsoft.Maps.Map('#myMap');

      window.Microsoft.Maps.loadModule('Microsoft.Maps.Directions', () => {
        const directionsManager = new window.Microsoft.Maps.Directions.DirectionsManager(map);
        directionsManager.setRenderOptions({ itineraryContainer: document.getElementById('printoutPanel') });
        directionsManager.setRequestOptions({
          routeMode: window.Microsoft.Maps.Directions.RouteMode.truck,
          vehicleSpec: {
            dimensionUnit: 'ft',
            weightUnit: 'lb',
            vehicleHeight: 5,
            vehicleWidth: 3.5,
            vehicleLength: 30,
            vehicleWeight: 30000,
            vehicleAxles: 3,
            vehicleTrailers: 2,
            vehicleSemi: true,
            vehicleMaxGradient: 10,
            vehicleMinTurnRadius: 15,
            vehicleAvoidCrossWind: true,
            vehicleAvoidGroundingRisk: true,
            vehicleHazardousMaterials: 'F',
            vehicleHazardousPermits: 'F',
          },
        });

        const wp1 = new window.Microsoft.Maps.Directions.Waypoint({
          address: '17 Budd St, Collingwood, 3066',
          // location: new window.Microsoft.Maps.Location(40.419228, -80.018138),
        });

        const wp2 = new window.Microsoft.Maps.Directions.Waypoint({
          address: '55 English Street, Essendon Fields Victoria 3041',
          // location: new window.Microsoft.Maps.Location(40.43755, -79.99361),
        });

        directionsManager.addWaypoint(wp1);
        directionsManager.addWaypoint(wp2);

        directionsManager.calculateDirections();

        window.Microsoft.Maps.Events.addHandler(directionsManager, 'directionsUpdated', (event) => {
          const { routeSummary } = event;

          const [first] = routeSummary;

          setETA(absoluteETA(first.timeWithTraffic));
        });
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const LoadExternalScript = () => {
      const externalScript = document.createElement('script');
      externalScript.onerror = loadError;
      externalScript.id = 'external';
      externalScript.async = true;
      externalScript.type = 'text/javascript';
      // externalScript.setAttribute('crossorigin', 'anonymous');
      document.body.appendChild(externalScript);
      externalScript.src = `https://www.bing.com/api/maps/mapcontrol?key=${process.env.REACT_APP_BING_KEY}&callback=loadMapScenario`;
    };

    LoadExternalScript();
  }, []);

  return (
    <MapWrapper setDisplayMap={showMap}>
      <ShowMapComponent showMap={false} title="Close Map" />
      <span className="etaTime">ETA {eta}</span>
      <div id="myMap" style={{ width: '100%', height: '100%', marginTop: '10px' }} />
    </MapWrapper>
  );
};

export { Map };
