import { css } from 'styled-components';
import { down, up } from 'styled-breakpoints';

const hideOnDesktop = css`
  ${up('md')} {
    display: none;
  }
`;

const hideOnMobile = css`
  ${down('sm')} {
    display: none;
  }
`;

const mobileOnly = down('sm');
const desktopOnly = up('md');

export { mobileOnly, hideOnDesktop, desktopOnly, hideOnMobile };
