import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Modal, TextButton } from '../ui-library';
import { SKUTable } from '../sku-table';
import { useModal } from '../../hooks/use-modal';

const SKUDetails = ({ items = [] }) => {
  const { closeModal, isModalOpen, openModal } = useModal();

  if (!items || items.length === 0) return null;

  return (
    <>
      <Modal
        dismissible
        dismissOnOverlayClick
        title="SKU Details"
        size="lg"
        isOpen={isModalOpen}
        isScrollable
        closeModal={closeModal}
      >
        <Wrapper>{isModalOpen && <SKUTable items={items} />}</Wrapper>
      </Modal>
      <StyledTextButton variant="secondary" onClick={openModal}>
        View Details
      </StyledTextButton>
    </>
  );
};

SKUDetails.propTypes = {
  items: PropTypes.shape.isRequired,
};

const Wrapper = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr;
  padding-bottom: 2rem;
`;

const StyledTextButton = styled(TextButton)`
  padding: 0;
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  :hover:not([disabled]) {
    text-decoration: none;
  }
`;

export { SKUDetails };
