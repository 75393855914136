import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { FilterPanelLayout } from './filter-panel-layout';
import { Form, ResetButton, SubmitButton } from '../form';
import { Input } from '../form/input';

const FilterTypeInput = ({
  defaultValues,
  placeholder,
  name,
  resetKey,
  onSubmit,
  onCancel,
  onUpdateLabel,
  validation,
}) => {
  const handleOnSubmit = (values) => {
    onSubmit(values);
    onUpdateLabel(values[name]);
  };

  const handleOnReset = () => {
    onSubmit(resetKey);
    onUpdateLabel();
  };

  const mount = () => {
    onUpdateLabel(defaultValues[name]);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(mount, []);

  return (
    <Form onSubmit={handleOnSubmit} onReset={handleOnReset} defaultValues={defaultValues}>
      <FilterPanelLayout
        onCancel={onCancel}
        body={
          <Input validation={validation} name={name} type="text" placeholder={placeholder} id={name} maxLength="70" />
        }
        footer={
          <>
            <ResetButton variant="tertiary">Clear</ResetButton>
            <SubmitButton>Apply</SubmitButton>
          </>
        }
      />
    </Form>
  );
};

FilterTypeInput.propTypes = {
  defaultValues: PropTypes.shape({}),
  name: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onUpdateLabel: PropTypes.func,
  resetKey: PropTypes.string,
  placeholder: PropTypes.string,
  validation: PropTypes.shape({}),
};

FilterTypeInput.defaultProps = {
  defaultValues: {},
  onSubmit: () => null,
  onCancel: () => null,
  onUpdateLabel: () => null,
  resetKey: '',
  placeholder: '',
  validation: null,
};

export { FilterTypeInput };
