import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box, Button, Flex, form, Modal, ModalFooterButtonWrapper, P } from '../../../components/ui-library';
import { Link } from '../../../components/link';

const { SubmitButton, Form, Input, useFormState } = form;

const Footer = () => {
  const { watch } = useFormState();
  const hasDeclared = watch('acceptTCs');

  return (
    <SubmitButton minWidth="180px" disabled={!hasDeclared}>
      Submit Booking
    </SubmitButton>
  );
};

const ReviewButtonBar = ({ onSubmit, onEdit }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  // @TODO: handle error state
  // eslint-disable-next-line no-unused-vars
  const [hasError, setHasError] = useState(false);

  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      <ReviewPanel>
        <Button onClick={onEdit} variant="secondary" minWidth="216px" mr="0.5rem">
          Edit Booking
        </Button>
        <Button onClick={() => setIsModalOpen(true)} minWidth="216px" ml="0.5rem">
          Submit Booking
        </Button>
      </ReviewPanel>

      {hasError ? (
        <Modal
          title="Failed to Submit Booking"
          isOpen={isModalOpen}
          closeModal={closeModal}
          footer={
            <ModalFooterButtonWrapper closeModal={closeModal}>
              {/* @TODO: handle retry */}
              <Button onClick={() => {}}>Retry</Button>
            </ModalFooterButtonWrapper>
          }
        >
          <Content>
            Unfortunately your booking request did not submit successfully. Please make sure you are connected to the
            internet and try again.
            <br />
            <br />
            If this problem persists, please contact our{' '}
            <StyledLink href="mailto:portalsupport@linfox.com" target="_blank">
              Portal Support
            </StyledLink>
          </Content>
        </Modal>
      ) : (
        <Form mode="onChange" onSubmit={onSubmit}>
          <Modal title="Booking Confirmation" isOpen={isModalOpen} closeModal={closeModal} footer={<Footer />}>
            <Flex flex={1} mb={3}>
              <Input type="checkbox" name="acceptTCs" alignItems="flex-start">
                <Content color="greySeven">
                  I declare in signing this declaration that, to the best of my knowledge, the above information is true
                  and correct. I also declare this shipment does not comprise of any dangerous or fragile goods unless
                  otherwise stated. Duties and Taxes are the responsibility of the receiver, unless otherwise instructed
                  by the shipper.
                </Content>
              </Input>
            </Flex>
            <Box position="relative" left="40px">
              <StyledLink to="/terms-of-use" target="_blank">
                View Terms of Use
              </StyledLink>
            </Box>
          </Modal>
        </Form>
      )}
    </>
  );
};

const ReviewPanel = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.white};
  position: fixed;
  z-index: 5;
  bottom: 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-top: 1px solid ${({ theme }) => theme.colors.borderColorOne};
`;

const Content = styled(P)`
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.colors.greySeven};
`;

const StyledLink = styled(Link)`
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.colors.interactionSix};
`;

ReviewButtonBar.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export { ReviewButtonBar };
