import { Controller } from 'react-hook-form';
import { space } from 'styled-system';
import { useRef, useState } from 'react';
import { without } from 'lodash';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { backgroundStyle, borderStyle, focusStyle, sizeStyle } from '../styles';
import { Cross, Plus } from '../../../icons';
import { Flex } from '../../../grid';
import { useFormState } from '../..';

// @TODO: hit enter, adds the value
const ListInput = ({ name, placeholder }) => {
  const [hasFocus, setFocus] = useState(false);
  const [hasHover, setHover] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const inputRef = useRef();

  const { control, watch } = useFormState();

  const defaultValue = watch(name) || [];

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ onChange, value }) => {
        const hasItems = value && value.length > 0;
        const items = hasItems ? value.split(',') : [];

        const addNewItem = () => {
          if (inputRef.current && inputRef.current.value) {
            const values = [...new Set([...items, inputRef.current.value])];
            onChange(values.join());

            inputRef.current.value = '';
            setIsActive(false);
          }
        };

        const removeItem = (item) => {
          onChange(without(items, item).join());
        };

        return (
          <>
            <Wrapper hasFocus={hasFocus} mb={hasItems && 3}>
              <StyledListInput
                aria-label={placeholder}
                hasHover={hasHover}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                onChange={(event) => setIsActive(!!event.target.value)}
                placeholder={placeholder}
                type="text"
                ref={inputRef}
              />
              <AddButton type="button" onClick={addNewItem} isActive={isActive}>
                <Plus />
              </AddButton>
            </Wrapper>
            <Flex>
              {items.map((item) => (
                <Item key={item} alignItems="center">
                  {item}
                  <Cross type="button" onClick={() => removeItem(item)} />
                </Item>
              ))}
            </Flex>
          </>
        );
      }}
    />
  );
};

const Wrapper = styled(Flex)`
  ${focusStyle};
  max-width: 50%;
`;

const StyledListInput = styled.input`
  ${backgroundStyle};
  ${borderStyle};
  ${sizeStyle};
  ${space};

  font-size: ${({ theme }) => theme.fontSize.base};
  border-radius: 4px 0 0 4px;

  ${({ hasError, theme }) =>
    hasError &&
    css`
      border-color: ${theme.colors.dangerSeven};
    `}

  ${({ disabled, theme }) =>
    disabled &&
    css`
      border-color: ${theme.colors.greyTwo};
    `}

  &::placeholder {
    color: ${({ theme }) => theme.colors.greyFour};
    opacity: 1;
  }
`;

const AddButton = styled.button`
  background-color: ${({ theme, isActive }) => (isActive ? theme.colors.primaryFour : theme.colors.greyThree)};
  width: 48px;
  height: 46px;
  border-radius: 0px 4px 4px 0px;

  svg {
    fill: ${({ theme }) => theme.colors.greySeven};
  }
`;

const Item = styled.button`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.greyTwo};
  color: ${({ theme }) => theme.colors.greySix};
  padding: 0.5rem 0.75rem;
  border-radius: ${({ theme }) => theme.global.borderRadius};
  margin-right: 0.5rem;

  svg {
    margin-left: 4px;
    height: 18px;
    width: 18px;
    fill: ${({ theme }) => theme.colors.greySeven};
  }
`;

ListInput.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};

ListInput.defaultProps = {
  placeholder: '',
};

export { ListInput };
