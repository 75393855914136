import styled from 'styled-components';

const FeatureImage = styled.div`
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
`;

export { FeatureImage };
