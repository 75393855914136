const setQueryString = (params) => {
  // Not all filter parameters should be included in the URL.
  const { keyword, limit, start, ...queryParameters } = params;

  // If keyword is present, only `keyword` should be in the URL.
  const searchParams = new URLSearchParams(keyword ? { keyword } : queryParameters);

  let newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;

  const queryString = searchParams.toString();

  if (queryString !== '') {
    newUrl = `${newUrl}?${queryString}`;
  }

  window.history.replaceState({ path: newUrl }, '', newUrl);
};

export { setQueryString };
