import { filter, first, get, isEmpty, map, orderBy, reduce } from 'lodash';
import { flow, getEnv, getRoot, types } from 'mobx-state-tree';

import { transformCaseDetails } from '../../utils/transform-case-details';

const getCommentsWithAttachments = (comments) => filter(comments, ({ documents }) => (documents || []).length > 0);

const addMetadataToAttachment =
  ({ createdByCustomer, createdByLinfox, createdOn }) =>
  (attachment) => ({
    ...attachment,
    createdBy: createdByCustomer || createdByLinfox,
    createdOn,
  });

const getCommentAttachments = (comments) =>
  reduce(
    getCommentsWithAttachments(comments),
    (acc, { documents, ...metadata }) => {
      const attachmentsWithMetadata = map(documents, addMetadataToAttachment(metadata));
      return [...acc, ...orderBy(attachmentsWithMetadata, ['createdOn'], ['desc'])];
    },
    []
  );

const CaseStore = types
  .model('CaseStore', {
    caseContractID: types.maybeNull(types.string),
    cases: types.optional(types.array(types.frozen({})), []),
    caseTypesCount: types.frozen({}),
    fetchingCaseCount: false,
    hasFetched: false,
    isFetching: false,
    totalResults: types.optional(types.number, 0),
  })
  .actions((self) => {
    const { ToastStore } = getRoot(self);
    const { api, logger } = getEnv(self);

    return {
      loadCases: flow(function* (params) {
        self.isFetching = true;

        self.caseContractID = null;

        const { data, status } = yield api.get(`cases?${params.toString()}`);

        const { cases = [], count, pagination = { total: 0 } } = data;

        self.totalResults = get(pagination, 'total', 0);

        self.caseTypesCount = count;

        if (status !== 200) {
          ToastStore.addToast({ variant: 'error', description: 'There was an error fetching cases.' });
        }

        self.isFetching = false;

        return cases;
      }),
      createCase: flow(function* (body) {
        const response = yield api.post('cases/new', { body });

        const {
          status,
          data: { message },
        } = response;

        if (status !== 200) {
          logger.error('Case Creation failed', message);

          ToastStore.addToast({
            variant: 'error',
            description: 'There was an error creating this case.',
          });

          return { success: false };
        }

        const {
          data: { caseID },
        } = message;

        const {
          contractID,
          caseDetails: { attachments, caseTitle, description = null },
        } = body;

        self.caseContractID = contractID;

        if (!isEmpty(attachments) && caseID) {
          yield self.postComment({
            attachments,
            caseID,
            message: description || caseTitle,
            // required to post comment to both portals, when its the first
            postToAllPortals: true,
          });
        }

        ToastStore.addToast({
          variant: 'success',
          description: 'Case created.',
        });

        return { success: true };
      }),
      fetchCaseDetail: flow(function* (caseID) {
        if (!caseID) {
          return { caseDetails: null };
        }

        const response = yield api.get(`case/${caseID}`);
        const data = get(response, 'data');

        self.caseContractID = get(data, 'caseDetails.contractID');

        // TODO: handle this renaming in the API
        const { fields, groupedFields, statusFields } = transformCaseDetails(data);

        const commentsResponse = yield api.get(`case/comments/${caseID}`);
        const commentsData = get(commentsResponse, 'data.data');

        const attachments = getCommentAttachments(commentsData);

        return {
          caseDetails: get(data, 'caseDetails'),
          groupedFields,
          fields,
          statusFields,
          data,
          attachments,
          comments: commentsData || [],
        };
      }),
      fetchCaseTemplate: flow(function* (contractID) {
        if (!contractID) {
          return { data: null };
        }

        const response = yield api.get(`case/templates?contractID=${contractID}`);

        const { data } = response;

        const { templateJSON } = data;

        return {
          ...templateJSON,
          fields: templateJSON.fields.map((field) => ({
            ...field,
            apiField: field.apiField.replace('caseDetails.', ''),
          })),
        };
      }),
      fetchAttachment: flow(function* (attachment) {
        try {
          const downloadURL = yield api.stateMachine('cases/attachment', {
            body: {
              attachmentId: attachment.documentID,
              fileName: attachment.name,
              mimeType: attachment.contentType,
            },
          });

          return downloadURL;
        } catch (error) {
          logger.error('Error downloading file.', { error, attachment });

          ToastStore.addToast({
            variant: 'error',
            description: `${attachment.name} could not be downloaded at this time.`,
          });

          return null;
        }
      }),
      postComment: flow(function* ({ caseID, message, attachments, postToAllPortals = false }) {
        const ContractID = self.caseContractID;

        if (!caseID || !ContractID) {
          ToastStore.addToast({ variant: 'error', description: 'There was an error creating this comment.' });

          return false;
        }

        const { data } = yield api.post('cases/comment', {
          body: {
            CaseID: caseID,
            ContractID,
            Description: message,
            postToAllPortals,
          },
        });

        const commentIDs = get(data, 'message.commentIDs');

        if ((commentIDs.teamCommentID || commentIDs.customerCommentID) && attachments) {
          yield self.uploadAttachments({
            attachments,
            caseID,
            commentIDs,
            ContractID,
            Subject: message,
          });
        }

        // Fetch and return new comment data.
        const commentsResponse = yield api.get(`case/comments/${caseID}`);

        return get(commentsResponse, 'data.data');
      }),
      uploadAttachments: flow(function* ({ attachments, caseID, commentIDs, ContractID, Subject }) {
        yield Promise.all(
          attachments.map(async (file) => {
            const {
              data: { message },
            } = await api.post('attachment/new', {
              body: { fileName: file.name, fileType: file.type },
            });

            const { key, uploadURL } = message;

            const upload = await fetch(uploadURL, {
              method: 'PUT',
              body: file,
            });

            if (upload.status === 200) {
              try {
                const data = await api.stateMachine('cases/attachment/new', {
                  body: {
                    caseID,
                    key,
                    MimeType: file.type,
                    FileName: file.name,
                    IsDocument: true,
                    ContractID,
                    commentIDs,
                    Subject,
                  },
                });

                const { error = false } = data;

                if (error) {
                  const { Cause } = error;

                  const { errorMessage } = JSON.parse(Cause);

                  throw errorMessage;
                }
              } catch (error) {
                // TODO: use error modal
                // eslint-disable-next-line no-alert
                window.alert(error);
              }
            }
          })
        );
      }),
      getTemplate: flow(function* ({ accountID }) {
        const { data, status } = yield api.get(`case/listingTemplate?accountID=${accountID}`);

        if (status !== 200) {
          logger.error('Failed to load case listing template', { accountID, data, status });
          ToastStore.addToast({ variant: 'error', description: 'There was an error fetching case listing template.' });
          return null;
        }

        return data;
      }),
    };
  })
  .views((self) => ({
    // TODO: update to support multiple contracts
    get contract() {
      return first(self.caseTypesPerContract) || {};
    },
    get caseTypeSelectOptions() {
      const { CaseTypes = [] } = self.contract;
      const caseTypeOptions = CaseTypes.map(({ CaseType, CaseTypeID }) => ({
        label: CaseType,
        value: CaseTypeID,
      }));

      return caseTypeOptions;
    },
    get hasFetchedCases() {
      return self.cases.length > 0;
    },
    get dateDefaultValue() {
      return {
        from: null,
        to: null,
      };
    },
    get dateFilterLabel() {
      return 'foo';
    },
  }));

export { CaseStore };
