import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import { Alert, constants, Container, DefectStatusList, Flex, H1, H3, TextButton } from '../../components/ui-library';
import { useRootStore } from '../../components/store-provider/lib';

const DefectStatus = () => {
  const { DefectRequestStore } = useRootStore();

  const { failedRequests, successfulRequests, retryFailedRequests } = DefectRequestStore;

  return (
    <Wrapper maxWidth={constants.globalStyles.formMaxWidth} flex={1} flexDirection="column">
      <H1 mb="2rem">Your request was submitted</H1>
      {!isEmpty(failedRequests) && (
        <Flex flexDirection="column">
          <Alert type="error">
            Oops! Something went wrong when the DRR was submitted to SAP. Please click `Try Again’ to submit again. If
            you keep getting errors, please contact your Site Team for help.
          </Alert>
          <H3 mt="2rem" mb="1rem">
            Failed submission
          </H3>
          <DefectStatusList defects={failedRequests} success={false} />
          <TextButton mx="auto" mt="1.5rem" mb="3rem" variant="secondary" onClick={retryFailedRequests}>
            Try Again
          </TextButton>
        </Flex>
      )}
      {!isEmpty(successfulRequests) && (
        <Flex flexDirection="column">
          <Alert type="info">
            Please take a screenshot or write down the DRR numbers before you close this screen. You can’t get the
            numbers back if you don’t note them down now.
          </Alert>
          <H3 mt="2rem" mb="1rem">
            Successfully submitted
          </H3>
          <DefectStatusList defects={successfulRequests} success />
          <TextButton mt="1.5rem" mx="auto" variant="secondary" to="/">
            Back to Home
          </TextButton>
        </Flex>
      )}
      {isEmpty(successfulRequests) && isEmpty(failedRequests) && (
        <Flex flexDirection="column">
          <Alert type="info">
            This request submission can no longer be viewed. To submit another Defect Repair Requisition please follow
            the link below.
          </Alert>
          <TextButton mt="1.5rem" mx="auto" variant="secondary" to="/defects/new">
            New Defect Request
          </TextButton>
        </Flex>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(Container)`
  padding: ${({ theme }) => theme.space.xl} ${({ theme }) => theme.space.sm};
  min-height: calc(100vh - ${({ theme }) => theme.global.headerHeight});
`;

const ObservedDefectStatus = observer(DefectStatus);

export { ObservedDefectStatus as DefectStatus };
