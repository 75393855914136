import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import { Alert, Box } from '../ui-library';
import { Link } from '../link';
import { useRootStore } from '../store-provider/lib';

// @TODO: some animations would be nice ✨
const ToastNotifications = () => {
  const { ToastStore } = useRootStore();

  const { toasts } = ToastStore;

  if (isEmpty(toasts)) {
    return null;
  }

  return (
    <Wrapper position="fixed" mb={2} zIndex={100} bottom={0} width="100%">
      <Box width="fit-content" mx="auto">
        {toasts.map(({ description, variant, linkTo, linkLabel }) => (
          <Alert type={variant} key={description} mb={1}>
            {description}
            {linkTo && linkLabel && (
              <>
                {' '}
                <Link to={linkTo}>{linkLabel}</Link>
              </>
            )}
          </Alert>
        ))}
      </Box>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  pointer-events: none;
`;

const ObservedToastNotifications = observer(ToastNotifications);

export { ObservedToastNotifications as ToastNotifications };
