import PropTypes from 'prop-types';

const Calendar = ({ fill, ...rest }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M18.3 4.8H16.5V3.9C16.5 3.66131 16.4052 3.43239 16.2364 3.2636C16.0676 3.09482 15.8387 3 15.6 3C15.3613 3 15.1324 3.09482 14.9636 3.2636C14.7948 3.43239 14.7 3.66131 14.7 3.9V4.8H9.3V3.9C9.3 3.66131 9.20518 3.43239 9.0364 3.2636C8.86761 3.09482 8.63869 3 8.4 3C8.1613 3 7.93239 3.09482 7.7636 3.2636C7.59482 3.43239 7.5 3.66131 7.5 3.9V4.8H5.7C4.98392 4.8 4.29716 5.08446 3.79081 5.59081C3.28446 6.09716 3 6.78392 3 7.5V18.3C3 19.0161 3.28446 19.7028 3.79081 20.2092C4.29716 20.7155 4.98392 21 5.7 21H18.3C19.0161 21 19.7028 20.7155 20.2092 20.2092C20.7155 19.7028 21 19.0161 21 18.3V7.5C21 6.78392 20.7155 6.09716 20.2092 5.59081C19.7028 5.08446 19.0161 4.8 18.3 4.8ZM19.2 18.3C19.2 18.5387 19.1052 18.7676 18.9364 18.9364C18.7676 19.1052 18.5387 19.2 18.3 19.2H5.7C5.46131 19.2 5.23239 19.1052 5.0636 18.9364C4.89482 18.7676 4.8 18.5387 4.8 18.3V12H19.2V18.3ZM19.2 10.2H4.8V7.5C4.8 7.2613 4.89482 7.03239 5.0636 6.8636C5.23239 6.69482 5.46131 6.6 5.7 6.6H7.5V7.5C7.5 7.73869 7.59482 7.96761 7.7636 8.1364C7.93239 8.30518 8.1613 8.4 8.4 8.4C8.63869 8.4 8.86761 8.30518 9.0364 8.1364C9.20518 7.96761 9.3 7.73869 9.3 7.5V6.6H14.7V7.5C14.7 7.73869 14.7948 7.96761 14.9636 8.1364C15.1324 8.30518 15.3613 8.4 15.6 8.4C15.8387 8.4 16.0676 8.30518 16.2364 8.1364C16.4052 7.96761 16.5 7.73869 16.5 7.5V6.6H18.3C18.5387 6.6 18.7676 6.69482 18.9364 6.8636C19.1052 7.03239 19.2 7.2613 19.2 7.5V10.2Z"
      fill={fill}
    />
  </svg>
);

Calendar.propTypes = {
  fill: PropTypes.string,
};

Calendar.defaultProps = {
  fill: '#fff',
};

export { Calendar };
