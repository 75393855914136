import { useLocation } from 'react-router-dom';

const useHydrateFilterValues = (filters) => {
  const { search } = useLocation();

  const params = new URLSearchParams(search);

  const requestFilters = { ...filters };

  // override default filters with values from query string
  params.forEach((value, key) => {
    requestFilters[key] = value;
  });

  return requestFilters;
};

export { useHydrateFilterValues };
