import PropTypes from 'prop-types';

const CasePropType = {
  createdOn: PropTypes.string.isRequired,
  createdBy: PropTypes.string.isRequired,
  customerName: PropTypes.string,
  caseNumber: PropTypes.string.isRequired,
  caseID: PropTypes.string.isRequired,
  orderNumber: PropTypes.string,
  caseType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  lastUpdatedOn: PropTypes.string,
  deliveryState: PropTypes.string.isRequired,
};

export { CasePropType };
