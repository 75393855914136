import { flow, getEnv, types } from 'mobx-state-tree';
import { get } from 'lodash';

import { User } from '../../models/user';

const AuthStore = types
  .model({
    user: types.maybeNull(User),
    isFetching: false,
    loginFailed: false,
  })
  .actions((self) => {
    const { api, auth, logger } = getEnv(self);

    return {
      refreshToken: flow(function* () {
        try {
          const accessToken = yield auth.refreshToken();

          if (!accessToken) {
            throw new Error('refreshToken returned null.');
          }

          return accessToken;
        } catch (err) {
          logger.error('Error occurred while retrieving access token', err);

          throw err;
        }
      }),
      login: flow(function* ({ acquireSilently = false } = {}) {
        self.loginFailed = false;

        try {
          self.isFetching = true;
          const response = yield api.get('profile', {
            acquireSilently,
          });
          self.isFetching = false;

          if (response && response.status === 200) {
            self.user = response.data.data;
            self.isFetching = false;

            // @TODO: Add tracking/log events
            // logEvent(analyticsCategory.USER, 'Login', 'Success');
          } else if (!acquireSilently) {
            throw response.data;
          }
        } catch (err) {
          self.loginFailed = true;
          self.isFetching = false;

          // @TODO: Add tracking/log events
          // logEvent(analyticsCategory.USER, 'Login', 'Failed');
          logger.error('Error occurred during user login', err);
        }
      }),
      logout() {
        auth.logout();
      },
    };
  })
  .views((self) => ({
    get organisation() {
      return get(self, 'user.AccountName');
    },
    get contracts() {
      const contracts = get(self, 'user.claimsSet.claimData.contracts');

      if (!contracts) return [];

      return contracts.toJSON();
    },
    get contractOptions() {
      const contracts = get(self, 'contracts');

      return contracts.map((contract) => ({ label: contract.name, value: contract.contractId }));
    },
    contractsByPrivilege(privilege) {
      const contracts = get(self, 'contracts');

      return contracts.filter((contract) => {
        return contract.privilege.find(({ name }) => name === privilege);
      });
    },
    get emailAddress() {
      return get(self, 'user.EmailAddress');
    },
    get fullName() {
      return get(self, 'user.FullName');
    },
    get isSignedIn() {
      return !!self.user;
    },
    get contractId() {
      // Current assumption a user only has one contract
      return get(self, 'user.claimsSet.claimData.contracts[0].contractId');
    },
  }));

export { AuthStore };
