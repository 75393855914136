import { animated } from 'react-spring';
import styled from 'styled-components';

import { hideOnDesktop } from '../../../utils/responsive-styles';

const DRAWER_WIDTH = '90vw';

const Panel = styled(animated.div)`
  ${hideOnDesktop};
  width: ${DRAWER_WIDTH};
  overflow-y: scroll;
  height: 100vh;
  background: ${({ theme }) => theme.colors.greyTen};
  position: fixed;
  left: 0;
  scrollbar-width: none;
  top: 0;
  z-index: 4;

  &::-webkit-scrollbar {
    width: 0;
  }
`;

const InnerPanel = styled.div`
  padding: 0 ${({ theme }) => theme.space.xs};
`;

const Divider = styled.div`
  height: 1px;
  background: ${({ theme }) => theme.colors.white};
  opacity: 0.15;
`;

const TopPanel = styled.div`
  background: ${({ theme }) => theme.colors.greyNine};
  padding: 0 ${({ theme }) => theme.space.xs};
  height: ${({ theme }) => theme.global.headerHeight};
  display: flex;
  align-items: center;
`;

const CloseButton = styled.button`
  > svg {
    width: 33px;
    height: 33px;
  }
`;

const Overlay = styled.div`
  ${hideOnDesktop};
  width: 100vw;
  z-index: 3;
  opacity: 0.4;
  background: black;
  content: '';
  display: block;
  height: 100vh;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
`;

export { Panel, InnerPanel, Divider, TopPanel, CloseButton, Overlay };
