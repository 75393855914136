import { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Col, form, Row } from '../../ui-library';

const { Input, Label, useFormState } = form;

const faultCategoryOptions = [
  { label: 'Axle', value: 'Axle' },
  { label: 'Body', value: 'Body' },
  { label: 'Brakes', value: 'Brakes' },
  { label: 'Cabin', value: 'Cabin' },
  { label: 'Chassis', value: 'Chassis' },
  { label: 'Clutch', value: 'Clutch' },
  { label: 'Container Repair', value: 'Container Repair' },
  { label: 'Driveline', value: 'Driveline' },
  { label: 'Electrical', value: 'Electrical' },
  { label: 'Engine', value: 'Engine' },
  { label: 'Fridge', value: 'Fridge' },
  { label: 'Fuel System', value: 'Fuel' },
  { label: 'Gearbox', value: 'Gearbox' },
  { label: 'Hydraulics', value: 'Hydraulics' },
  { label: 'Miscel', value: 'Miscel' },
  { label: 'Security', value: 'Security' },
  { label: 'Steering', value: 'Steering' },
  { label: 'Suspension', value: 'Suspension' },
  { label: 'Tailgate', value: 'Tailgate' },
  { label: 'Tailshaft', value: 'Tailshaft' },
  { label: 'Turntable', value: 'Turntable' },
  { label: 'Tyres', value: 'Tyres' },
];

const priorityOptions = [
  { label: 'Priority 1 - Unsafe, Defective, Safety Critical and/or Not Compliant', value: 'UNSAFE' },
  { label: 'Priority 2 - Urgent; Operator believes equipment is safe and not dangerous', value: 'URGENT' },
  {
    label: 'Priority 3 - Non-Urgent/Cosmetic: e.g. paint peeling, bracket bent, scuffed mudguard',
    value: 'NON-URGENT',
  },
];

const IndividualDefect = ({ number, setValid }) => {
  const { isValid } = useFormState();

  useEffect(() => setValid(isValid), [isValid, setValid]);

  return (
    <>
      <StyledRow mb="3rem">
        <StyledCol>
          <Label htmlFor={`faultCategory-${number}`}>Category</Label>
          <Input id={`faultCategory-${number}`} type="select" options={faultCategoryOptions} name="faultCategory" />
        </StyledCol>
        <StyledCol>
          <Label htmlFor={`DRR_REPAIRCODE-${number}`}>Priority</Label>
          <Input id={`DRR_REPAIRCODE-${number}`} type="select" options={priorityOptions} name="DRR_REPAIRCODE" />
        </StyledCol>
      </StyledRow>
      <StyledRow mb="1rem">
        <StyledCol>
          <Label htmlFor={`faultDescription-${number}`}>Description</Label>
          <Input
            id={`faultDescription-${number}`}
            type="textarea"
            placeholder="Describe the defect in more detail"
            name="faultDescription"
          />
        </StyledCol>
      </StyledRow>
    </>
  );
};
IndividualDefect.propTypes = {
  number: PropTypes.number.isRequired,
  setValid: PropTypes.func.isRequired,
};

IndividualDefect.displayName = 'IndividualDefect';

const StyledCol = styled(Col).attrs(() => ({ width: 1 }))``;

const StyledRow = styled(Row).attrs(() => ({ width: 'auto' }))``;

export { IndividualDefect };
