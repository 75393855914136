import { get, isEmpty, noop, omit } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BookingFormRow } from '../booking-form-row';
import { Box, constants, Flex, form, H4, icons } from '../../ui-library';
import { CustomAddress } from './custom-address';
import { DynamicFormInput } from '../../dynamic-form-input';
import { LabelledInput } from '../../labelled-input';
import { LocationPicker } from './location-picker';
import { useBookingState } from '../../booking-context';
import { useRootStore } from '../../store-provider/lib';
import { useTemplateState } from '../../template-context';

const { Input, useFormState } = form;
const { InfoWithBackground, Messages } = icons;

const useCustomAddress = (newLocationField) => {
  const { setValue, watch } = useFormState();

  const { apiFields } = useTemplateState();

  let isCustomAddress = false;

  // determine if the current template has `newLocationField` set.
  if (get(apiFields, newLocationField, false)) {
    // we cannot return the result of watch if newLocationField is not set in the template,
    // as `watch(undefined)` would return the entire form state.
    isCustomAddress = watch(apiFields[newLocationField]);
  }

  const setCustomAddress = useCallback((value) => {
    setValue(apiFields[newLocationField], value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [isCustomAddress, setCustomAddress];
};

const AddressInformation = ({ isPickUp, newLocationField }) => {
  const { BookingStore } = useRootStore();

  const { errors, getValues } = useFormState();
  const { bookingData, addPostSaveHook } = useBookingState();

  const [isCustomAddress, setCustomAddress] = useCustomAddress(newLocationField);

  /* pickup vs pickUp here is intentional: LocationPicker uses "pickup" for API field lookups on booking data */
  /* while CustomAddress uses it to match template keys. This inconsistency is driven by the template... */
  const locationKey = isPickUp ? 'pickup' : 'delivery';
  const templateLocationKey = isPickUp ? 'pickUp' : 'delivery';

  const [selectedAddress, setSelectedAddress] = useState(() => {
    const location = get(bookingData, `${locationKey}Details.location`, {});

    // Ensure this location has at least one field with valid data.
    // Don't include co-ordinates or country in this check.
    const values = Object.values(omit(location, ['coordinates', 'country']));

    const validFields = values.filter((x) => !isEmpty(x));

    if (validFields.length > 0) return location;

    return null;
  });

  useEffect(() => {
    const saveLocation = async () => {
      const { [`${locationKey}Details`]: values } = getValues();
      const { location } = values;

      const {
        coordinates = null,
        locationName,
        locationType,
        address1,
        address2,
        postcode,
        suburb,
        state,
        country,
      } = location;

      if (locationName && address1 && postcode && suburb && state && country) {
        const { contractID, serviceID } = bookingData;

        const data = {
          contractID,
          serviceID,
          locationName,
          address1,
          address2,
          postcode,
          suburb,
          state,
          country,
        };

        if (coordinates) {
          const { latitude = null, longitude = null } = coordinates;

          // Convert lat / lng values to strings before sending them to Dynamics.
          // TODO: Linfox to determine why endpoints expect different variable types.
          data.coordinates = {
            latitude: latitude && latitude.toString(),
            longitude: longitude && longitude.toString(),
          };
        }

        if (locationType) {
          data.locationType = locationType;
        }

        const savedLocation = await BookingStore.saveLocation(data);

        if (savedLocation) {
          setCustomAddress(false);
          setSelectedAddress(location);
        }
      }
    };

    addPostSaveHook({ [templateLocationKey]: isCustomAddress ? saveLocation : noop });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCustomAddress]);

  const [error, setError] = useState(null);

  // Check to see if we have any validation errors on the `location` object.
  // This tells us we have an invalid address selected, and should display an error
  // if the user has not selected "Specify a custom address" (as the per-field validation errors
  // will not be visible in this case)
  const { [`${locationKey}Details`]: { location: locationErrors = null } = {} } = errors;

  if (locationErrors) {
    if (!error) {
      setError('Please select a valid address.');
    }
  } else if (error) {
    setError(null);
  }

  return (
    <>
      <DynamicFormInput
        name={newLocationField}
        render={(props) => (
          <BookingFormRow>
            <LabelledInput {...props} />
          </BookingFormRow>
        )}
      />
      <LocationPicker
        display={!isCustomAddress}
        error={error}
        isPickUp={isPickUp}
        locationKey={locationKey}
        selectedAddress={selectedAddress}
        setSelectedAddress={setSelectedAddress}
        templateLocationKey={templateLocationKey}
      />
      <CustomAddress display={!!isCustomAddress} locationKey={templateLocationKey} />
      <BookingFormRow
        isInputRow
        icon={
          <IconWrapper>
            <Messages />
          </IconWrapper>
        }
        iconHeight="16px"
        mb={4}
      >
        <Flex>
          <DynamicFormInput
            name={`${templateLocationKey}ContactName`}
            render={(props) => (
              <Box width={260} mr={3}>
                <LabelledInput {...props} />
              </Box>
            )}
          />
          <DynamicFormInput
            name={`${templateLocationKey}ContactPhone`}
            render={(props) => (
              <Box width={260}>
                <LabelledInput {...props} />
              </Box>
            )}
          />
        </Flex>
      </BookingFormRow>
      <BookingFormRow>
        <DynamicFormInput
          name={`${templateLocationKey}Reference`}
          render={(props) => (
            <Box width={260}>
              <LabelledInput {...props} />
            </Box>
          )}
        />
      </BookingFormRow>
      <DynamicFormInput
        name={`${templateLocationKey}Instructions`}
        render={({ label, name, ...props }) => (
          <BookingFormRow
            icon={<InfoWithBackground backgroundColor={constants.colors.successTen} />}
            iconHeight="22px"
            iconWidth="22px"
            iconColor={constants.colors.white}
          >
            <H4 as="label" htmlFor={name} color="successTen" mt={0} display="inline-block">
              {label}
            </H4>
            <Input name={name} {...props} />
          </BookingFormRow>
        )}
      />
    </>
  );
};

const IconWrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background-color: ${({ theme }) => theme.colors.greyTwo};
`;

AddressInformation.propTypes = {
  isPickUp: PropTypes.bool,
  newLocationField: PropTypes.oneOf(['newDeliveryLocation', 'newPickUpLocation']).isRequired,
};

AddressInformation.defaultProps = {
  isPickUp: false,
};

export { AddressInformation };
