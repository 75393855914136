import { PropTypes } from 'prop-types';
import { variant } from 'styled-system';
import styled from 'styled-components';

import { ALERT_TYPES, colors } from '../../constants';
import { Cross, CrossWithBackground, InfoWithBackground, TickWithBackground } from '../icons';
import { Grid } from '../grid';

const ALERT_ICONS = {
  [ALERT_TYPES.INFO]: <InfoWithBackground {...{ backgroundColor: colors.infoTen, fill: colors.white }} />,
  [ALERT_TYPES.ERROR]: <CrossWithBackground {...{ backgroundColor: colors.dangerSeven, fill: colors.white }} />,
  [ALERT_TYPES.SUCCESS]: <TickWithBackground {...{ backgroundColor: colors.successTen, fill: colors.white }} />,
  [ALERT_TYPES.GENERAL]: <InfoWithBackground {...{ backgroundColor: colors.greyNine, fill: colors.primaryFour }} />,
};

const wrapperVariant = variant({
  variants: {
    [ALERT_TYPES.INFO]: {
      backgroundColor: colors.infoOne,
      color: colors.infoTen,
      textDecorationColor: colors.infoTen,
    },
    [ALERT_TYPES.ERROR]: {
      backgroundColor: colors.dangerOne,
      color: colors.dangerSeven,
      textDecorationColor: colors.dangerSeven,
    },
    [ALERT_TYPES.SUCCESS]: {
      backgroundColor: colors.successOne,
      color: colors.successTen,
      textDecorationColor: colors.successTen,
    },
    [ALERT_TYPES.GENERAL]: {
      backgroundColor: colors.primaryFour,
      color: colors.greyNine,
      textDecorationColor: colors.greyNine,
      borderRadius: 'none',
    },
  },
});

const buttonVariant = variant({
  variants: {
    [ALERT_TYPES.INFO]: {
      fill: colors.infoTen,
    },
    [ALERT_TYPES.ERROR]: {
      fill: colors.dangerSeven,
    },
    [ALERT_TYPES.SUCCESS]: {
      fill: colors.successTen,
    },
    [ALERT_TYPES.GENERAL]: {
      fill: colors.greyNine,
    },
  },
});

const Alert = ({ type, children, onClose, ...props }) => (
  <AlertWrapper variant={type} {...props}>
    {ALERT_ICONS[type]}
    <span>{children}</span>
    {onClose && (
      <CrossWrapper type="button" title="close-alert" variant={type} onClick={onClose}>
        <Cross />
      </CrossWrapper>
    )}
  </AlertWrapper>
);

const AlertWrapper = styled(Grid)`
  ${wrapperVariant}

  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  font-size: ${({ theme }) => theme.fontSize.small};
  grid-auto-flow: column;
  grid-template-columns: max-content 1fr max-content;
  grid-column-gap: 1rem;
  padding: 1rem;
`;

const CrossWrapper = styled.button`
  margin-left: ${({ theme }) => theme.space.xl};

  > svg {
    ${buttonVariant}
    height: 20px;
    width: 20px;
  }
`;

Alert.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  onClose: PropTypes.func,
  type: PropTypes.oneOf(Object.values(ALERT_TYPES)).isRequired,
};

Alert.defaultProps = {
  onClose: null,
};

export { Alert };
