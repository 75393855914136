import styled, { css } from 'styled-components';

import { styledSystemProps } from './font-styles';

const labelStyles = css`
  font-family: ${({ theme }) => theme.global.baseFontFamily};
  font-size: ${({ theme }) => theme.fontSize.medium};
  line-height: ${({ theme }) => theme.lineHeight.body.large};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin: 0.5rem 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: ${({ theme }) => theme.fontSize.base};
    line-height: ${({ theme }) => theme.lineHeight.body.medium};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    font-size: ${({ theme }) => theme.fontSize.xSmall};
    line-height: ${({ theme }) => theme.lineHeight.body.small};
  }
`;

const Label = styled.p`
  ${labelStyles};
  ${styledSystemProps};
`;

export { Label, labelStyles };
