import { createContext, useContext } from 'react';

const MapContext = createContext();

const { Provider } = MapContext;

const useMapState = () => {
  const context = useContext(MapContext);

  if (context === undefined) {
    throw new Error('Component must be used within a MapContextProvider.');
  }

  return context || {};
};

export { Provider as MapContextProvider, useMapState };
