import { reject } from 'lodash';
import { useState } from 'react';

import { form } from '../../components/ui-library';

const { useFormState } = form;

const useItemSelect = (field) => {
  const { watch } = useFormState();

  const [activeItemIndex, setActiveItemIndex] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);

  const clearSelection = () => setSelectedItems([]);

  const value = watch(field) || [];

  const activeItem = value?.[activeItemIndex] || null;

  const handleAddItem = (event) => {
    return [...value, ...event[field]];
  };

  const handleRemoveItems = () => reject(value, (item, index) => selectedItems.includes(index));

  const handleSaveItem = (event) => {
    // `event[field]` contains a 1-item array of the new data
    const [updatedItem] = event[field];

    const updatedValues = [...value];

    updatedValues[activeItemIndex] = updatedItem;

    return updatedValues;
  };

  const handleSubmit = (event) => {
    if (activeItemIndex === null) {
      return handleAddItem(event);
    }

    return handleSaveItem(event);
  };

  const isAllSelected = selectedItems.length === value.length;

  const toggleAll = () => {
    if (!isAllSelected) {
      setSelectedItems(value.map((item, index) => index));
    } else {
      setSelectedItems([]);
    }
  };

  const toggleItem = (item) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(selectedItems.filter((cur) => cur !== item));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  return {
    activeItem,
    clearSelection,
    handleAddItem,
    handleRemoveItems,
    handleSubmit,
    isAllSelected,
    selectedItems,
    setActiveItemIndex,
    toggleAll,
    toggleItem,
  };
};

export { useItemSelect };
