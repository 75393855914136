import PropTypes from 'prop-types';

import { Breadcrumbs, Button, Dropdown, Flex } from '../ui-library';
import { HeaderBar } from '../header-bar';
import { useDetailContext } from '../detail-context';
import { useRootStore } from '../store-provider/lib';

const ActionBar = ({ handleOnDuplicate }) => {
  const { data, bookingID } = useDetailContext();

  const {
    BookingStore: { fetchTemplateFile, loadBookings },
  } = useRootStore();

  const breadCrumbTrail = {
    title: data.bookingNumber,
    ancestors: [
      {
        onClick: () => loadBookings(),
        title: 'All Bookings',
        url: '/bookings',
      },
    ],
  };

  const { labelData = [] } = data;

  const options = labelData.map(({ name, value }) => ({
    label: name,
    onClick: async () => {
      const url = await fetchTemplateFile({
        bookingID,
        templateID: value,
      });

      if (url) {
        const a = document.createElement('a');
        a.href = url;
        a.target = '_blank';
        a.click();
      }
    },
  }));

  return (
    <HeaderBar>
      <Breadcrumbs entry={breadCrumbTrail} />
      <Flex>
        {options.length > 0 && <Dropdown options={options} title="Download" isRightAligned />}
        <Button onClick={handleOnDuplicate} variant="secondary" ml={1}>
          Duplicate Booking
        </Button>
      </Flex>
    </HeaderBar>
  );
};

ActionBar.propTypes = {
  handleOnDuplicate: PropTypes.func.isRequired,
};

export { ActionBar };
