import PropTypes from 'prop-types';

const userPropTypes = PropTypes.shape({
  AADUserObjectID: PropTypes.string,
  AccountID: PropTypes.string,
  ContactID: PropTypes.string,
  EmailAddress: PropTypes.string,
  FullName: PropTypes.string,
  PhoneNumber: PropTypes.string,
  id: PropTypes.string,
  status: PropTypes.string,
});

export { userPropTypes };
