import { ifProp } from 'styled-tools';
import { useState } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import styled, { css } from 'styled-components';

import { borderStyle, focusStyle, sizeStyle } from '../../styles';
import { ClearIndicator } from '../clear-indicator';
import { DropdownIndicator } from '../dropdown-indicator';

const SelectDropdown = ({
  disabled,
  hasError,
  id,
  isFetching,
  isMulti,
  options,
  onClear,
  onChange,
  placeholder,
  selected,
  ...props
}) => {
  const [hasFocus, setFocus] = useState(false);

  const value = isMulti
    ? options.filter((option) => selected && selected.split(',').includes(option.value)) || []
    : options.find((option) => option.value === selected) || '';

  return (
    <StyledSelect
      components={{ ClearIndicator, DropdownIndicator, IndicatorSeparator: null }}
      hasError={hasError}
      hasFocus={hasFocus}
      inputId={id}
      isDisabled={disabled || isFetching}
      isMulti={isMulti}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      {...props}
      onChange={(event) => {
        if (!event) {
          onChange('');
          onClear();
        } else if (Array.isArray(event)) {
          // if multiple items are selected, save a comma-delimited string
          const selectedValues = event.map((item) => item.value);

          onChange(selectedValues.join());
        } else {
          onChange(event.value);
        }
      }}
      options={options}
      placeholder={isFetching ? 'Please wait...' : placeholder}
      value={value}
    />
  );
};

SelectDropdown.propTypes = {
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  id: PropTypes.string.isRequired,
  isFetching: PropTypes.bool,
  isMulti: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string.isRequired, value: PropTypes.string.isRequired })
  ).isRequired,
  onClear: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  selected: PropTypes.string,
};

SelectDropdown.defaultProps = {
  disabled: false,
  hasError: false,
  isFetching: false,
  isMulti: false,
  onClear: () => null,
  placeholder: 'Select...',
  selected: null,
};

const StyledSelect = styled(ReactSelect).attrs(() => ({ classNamePrefix: 'react-select' }))`
  .react-select__control {
    ${borderStyle};
    ${focusStyle}

    &:hover {
      border-color: ${({ theme }) => theme.colors.interactionFour};
      cursor: pointer;
    }
  }

  .react-select__menu {
    ${borderStyle};
  }

  .react-select__option {
    cursor: pointer;
  }

  .react-select__value-container {
    ${sizeStyle}
  }

  .react-select__placeholder {
    color: ${({ theme }) => theme.colors.greyFour};
  }

  ${ifProp(
    'hasError',
    css`
      .react-select__control {
        border-color: ${({ theme }) => theme.colors.dangerSeven};
      }
    `
  )}
`;

export { SelectDropdown };
