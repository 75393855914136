import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';

import { Breadcrumbs, Dropdown } from '../ui-library';
import { DeleteBookingModal } from './delete-booking-modal';
import { HeaderBar } from '../header-bar';
import { useBookingState } from '../booking-context';
import { useRootStore } from '../store-provider/lib';

const BookingDraftHeaderBar = () => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { bookingData, saveBooking } = useBookingState();
  const { BookingStore } = useRootStore();
  const history = useHistory();

  const bookingID = get(bookingData, 'bookingID');
  const bookingNumber = get(bookingData, 'bookingNumber');

  const breadCrumbTrail = {
    title: bookingNumber || 'New Booking',
    ancestors: [
      {
        onClick: () => BookingStore.loadBookings(),
        title: 'All Bookings',
        url: '/bookings',
      },
    ],
  };

  const handleOnDeleteBooking = async () => {
    await BookingStore.deleteBooking(bookingID);
    BookingStore.loadBookings();
    setIsDeleteModalOpen(false);
    history.push('/bookings');
  };

  const options = [
    {
      label: 'Save',
      onClick: saveBooking,
    },
    {
      label: 'Save & Exit',
      onClick: async () => {
        await saveBooking();
        BookingStore.loadBookings();
        history.push('/bookings');
      },
    },
    {
      label: 'Delete Booking',
      onClick: () => {
        setIsDeleteModalOpen(true);
      },
    },
  ];

  return (
    <>
      <HeaderBar>
        <Breadcrumbs entry={breadCrumbTrail} />
        {/* @TODO: designs of this button slightly differ, not imperative for MVP */}
        <Dropdown options={options} title="Draft" isRightAligned />
      </HeaderBar>
      <DeleteBookingModal
        onDeleteBooking={handleOnDeleteBooking}
        closeModal={() => setIsDeleteModalOpen(false)}
        isModalOpen={isDeleteModalOpen}
      />
    </>
  );
};

export { BookingDraftHeaderBar };
