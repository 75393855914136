import { trim } from 'lodash';
import { useHistory } from 'react-router-dom';

import { form } from '../ui-library';

const { Form, Input, SubmitButton } = form;

const AnonymousTrackAndTrace = () => {
  const history = useHistory();

  const onSubmit = (values) => {
    const shipment = trim(values.shipment);

    if (shipment) {
      history.push(`/track/${shipment}`);
      // logEvent(analyticsCategory.TRACK, 'Search:Anonymous', 'Submit');
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      <Input
        mt="2rem" // TODO: setting my on this element will break validation error text.
        id="shipment"
        type="text"
        name="shipment"
        placeholder="Enter Linfox Shipment ID"
        validation={{
          required: 'A Shipment ID is required.',
          maxLength: {
            value: 35,
            message: 'Shipment ID should not be longer than 35 characters.',
          },
        }}
      />
      <SubmitButton mt="2rem" width="100%">
        Track
      </SubmitButton>
    </Form>
  );
};

export { AnonymousTrackAndTrace };
