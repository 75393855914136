import { isEmpty } from 'lodash';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';

import { Attachment } from '../attachment';
import { attachmentPropType } from '../../prop-types';
import { formatDate } from '../../utils/format-date';

const getInitials = (string) => {
  return string
    .split(' ')
    .map((n) => n[0])
    .join('');
};

const Comment = ({ handleAttachmentDownload, name, date, children, fromLinfox, attachments }) => (
  <Wrapper>
    <Avatar fromLinfox={fromLinfox}>{getInitials(name)}</Avatar>
    <Name>
      {name}
      {date && <DateCreated>{formatDate(date)}</DateCreated>}
    </Name>
    <CommentContent fromLinfox={fromLinfox}>
      <Body>{children}</Body>
    </CommentContent>
    {!isEmpty(attachments) && (
      <AttachmentList data-testid="attachment-group">
        <h5>
          {attachments.length} Attachment{attachments.length > 1 && 's'}
        </h5>
        {attachments.map((attachment) => (
          <Attachment
            key={attachment.documentID}
            handleAttachmentDownload={handleAttachmentDownload}
            {...{ attachment }}
          />
        ))}
      </AttachmentList>
    )}
  </Wrapper>
);

const Wrapper = styled.div`
  font-size: 14px;
  line-height: ${({ theme }) => theme.global.baseLineHeight};
  padding: ${({ theme }) => `${theme.space.xl} 0 0 ${theme.space.xl}`};
  position: relative;

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
`;

const CommentContent = styled.div`
  background-color: ${({ fromLinfox, theme }) => (fromLinfox ? theme.colors.interactionHalf : theme.colors.greyTwo)};
  border-radius: 4px;
  padding: ${({ theme }) => `${theme.space.xs}`};
  margin-top: ${({ theme }) => `${theme.space.xxs}`};
  margin-right: ${({ theme }) => `${theme.space.xl}`};
  width: max-content;
  max-width: 100%;
`;

const Name = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

const Body = styled.div`
  margin: 0;
  color: ${({ theme }) => theme.colors.greySeven};
`;

const Avatar = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 18px;
  font-size: 12px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-transform: uppercase;
  background-color: ${({ fromLinfox, theme }) => (fromLinfox ? '#f0f9fe' : theme.colors.greyTwo)};
  color: ${({ theme }) => theme.colors.greyNine};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: ${({ theme }) => theme.space.xl};
  left: 0;
  margin-top: -9px;
`;

const DateCreated = styled.span`
  font-size: 12px;
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  color: ${({ theme }) => theme.colors.greyFour};
  margin-left: ${({ theme }) => theme.space.xs};
`;

const AttachmentList = styled.div`
  max-width: max-content;

  h5 {
    color: ${({ theme }) => theme.colors.greyFive};
    margin-top: ${({ theme }) => theme.space.xs};
    margin-bottom: ${({ theme }) => theme.space.xs};
  }
`;

Comment.propTypes = {
  attachments: PropTypes.arrayOf(attachmentPropType),
  children: PropTypes.node,
  date: PropTypes.string,
  fromLinfox: PropTypes.bool,
  handleAttachmentDownload: PropTypes.func.isRequired,
  name: PropTypes.string,
};

Comment.defaultProps = {
  name: 'No name',
  date: '',
  children: null,
  fromLinfox: false,
  attachments: [],
};

export { Comment };
