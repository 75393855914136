import { useState } from 'react';

import { useTemplateState } from '../../../template-context';
import { useWebsocket } from '../../../../hooks/use-websocket';

const useCustomAddress = () => {
  const [locations, setLocations] = useState([]);

  const { normalisedFields, templateData = {} } = useTemplateState();

  const { contractID, serviceID } = templateData;

  const { connected, sendMessage } = useWebsocket({
    url: process.env.REACT_APP_WS_URL,
    onmessage: ({ data }) => {
      const response = JSON.parse(data);

      if (response.message) {
        const {
          message: { data: responseLocations = [] },
        } = response;

        setLocations(responseLocations);
      }
    },
  });

  const findLocation = (keyword, isPickUp) => {
    const locationTypeField = isPickUp ? normalisedFields.pickUpLocationType : normalisedFields.deliveryLocationType;

    const { default: locationType = null } = locationTypeField || {};

    const params = { keyword, contractID, serviceID };

    if (locationType) {
      params.locationType = locationType;
    }

    sendMessage(params);
  };

  return { connected, findLocation, locations, setLocations };
};

export { useCustomAddress };
