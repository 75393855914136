import { Route, Switch } from 'react-router-dom';

import { CaseCreate } from '../../pages/case-create';
import { CaseDetail } from '../../pages/case-detail';
import { CaseListingTemplateProvider } from '../../contexts/case-listing-template';
import { Cases } from '../../pages/cases';
import { FilterProvider } from '../../contexts/filter';
import { useCaseListingTemplate } from '../../hooks/use-case-listing-template';

const defaultFilters = {
  caseType: null,
  caseStatus: null,
  casePriority: null,
  contractID: null,
  deliveryState: null,
  limit: 25,
  location: null,
  keyword: null,
  createdOnFrom: null,
  createdOnTo: null,
  start: 0,
  sort: null,
};

const CaseRouter = () => {
  const { columns } = useCaseListingTemplate();

  return (
    <CaseListingTemplateProvider columns={columns}>
      <FilterProvider defaultFilters={defaultFilters}>
        <Switch>
          <Route exact path="/cases" component={Cases} />
          <Route exact path="/cases/new/:contractID" component={CaseCreate} />
          <Route exact path="/cases/:caseID" component={CaseDetail} />
        </Switch>
      </FilterProvider>
    </CaseListingTemplateProvider>
  );
};

export { CaseRouter };
