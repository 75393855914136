// import { grid } from 'styled-system';
import styled from 'styled-components';

import { Box } from './box';

const Grid = styled(Box)`
  display: grid;
`;

export { Grid };
