import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button, Flex, icons } from '../../ui-library';
import { ContractPermissions } from '../../contract-permissions';
import { useRootStore } from '../../store-provider/lib';

const { LoadingIndicator } = icons;

const Permissions = ({ nextPage }) => {
  const { UserStore } = useRootStore();

  const { selected } = UserStore;

  const { isFetching } = selected;

  return (
    <>
      <Flex flexDirection="column" mt="1rem" justifyContent="flex-end">
        {isFetching && <LoadingIndicator />}
        {!isFetching && (
          <>
            <ContractPermissions />
            <ButtonWrapper justifyContent="flex-end">
              <Button minWidth={160} onClick={nextPage}>
                Complete
              </Button>
            </ButtonWrapper>
          </>
        )}
      </Flex>
    </>
  );
};

Permissions.propTypes = {
  nextPage: PropTypes.func.isRequired,
};

const ButtonWrapper = styled(Flex)`
  margin-top: ${({ theme }) => theme.space.sm};
`;

const ObservedPermissions = observer(Permissions);

export { ObservedPermissions as Permissions };
