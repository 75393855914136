import { down, up } from 'styled-breakpoints';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import { AnonymousTrackAndTrace } from '../../components/anonymous-track-and-trace';
import { ContactDetails, FeatureImage, Flex, H1, usePageTitle } from '../../components/ui-library';
import { QuickLinks } from '../../components/quick-links';
import { useBranding } from '../../contexts/branding';
import { useFeatureFlag } from '../../hooks/use-feature-flag';
import { useRootStore } from '../../components/store-provider/lib';

const Home = () => {
  const branding = useBranding();
  const portalName =
    branding === 'bevChain' ? process.env.REACT_APP_PORTAL_NAME_BEVCHAIN : process.env.REACT_APP_PORTAL_NAME;
  usePageTitle(portalName);
  const portalImage =
    branding === 'bevChain' ? process.env.REACT_APP_HERO_IMAGE_BEVCHAIN : process.env.REACT_APP_HERO_IMAGE;

  const {
    AuthStore: { fullName },
  } = useRootStore();

  return (
    <Wrapper flex={1} justifyContent="center" flexDirection={{ xs: 'column', md: 'row' }}>
      <ImageColumn>
        <FeatureImage src={`/assets/${portalImage}`} />
      </ImageColumn>
      <Flex flex={1}>
        <WelcomeColumn flexDirection="column" justifyContent="space-between" mx="auto">
          <div>
            <H1>{fullName ? `Welcome ${fullName}` : portalName}</H1>
            {useFeatureFlag('TRACK_AND_TRACE') && branding === 'linfox' && <AnonymousTrackAndTrace />}
            <QuickLinks />
          </div>
          <ContactDetails />
        </WelcomeColumn>
      </Flex>
    </Wrapper>
  );
};

const Wrapper = styled(Flex)`
  min-height: calc(100vh - ${({ theme }) => theme.global.headerHeight});
  max-width: ${({ theme }) => theme.global.pageMaxWidth};
`;

const ImageColumn = styled.div`
  flex: 1;
  height: inherit;
  position: relative;

  ${down('md')} {
    min-height: 15vh;
  }
`;

const WelcomeColumn = styled(Flex)`
  padding: ${({ theme }) => theme.space.xl} ${({ theme }) => theme.space.sm};

  ${up('md')} {
    width: 32rem;
  }
`;

const ObservedHome = observer(Home);
export { ObservedHome as Home };
