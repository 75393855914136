import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import {
  CancelledCircle,
  Circle,
  ColumnWrapper,
  DateWrapper,
  DescWrapper,
  EtaWrapper,
  InPastCircle,
  Line,
  MilestoneInnerWrapper,
  MilestoneWrapper,
  StatusWrapper,
  StyledDownloadIcon,
  SubSection,
} from '../styles';
import { deliveryStatuses } from '../../../constants/statuses';
import { formatDateEta } from '../../../utils/format-date';
import { icons, ShowOn } from '../../ui-library';
import { PodDownloadLink } from '../../pod-download-link';
import { ShowMapComponent } from '../../bing-map';
import { useFeatureFlag } from '../../../hooks/use-feature-flag';
import { useMapState } from '../../bing-map/map-context';

const { Cross, Tick, Truck } = icons;

const buildDateComponent = (actualDate, estimatedRangeStartDate, estimatedRangeEndDate, podID, isLastMile) => {
  const dateString = formatDateEta(estimatedRangeStartDate, estimatedRangeEndDate, actualDate);

  if (isLastMile) {
    return <ShowMapComponent showMap title="Show on Map" />;
  }

  return (
    <DateWrapper>
      {dateString}
      {!isEmpty(podID) && (
        <PodDownloadLink podID={podID} variant="detail">
          <StyledDownloadIcon />
          POD
        </PodDownloadLink>
      )}
    </DateWrapper>
  );
};

const buildIconCircle = (isCurrentMilestone, title, isCancelledMilestone, milestoneIsInFuture) => {
  let IconComponent = Tick;
  let CircleComponent = milestoneIsInFuture ? Circle : InPastCircle;

  if (title === deliveryStatuses.inTransit.key && isCurrentMilestone) {
    IconComponent = Truck;
  }

  if (isCancelledMilestone) {
    IconComponent = Cross;
    CircleComponent = CancelledCircle;
  }

  return (
    <CircleComponent>
      <IconComponent />
    </CircleComponent>
  );
};

const buildTitle = (isCurrentMilestone, title) => {
  if (title === deliveryStatuses.lastMile.key && isCurrentMilestone) {
    return `${deliveryStatuses.inTransit.label} - ${title}`;
  }

  return title;
};

const buildDescMobile = (isCurrentMilestone, title, eta) => {
  let desc = '';

  if (title === deliveryStatuses.inTransit.key && isCurrentMilestone) {
    desc = 'Live ETA available once driver is en route';
  }
  if (title === deliveryStatuses.lastMile.key && isCurrentMilestone) {
    desc = <EtaWrapper>ETA {eta}</EtaWrapper>;
  }
  return desc;
};

const ShipmentMilestone = ({
  title,
  estimatedStartDate,
  estimatedEndDate,
  actualDate,
  index,
  currentMilestoneIndex,
  isCancelled,
  isLastMile,
  isInTransit,
  setGap,
  podID,
}) => {
  const { eta } = useMapState();
  const isCancelledMilestone = title === deliveryStatuses.cancelled.key;
  const isCurrentMilestone = currentMilestoneIndex === index && !isCancelled;
  const milestoneIsInFuture = currentMilestoneIndex < index;

  const enableLastMile = useFeatureFlag('LAST_MILE');

  const hideMilestoneDueToCancelledShipment = isCancelled && milestoneIsInFuture;
  const iconCircle = buildIconCircle(isCurrentMilestone, title, isCancelledMilestone, milestoneIsInFuture);
  const titleLine = buildTitle(isCurrentMilestone, title);
  const descriptionLine = buildDescMobile(isCurrentMilestone, title, eta);
  const showLine = index !== 0;

  const dateComponent = buildDateComponent(
    actualDate,
    estimatedStartDate,
    estimatedEndDate,
    podID,
    isLastMile && enableLastMile
  );

  const milestoneBody = (
    <>
      <ShowOn screenSize="mobile" height="100%">
        <MilestoneInnerWrapper
          isCurrentMilestone={isCurrentMilestone}
          data-testid="shipment-milestone-mobile"
          {...{ isInTransit, isLastMile, index }}
        >
          <StatusWrapper isCurrentMilestone={isCurrentMilestone}>{iconCircle}</StatusWrapper>
          <ColumnWrapper>
            <SubSection>{titleLine}</SubSection>
            {(isInTransit || isLastMile) && <DescWrapper>{descriptionLine}</DescWrapper>}
            <SubSection>{dateComponent}</SubSection>
          </ColumnWrapper>
        </MilestoneInnerWrapper>
      </ShowOn>
      <ShowOn screenSize="desktop" height="100%">
        <MilestoneInnerWrapper isCurrentMilestone={isCurrentMilestone} data-testid="shipment-milestone">
          <SubSection>{titleLine}</SubSection>
          <SubSection>
            <StatusWrapper isCurrentMilestone={isCurrentMilestone}>
              {(isLastMile || isInTransit) && enableLastMile ? descriptionLine : iconCircle}
            </StatusWrapper>
          </SubSection>
          <SubSection>{dateComponent}</SubSection>
        </MilestoneInnerWrapper>
      </ShowOn>
    </>
  );

  return (
    <>
      <MilestoneWrapper {...{ index, isCurrentMilestone, hideMilestoneDueToCancelledShipment, setGap }}>
        {milestoneBody}
      </MilestoneWrapper>
      {showLine && (
        <Line
          {...{
            index,
            setGap,
            milestoneIsInFuture,
            showCancelledLine: hideMilestoneDueToCancelledShipment || isCancelledMilestone,
          }}
        />
      )}
    </>
  );
};

ShipmentMilestone.propTypes = {
  title: PropTypes.string.isRequired,
  estimatedStartDate: PropTypes.string,
  estimatedEndDate: PropTypes.string,
  actualDate: PropTypes.string,
  index: PropTypes.number.isRequired,
  currentMilestoneIndex: PropTypes.number.isRequired,
  isCancelled: PropTypes.bool,
  isLastMile: PropTypes.bool,
  isInTransit: PropTypes.bool,
  isInDelivery: PropTypes.bool,
  setGap: PropTypes.string,
  podID: PropTypes.string,
};

ShipmentMilestone.defaultProps = {
  estimatedStartDate: null,
  estimatedEndDate: null,
  actualDate: null,
  isCancelled: false,
  isLastMile: false,
  isInTransit: false,
  isInDelivery: false,
  setGap: null,
  podID: null,
};

export { ShipmentMilestone };
