import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { Filter, FilterBar, icons } from '../ui-library';
import { useRootStore } from '../store-provider/lib';

const { Status, Location, Users } = icons;

const BookingFilterBar = ({ showModal }) => {
  const { BookingStore } = useRootStore();

  const { activeParameters, keyword, setParameters, clearFilters, isFiltersActive } = BookingStore;

  const buttonProps = {
    children: 'New Booking',
    onClick: showModal,
  };

  const searchProps = {
    keyword,
    placeholder: 'Search bookings by Booking Number',
    onSubmit: setParameters,
  };

  return (
    <FilterBar
      buttonProps={buttonProps}
      clearFilters={clearFilters}
      searchProps={searchProps}
      isFiltersActive={isFiltersActive}
    >
      <Filter
        id="location"
        type="input"
        defaultChipLabel="All Locations"
        chipIcon={<Location />}
        data={{ placeholder: 'Enter Location' }}
        onChange={({ location }) => setParameters({ location })}
        defaultValues={{ location: activeParameters.location }}
      />
      <Filter
        id="bookingStatus"
        type="radio"
        defaultChipLabel="Every Status"
        chipIcon={<Status />}
        data={{
          options: [
            { key: 'Draft', label: 'Draft' },
            { key: 'Submitted', label: 'Submitted' },
            { key: 'Accepted', label: 'Accepted' },
            { key: 'Cancelled', label: 'Cancelled' },
          ],
        }}
        onChange={({ bookingStatus }) => {
          if (bookingStatus === 'Every Status') {
            setParameters({ bookingStatus: '' });
          } else {
            setParameters({ bookingStatus });
          }
        }}
        resetKey="Every Status"
        defaultValues={{ bookingStatus: activeParameters.bookingStatus }}
      />
      <Filter
        id="createdBy"
        type="input"
        defaultChipLabel="Created By"
        chipIcon={<Users />}
        data={{ placeholder: 'Enter Account Name' }}
        onChange={({ createdBy }) => setParameters({ createdBy })}
        defaultValues={{ createdBy: activeParameters.createdBy }}
      />
    </FilterBar>
  );
};

BookingFilterBar.propTypes = {
  showModal: PropTypes.func,
};

BookingFilterBar.defaultProps = {
  showModal: () => null,
};

const ObservedBookingFilterBar = observer(BookingFilterBar);
export { ObservedBookingFilterBar as BookingFilterBar };
