import { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { LoadingIndicator } from '../icons';

const Toggle = ({ options, selectedKey, onChange, isFetching }) => (
  <Switch>
    {options.map(({ key, label, count, icon }) => (
      <OptionButton
        aria-label="button"
        key={key}
        onClick={() => {
          onChange(key);
        }}
        onKeyDown={(event) => {
          if (event.key === ' ') {
            event.preventDefault();
            onChange(key);
          }
        }}
      >
        <OptionRadio
          tabIndex="-1"
          checked={key === selectedKey}
          onChange={() => {}}
          name="filter-toggle"
          id={`filter-toggle-${key}`}
          type="radio"
        />
        <OptionLabel htmlFor={`filter-toggle-${key}`} tabIndex="0">
          {icon && <IconWrapper>{icon}</IconWrapper>}
          {label}
          {/* TODO: refactor this key !== Closed business, as Toggle shouldnt be coupled to cases */}
          {isFetching && key !== 'Closed' && <CountLoadingIndicator size="12px" />}
          {!isFetching && key !== 'Closed' && <Count>{count}</Count>}
        </OptionLabel>
      </OptionButton>
    ))}
  </Switch>
);

const Switch = styled.div`
  display: flex;
  line-height: 2rem;
  font-size: 12px;
  z-index: 0;
`;

const OptionButton = styled.div`
  position: relative;
  margin-left: -1px;

  :first-child {
    margin-left: 0;
  }

  :first-child label {
    border-radius: 1rem 0 0 1rem;
  }

  :last-child label {
    border-radius: 0 1rem 1rem 0;
  }
`;

const IconWrapper = styled.span`
  display: flex;
  margin-right: 5px;
`;

const OptionLabel = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  border: solid 1px ${({ theme }) => theme.colors.greyThree};
  color: ${({ theme }) => theme.colors.greyEight};
  cursor: pointer;
  padding: 0 ${({ theme }) => theme.space.xs};
  z-index: 1;
  user-select: none;
  white-space: nowrap;

  :focus {
    z-index: 5;
    border-color: ${({ theme }) => theme.colors.greyFour};
  }

  :hover {
    z-index: 5;
    border-color: ${({ theme }) => theme.colors.greyFive};
  }
`;

const OptionRadio = styled.input`
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;

  :checked + label {
    background: ${({ theme }) => theme.colors.primaryFour};

    :not(:focus) {
      border-color: ${({ theme }) => theme.colors.primaryFour};
    }

    :hover {
      z-index: 5;
      border-color: ${({ theme }) => theme.colors.greyFive};
    }
  }
`;

const Count = styled.span`
  font-weight: 600;
  margin-left: ${({ theme }) => theme.space.xxs};
`;

const CountLoadingIndicator = styled(Count).attrs(() => ({ as: LoadingIndicator }))``;

Toggle.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedKey: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
};

Toggle.defaultProps = {
  isFetching: false,
};

const MemoedToggle = memo(Toggle);
export { MemoedToggle as Toggle };
