import { useContext } from 'react';
import PropTypes from 'prop-types';

import { FilterContext } from '../filter-provider';
import { TextButton } from '../../text-button';

const ClearFilterButton = ({ isFiltersActive, clearFilters, children }) => {
  const { setIsInactiveFilters } = useContext(FilterContext);

  if (!isFiltersActive) {
    return null;
  }

  const onClick = () => {
    setIsInactiveFilters(true);
    clearFilters();
  };

  return (
    <TextButton variant="secondary" onClick={onClick}>
      {children}
    </TextButton>
  );
};

ClearFilterButton.propTypes = {
  children: PropTypes.node.isRequired,
  clearFilters: PropTypes.func.isRequired,
  isFiltersActive: PropTypes.bool.isRequired,
};

export { ClearFilterButton };
