import { colors } from './colors';

const globalStyles = {
  alternativeFontFamily: 'Brandon Grotesque',
  baseFontColor: colors.greyTen,
  baseFontColorInverted: colors.greyThree,
  baseFontFamily: 'Arial, sans-serif',
  brandFontFamily: 'Arial, sans-serif',
  baseFontSize: '16px',
  baseLineHeight: '1.44',
  backgroundColor: '#fafbfb',
  borderRadius: '4px',
  boxShadowDark: '0 3px 5px 2px rgba(76,76,76, 0.04)}',
  boxShadowLight: '0 5px 5px 3px rgba(43, 43, 43, 0.04)',
  boxShadowMiniCartFooter: '2px 0 6px 1px rgba(0, 0, 0, 0.2)',
  buttonHeight: '49px',
  buttonHeightMobile: '44px',
  detailsMaxWidth: '60rem',
  wideDetailsMaxWidth: '80rem',
  easing: 'cubic-bezier(0.165, 0.84, 0.44, 1)',
  flyoutWidth: '584px',
  formMaxWidth: 600,
  headerHeight: '72px',
  filterHeight: '60px',
  headingFontFamily: 'Brandon Grotesque, sans-serif',
  letterSpacing: '0.0125rem',
  logoWidth: '103px',
  overlayLight: 'rgba(33, 33, 33, 0.75)',
  overlayDark: 'rgba(255, 255, 255, 0.75)',
  pageMaxWidth: '105rem',
  tapHighlightColor: 'rgba(255, 255, 255, 0)',
  transitionSpeed: '260ms',
  takeoverSlideSpeedSeconds: 0.4,
};

export { globalStyles };
