import { createGlobalStyle } from 'styled-components';

import { globalStyles } from '../../constants';

const TypographyStyles = createGlobalStyle`
  html {
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: ${globalStyles.baseFontFamily};
    color: ${globalStyles.baseFontColor};
    font-size: ${globalStyles.baseFontSize};

    i,
    em {
      font-style: italic;
    }

    a {
      color: inherit;

      &[href*="mailto:"] {
        word-wrap: break-word;
      }
    }
  }
`;

export { TypographyStyles };
