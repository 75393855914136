import { createContext, useContext } from 'react';

const DetailContext = createContext();

const useDetailContext = () => {
  const context = useContext(DetailContext);

  if (context === undefined) {
    throw new Error('Component must be used within a DetailProvider.');
  }

  return context;
};

const { Provider } = DetailContext;

export { useDetailContext, Provider as DetailProvider };
