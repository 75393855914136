/**
 * Single source of truth for spacing, in a format that is required
 * by Styled System but also in named form for wider usage
 *
 * You can use it directly...
 *
 * import { space } from '../../styles/vars';
 *
 * const Container = styled.div`
 *  margin-top: ${space.xl};
 * `;
 *
 * Or via the theme
 *
 * const Container = styled.div`
 *  margin-top: ${({ theme }) => theme.space.xl};
 * `;
 *
 */

const space = [0, '0.5rem', '1rem', '1.5rem', '2rem', '2.5rem', '3rem', '4rem'];

/**
 * Aliases for space, we need to keep the above structure
 * as well for Styled System's array props
 */
/* eslint-disable prefer-destructuring */

space.x0 = space[1]; // .5rem
space.x1 = space[2]; // 1rem
space.x01 = space[3]; // 1.5rem
space.x2 = space[4]; // 2rem
space.x02 = space[5]; // 2.5rem
space.x3 = space[6]; // 3rem
space.x4 = space[7]; // 4rem

space.xxs = space[1]; // .5rem
space.xs = space[2]; // 1rem
space.sm = space[3]; // 1.5rem
space.md = space[4]; // 2rem
space.lg = space[5]; // 2.5rem
space.xl = space[6]; // 3rem
space.xxl = space[7]; // 4rem
/* eslint-enable */

export { space };
