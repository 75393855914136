import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button } from '../../button';
import { ClearFilterButton } from '../clear-filter-button';
import { FilterBarWrapper } from '../filter-bar-wrapper';
import { FilterProvider } from '../filter-provider';
import { mobileOnly } from '../../../utils/responsive-styles';
import { Search } from '../../search';

const FilterBar = ({ isFiltersActive, clearFilters, searchProps, buttonProps, children }) => {
  const { keyword } = searchProps;

  // Hide filters if a keyword is currently set (eg: a search is active)
  const [hideFilters, setHideFilters] = useState(!!keyword);

  return (
    <FilterProvider hideFilters={hideFilters}>
      <FilterBarWrapper>
        <Search {...searchProps} setHideFilters={setHideFilters} />
        {!hideFilters && (
          <>
            <FilterContainer>
              <Divider />
              {children}
              <ClearFilterButton isFiltersActive={isFiltersActive} clearFilters={clearFilters}>
                Clear Filters
              </ClearFilterButton>
            </FilterContainer>
            {buttonProps && <Button ml="auto" {...buttonProps} />}
          </>
        )}
      </FilterBarWrapper>
    </FilterProvider>
  );
};
const FilterContainer = styled.div`
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  grid-gap: 0.5rem;
  grid-template-columns: max-content;
  justify-content: start;
  width: 100%;

  ${mobileOnly} {
    margin: 0px -1rem -0.5rem;
    padding: 0 1rem;
    overflow-x: auto;
    width: auto;
  }
`;

const Divider = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    border-left: 1px solid ${({ theme }) => theme.colors.greyThree};
    height: ${({ theme }) => theme.space.sm};
  }
`;

FilterBar.propTypes = {
  buttonProps: PropTypes.shape(Button.propTypes),
  children: PropTypes.node.isRequired,
  clearFilters: PropTypes.func.isRequired,
  searchProps: PropTypes.shape({
    keyword: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
  }),
  isFiltersActive: PropTypes.bool,
};

FilterBar.defaultProps = {
  buttonProps: undefined,
  isFiltersActive: false,
  searchProps: {},
};

export { FilterBar };
