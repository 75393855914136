import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

import { CheckboxWrapper } from './checkbox-wrapper';
import { StyledInput } from '../styles';
import { useFormState } from '../..';

const Checkbox = ({ children, disabled, indeterminate, name, size, validation, alignItems, ...props }) => {
  const { control, watch } = useFormState();

  const id = `checkbox-${name}`;

  const checked = watch(name);

  return (
    <Controller
      control={control}
      defaultValue={!!checked}
      name={name}
      rules={validation}
      render={({ onBlur, onChange, value }) => (
        <CheckboxWrapper
          value={!!value}
          inputId={id}
          label={children}
          alignItems={alignItems}
          onChange={(event) => {
            onBlur();
            onChange(event);
          }}
          size={size}
        >
          <StyledInput {...props} disabled={disabled} id={id} name={name} defaultChecked={!!value} type="checkbox" />
        </CheckboxWrapper>
      )}
    />
  );
};

Checkbox.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  indeterminate: PropTypes.bool,
  alignItems: PropTypes.oneOf(['flex-start', 'center']),
  name: PropTypes.string.isRequired,
  validation: PropTypes.shape(),
  size: PropTypes.oneOf(['sm', 'md']),
};

Checkbox.defaultProps = {
  children: null,
  disabled: false,
  indeterminate: false,
  validation: null,
  alignItems: 'center',
  size: 'md',
};

Checkbox.displayName = 'Checkbox';

export { Checkbox, CheckboxWrapper as UncontrolledCheckbox };
