import { createGlobalStyle } from 'styled-components';
import { forEach, toPairs } from 'lodash';

import { breakpoints, rem } from '../../constants';

const createSpacing = () => {
  let rems = ``;

  forEach(toPairs(rem), ([breakpoint, size], index) => {
    if (index === 0) {
      rems += `
      font-size: ${size};
    `;
    } else {
      rems += `
      @media (min-width: ${breakpoints[breakpoint]}) {
        font-size: ${size};
      }
    `;
    }
  });

  return `
    html {
      ${rems}
    }
  }`;
};

const SpacingStyles = createGlobalStyle`
  ${createSpacing()};
`;

export { SpacingStyles };
